import { Gate } from '../../common/interfaces/gate.interface';
import { AccountContext } from './common/unified-workflow-context.interface';
import { verifyExistingUser } from './services';
import { Step } from './steps';
import ApplicationTypeModel from './Steps/ApplicationType/Model';
import CheckUserCredentialsModel from './Steps/CheckUserCredentials/Model';
import CoApplicantModel from './Steps/CoApplicant/Model';
import ExitReturningUserToSignInController from './Steps/ExitReturningUserToSignIn/Controller';
import ExitToDashboard from './Steps/ExitToDashboard/Controller';
import ExitToOffersController from './Steps/ExitToOffers/Controller';
import ExitToSignInController from './Steps/ExitToSignIn/Controller';
import PersonalDetailsModel from './Steps/PersonalDetails/Model';
import PillowModel from './Steps/Pillow/Model';
import PinVerifyController from './Steps/PinVerify/Controller';
import PinVerifyModel from './Steps/PinVerify/Model';
import PrimaryGoalModel from './Steps/PrimaryGoal/Model';
import PurchaseTypeModel from './Steps/PurchaseType/Model';
import ReviewStepController from './Steps/Review/Controller';
import ReviewModel from './Steps/Review/Model';
import ReviewApplicantModel from './Steps/ReviewApplicant/Model';
import ReviewCoApplicantController from './Steps/ReviewCoApplicant/Controller';
import ReviewCoApplicantModel from './Steps/ReviewCoApplicant/Model';
import ReviewCoApplicantHousingInfoModel from './Steps/ReviewCoApplicantHousingInfo/Model';
import ReviewCoApplicantPersonalInfoModel from './Steps/ReviewCoApplicantPersonalInfo/Model';
import ReviewHousingModel from './Steps/ReviewHousing/Model';
import VehicleConfirmController from './Steps/VehicleConfirm/Controller';
import VehicleConfirmModel from './Steps/VehicleConfirm/Model';
import VehicleIdentityModel from './Steps/VehicleIdentity/Model';
import VehicleManualFindByPlateController from './Steps/VehicleManualFindByPlate/Controller';
import FindVehicleByLicensePlateModel from './Steps/VehicleManualFindByPlate/Model';
import VehicleManualFindByVinController from './Steps/VehicleManualFindByVin/Controller';
import FindVehicleByVinModel from './Steps/VehicleManualFindByVin/Model';
import VehicleManualMakeSelectionController from './Steps/VehicleManualMakeSelection/Controller';
import SelectVehicleMakeModel from './Steps/VehicleManualMakeSelection/Model';
import VehicleManualModelSelectionController from './Steps/VehicleManualModelSelection/Controller';
import SelectVehicleModelModel from './Steps/VehicleManualModelSelection/Model';
import SelectVehicleYearModel from './Steps/VehicleManualYearSelection/Model';
import WelcomeModel from './Steps/Welcome/Model';
import defaultConfiguration, { defaultServiceOptions } from './XState/Model';
import { ContextValidators } from './XState/utils';

export const SIGN_UP_GATE: Gate<AccountContext> = {
  name: 'unified-default-signup',
  initial: Step.Pillow,
  showRoutes: true,
  cacheData: true,
  serviceOptions: {
    ...defaultServiceOptions,
    services: (api: any) => ({
      verifyExistingUser: (
        ctx: AccountContext,
        { data }: { data: Partial<AccountContext> },
      ) => verifyExistingUser(data, api),
    }),
  },
  steps: [
    {
      name: Step.Pillow,
      state: (defaultConfiguration.states as any)[Step.Pillow],
      model: PillowModel,
      path: '',
    },
    {
      name: Step.Welcome,
      state: (defaultConfiguration.states as any)[Step.Welcome],
      model: WelcomeModel,
      path: 'welcome',
    },
    {
      name: Step.ApplicationType,
      state: (defaultConfiguration.states as any)[Step.ApplicationType],
      model: ApplicationTypeModel,
      path: 'application-type',
      stepValidators: ContextValidators[Step.ApplicationType],
    },
    {
      name: Step.PurchaseType,
      state: (defaultConfiguration.states as any)[Step.PurchaseType],
      model: PurchaseTypeModel,
      path: 'purchase-type',
      stepValidators: ContextValidators[Step.PurchaseType],
    },
    {
      name: Step.PrimaryGoal,
      state: (defaultConfiguration.states as any)[Step.PrimaryGoal],
      model: PrimaryGoalModel,
      path: 'primary-goal',
      stepValidators: ContextValidators[Step.PrimaryGoal],
      withDynamicConfiguration: true,
    },
    {
      name: Step.PersonalDetails,
      state: (defaultConfiguration.states as any)[Step.PersonalDetails],
      model: PersonalDetailsModel,
      path: 'personal-details',
      stepValidators: ContextValidators[Step.PersonalDetails],
    },
    {
      name: Step.CheckUserCredentials,
      state: (defaultConfiguration.states as any)[Step.CheckUserCredentials],
      model: CheckUserCredentialsModel,
      path: 'check-user-credentials',
      stepValidators: ContextValidators[Step.CheckUserCredentials],
    },
    {
      name: Step.CoApplicant,
      state: (defaultConfiguration.states as any)[Step.CoApplicant],
      model: CoApplicantModel,
      path: 'co-applicant',
      stepValidators: ContextValidators[Step.CoApplicant],
    },
    {
      name: Step.VehicleIdentity,
      state: (defaultConfiguration.states as any)[Step.VehicleIdentity],
      model: VehicleIdentityModel,
      path: 'vehicle-identity',
      stepValidators: ContextValidators[Step.VehicleIdentity],
    },
    {
      name: Step.SelectVehicleYear,
      state: (defaultConfiguration.states as any)[Step.SelectVehicleYear],
      model: SelectVehicleYearModel,
      path: 'select-vehicle-year',
      stepValidators: ContextValidators[Step.SelectVehicleYear],
    },
    {
      name: Step.SelectVehicleMake,
      state: (defaultConfiguration.states as any)[Step.SelectVehicleMake],
      model: SelectVehicleMakeModel,
      path: 'select-vehicle-make',
      stepValidators: ContextValidators[Step.SelectVehicleMake],
      controller: VehicleManualMakeSelectionController,
    },
    {
      name: Step.SelectVehicleModel,
      state: (defaultConfiguration.states as any)[Step.SelectVehicleModel],
      model: SelectVehicleModelModel,
      path: 'select-vehicle-model',
      stepValidators: ContextValidators[Step.SelectVehicleModel],
      controller: VehicleManualModelSelectionController,
    },
    {
      name: Step.FindVehicleByLicensePlate,
      state: (defaultConfiguration.states as any)[
        Step.FindVehicleByLicensePlate
      ],
      model: FindVehicleByLicensePlateModel,
      path: 'search-by-license-plate',
      stepValidators: ContextValidators[Step.FindVehicleByLicensePlate],
      controller: VehicleManualFindByPlateController,
    },
    {
      name: Step.FindVehicleByVin,
      state: (defaultConfiguration.states as any)[Step.FindVehicleByVin],
      model: FindVehicleByVinModel,
      path: 'search-by-vin',
      stepValidators: ContextValidators[Step.FindVehicleByVin],
      controller: VehicleManualFindByVinController,
    },
    {
      name: Step.VehicleConfirm,
      state: (defaultConfiguration.states as any)[Step.VehicleConfirm],
      model: VehicleConfirmModel,
      path: 'vehicle-confirm',
      stepValidators: ContextValidators[Step.VehicleConfirm],
      controller: VehicleConfirmController,
      withDynamicConfiguration: true,
    },
    {
      name: Step.Review,
      state: (defaultConfiguration.states as any)[Step.Review],
      model: ReviewModel,
      path: 'review',
      stepValidators: ContextValidators[Step.Review],
      controller: ReviewStepController,
    },
    {
      name: Step.ReviewApplicant,
      state: (defaultConfiguration.states as any)[Step.ReviewApplicant],
      model: ReviewApplicantModel,
      path: 'review-applicant',
      stepValidators: ContextValidators[Step.ReviewApplicant],
    },
    {
      name: Step.ReviewHousing,
      state: (defaultConfiguration.states as any)[Step.ReviewHousing],
      model: ReviewHousingModel,
      path: 'review-housing',
      stepValidators: ContextValidators[Step.ReviewHousing],
    },
    {
      name: Step.ReviewCoApplicant,
      state: (defaultConfiguration.states as any)[Step.ReviewCoApplicant],
      model: ReviewCoApplicantModel,
      path: 'review-co-applicant',
      stepValidators: ContextValidators[Step.ReviewCoApplicant],
      controller: ReviewCoApplicantController,
    },
    {
      name: Step.ReviewCoApplicantPersonalInfo,
      state: (defaultConfiguration.states as any)[
        Step.ReviewCoApplicantPersonalInfo
      ],
      model: ReviewCoApplicantPersonalInfoModel,
      path: 'review-co-applicant-personal-info',
      stepValidators: ContextValidators[Step.ReviewCoApplicantPersonalInfo],
    },
    {
      name: Step.ReviewCoApplicantHousingInfo,
      state: (defaultConfiguration.states as any)[
        Step.ReviewCoApplicantHousingInfo
      ],
      model: ReviewCoApplicantHousingInfoModel,
      path: 'review-co-applicant-housing-info',
      stepValidators: ContextValidators[Step.ReviewCoApplicantHousingInfo],
    },
    {
      name: Step.PinVerify,
      state: (defaultConfiguration.states as any)[Step.PinVerify],
      model: PinVerifyModel,
      path: 'pin-verify',
      stepValidators: ContextValidators[Step.PinVerify],
      controller: PinVerifyController,
    },
    {
      name: Step.ExitToSignIn,
      state: (defaultConfiguration.states as any)[Step.ExitToSignIn],
      controller: ExitToSignInController,
    },
    {
      name: Step.ExitToOffers,
      state: (defaultConfiguration.states as any)[Step.ExitToOffers],
      controller: ExitToOffersController,
    },
    {
      name: Step.ExitReturningUserToSignIn,
      state: (defaultConfiguration.states as any)[
        Step.ExitReturningUserToSignIn
      ],
      controller: ExitReturningUserToSignInController,
    },
    {
      name: Step.ExitToDashboard,
      state: (defaultConfiguration.states as any)[Step.ExitToDashboard],
      controller: ExitToDashboard,
    },
  ],
};
