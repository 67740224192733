import { SearchParamOptionsInterface } from './urlParams';

export class SearchParamConfig {
  localStorageKey?: string;
  removeAfterAuth = true;

  constructor({
    localStorageKey,
    keepAfterAuth,
  }: SearchParamOptionsInterface = {}) {
    this.localStorageKey = localStorageKey;
    this.removeAfterAuth = keepAfterAuth !== true;
  }
}
