import PropTypes from 'prop-types';
import { useState } from 'react';

import { Icon } from '../Icon/Icon';
import { IconTypes } from '../Icon/iconTypes';
import { RotationDirections } from '../Icon/rotationDirections';
import {
  Container,
  Content,
  Control,
  ControlContainer,
  Divider,
  Title,
} from './styles';

export const Accordion = ({
  children,
  bottomDivider = true,
  title = '',
}: {
  children: any;
  bottomDivider: any;
  title: any;
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Container>
      <ControlContainer>
        <Title>{title}</Title>
        <Control onClick={toggleOpen}>
          <Icon
            name={IconTypes.CARET}
            rotate={isOpen ? RotationDirections.DOWN : RotationDirections.UP}
          />
        </Control>
      </ControlContainer>
      <Content $isOpen={isOpen}>{children}</Content>
      {bottomDivider ? <Divider /> : null}
    </Container>
  );
};

Accordion.propTypes = {
  bottomDivider: PropTypes.bool,
  title: PropTypes.string,
};
