/**
 * Outlines all the dependencies we are building on the page.
 *
 * Since this is a "widget" we need to build out the things we would expect in the constructed HTML document.
 */

import { loadStylesheets } from './stylesheet';

const stylesheets: string[] = [
  'https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Montserrat:wght@400;500;700',
];

// initialize any style dependencies for this repository.
export function initialize() {
  return new Promise((resolve: any) => {
    loadStylesheets(stylesheets);
    resolve();
  });
}
