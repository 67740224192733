import moment from 'moment';

export const dateToFormValue = (
  dateValue: string | null | undefined,
  isShortenDate?: boolean,
): string | undefined => {
  if (!dateValue) {
    return '';
  }

  const validationRegex = isShortenDate ? /^\d{4}-\d{2}/ : /^\d{4}-\d{2}-\d{2}/;

  // Adding this regexp to ignore already transformed or invalid values
  if (!validationRegex.test(dateValue)) {
    return dateValue;
  }

  const parts = dateValue.match(/\d+/g) || [];
  const [yyyy, mm, dd] = parts.slice(0, 3);

  return isShortenDate ? `${mm}${yyyy}` : `${mm}${dd}${yyyy}`;
};

export const formValueToDate = (
  formValue: string | null | undefined,
  isShortenDate?: boolean,
): string | undefined => {
  if (!formValue) {
    return '';
  }

  const validationRegex = isShortenDate
    ? /^(0[1-9]|1[012])\d{4}$/
    : /^(0[1-9]|1[012])(0[1-9]|[12][\d]|3[01])\d{4}$/;

  // Adding this regexp to ignore already transformed or invalid values
  if (!validationRegex.test(formValue)) {
    return formValue;
  }

  const month = formValue.slice(0, 2);
  const day = formValue.slice(2, 4);
  const year = formValue.slice(isShortenDate ? 2 : 4);
  const formattedDate = isShortenDate ? [year, month] : [year, month, day];

  // Radix removes milliseconds so we should do the same
  return moment
    .utc(formattedDate.join('-'))
    .toISOString()
    .replace(/[.]\d+/, '');
};
