import { merge, pick } from 'lodash';

import coApplicantIcon from '../../../../../assets/Co-Applicant.svg';
import { AGREEMENT_STATUSES } from '../../../../../constants/choices/checklight';
import { fields } from '../../../../../forms/applicant';
import { CoApplicantContext } from '../../../../common/interfaces/co-applicant.interface';
import {
  applicantReviewSignals,
  getApplicantDetails,
} from '../../../../common/Steps/ApplicantReview/utils';
import BaseModel from '../common/BaseModel';

const getReviewOptions = (context: CoApplicantContext) => {
  const dataSource =
    context.coApplicant?.residences?.sameAsApplicant === AGREEMENT_STATUSES.YES
      ? context.currentUser
      : context.coApplicant;

  return merge(
    [],
    getApplicantDetails(
      {
        ...context.coApplicant,
        residences: dataSource?.residences,
      },
      applicantReviewSignals,
    ),
    [{ label: 'Co-applicant', image: coApplicantIcon }],
  );
};

const model = (send: any, { context }: any) =>
  merge({}, BaseModel, {
    form: {
      fields: merge({}, pick(fields, 'applicantDetails'), {
        applicantDetails: {
          options: getReviewOptions(context),
          onChange: send,
        },
      }),
      actions: {
        primary: {
          label: 'Save & Continue',
          testId: 'continue',
        },
      },
    },
    headerBlock: {
      title: 'Review co-applicant details',
      subtitle: ['Co-Applicant', '100% complete'],
      progressBar: {
        progress: 100,
        withContainer: true,
      },
    },
    info: {
      title: 'Review co-applicant details',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Does everything look correct? Use this time to correct any mistakes or confirm these details, as accurate information will allow for the smoothest application process possible.',
          ],
        },
      ],
    },
  });

export default model;
