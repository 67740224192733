import { get, merge } from 'lodash';

import { componentTypes } from '../../constants/componentTypes';
import { Workflow } from '../../constants/Workflow';
import { FormFieldsModelT } from '../../forms/formFieldsModelT';
import { optionsList as ownershipOptionsList } from '../../forms/housingTypeOptions';
import { unifiedOptionsList } from '../../forms/housingTypeOptions';
import { populateFields } from '../../forms/populateFields';
import { commonFields } from '../../utils/helpers/housing-info';
import { displayPrevious } from '../../utils/helpers/housing-info';
import { FormFiledInterface } from '../shared/Form/interfaces/formFields.interface';

const ownershipOptions = {
  [Workflow.checklight]: merge([], ownershipOptionsList, [
    { label: 'Own' },
    { label: 'Rent from landlord' },
    { label: 'Other' },
  ]),
  [Workflow.unified]: unifiedOptionsList,
};

const currentHousingFields = (workflow: Workflow) =>
  merge({}, commonFields(true), {
    ownershipStatus: {
      options: ownershipOptions[workflow],
    },
  });

const previousHousingSubtitle = {
  [Workflow.checklight]: {
    label: 'Previous address',
    name: 'subtitle',
    component: componentTypes.SUBTITLE,
  },
  [Workflow.unified]: {
    label: 'Previous address',
    hint: 'Our lenders require two years of residence history. Please share your previous residence with us.',
    name: 'subtitle',
    component: componentTypes.SUBTITLE,
  },
};

const formattedPreviousAddressFields = (
  previousFields: FormFieldsModelT,
): FormFieldsModelT =>
  Object.entries<FormFiledInterface>(previousFields).reduce(
    (acc, [key, value]) => {
      return {
        ...acc,
        [`${key}Previous`]: {
          ...value,
          shouldUnregister: true,
          dependsOn: {
            fields: ['residences.current.activeStartDate'],
            handler: ({ data, setVisible, setValidation }: any) => {
              const currentStartDate = get(
                data,
                'residences.current.activeStartDate',
              );
              const displayPreviousHousing = displayPrevious(currentStartDate);

              setVisible(displayPreviousHousing);

              if (key === 'activeStartDate') {
                setValidation({
                  validate: (v: any) =>
                    Date.parse(currentStartDate as string) > Date.parse(v) ||
                    'Date should not be greater then start date of current property',
                });
              }
            },
          },
        },
      };
    },
    {},
  );

export const presModel = (workflow: Workflow, data: any) => {
  const formFields = {
    ...currentHousingFields(workflow),
    ...formattedPreviousAddressFields({
      subtitle: previousHousingSubtitle[workflow],
      ...commonFields(false),
    }),
  };
  return {
    fields: merge({}, formFields, populateFields(formFields, data)),
    actions: {
      primary: {
        label: 'Save',
        testId: 'nameSave',
      },
      secondary: {
        label: 'Cancel',
        testId: 'nameCancel',
      },
    },
    withConfirmModal: true,
  };
};
