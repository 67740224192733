import { theme } from '../../../common/theme';
import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import { ProgressBar } from '../ProgressBar/ProgressBar';
import {
  Container,
  Paragraph,
  SecondaryTitle,
  Subtitle,
  Title,
} from './styles';

export interface HeaderBlockProps {
  paragraph?: HTMLElement | string | JSX.Element | null;
  progressBar?: any;
  subtitle?: string[] | string;
  subtitleColor?: string;
  subtitleParams?: any;
  title?: string;
  titleColor?: string;
  titleParams?: any;
  isMobile?: boolean;
  isModal?: boolean;
  persistImage?: boolean;
  isForm?: boolean;
  isDesktop?: boolean;
  isSecondary?: boolean;
}

const HeaderBlockComponent = ({
  paragraph = null,
  progressBar,
  subtitle = '',
  subtitleColor,
  title,
  titleColor,
  isMobile,
  isDesktop,
  isModal,
  persistImage,
  isSecondary,
  isForm,
}: HeaderBlockProps) => {
  const subtitleContent = Array.isArray(subtitle)
    ? subtitle.reduce(
        (acc: any, s) =>
          acc === null ? (
            s
          ) : (
            <>
              {acc}
              <span style={{ padding: `0 ${theme.unit[1]}` }}> | </span>
              {s}
            </>
          ),
        null,
      )
    : subtitle;
  return (
    <Container $isModal={isModal} $isSecondary={isSecondary}>
      {progressBar && (isDesktop || !persistImage) ? (
        <ProgressBar {...(progressBar || {})} />
      ) : null}
      {subtitleContent ? (
        <Subtitle $color={subtitleColor} $isModal={isModal}>
          {subtitleContent}
        </Subtitle>
      ) : null}
      {isSecondary ? (
        <SecondaryTitle
          $color={titleColor}
          $isMobile={isMobile}
          $isModal={isModal}
        >
          {title}
        </SecondaryTitle>
      ) : !!title ? (
        <Title $color={titleColor} $isMobile={isMobile} $isModal={isModal}>
          {title}
        </Title>
      ) : null}

      {paragraph ? (
        <Paragraph $isForm={isForm} $isModal={isModal}>
          {paragraph}
        </Paragraph>
      ) : null}
    </Container>
  );
};
export const HeaderBlock = withMatchMedia(HeaderBlockComponent);
