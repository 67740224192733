import { helpers, theme, unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const ErrorText = styled('span', ({ $theme }: any) => ({
  color: $theme.colors.error,
  paddingTop: unit[1],
}));

export const FileElementWrapper = styled(
  'div',
  ({ disabled, $theme, $isHidden }: any) => ({
    cursor: 'pointer',
    ...(helpers.flexVerticalContainer as any),
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
    borderStyle: 'solid',
    borderColor: $theme.colors.secondaryOverlay,
    borderWidth: '1px 1px 0',
    height: 'auto',
    padding: `${unit[6]} ${unit[3]}`,
    ...($isHidden
      ? {
          display: 'none',
        }
      : {}),
    ...(!disabled
      ? {
          ':hover': {
            backgroundColor: $theme.colors.tertiaryOverlay,
          },
        }
      : {
          pointerEvents: 'none',
        }),
    ':last-of-type': {
      borderWidth: '1px',
      borderBottomLeftRadius: unit[2],
      borderBottomRightRadius: unit[2],
      marginBottom: unit[2],
    },
    ':first-of-type': {
      borderTopLeftRadius: unit[2],
      borderTopRightRadius: unit[2],
    },
  }),
);

export const FileUploadInput = styled('input', () => ({
  display: 'none',
  width: 0,
  height: 0,
})) as any;

export const FileNameContainer = styled('div', () => ({
  display: 'flex',
  flexWrap: 'wrap',
}));

export const SubtitleContainer = styled('div', ({ $theme }: any) => ({
  ...(theme.fonts.headingMedium as any),
  color: $theme.colors.primaryActive,
  textAlign: 'left',
  whiteSpace: 'pre',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 400,
  marginRight: unit[1],
  paddingTop: unit[1],
}));

export const LeftContentContainer = styled('div', () => ({
  flexGrow: 1,
  marginRight: unit[1],
  minWidth: 0,
}));
