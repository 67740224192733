export enum ProgressStepName {
  NEW_APPLICATION = 'NEW_APPLICATION',
  COMPLETE_APPLICATION = 'COMPLETE_APPLICATION',
  SUBMITTED_TO_LENDER = 'SUBMITTED_TO_LENDER',
  APPROVED_BY_LENDER = 'APPROVED_BY_LENDER',
  PREPARING_CONTRACTS = 'PREPARING_CONTRACTS',
  SIGNING_CONTRACTS = 'SIGNING_CONTRACTS',
  REVIEWING_DOCUMENTS = 'REVIEWING_DOCUMENTS',
  FINALIZING_STIPS = 'FINALIZING_STIPS',
  WAITING_ON_FUNDING = 'WAITING_ON_FUNDING',
  LENDER_FUNDED = 'LENDER_FUNDED',
}

export type ProgressStep = {
  applicationStatuses: string[];
  completed: boolean;
  description: string;
  title: string;
};
