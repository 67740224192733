/* eslint-disable react-refresh/only-export-components */
import React, { isValidElement, useMemo } from 'react';
import ReactDOM from 'react-dom';

import { componentTypes } from '../../../constants/componentTypes';
import { useModal } from '../../../hooks/useModal/useModal';
import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import { use100vh } from '../../../utils/use100vh';
import { ButtonGroup } from '../ButtonGroup/ButtonGroup';
import { ButtonGroupProps } from '../ButtonGroup/ButtonGroup';
import { Form } from '../Form/Form';
import { Header } from '../Header/Header';
import { HeaderBlock } from '../HeaderBlock/HeaderBlock';
import { SmoothImage } from '../SmoothImage/SmoothImage';
import {
  ButtonGroupContainer,
  CloseContainer,
  HeaderBlockContainer,
  HeaderContainer,
  ImageBackground,
  ImageContainer,
  LoadingContainer,
  ModalBackdrop,
  ModalBody,
  ModalContainer,
} from './styles';

export interface ModalProps {
  buttonGroupPresModel?: ButtonGroupProps;
  children?: React.ReactNode;
  isDesktop?: boolean;
  isMobile?: boolean;
  onClose?: () => void;
  subtitle?: string;
  subtitleColor?: string;
  title: string;
  modalImage?: {
    src?: string;
    fallbackSrc?: string;
  };
  testId?: string;
  isTablet?: boolean;
  paragraph?: string;
  isLoading?: boolean;
  showClose?: boolean;
}

export interface ModalHeaderProps {
  isMobile: boolean;
  onClose?: () => void;
  modalImage: any;
}

export const defaultModalButtons = (onClick?: () => void) => ({
  rtl: true,
  primaryButton: {
    name: 'back',
    children: 'Back',
    testId: 'back',
    ...(onClick ? { onClick } : {}),
  },
});

const ModalHeader = ({ isMobile, onClose, modalImage, showClose }: any) => {
  return (
    <HeaderContainer $isMobile={isMobile}>
      <CloseContainer>
        <Header
          showBack={false}
          showClose={showClose}
          isModal
          onClose={onClose}
        />
      </CloseContainer>
      {modalImage ? (
        <ImageBackground $isMobile={isMobile}>
          <ImageContainer>
            <SmoothImage {...modalImage} />
          </ImageContainer>
        </ImageBackground>
      ) : null}
    </HeaderContainer>
  );
};

const formConfig = {
  fields: {
    loading: {
      name: 'loading',
      isLoading: true,
      component: componentTypes.SINGLE_SELECT,
    },
  },
  actions: {
    primary: {
      isHidden: true,
    },
  },
};

const ModalComponent = ({
  buttonGroupPresModel: buttonGroup,
  children,
  isDesktop,
  isTablet,
  isMobile,
  onClose: handleClose,
  title,
  subtitle,
  subtitleColor,
  paragraph,
  modalImage,
  testId,
  isLoading,
  showClose = true,
}: ModalProps) => {
  const { close } = useModal();
  const onClose = useMemo(() => {
    if (showClose) {
      return handleClose || close;
    }
  }, [handleClose, close, showClose]);
  const buttonGroupPresModel = useMemo(() => {
    return {
      ...buttonGroup,
      ...(buttonGroup?.primaryButton
        ? {
            primaryButton: {
              ...buttonGroup.primaryButton,
              onClick: buttonGroup.primaryButton.onClick || onClose,
            },
          }
        : {}),
    };
  }, [buttonGroup, onClose]);
  return ReactDOM.createPortal(
    <ModalBackdrop
      $height={use100vh()}
      $isDesktop={isDesktop}
      $isMobile={isMobile}
      $isTablet={isTablet}
      onClick={onClose}
    >
      <ModalContainer
        $isDesktop={isDesktop}
        $isMobile={isMobile}
        $isTablet={isTablet}
        data-testid={testId || 'popup'}
        // stop modal container from inheriting close action from backdrop click
        onClick={(e: any) => e.stopPropagation()}
      >
        {/* row one */}
        <ModalHeader
          isMobile={isMobile}
          modalImage={modalImage}
          showClose={showClose}
          onClose={onClose}
        />
        {/* row two */}
        <ModalBody $isMobile={isMobile} $withMargin={!modalImage}>
          <HeaderBlockContainer
            $extendedMarginBottom={isValidElement(children) || !modalImage}
            $showBorder={!modalImage}
          >
            <HeaderBlock
              paragraph={paragraph}
              subtitle={subtitle}
              subtitleColor={subtitleColor}
              title={title}
              isModal
            />
          </HeaderBlockContainer>
          {isLoading ? (
            <LoadingContainer>
              <Form {...{ form: formConfig }} />
            </LoadingContainer>
          ) : (
            children
          )}
        </ModalBody>
        {/* row three */}
        <ButtonGroupContainer $isMobile={isMobile}>
          {buttonGroupPresModel ? (
            <ButtonGroup {...buttonGroupPresModel} />
          ) : null}
        </ButtonGroupContainer>
      </ModalContainer>
    </ModalBackdrop>,
    document.getElementById('main-layout-root') as Element | DocumentFragment,
  );
};

export const Modal = withMatchMedia(ModalComponent);
