import { merge } from 'lodash';

import {
  addressFields,
  getAddressFieldName,
} from '../../../../../common/housing-info';
import { AGREEMENT_STATUSES } from '../../../../../constants/choices/checklight';
import { fields as coApplicantFields } from '../../../../../forms/co-applicant';
import { FormFieldsModelT } from '../../../../../forms/formFieldsModelT';
import { populateFields } from '../../../../../forms/populateFields';
import { fields } from '../../../../../forms/user';
import { Signal } from '../../../../common/constants/signals';
import { AccountState } from '../../common/unified-workflow-context.interface';
import BaseModel from '../common/BaseModel';

const formattedAddressFields: FormFieldsModelT = addressFields
  .concat(['monthlyPaymentAmount'])
  .reduce(
    (acc, field) => ({
      ...acc,
      [field]: {
        ...fields[field],
        name: getAddressFieldName(field, true),
        shouldUnregister: true,
        dependsOn: {
          fields: ['sameAsApplicant'],
          handler: ({ data, setVisible }: any) => {
            setVisible(data.sameAsApplicant === AGREEMENT_STATUSES.NO);
          },
        },
      },
    }),
    {},
  );

const accountFields = {
  sameAsApplicant: {
    ...coApplicantFields.sameAsApplicant,
    name: 'sameAsApplicant',
    value: AGREEMENT_STATUSES.YES,
  },
  ...formattedAddressFields,
};

const model = (send: any, { context }: AccountState) => {
  const { coApplicant } = context;
  const savedFields = populateFields(accountFields, coApplicant);
  const populatedFields = merge({}, accountFields, savedFields);
  return merge({}, BaseModel, {
    template: {
      header: {
        showBack: true,
        onBack: () => {
          send(Signal.Previous);
        },
      },
    },
    headerBlock: {
      progressBar: {
        progress: 90,
        withContainer: true,
      },
      title: 'Edit Co-applicant housing details',
      subtitle: ['Account', '90% complete'],
    },
    form: {
      fields: populatedFields,
      actions: {
        primary: {
          testId: 'continue',
          label: 'Continue',
          handler: (data: any) => {
            send(Signal.Next, {
              data: { coApplicant: { ...context.coApplicant, ...data } },
            });
          },
        },
        secondary: {
          testId: 'cancel',
          label: 'Cancel',
          handler: () => {
            send(Signal.Previous);
          },
        },
      },
      autoFocus: true,
      withConfirmModal: true,
    },
    info: {
      title: 'Review co-applicant housing details',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Does everything look correct? Use this time to correct any mistakes or confirm these details, as accurate information will allow for the smoothest application process possible.',
          ],
        },
      ],
    },
  });
};

export default model;
