enum IconTypes {
  ADD = 'ADD', // '+'
  BANK = 'BANK',
  ARROW = 'ARROW', // '<-' traditional arrow
  CONTROL = 'CONTROL', // check-mark
  DANGER = 'DANGER', // 'X'
  HOME_FAMILY = 'HOME_FAMILY',
  HOME_OWN = 'HOME_OWN',
  HOME_RENT = 'HOME_RENT',
  HOME_OTHER = 'HOME_OTHER',
  INDETERMINATE = 'INDETERMINATE', // the dash part of '[-]'
  MENU = 'MENU', // 'hamburger' menu
  OPTIONS = 'OPTIONS', // three dots
  PLATE = 'PLATE', // license plate square
  CARET = 'CARET', // caret '>'
  TOOLTIP = 'TOOLTIP', // circle 'i'
  VIN = 'VIN', // vin square
  YMMT = 'YMMT', // some vehicle from the right side
  CL = 'CL', // CHECKLIGHT logo
}

export { IconTypes };
