import { useContext, useMemo } from 'react';

import { DynamicBrandingContext } from '../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { ErrorsBlock } from '../shared/ErrorsBlock/ErrorsBlock';
import { defaultModalButtons } from '../shared/Modal/Modal';
import { Modal } from '../shared/Modal/Modal';
import { PanelList } from '../shared/PanelList/PanelList';
import { selectedOfferPanelsConfig } from './panelsConfig';

export const SelectedOfferSummary = ({
  selectedOfferState,
  autopayNumber,
}: any) => {
  const { result: selectedOffer, loading } = selectedOfferState;
  const {
    branding: { theme: $theme },
  } = useContext(DynamicBrandingContext);

  const selectedOfferPanels = useMemo(
    () => (selectedOffer ? selectedOfferPanelsConfig(selectedOffer) : []),
    [selectedOffer],
  );

  return (
    <Modal
      buttonGroupPresModel={defaultModalButtons()}
      isLoading={loading}
      subtitle={autopayNumber}
      subtitleColor={$theme.colors.primaryActive}
      testId="selectedLoanSummaryPopup"
      title="Selected offer summary"
    >
      {selectedOfferState.error ? (
        <ErrorsBlock errorsList={selectedOfferState.error} />
      ) : (
        <PanelList
          panelHeading={{
            label: 'Selected offer',
            style: { backgroundColor: 'auto' },
          }}
          panelList={selectedOfferPanels}
          isBorderWrapped
        />
      )}
    </Modal>
  );
};
