import { Fragment, useCallback, useMemo, useReducer } from 'react';

import { useModal } from '../../../hooks/useModal/useModal';
import { Warning } from './Warning';

const verifyWarnings = (data: any, field: any) => {
  const { warningValidation } = field;
  let error;
  if (warningValidation.min > data) {
    error = 'min';
  }
  if (warningValidation.max < data) {
    error = 'max';
  }
  return (
    error && {
      field,
      value: data,
      title: warningValidation.title,
      subtitle: warningValidation.subtitle,
    }
  );
};

export const withWarning =
  ({ field, setValue, trigger, clearErrors }: any) =>
  (FormControl: any) => {
    return (props: any) => {
      const { isShowing, open, close } = useModal();
      const [warningDisplayed, setWarningDisplayed] = useReducer(
        (state: any, value: any) => value,
        null,
      );

      const showWarning = useCallback(
        async (data: any, invalid: any) => {
          // Skip if viewed or invalid
          if (warningDisplayed === data || invalid) {
            return;
          }
          setWarningDisplayed(data);

          // Field is marked as invalid only after submit
          // Lines below check if field is valid at the moment
          const isValid = await trigger(field.name);
          clearErrors(field.name);

          // Skip if invalid before submit
          if (!isValid) {
            return;
          }

          const warningValidationError = verifyWarnings(data, field);
          // Skip if passing warning conditions
          if (!warningValidationError) {
            return;
          }

          open();
        },
        [open, warningDisplayed, setWarningDisplayed],
      );

      const warningModal = useMemo(() => {
        const warningValidationError = verifyWarnings(warningDisplayed, field);

        const onSubmitWarning = (updatedData: any) => {
          setValue(...Object.entries(updatedData)[0]);
          close();
        };

        return isShowing ? (
          <Warning
            popupInfo={warningValidationError}
            onClose={close}
            onSubmit={onSubmitWarning}
          />
        ) : null;
      }, [isShowing, close, warningDisplayed]);

      return (
        <Fragment>
          <FormControl {...props} onBlur={showWarning} />
          {warningModal}
        </Fragment>
      );
    };
  };
