import { merge } from 'lodash';

import { unifiedOptionsList } from '../../../../../forms/housingTypeOptions';
import { populateFields } from '../../../../../forms/populateFields';
import { commonFields } from '../../../../../utils/helpers/housing-info';
import { withContext } from '../../../../../utils/workflow/withContext';
import { applicantBaseModel } from '../../../../common/Steps/common/ApplicantBaseModel';
import BaseModel from '../common/BaseModel';

const fieldsConfig = merge({}, commonFields(true), {
  ownershipStatus: {
    options: unifiedOptionsList,
  },
});

const model = () =>
  merge({}, applicantBaseModel, BaseModel, {
    form: {
      fields: fieldsConfig,
    },
    headerBlock: {
      title: 'Help us with any missing housing details',
      subtitle: ['Applicant', '40% complete'],
      progressBar: {
        progress: 40,
      },
    },
    info: {
      title: 'Applicant housing details',
      content: [
        {
          copy: [
            'At a minimum, we need to know where you have lived for the past two years.',
            'Haven’t been at your current residence for that long? Do not fret, you’ll be given the chance to add additional housing information in order to satisfy this requirement.',
          ],
        },
      ],
    },
  });

const populateData =
  (fields: any) =>
  (send: any, { context }: any) => {
    return populateFields(fields, context.updatedUser);
  };

const modalFn = (...args: [any, any]) =>
  withContext({
    model: model(),
    populateFields: populateData,
  })(...args);

export default modalFn;
