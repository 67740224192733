export const addressFields = [
  'lineOne',
  'lineTwo',
  'city',
  'province',
  'postalCode',
];

export const previousAddressFields = addressFields.concat('activeStartDate');

export const getAddressFieldsPaths = (primary: boolean) =>
  addressFields.map(
    (field) =>
      `residences.${primary ? 'current' : 'previous'}.physicalAddress.${field}`,
  );

export const getAddressFieldName = (
  field: string,
  primary: boolean,
): string => {
  return [
    'residences',
    primary ? 'current' : 'previous',
    ...(addressFields.includes(field) ? ['physicalAddress'] : []),
    field,
  ].join('.');
};

export const getAddressFieldsNames = (
  fields: string[],
  primary: boolean,
): { [key: string]: { name: string } } => {
  return fields.reduce(
    (acc, field) => ({
      ...acc,
      [field]: {
        name: getAddressFieldName(field, primary),
      },
    }),
    {},
  );
};
