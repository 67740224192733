import { ErrorBoundary } from 'react-error-boundary';

import ErrorImg from '../../assets/hero-error-with-smoke.svg';
import { Button } from '../shared/Button/Button';
import {
  Container,
  ErrorContainer,
  Image,
  ImageContainer,
  SubTitle,
  Title,
} from './styles';

export const ErrorPage = () => {
  const redirectToRoot = () => {
    window.location.assign('/');
  };

  return (
    <ErrorContainer>
      <ImageContainer>
        <Image src={ErrorImg} />
      </ImageContainer>

      <Container>
        <Title>Something went wrong on our end...</Title>
        <SubTitle>
          Something went wrong trying to display this page.
          <br />
          Try coming back to it later.
        </SubTitle>
      </Container>

      <Container>
        <Button onClick={redirectToRoot}>Dashboard</Button>
      </Container>
    </ErrorContainer>
  );
};

export const ErrorHandlingLayer = ({ children }: any) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>{children}</ErrorBoundary>
  );
};
