import { theme, unit } from '../../../../common/theme';
import { styled } from '../../../../providers/context/StyletronProvider/withStyletronProvider';

export const ContentTitleContainer = styled('div', () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: unit[6],
}));

export const ContentTitle = styled('div', () => ({
  ...theme.fonts.titleMedium,
  fontSize: '20px',
}));

export const ContentBlock = styled('div', () => ({
  marginBottom: unit[9],
}));
