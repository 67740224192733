import { useEffect, useReducer, useState } from 'react';
import TagManager from 'react-gtm-module';
import { Navigate } from 'react-router-dom';

import { unit } from '../common/theme';
import { Loader } from '../components/shared/Loader/Loader';
import { config } from '../config';
import { useAPI } from '../hooks/useAPI/useAPI';
import { useBranding } from '../hooks/useBranding/useBranding';
import { useCurrentUserContext } from '../hooks/useCurrentUserContext/useCurrentUserContext';
import { useWorkflowPathname } from '../hooks/useWorkflowPathname/useWorkflowPathname';
import { SearchParamKey } from '../services/urlParams/urlParams';
import { urlParamHook } from '../services/urlParams/urlParamsService';

function AuthorizedGuard({ children }: { children: JSX.Element }) {
  const { saveParam } = urlParamHook(SearchParamKey.leadChannelCode);
  const { savedParam: autopayNumber } = urlParamHook(
    SearchParamKey.autopayNumber,
  );
  const { savedParam: applicationUpdateMessage } = urlParamHook(
    SearchParamKey.applicationUpdateMessage,
  );
  const { saveParam: saveShowProgressModal } = urlParamHook(
    SearchParamKey.showProgressModal,
  );
  const { failedToRetrieveBranding } = useBranding();
  const [currentUserContext, setCurrentUserContext] = useCurrentUserContext();
  const { id: authenticatedUserId } = currentUserContext;
  const [isValidUser, setIsValidUser] = useReducer(() => true, false);
  const [fetchSessionUserComplete, setFetchSessionUserComplete] =
    useState(false);

  const api = useAPI();

  const workflowRootPath = useWorkflowPathname();

  const isRateUpdateRoute = window.location.pathname.includes('rate-update');

  const isApplicationUpdateRoute =
    window.location.pathname.includes('application-update');

  const sendUpdateRouteTriggeredEvent = (authenticated: boolean) => {
    if (!isRateUpdateRoute && !isApplicationUpdateRoute) {
      return;
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'application-update-reason',
        'Logged In': authenticated,
        Location: window.location.pathname,
        Reason:
          isApplicationUpdateRoute && applicationUpdateMessage
            ? applicationUpdateMessage
            : isRateUpdateRoute
            ? 'HAS_APPROVAL'
            : '',
        'AutoPay Number': autopayNumber || '',
      },
    });
  };

  useEffect(() => {
    if (isRateUpdateRoute || isApplicationUpdateRoute) {
      saveShowProgressModal('true');
    }

    if (failedToRetrieveBranding) {
      // resets localStorage with default value, if branding call fails
      // this scenario mimics a leadChannel that does not exist being passed as a query parameter
      saveParam(config.defaultLeadChannelCode);
    }

    // User is already authenticated
    if (authenticatedUserId) {
      setIsValidUser();
      sendUpdateRouteTriggeredEvent(true);

      setFetchSessionUserComplete(true);
      return;
    }

    // User is missing token
    const token = localStorage.getItem(config.session.CHECKLIGHT_TOKEN_KEY);
    if (!token) {
      sendUpdateRouteTriggeredEvent(false);

      setFetchSessionUserComplete(true);
      return;
    }

    // Fetch user data
    api
      .get('/users/current')
      .then((userData: any) => {
        setCurrentUserContext({ ...currentUserContext, ...userData });
        setIsValidUser();
        sendUpdateRouteTriggeredEvent(true);

        setFetchSessionUserComplete(true);
      })
      .catch(() => {
        sendUpdateRouteTriggeredEvent(false);

        setFetchSessionUserComplete(true);
      });
  }, []);

  if (!fetchSessionUserComplete) {
    return <Loader size={unit[6]} fullScreen />;
  }

  if (isValidUser) {
    return <Navigate to={'/' + workflowRootPath} replace />;
  }
  return children;
}

export default AuthorizedGuard;
