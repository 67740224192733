import * as Sentry from '@sentry/react';

import { LoanDocumentType } from '../types/LoanDocumentType';

export const reportUploadError =
  (document: LoanDocumentType) =>
  (error: Error): LoanDocumentType => {
    Sentry.captureException(error, {
      extra: {
        FileSize: document.file.size,
        FileType: document.file.type,
      },
      tags: { 'document.loan.type': document.type },
    });

    return document;
  };
