import { get, isEmpty, isEqual, omit } from 'lodash';
import { assign, MachineConfig } from 'xstate';

import { AGREEMENT_STATUSES } from '../../../../constants/choices/checklight';
import { isOlderThan } from '../../../../utils/pipes/date';
import { CoApplicantContext } from '../../../common/interfaces/co-applicant.interface';
import { incomeReviewSignals } from '../../../common/Steps/ApplicantIncome/utils';
import { applicantReviewSignals } from '../../../common/Steps/ApplicantReview/utils';
import { isRequiredPreviousIncome } from '../../../common/utils/applicant.utils';
import { Step } from '../steps';

export const Signal = {
  ...incomeReviewSignals,
  ...applicantReviewSignals,
  Next: 'Next',
  Previous: 'Previous',
};

const mapCoApplicantWithUpdatedResidences = (context: any) => {
  const {
    currentUser,
    currentVehicle: { coApplicant = {} },
  } = context;
  const userAddress = get(currentUser, 'residences.current.physicalAddress');
  const coApplicantAddress = get(
    coApplicant,
    'residences.current.physicalAddress',
  );

  let isSameAddress: boolean;

  if (isEmpty(coApplicantAddress)) {
    isSameAddress = true;
  } else {
    isSameAddress = isEqual(
      omit(userAddress, 'id'),
      omit(coApplicantAddress, 'id'),
    );
  }

  return {
    ...coApplicant,
    residences: {
      ...(isSameAddress
        ? {
            sameAsApplicant: AGREEMENT_STATUSES.YES,
          }
        : {
            ...coApplicant.residences,
            sameAsApplicant: AGREEMENT_STATUSES.NO,
          }),
    },
  };
};

export const workflowName = 'unified-default-co-applicant';
export const defaultConfiguration: MachineConfig<CoApplicantContext, any, any> =
  {
    id: workflowName,
    initial: Step.PersonalDetails,
    states: {
      [Step.PersonalDetails]: {
        on: {
          [Signal.Next]: {
            target: Step.HousingDetails,
            actions: 'patchUser',
          },
        },
        entry: assign((context: any) => {
          if (context.isMapped) {
            return context;
          }
          return {
            ...context,
            isMapped: true,
            coApplicant: mapCoApplicantWithUpdatedResidences(context),
          };
        }),
      },
      [Step.HousingDetails]: {
        on: {
          [Signal.Previous]: Step.PersonalDetails,
          [Signal.Next]: [
            {
              target: Step.PreviousAddress,
              actions: 'patchUser',
              cond: (_: any, { data }: any) =>
                data.residences.sameAsApplicant === AGREEMENT_STATUSES.NO &&
                !isOlderThan(
                  get(data, 'residences.current.activeStartDate'),
                  24,
                ),
            },
            {
              target: Step.PrimaryIncome,
              actions: 'updateUser',
            },
          ],
        },
      },
      [Step.PreviousAddress]: {
        on: {
          [Signal.Previous]: Step.HousingDetails,
          [Signal.Next]: {
            target: Step.PrimaryIncome,
            actions: 'patchUser',
          },
        },
      },
      [Step.PrimaryIncome]: {
        on: {
          [Signal.Previous]: [
            {
              target: Step.PreviousAddress,
              cond: (context: any) =>
                !isOlderThan(
                  get(
                    context,
                    'coApplicant.residences.current.activeStartDate',
                  ),
                  24,
                ) &&
                get(context, 'coApplicant.residences.sameAsApplicant') ===
                  AGREEMENT_STATUSES.NO,
            },
            {
              target: Step.HousingDetails,
            },
          ],
          [Signal.Next]: [
            {
              target: Step.PrimaryPreviousIncome,
              actions: 'patchUser',
              cond: (_, { data: { incomes } }) =>
                isRequiredPreviousIncome(incomes?.current),
            },
            {
              target: Step.ReviewIncome,
              actions: [
                'patchUser',
                assign((context) => ({
                  ...context,
                  coApplicant: {
                    ...context.coApplicant,
                    incomes: {
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      ...context.coApplicant!.incomes!,
                      previous: undefined,
                    },
                  },
                })),
              ],
            },
          ],
        },
      },
      [Step.PrimaryPreviousIncome]: {
        on: {
          [Signal.Previous]: Step.PrimaryIncome,
          [Signal.Next]: {
            target: Step.ReviewIncome,
            actions: 'patchUser',
          },
        },
      },
      [Step.ReviewIncome]: {
        on: {
          [Signal.EditIncomePrimary]: {
            target: Step.EditPrimaryIncome,
            actions: assign({
              editIncome: (context, { editIncome }: any) => editIncome,
            }),
          },
          [Signal.AddIncome]: Step.AddAdditionalIncome,
          [Signal.EditIncomeAdditional]: {
            target: Step.EditAdditionalIncome,
            actions: assign({
              editIncome: (context, { editIncome }: any) => editIncome,
            }),
          },
          [Signal.Previous]: [
            {
              target: Step.PrimaryPreviousIncome,
              cond: (context) =>
                isRequiredPreviousIncome(
                  get(context, 'coApplicant.incomes.current' as any),
                ),
            },
            {
              target: Step.PrimaryIncome,
            },
          ],
          [Signal.Next]: Step.ReviewCoApplicant,
        },
      },
      [Step.EditPrimaryIncome]: {
        on: {
          [Signal.Next]: {
            target: Step.ReviewIncome,
            actions: assign((context, { data }) => ({
              ...context,
              coApplicant: {
                ...context.coApplicant,
                incomes: {
                  ...data,
                  additional: context.coApplicant?.incomes?.additional,
                },
              },
            })),
          },
          [Signal.Previous]: Step.ReviewIncome,
        },
      },
      [Step.AddAdditionalIncome]: {
        on: {
          [Signal.Next]: {
            target: Step.ReviewIncome,
            actions: 'patchUser',
          },
          [Signal.Previous]: Step.ReviewIncome,
        },
      },
      [Step.EditAdditionalIncome]: {
        on: {
          [Signal.Next]: {
            target: Step.ReviewIncome,
            actions: 'patchUser',
          },
          [Signal.Previous]: Step.ReviewIncome,
        },
      },
      [Step.ReviewCoApplicant]: {
        on: {
          [Signal.ReviewApplicant]: Step.ReviewPersonalDetails,
          [Signal.ReviewHousing]: Step.ReviewHousingDetails,
          [Signal.ReviewIncome]: Step.FinalIncomeReview,
          [Signal.Previous]: Step.ReviewIncome,
          [Signal.Next]: [
            {
              target: Step.Done,
              // if both applicant and co-applicant have provided SSN, skip review gate
              cond: (ctx) =>
                get(ctx, 'currentUser.personalInfo.hasSSN') === true &&
                get(ctx, 'coApplicant.personalInfo.hasSSN') === true,
              actions: 'setReviewComplete',
            },
            {
              target: Step.RedirectToReview,
              actions: 'setCoApplicantComplete',
            },
          ],
        },
      },
      [Step.ReviewPersonalDetails]: {
        on: {
          [Signal.Previous]: Step.ReviewCoApplicant,
          [Signal.Next]: {
            target: Step.ReviewCoApplicant,
            actions: 'patchUser',
          },
        },
      },
      [Step.ReviewHousingDetails]: {
        on: {
          [Signal.Previous]: Step.ReviewCoApplicant,
          [Signal.Next]: {
            target: Step.ReviewCoApplicant,
            actions: 'updateUser',
          },
        },
      },
      [Step.FinalIncomeReview]: {
        on: {
          [Signal.Next]: Step.ReviewCoApplicant,
          [Signal.EditIncomePrimary]: {
            target: Step.FinalIncomeReviewPrimaryEdit,
            actions: assign({
              editIncome: (context, { editIncome }: any) => editIncome,
            }),
          },
          [Signal.AddIncome]: Step.FinalIncomeReviewAdditionalAdd,
          [Signal.EditIncomeAdditional]: {
            target: Step.FinalIncomeReviewAdditionalEdit,
            actions: assign({
              editIncome: (context, { editIncome }: any) => editIncome,
            }),
          },
          [Signal.Previous]: Step.ReviewCoApplicant,
        },
      },
      [Step.FinalIncomeReviewPrimaryEdit]: {
        on: {
          [Signal.Next]: {
            target: Step.FinalIncomeReview,
            actions: assign((context, { data }) => ({
              ...context,
              coApplicant: {
                ...context.coApplicant,
                incomes: {
                  ...data,
                  additional: context.coApplicant?.incomes?.additional,
                },
              },
            })),
          },
          [Signal.Previous]: Step.FinalIncomeReview,
        },
      },
      [Step.FinalIncomeReviewAdditionalAdd]: {
        on: {
          [Signal.Next]: {
            target: Step.FinalIncomeReview,
            actions: 'patchUser',
          },
          [Signal.Previous]: Step.FinalIncomeReview,
        },
      },
      [Step.FinalIncomeReviewAdditionalEdit]: {
        on: {
          [Signal.Next]: {
            target: Step.FinalIncomeReview,
            actions: 'patchUser',
          },
          [Signal.Previous]: Step.FinalIncomeReview,
        },
      },
      [Step.RedirectToReview]: {
        type: 'final',
      },
      [Step.Done]: {
        type: 'final',
      },
    },
  };

// NOTE: You can paste the content of this file into @see https://xstate.js.org/viz
// and uncomment this line to test the logic manually
// Machine({ ...defaultConfiguration }, { ...defaultServiceOptions });

export default defaultConfiguration;
