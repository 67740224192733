import { useState } from 'react';

import { UserVehicle } from '../../../common/interfaces/vehicle/vehicle.interface';
import { DuplicateApplicationModal } from '../../../components/DuplicateApplicationModal/DuplicateApplicationModal';
import { VehicleConfirmation } from '../../../components/VehicleConfirmation/VehicleConfirmation';
import { useModal } from '../../../hooks/useModal/useModal';
import { SearchParamKey } from '../../../services/urlParams/urlParams';
import { urlParamHook } from '../../../services/urlParams/urlParamsService';

export const NotificationModals = ({
  currentVehicle,
  children,
}: {
  currentVehicle: UserVehicle;
  children: JSX.Element;
}) => {
  const { isShowingAny } = useModal();

  const {
    savedParam: hasDuplicateApplication,
    removeParam: removeDuplicateApplication,
  } = urlParamHook(SearchParamKey.hasDuplicateApplication);

  const [duplicatedAppNotificationClosed, setDuplicatedAppNotificationClosed] =
    useState(false);

  const isDisplayedVehicleConfirmation =
    !currentVehicle.isConfirmed && !isShowingAny;
  const isDisplayedDuplicateApplication =
    hasDuplicateApplication === 'true' &&
    !duplicatedAppNotificationClosed &&
    !isShowingAny;

  if (isDisplayedVehicleConfirmation) {
    return <VehicleConfirmation currentVehicle={currentVehicle} />;
  }
  if (isDisplayedDuplicateApplication) {
    return (
      <DuplicateApplicationModal
        currentVehicle={currentVehicle}
        onClose={() => {
          setDuplicatedAppNotificationClosed(true);
          removeDuplicateApplication();
        }}
      />
    );
  }

  return children;
};
