import { useContext } from 'react';

import CurrentUserContext from '../../providers/context/CurrentUserContext/CurrentUserContext';
import type { UserContextT } from '../../providers/context/CurrentUserContext/CurrentUserContextT';

/**
 * useCurrentUserContext - Contextual information about the current application state
 *
 * This context is easily overridden by our functional test so that we can test the UI code with different
 * currentUserContext configurations. We don't even have to use mocks, awesome!
 *
 * @see https://reactjs.org/docs/context.html#api
 * @returns [currentUserContext, setCurrentUserContext] - The current immutable user context, and a method
 * to update the currentUserContext
 */
export const useCurrentUserContext = (): [
  UserContextT,
  (newUserContext: UserContextT) => void,
] => useContext(CurrentUserContext);
