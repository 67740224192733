import { Navigate } from 'react-router-dom';

import { useWorkflowPathname } from '../../../../../hooks/useWorkflowPathname/useWorkflowPathname';

const ExitToDashboard = () => {
  const rootPath = useWorkflowPathname();

  return <Navigate to={`/${rootPath}`} />;
};

export default ExitToDashboard;
