import ReviewController from '../../../../common/Steps/OffersReview/Controller';

const OffersReviewController = ({ context, ...props }: any) => {
  return (
    <ReviewController
      {...props}
      context={{
        ...context,
        offers: {
          ...context.offers,
          downPayment: context.downPayment,
        },
      }}
    />
  );
};

export default OffersReviewController;
