import { theme, unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const Container = styled('div', () => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: theme.unit[4],
}));

export const Back = styled('div', ({ $theme }: any) => ({
  color: $theme.colors.infoOverlay,
  cursor: 'pointer',
}));

export const Close = styled('div', ({ $theme }: any) => ({
  color: $theme.colors.infoOverlay,
  marginLeft: 'auto',
  cursor: 'pointer',
}));

export const IconContainer = styled('div', ({ $theme }: any) => ({
  color: $theme.colors.infoOverlay,
  marginLeft: 'auto',
  cursor: 'pointer',
}));

export const LeftContent = styled('div', {
  display: 'flex',
  gap: unit[2],
});
