import { styled } from 'styletron-react';

import { font, unit } from '../../common/theme/index';

export const ErrorContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const ImageContainer = styled('div', {
  display: 'block',
  height: '55vh',
});

export const Container = styled('div', {
  display: 'block',
  textAlign: 'center',
  padding: '0px 16px',
  margin: '0 auto',
});

export const Image = styled('img', {
  display: 'block',
  height: '100%',
  width: '100%',
  margin: '0 auto',
  objectFit: 'contain',
});

export const Title = styled('label', {
  display: 'block',
  fontFamily: font.family.Merriweather,
  fontSize: unit[3],
  lineHeight: unit[4],
  fontWeight: 'bolder',
  marginBottom: unit[3],
});

export const SubTitle = styled('label', {
  display: 'block',
  fontFamily: font.family.Merriweather,
  fontSize: '0.9rem',
  color: '#aaaaaa',
  marginBottom: unit[8],
});
