import { ReferenceRelationship } from './relationshipTypeEnum';

export const REFERENCES_RELATIONSHIP = [
  {
    label: 'Spouse',
    value: ReferenceRelationship.SPOUSE,
  },
  {
    label: 'Parent',
    value: ReferenceRelationship.PARENT,
  },
  {
    label: 'Guardian',
    value: ReferenceRelationship.GUARDIAN,
  },
  {
    label: 'Relative',
    value: ReferenceRelationship.RELATIVE,
  },
  {
    label: 'Friend',
    value: ReferenceRelationship.FRIEND,
  },
  {
    label: 'Other',
    value: ReferenceRelationship.OTHER,
  },
];
