import { merge } from 'lodash';
import { useCallback, useMemo } from 'react';

import { PillowForm } from '../../../../../components/PillowForm';
import { populateFields } from '../../../../../forms/populateFields';

const Controller = (props: any) => {
  const { presModel, send, context: workflowDetails } = props;
  const { updatedUser = {}, currentUser } = workflowDetails || {};

  const user = useMemo(
    () => merge({}, currentUser, updatedUser),
    [updatedUser, currentUser],
  );

  const onBack = useCallback(() => {
    send('Previous', { selectedStepFromApplicantReview: null });
  }, [send]);

  const onSubmit = useCallback(
    (data: any) => {
      send('Next', {
        updatedUser: merge({}, updatedUser, data),
        selectedStepFromApplicantReview: null,
      });
    },
    [send, updatedUser],
  );

  const enhancedProps = useMemo(
    () =>
      merge(
        {},
        props,
        { presModel },
        {
          presModel: {
            template: {
              header: {
                onBack,
              },
            },
            form: {
              fields: populateFields(presModel.form.fields, user),
              actions: {
                primary: {
                  handler: onSubmit,
                },
                secondary: {
                  handler: onBack,
                },
              },
            },
          },
        },
      ),
    [props, onSubmit, presModel, user, onBack],
  );

  return <PillowForm {...enhancedProps} />;
};

Controller.displayName = 'ApplicantReviewPersonalInfo.Controller';
export default Controller;
