import { get } from 'lodash';

import { NotImplementedError } from '../errors/NotImplementedError';

export enum PayFrequency {
  WEEKLY = 'WEEKLY',
  BI_WEEKLY = 'BI_WEEKLY',
  TWICE_MONTHLY = 'TWICE_MONTHLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

/**
 * Most of values should be equal to packages/core/los/data/comprehensiveApplication/types/incomeFrequencyEnum.js
 * [NOTE] having it separate to differentiate LOS vs APEX/CHECKLIGHT because LOS don't support all values
 */
const annualPaymentOccurances = {
  [PayFrequency.WEEKLY]: 52,
  [PayFrequency.BI_WEEKLY]: 26,
  [PayFrequency.MONTHLY]: 12,
  [PayFrequency.TWICE_MONTHLY]: 24,
  [PayFrequency.YEARLY]: 1,
};

export const getAnnualPaymentCountByPayFrequency = (
  frequency: PayFrequency,
) => {
  const occurrences = get(annualPaymentOccurances, frequency);
  if (occurrences) {
    return occurrences;
  }
  throw new NotImplementedError(
    'Unable to determine the total number of payments',
  );
};

export const convertAmountToNewPayFrequency = (
  sourceFrequency: PayFrequency,
  targetFrequency: PayFrequency,
  amount?: number,
) => {
  if (sourceFrequency === targetFrequency) {
    return amount;
  }

  const annualPaymentCountForSourceFrequency =
    getAnnualPaymentCountByPayFrequency(sourceFrequency);
  const annualTotalAmountForSourceFrequency =
    (amount || 0) * annualPaymentCountForSourceFrequency;
  const annualPaymentCountForTargetFrequency =
    getAnnualPaymentCountByPayFrequency(targetFrequency);
  return +(
    annualTotalAmountForSourceFrequency / annualPaymentCountForTargetFrequency
  ).toFixed();
};

export const getAnnualAmount = (
  sourceFrequency: PayFrequency,
  amount?: number,
) => getAmountByPayFrequency(PayFrequency.YEARLY)(sourceFrequency, amount);

export const getAmountByPayFrequency =
  (targetFrequency: PayFrequency) =>
  (sourceFrequency: PayFrequency, amount?: number) => {
    if (sourceFrequency !== targetFrequency) {
      return convertAmountToNewPayFrequency(
        sourceFrequency,
        targetFrequency,
        amount,
      );
    }
    return Number(amount);
  };
