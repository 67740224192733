import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { AddAdditionalIncome } from '../../../components/Incomes/common/AddAdditionalIncome/AddAdditionalIncome';
import { EditPageHeader } from '../../../components/shared/PageHeader/EditPageHeader';

export const IncomeAdd = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <EditPageHeader
        breadCrumbs={['Account', 'Income']}
        handleGoBack={() => navigate('..')}
        title="Tell us more about your income."
      />
      <AddAdditionalIncome />
    </Fragment>
  );
};
