import { cloneDeep, merge, pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useAsync } from 'react-async-hook';

import { PillowForm } from '../../../../../components/PillowForm';
import { useAPI } from '../../../../../hooks/useAPI/useAPI';

const errorMessage =
  'An unexpected exception occurred while attempting to load the vehicle makes';

const Controller = ({ send, context, presModel }: any) => {
  const { editedVehicle } = context;
  const api = useAPI();

  const loadMakes = useAsync(
    ({ year }: any): Promise<any> => api.get(`/vehicles/years/${year}/makes`),
    [editedVehicle],
  );

  const getVehicleData = useCallback(
    (newMakeId: string) =>
      newMakeId === editedVehicle.makeId
        ? editedVehicle
        : pick(editedVehicle, ['vehicleInformationSource', 'year']),
    [editedVehicle],
  );

  const onSubmit = useCallback(
    (makeId: any) => {
      const { label: make } =
        loadMakes.result?.find(({ value }: any) => makeId === value) || {};
      send('Next', {
        data: {
          editedVehicle: {
            ...getVehicleData(makeId),
            make,
            makeId,
          },
        },
      });
    },
    [send, loadMakes, getVehicleData],
  );

  const enhancedProps = useMemo(
    () =>
      cloneDeep(
        merge(
          {},
          { presModel },
          {
            presModel: {
              form: {
                fields: {
                  makeId: {
                    isLoading: loadMakes.loading,
                    options: loadMakes.result,
                    onChange: onSubmit,
                  },
                },
                globalErrors: loadMakes.error && [errorMessage],
              },
            },
          },
        ),
      ),
    [presModel, loadMakes, onSubmit],
  );

  return <PillowForm {...enhancedProps} />;
};

Controller.displayName = 'VehicleManualMakeSelection.Controller';
export default Controller;
