import moment from 'moment';

export const isOlderThan = (
  enteredDate: string | undefined,
  monthsAmountToCompare: number,
): boolean => {
  // Do not show previous if no date
  if (!enteredDate) {
    return true;
  }
  return moment().diff(moment(enteredDate), 'months') >= monthsAmountToCompare;
};
