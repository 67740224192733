import TagManager from 'react-gtm-module';

export const sendButtonEvent = (name: string | undefined) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'Button',
      Button: name,
      Location: window.location.pathname,
    },
  });
};
