import { merge } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';

import { populateFields } from '../../../../../forms/populateFields';
import { useAPI } from '../../../../../hooks/useAPI/useAPI';
import { generateComponentPropsWithListOptions } from '../../../../../utils/listOptions/generateComponentPropsWithListOptions';
import { Signal } from '../../../../common/constants/signals';
import View from './View';

const Controller = ({ send, context, presModel }: any) => {
  const { editedVehicle } = context;

  const api = useAPI();

  const [submitState, updateSubmitState] = useState<{
    trimId: string;
    mileage: number;
    loan?: any;
  }>();

  const loadTrims: any = useAsync(
    ({
      year,
      makeId,
      modelId,
    }: any): Promise<{ label: string; value: string }[]> =>
      api.get(
        `/vehicles/years/${year}/makes/${makeId}/models/${modelId}/trims`,
      ),
    [editedVehicle],
  );

  const loadImages = useAsyncCallback(async (trimId) => {
    const { year, makeId, modelId } = editedVehicle;
    return await api.get(
      `/vehicles/years/${year}/makes/${makeId}/models/${modelId}/trims/${trimId}/images`,
    );
  });

  const imageList = useMemo(
    () => loadImages.result || editedVehicle.imageList,
    [loadImages, editedVehicle],
  );

  useEffect(() => {
    if (submitState && !loadImages.loading) {
      const currentlySelectedTrim = loadTrims.result?.find(
        ({ value: trimId }: any = {}) => trimId === submitState.trimId,
      );
      send(Signal.Next, {
        data: {
          vehicle: {
            ...editedVehicle,
            ...submitState,
            trim: currentlySelectedTrim?.label,
            trimId: currentlySelectedTrim?.value,
            imageList,
          },
          editedVehicle: undefined,
        },
      });
    }
  }, [send, submitState, loadImages, editedVehicle, loadTrims, imageList]);

  const vehicleName = useMemo(() => {
    const { year, make, model: vehicleModel } = editedVehicle;
    return [year, make, vehicleModel].join(' ');
  }, [editedVehicle]);

  const enhancedProps = useMemo(
    () =>
      merge(
        {},
        { presModel },
        {
          presModel: {
            template: {
              img: {
                isLoading: loadImages.loading,
                imageList,
              },
            },
            form: {
              actions: {
                primary: {
                  isLoading: submitState && loadImages.loading,
                  handler: updateSubmitState,
                },
              },
              fields: {
                trimId: {
                  ...generateComponentPropsWithListOptions(loadTrims.result),
                  isLoading: loadTrims.loading,
                  onChange: loadImages.execute,
                },
              },
              globalErrors: loadTrims.error && ['Failed to load trims'],
            },
          },
          vehicleName,
        },
        {
          presModel: {
            form: {
              fields: merge(
                {},
                presModel.form.fields,
                populateFields(presModel.form.fields, editedVehicle),
              ),
            },
          },
        },
      ),
    [
      presModel,
      loadImages,
      loadTrims,
      submitState,
      updateSubmitState,
      vehicleName,
      imageList,
      editedVehicle,
    ],
  );

  return <View {...enhancedProps} />;
};

Controller.displayName = 'VehicleLookupConfirm.Controller';
export default Controller;
