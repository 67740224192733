import { get, has } from 'lodash';

import { NEW_PRUI_KEY, OLD_PRUI_KEY } from '../constants/constants';

export const migrateUniqueIdentifier = (
  attribution: Record<string, unknown>,
): Record<string, unknown> => {
  // If the "old" key is NOT present, we don't need to do anything
  if (!has(attribution, OLD_PRUI_KEY)) {
    return attribution;
  }

  return {
    ...attribution,
    [NEW_PRUI_KEY]: get(attribution, OLD_PRUI_KEY, ''),
  };
};
