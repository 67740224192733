import { ApplicantState } from '../ApplicantState';
import { ApplicantDto } from '../dto/ApplicantDto';
import { toApplicantStateFromIncomes } from './toApplicantStateFromIncomes';
import { toApplicantStateFromResidences } from './toApplicantStateFromResidences';

export const toApplicantState = (
  applicant: ApplicantDto,
): Partial<ApplicantState> => ({
  ...toApplicantStateFromIncomes(applicant.income),
  ...toApplicantStateFromResidences(applicant.residence),

  ...(applicant.personalInfo?.birthDate && {
    dateOfBirth: applicant.personalInfo.birthDate,
  }),
});
