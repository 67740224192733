import { merge, pick } from 'lodash';

import { fields } from '../../../../../forms/application';
import { populateFields } from '../../../../../forms/populateFields';
import { Signal } from '../../../../common/constants/signals';
import { AccountState } from '../../common/unified-workflow-context.interface';
import BaseModel from '../common/BaseModel';

const model = (send: any, { context: { user, coApplicant } }: AccountState) => {
  const formFields = pick(fields, ['applicationType']);
  const savedFields = populateFields(formFields, user);
  const populatedFields = merge({}, formFields, savedFields);
  const progress = coApplicant.coApplicantAnswer ? 18 : 21;

  return merge({}, BaseModel, {
    template: {
      header: {
        showBack: true,
        onBack: () => {
          send(Signal.Previous);
        },
      },
    },
    headerBlock: {
      progressBar: {
        progress,
        withContainer: true,
      },
      title: 'Application type',
      subtitle: ['Account', `${progress}% complete`],
    },
    form: {
      fields: populatedFields,
      actions: {
        primary: {
          testId: 'continue',
          label: 'Continue',
          handler: (data: any) => {
            send(Signal.Next, {
              data,
            });
          },
        },
      },
    },
    info: {
      title: 'Application Type',
      subtitle: 'Help',
      content: [
        {
          copy: [
            "We provide our customers with solutions that improve their financial situation. If you're in the market for a new or used car, we can help you get the rate you deserve. If you already have an auto loan, we could help you find a better rate that lowers your payment.",
          ],
        },
      ],
    },
  });
};

export default model;
