import { font, theme } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

const MainContainer = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
}));

const NavItemContainer = styled(
  'div',
  ({ $isSelected, $notificationCount, $isLast, $theme }: any) => ({
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.unit[3]} ${
      $notificationCount ? theme.unit[3] : theme.unit[11]
    } ${theme.unit[3]} ${theme.unit[3]}`,
    backgroundColor: $isSelected
      ? $theme.colors.transparency.whiteTransparentLight
      : $theme.colors.infoOverlay,
    borderRadius: theme.unit[1],
    ':hover': {
      backgroundColor: $theme.colors.transparency.whiteTransparentLight,
      color: $theme.colors.primaryOverlay,
    },
    fontWeight: 500,
    marginBottom: $isLast ? '' : theme.unit[1],
  }),
);

const LabelContainer = styled('span', ({ $isSelected, $theme }: any) => ({
  color: $isSelected
    ? $theme.colors.secondary
    : $theme.colors.transparency.whiteTransparentMedium,
  lineHeight: '23px',
  fontWeight: '500',
  display: 'block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const NotificationContainer = styled('div', ({ $theme }: any) => ({
  backgroundColor: $theme.colors.primaryOverlay,
  marginLeft: theme.unit[3],
  borderRadius: '4px',
  fontWeight: '500',
  fontSize: font.size.headingSmall,
  padding: `4px ${theme.unit[2]}`,
  color: $theme.colors.infoOverlay,
}));

export {
  NotificationContainer,
  LabelContainer,
  NavItemContainer,
  MainContainer,
};
