import { get, isNaN, isNumber, toString } from 'lodash';
import { ChangeEvent, KeyboardEvent } from 'react';
import { UseFormSetValue, UseFormTrigger } from 'react-hook-form';

export const inputEventHandler = {
  currency: {
    // Used to remove the $ prefix when the input field is blurred.
    blur:
      (
        name: string,
        setValue: UseFormSetValue<any>,
        trigger: UseFormTrigger<any>,
      ) =>
      (event: React.FocusEvent<HTMLInputElement>) => {
        if (get(event, 'target.value') === '$') {
          setValue(name, '');
        }

        // Trigger validation on blur as we've overridden the default blur behavior.
        trigger(name);
      },
    // Used to format the input field with commas and a $ prefix.
    change:
      (name: string, setValue: UseFormSetValue<any>) =>
      (event: ChangeEvent<HTMLInputElement>) => {
        setValue(
          name,
          inputEventHandler.currency.formatter(event.target.value),
          // Trigger validation on change as we've overridden the default change behavior.
          // Without shouldValidate react-hook-form isValid state will not be updated as the input value changes.
          { shouldDirty: true, shouldValidate: true },
        );
      },
    // Used to add the $ prefix when the input field is focused.
    focus:
      (name: string, setValue: UseFormSetValue<any>) =>
      (event: React.FocusEvent<HTMLInputElement>) => {
        if (!get(event, 'target.value')) {
          setValue(name, '$');
        }
      },
    formatter: (value: string | number | null): string => {
      if (!value) {
        return '';
      }

      const numberValue = isNumber(value)
        ? value
        : parseInt(value.replace(/[^.\d]/g, ''), 10);

      if (isNaN(numberValue)) {
        return '';
      }

      return `$${numberValue.toLocaleString()}`;
    },
    parser: (value: string | number | null | undefined) => {
      const stringValue = toString(value);

      return stringValue.replace(/[^.\d]/g, '');
    },
  },
  number: {
    keydown: (event: KeyboardEvent<HTMLInputElement>) => {
      const allowedKeys = [
        'Backspace',
        'Delete',
        'Enter',
        'ArrowLeft',
        'ArrowRight',
        'ArrowUp',
        'ArrowDown',
        'Tab',
      ];

      const allowedWithControl = ['a', 'c', 'v', 'x'];

      if (
        (event.ctrlKey || event.metaKey) &&
        allowedWithControl.includes(event.key)
      ) {
        return;
      }

      if (allowedKeys.includes(event.key)) {
        return;
      }

      if (isNaN(parseInt(event.key, 10))) {
        event.preventDefault();
      }
    },
    pasteCapture: (event: any) => {
      const clipboardData = get(event, 'clipboardData');

      if (clipboardData) {
        const value = clipboardData.getData('Text');

        if (!/^\d+$/g.test(value)) {
          event.preventDefault();
        }
      }
    },
  },
  phoneNumber: {
    formatter: (value: string | number | null | undefined) => {
      const stringValue = toString(value).replace(/[^\d]/g, '');
      let formattedString = '';

      formattedString +=
        stringValue.length >= 3
          ? `(${stringValue.substring(0, 3)}`
          : stringValue;

      formattedString +=
        stringValue.length >= 4
          ? `) ${stringValue.substring(3, 6)}`
          : stringValue.substring(3);

      formattedString +=
        stringValue.length >= 7 ? `-${stringValue.substring(6)}` : '';

      return formattedString;
    },
    parser: (value: string | number | null | undefined) => {
      const stringValue = toString(value);

      return stringValue.replace(/[^\d]/g, '');
    },
    keyDown: (event: KeyboardEvent<HTMLInputElement>) => {
      const allowedKeys = [
        'Backspace',
        'Delete',
        'Enter',
        'ArrowLeft',
        'ArrowRight',
        'ArrowUp',
        'ArrowDown',
        'Tab',
      ];

      const allowedWithControl = ['a', 'c', 'v', 'x'];

      if (
        (event.ctrlKey || event.metaKey) &&
        allowedWithControl.includes(event.key)
      ) {
        return;
      }

      if (allowedKeys.includes(event.key)) {
        return;
      }

      if (isNaN(parseInt(event.key, 10))) {
        event.preventDefault();
      }
    },
  },
  verificationCode: {
    keyDown: (event: KeyboardEvent<HTMLInputElement>) => {
      if (['Backspace', 'Delete'].includes(event.key)) {
        return;
      }

      const allowedWithControl = ['a', 'c', 'v', 'x'];

      if (
        (event.ctrlKey || event.metaKey) &&
        allowedWithControl.includes(event.key)
      ) {
        return;
      }

      const target = event.target as HTMLInputElement;

      // input allows for one-time-code event so the max
      // length attribute cannot be assigned and is checked here.
      if (isNaN(parseInt(event.key, 10)) || target.value.length >= 1) {
        event.preventDefault();
      }
    },
  },
};
