import { unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const Container = styled(
  'div',
  ({ $isTwoTabs, $positionAbsolute, $theme }: any) => ({
    display: $isTwoTabs ? 'inline-flex' : 'flex',
    border: $isTwoTabs ? `1px solid ${$theme.colors.secondaryOverlay}` : '',
    padding: $isTwoTabs ? '4px' : '',
    borderRadius: unit[3],
    ...($positionAbsolute
      ? {
          position: 'absolute',
          left: 0,
          top: 0,
        }
      : {}),
  }),
);

export const HorizontalSpacer = styled('div', ({ $isTwoTabs }: any) => ({
  minWidth: $isTwoTabs ? unit[1] : unit[2],
}));

export const TabsContainer = styled('div', ({ $isTwoTabs }: any) => ({
  // marginTop: `-${unit[2]}`,
  width: '100%',
  position: 'relative',
  overflow: 'auto',
  height: $isTwoTabs ? '' : unit[7],
}));

export const TabsWrapper = styled('div', {
  margin: [unit[2], 0, unit[6]].join(' '),
});
