import { merge } from 'lodash';

import { componentTypes } from '../../../../../constants/componentTypes';
import { InterestChoices } from '../../../../../constants/interestChoices';
import { InputFormatter } from '../../../../../utils/input/inputFormatter';
import { OffersContext } from '../../../../common/interfaces/offers.interface';
import BaseModel from '../common/BaseModel';
import { defaultInterest, getMaxDownPayment } from './utils';

const model = (send: any, { context }: { context: OffersContext }) =>
  merge({}, BaseModel, {
    template: {
      header: {
        showClose: false,
      },
      footer: {
        isHidden: true,
      },
    },
    form: {
      isStickyActions: true,
      fields: {
        sortBy: {
          name: 'sortBy',
          component: componentTypes.DROPDOWN,
          options: [
            {
              value: InterestChoices.LOWEST_PAYMENT,
              label: 'Lowest payment',
            },
            {
              value: InterestChoices.LOWEST_INTEREST_RATE,
              label: 'Lowest interest rate',
            },
          ],
          label: 'Sort offers by',
          value: context.offers?.interest || defaultInterest,
        },
        downPayment: {
          name: 'downPayment',
          label: 'Down payment',
          component: componentTypes.TEXT,
          format: InputFormatter.MONEY,
          validationRules: {
            max: {
              value: getMaxDownPayment(context),
              message: `The maximum down payment allowed for this loan is ${getMaxDownPayment(
                context,
              )}.  Please adjust.`,
            },
            min: {
              value: 0,
              message: 'Valid Down Payment is required',
            },
          },
          value: context.downPayment || 0,
        },
        offers: {
          name: 'offers',
          component: componentTypes.RADIO,
          largeTitle: true,
          value: context.offers?.selectedOfferOptionId || '0',
        },
      },
      actions: {
        primary: {
          testId: 'select',
          label: 'Select',
          preserveLabel: true,
          preserveTestId: true,
        },
      },
    },
    headerBlock: {
      title: 'Select a base offer from our lender network',
      subtitle: ['Offers', '50% complete'],
      progressBar: {
        progress: 50,
        withContainer: true,
      },
    },
    info: {
      title: 'Select a base offer from our lender network',
      subtitle: 'Help',
    },
  });

export default model;
