import { has } from 'lodash';

import { ContextValidators as ContextValidatorsType } from '../../../common/interfaces/workflow.interface';
import { Step, VerificationContext } from './Model';

const verificationContextProperties: (keyof VerificationContext)[] = [
  'requiredDocuments',
  'uploadedDocuments',
  'workflow',
  'currentVehicle',
];

export const ContextValidators: Record<
  string,
  ContextValidatorsType<VerificationContext>
> = [Step.VerificationDocumentsList, Step.VerificationDocumentUpload].reduce(
  (acc, step) => {
    return {
      ...acc,
      [step]: (context: VerificationContext) => {
        return verificationContextProperties.every((property) =>
          has(context, property),
        );
      },
    };
  },
  {},
);
