import { ChecklightSessionStatus } from '../../common';
import { ApplicationTaskEnum } from '../../constants/applicationTasks';
import { LoanTaskEnum } from '../../constants/loanTasks';
import { Workflow } from '../../constants/Workflow';

export const APPLICATION_STATUSES_PER_TASK = {
  [Workflow.checklight]: {
    [ApplicationTaskEnum.SignContracts]: [
      ChecklightSessionStatus.SIGN_CONTRACTS,
    ],
    [ApplicationTaskEnum.UploadDocuments]: [
      ChecklightSessionStatus.SUBMITTED_TO_LENDER,
      ChecklightSessionStatus.PREPARING_CONTRACTS,
      ChecklightSessionStatus.SIGN_CONTRACTS,
      ChecklightSessionStatus.REVIEWING_DOCUMENTS,
      ChecklightSessionStatus.FINALIZE_STIPS,
      ChecklightSessionStatus.WAITING_ON_FUNDING,
      ChecklightSessionStatus.APPROVED_BY_LENDER,
    ],
    [ApplicationTaskEnum.References]: [
      ChecklightSessionStatus.FINALIZE_STIPS,
      ChecklightSessionStatus.WAITING_ON_FUNDING,
    ],
  },
  [Workflow.unified]: {
    [LoanTaskEnum.ViewOffers]: [
      ChecklightSessionStatus.HAS_OFFERS,
      ChecklightSessionStatus.PENDING_SUBMISSION,
    ],
    [LoanTaskEnum.Applicant]: [ChecklightSessionStatus.SELECTED_OFFER],
    [LoanTaskEnum.CoApplicant]: [ChecklightSessionStatus.APPLICANT_COMPLETE],
    [LoanTaskEnum.Review]: [ChecklightSessionStatus.CO_APPLICANT_COMPLETE],
    [LoanTaskEnum.NoOffers]: [ChecklightSessionStatus.NO_OFFERS],
    [LoanTaskEnum.Invalid]: [
      ChecklightSessionStatus.EXPIRED,
      ChecklightSessionStatus.DUPLICATE,
      ChecklightSessionStatus.CANCELLED,
      ChecklightSessionStatus.CANCELLED_CONSUMER,
    ],
    [ApplicationTaskEnum.UploadDocuments]: [
      ChecklightSessionStatus.SUBMITTED_TO_LENDER,
      ChecklightSessionStatus.PREPARING_CONTRACTS,
      ChecklightSessionStatus.SIGN_CONTRACTS,
      ChecklightSessionStatus.REVIEWING_DOCUMENTS,
      ChecklightSessionStatus.FINALIZE_STIPS,
      ChecklightSessionStatus.WAITING_ON_FUNDING,
      ChecklightSessionStatus.APPROVED_BY_LENDER,
    ],
  },
};
