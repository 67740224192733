import { merge } from 'lodash';

import { ApplicationType } from '../../../../../common';
import { PillowType } from '../../../../../components/shared/PillowImage/pillowTypes';
import { componentTypes } from '../../../../../constants/componentTypes';
import { Signal } from '../../../../common/constants/signals';
import { DynamicWorkflowConfiguration } from '../../../../common/interfaces/gateStep.interface';
import {
  AccountState,
  VehicleContext,
  VehicleInformationSourceType,
} from '../../common/unified-workflow-context.interface';
import BaseModel from '../common/BaseModel';
import { optionsList, VehicleInformationSource } from './options';

const model = (
  send: any,
  { context: { coApplicant, user, editedVehicle } }: AccountState,
  dynamicWorkflowConfiguration: DynamicWorkflowConfiguration,
) => {
  const { vehicleInformationSource } = editedVehicle || {};
  const { applicationType } = user;
  const progress = coApplicant.coApplicantAnswer ? 54 : 56;

  const getVehicleData = (
    newVehicleInformationSource: VehicleInformationSourceType,
  ) => {
    return vehicleInformationSource !== newVehicleInformationSource
      ? {}
      : editedVehicle;
  };

  const sourceOptions = optionsList(
    dynamicWorkflowConfiguration?.unleash?.showPlateProvinceOption &&
      applicationType === ApplicationType.REFINANCE,
  );
  const defaultValue = sourceOptions.some(
    ({ value }) => value === vehicleInformationSource,
  )
    ? vehicleInformationSource
    : VehicleInformationSource.MakeAndModel;

  return merge({}, BaseModel, {
    template: {
      header: {
        showBack: true,
        onBack: () => {
          send(Signal.Previous);
        },
      },
      img: {
        type: PillowType.VEHICLE_LOOKUP,
      },
    },
    headerBlock: {
      title: 'Search for my vehicle by...',
      progressBar: {
        progress,
        withContainer: true,
      },
      subtitle: ['Account', `${progress}% complete`],
    },
    form: {
      fields: {
        vehicleInformationSource: {
          name: 'vehicleInformationSource',
          component: componentTypes.RADIO,
          options: sourceOptions,
          defaultValue,
          largeTitle: true,
        },
      },
      actions: {
        primary: {
          label: 'Continue',
          testId: 'continue',
          handler: ({
            vehicleInformationSource,
          }: Pick<VehicleContext, 'vehicleInformationSource'>) => {
            send(Signal.Next, {
              data: {
                editedVehicle: merge(
                  {},
                  getVehicleData(vehicleInformationSource),
                  {
                    vehicleInformationSource,
                  },
                ),
              },
            });
          },
        },
      },
    },
    info: {
      title: 'Identify vehicle',
      subtitle: 'Help',
      content: [
        {
          copy:
            applicationType === ApplicationType.PURCHASE
              ? [
                  'There are many ways to find your vehicle. Please use the method easiest to you whether it be by VIN, or manually using Vehicle Make, Model, Year, and Trim.',
                ]
              : [
                  'There are many ways to find your vehicle. Please use the method easiest to you whether it be by License Plate or VIN, or manually using Vehicle Make, Model, Year, and Trim.',
                ],
        },
      ],
    },
  });
};

export default model;
