import { Navigate } from 'react-router-dom';

import { useWorkflowPathname } from '../../../../../hooks/useWorkflowPathname/useWorkflowPathname';

const ExitToApplicant = ({ context }: any) => {
  const rootPath = useWorkflowPathname();

  return <Navigate state={context} to={`/${rootPath}/workflow/applicant/`} />;
};

export default ExitToApplicant;
