import { createContext } from 'react';

export interface MatchMediaContextProps {
  isDesktop?: boolean;
  isTablet?: boolean;
  isMobile?: boolean;
  isReady: boolean;
}

export const defaultContextValue: MatchMediaContextProps = {
  isReady: false,
};

export default createContext(defaultContextValue);
