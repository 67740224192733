import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useModal } from '../../hooks/useModal/useModal';
import { ModalWrapper } from './styles';

/**
 * Prevent page scrolling when a modal is open
 * Inspired by: https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
 */
export const ModalsScrollWrapper = ({ children }: any) => {
  const { isShowingAny, close } = useModal();
  const [distanceToModal, setDistanceToModal] = useState<number>();

  const location = useLocation();

  // Using object here to get new value on each state update
  const [previousScrollDistance, setPreviousScrollDistance] = useState<{
    distanceToModal?: number;
  }>({});

  /**
   * Save window.scroll value to state and restore scroll when modal is closed
   */
  useEffect(() => {
    // On modal open
    if (isShowingAny && distanceToModal === undefined) {
      setDistanceToModal(window.scrollY);
      return;
    }
    // On modal close
    if (!isShowingAny && distanceToModal !== undefined) {
      setDistanceToModal(undefined);
      setPreviousScrollDistance({ distanceToModal });
    }
  }, [isShowingAny, distanceToModal]);

  /**
   * Close modal & reset distanceToModal on location change:
   * location is being added to the dependency list in order to prevent an issue where the modal is open and
   * the user hits the back button on their browser. The main-layout-root will remain styled as if the modal was open,
   * causing all content on the page to be shifted upward off-screen (top property being set to negative value).
   * this ensures that we recalculate the distance to the modal when the user navigates.
   */
  useEffect(() => {
    close();

    setDistanceToModal(undefined);
    setPreviousScrollDistance({ distanceToModal });
  }, [location]);

  /**
   * Using second effect to give React time to update styling before calling scroll
   */
  useEffect(() => {
    if (previousScrollDistance.distanceToModal !== undefined) {
      window.scrollTo(0, previousScrollDistance.distanceToModal);
    }
  }, [previousScrollDistance]);

  return (
    <ModalWrapper $distanceToModal={distanceToModal} id="main-layout-root">
      {children}
    </ModalWrapper>
  );
};
