import { useMachine } from '@xstate/react';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useMemo } from 'react';
import { useAsync } from 'react-async-hook';
import { createMachine } from 'xstate';

import { unit } from '../../../common/theme/theme';
import { Loader } from '../../../components/shared/Loader/Loader';
import { Workflow } from '../../../constants/Workflow';
import { useAPI } from '../../../hooks/useAPI/useAPI';
import { useErrorReporting } from '../../../hooks/useErrorReporting/useErrorReporting';
import { FeatureFlag } from '../../../hooks/useFeatureFlags/featureFlagsEnum';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags/useFeatureFlags';
import { WorkflowInitialContext } from '../../../providers/context/WorkflowInitialContext/WorkflowInitialContext';
import { UnifiedWorkflowDynamicConfiguration } from '../../assembly/UnifiedDefaultSignUp/common/unified-workflow-context.interface';
import { Gate } from '../interfaces/gate.interface';
import { unifiedWorkflowDefaultConfiguration } from '../metadata/unified-workflow-default-configuration';
import { GateView } from './GateView';

export const GateController = ({
  gate,
  setSend,
  setCurrentStep,
  setContext,
  clearContext,
  // From HOC below
  initialStep,
  cachedContext,
  defaultConfiguration: stepTransitionConfig,
}: {
  gate: Gate<any>;
  setSend?: ({ func }: any) => void;
  setCurrentStep?: (step: string) => void;
  setContext?: (ctx: any) => void;
  clearContext?: () => void;
  initialStep?: string;
  cachedContext?: any;
  defaultConfiguration?: any;
}) => {
  const [initialContext, setInitialContext] = useContext(
    WorkflowInitialContext,
  );
  const { captureException } = useErrorReporting();
  const api = useAPI();
  const { isEnabled } = useFeatureFlags();

  const unleash = useMemo(
    () => ({
      showPlateProvinceOption: isEnabled(
        FeatureFlag.ENABLE_PLATE_TO_VIN_SERVICE,
      ),
    }),
    [isEnabled],
  );

  const contentfulRequest = useAsync(
    () =>
      api
        .get(`/ui-configuration/workflow?id=${Workflow.unified}`)
        .catch((e) => {
          captureException(e);
          return unifiedWorkflowDefaultConfiguration;
        }),
    [],
  );

  const xStateMachine = useMemo(() => {
    const xStateConfig = {
      id: gate.name,
      initial: initialStep || gate.initial,
      context: !isEmpty(cachedContext) ? cachedContext : initialContext,
      predictableActionArguments: true,
      states: gate.steps.reduce((acc, step) => {
        return {
          ...acc,
          [step.name]: step.state,
        };
      }, {}),
      ...stepTransitionConfig,
    };

    const xStateServices = {
      ...gate.serviceOptions,
      ...(gate.serviceOptions.services
        ? {
            services: gate.serviceOptions.services(api),
          }
        : {}),
    };

    return createMachine<any>(xStateConfig, xStateServices);
  }, []);

  const machine = useMachine(xStateMachine, { devTools: true });

  const [current, send] = machine;

  const { value: fullStepName } = current;

  const currentStep =
    typeof fullStepName === 'string'
      ? fullStepName
      : Object.keys(fullStepName)[0];

  // Setting callbacks for hoc wrappers
  useEffect(() => {
    setSend && setSend({ func: send });
  }, [send, setSend]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setCurrentStep && setCurrentStep(currentStep);
  }, [currentStep]);

  useEffect(() => {
    setContext && setContext(current.context);
  }, [current.context]);

  useEffect(() => {
    if (current.done) {
      clearContext && clearContext();
      setInitialContext(current.context);
    }
  }, [current.done]);

  // Rendering
  const stepConfig = useMemo(() => {
    return gate.steps.find((step) => step.name === currentStep);
  }, [gate, currentStep]);

  const dynamicWorkflowConfiguration = useMemo(
    () => ({
      contentful:
        contentfulRequest.result as UnifiedWorkflowDynamicConfiguration,
      unleash,
    }),
    [contentfulRequest.result, unleash],
  );

  if (stepConfig?.withDynamicConfiguration && contentfulRequest.loading) {
    return <Loader size={unit[6]} fullScreen />;
  }

  if (!stepConfig) {
    return null;
  }

  return (
    <GateView
      currentStep={currentStep}
      dynamicWorkflowConfiguration={dynamicWorkflowConfiguration}
      machine={machine}
      stepConfig={stepConfig}
    />
  );
};
