import PropTypes from 'prop-types';

import { Loader } from './styles';

export const PlaceholderLoader = ({ height, width }: any) => {
  return <Loader $height={height} $width={width} />;
};

PlaceholderLoader.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};
