import { fields } from '../../../../forms/user';
import { IncomeReviewSignal } from '../../interfaces/applicant.interface';
import { onEditIncome } from './utils';

export const incomeFormConfig = (
  send: any,
  options: any,
  signal: IncomeReviewSignal,
) => ({
  form: {
    fields: {
      employments: {
        ...fields.employments,
        options,
        onChange: onEditIncome(send, signal),
      },
    },
    actions: {
      primary: {
        label: 'No, continue',
      },
      secondary: {
        testId: 'addAdditionalIncome',
        label: 'Add income',
        handler: () => {
          send(signal.AddIncome);
        },
      },
    },
  },
});
