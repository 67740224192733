import {
  DocumentEnum,
  DOCUMENTS_CONFIG,
} from '../../../../../services/document/documentsConfig';

export enum DocumentStatus {
  Requested,
  Received,
}

export const documentPanelsConfig = (
  requiredDocuments: DocumentEnum[],
  uploadedDocuments: DocumentEnum[],
) =>
  requiredDocuments.map((doc, index) => {
    const { alwaysAllowUpload, ...documentConfig } =
      DOCUMENTS_CONFIG[doc] || {};

    const isUploaded = uploadedDocuments.includes(doc) && !alwaysAllowUpload;

    return {
      ...documentConfig,
      key: index.toString(),
      value: doc,
      status: isUploaded ? DocumentStatus.Received : DocumentStatus.Requested,
      subtitle: isUploaded ? 'Received' : 'Requested',
      disabled: isUploaded,
    };
  });
