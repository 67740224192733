import { merge } from 'lodash';

import { populateFields } from '../../../../forms/populateFields';

export const updatedUserValues =
  (fields: any) =>
  (send: any, { context }: any) => {
    const { updatedUser = {}, currentUser } = context;

    const user = merge({}, currentUser, updatedUser);

    return populateFields(fields, user);
  };

export const getPreviousIncomeValidateFn = (currentDate: any) => (v: any) =>
  Date.parse(currentDate) > Date.parse(v) ||
  'Date should not be greater then start date of current employment';
