/* eslint-disable no-throw-literal */
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { get, merge } from 'lodash';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useForm, useWatch } from 'react-hook-form';

import { isApplicationValid } from '../../../../../common';
import { PillowForm } from '../../../../../components/PillowForm';
import { ConfirmModal } from '../../../../../components/shared/confirmModal/ConfirmModal';
import { config } from '../../../../../config';
import { useAPI } from '../../../../../hooks/useAPI/useAPI';
import { useCurrentUserContext } from '../../../../../hooks/useCurrentUserContext/useCurrentUserContext';
import { useModal } from '../../../../../hooks/useModal/useModal';
import {
  DocumentEnum,
  DOCUMENTS_CONFIG,
} from '../../../../../services/document/documentsConfig';
import {
  createFilesPayload,
  FilesPayload,
} from '../../../../../services/document/documentService';
import { Signal, VerificationContext } from '../../XState/Model';

type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};

const Controller = (props: {
  presModel: any;
  send: any;
  context: WithRequiredProperty<
    VerificationContext,
    'selectedVerificationTask'
  >;
}) => {
  const methods = useForm();
  const {
    formState: { isValid, isDirty },
  } = methods;
  const { context, presModel, send } = props;
  const api = useAPI();
  const { isShowing, open, close } = useModal();
  const { selectedVerificationTask, currentVehicle, uploadedDocuments } =
    context;
  const [uploadProgress, setUploadProgress] = useState(0);

  const [currentUserContext] = useCurrentUserContext();

  const { firstName, lastName } = currentUserContext;

  const {
    checklightSession: { autopayNumber },
  } = currentVehicle;
  const {
    alwaysAllowUpload,
    description,
    documents = [],
    label,
    info,
  } = DOCUMENTS_CONFIG[selectedVerificationTask as DocumentEnum] || {};

  useEffect(() => {
    // go back to list in the case where no option was selected
    // or if the document has already been received
    if (
      !alwaysAllowUpload &&
      (!selectedVerificationTask ||
        uploadedDocuments.includes(selectedVerificationTask))
    ) {
      send('Previous');
    }
  });

  const updatedContext = useMemo(
    () => ({
      data: {
        uploadedDocuments: [...uploadedDocuments, selectedVerificationTask],
        selectedVerificationTask: null,
      },
    }),
    [uploadedDocuments, selectedVerificationTask],
  );

  const onModalConfirm = useCallback(() => {
    close();
    send(Signal.Previous);
  }, [send, close]);

  const confirmCancelModal = useMemo(
    () =>
      isShowing ? (
        <ConfirmModal
          onCancel={close}
          onConfirm={() => {
            onModalConfirm();
          }}
        />
      ) : null,
    [isShowing, onModalConfirm, close],
  );

  const onBack = useCallback(() => {
    if (isDirty) {
      open();
      return;
    }
    send('Previous');
  }, [open, send, isDirty]);

  type SignedUrlResponse = {
    url: string;
    uuid: string;
  };

  const getSignedUrl = (payload: FilesPayload): Promise<SignedUrlResponse> =>
    api
      .post<never, SignedUrlResponse>(
        `/documents/signed-url`,
        payload.metadata,
        {
          timeout: config.fileUploadTimeout,
        },
      )
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            AutopayNumber: payload.metadata.autopayNumber,
            FileSize: payload.file.size,
            FileType: payload.file.type,
          },
          tags: { 'document.subtype': payload.metadata.documentSubType },
        });

        throw error;
      });

  const uploadDocument =
    (payload: FilesPayload) =>
    (response: SignedUrlResponse): Promise<FilesPayload> => {
      return axios
        .put(response.url, payload.file, {
          headers: {
            'Content-Type': payload.file.type,
          },
          onUploadProgress(progressEvent) {
            if (progressEvent.total) {
              setUploadProgress(
                Math.round((progressEvent.loaded * 100) / progressEvent.total),
              );
            }
          },
        })
        .then(() => {
          reportInvalidApplicationDocumentUpload(payload);

          return payload;
        })
        .catch((error) => {
          Sentry.captureException(error, {
            extra: {
              AutopayNumber: payload.metadata.autopayNumber,
              FileSize: payload.file.size,
              FileType: payload.file.type,
            },
            tags: { 'document.subtype': payload.metadata.documentSubType },
          });

          throw error;
        })
        .finally(() => {
          setUploadProgress(0);
        });
    };

  const reportInvalidApplicationDocumentUpload = (
    documentPayload: FilesPayload,
  ) => {
    if (isApplicationValid(currentVehicle.checklightSession)) {
      return;
    }

    Sentry.captureException(
      new Error('User uploaded document [invalid application status]'),
      {
        extra: {
          applicationStatus: get(currentVehicle, 'checklightSession.status'),
          autopayNumber: get(currentVehicle, 'checklightSession.autopayNumber'),
          documentData: get(documentPayload, 'metadata'),
          userId: get(currentUserContext, 'id'),
        },
      },
    );
  };

  const notifyDocumentUploaded = (payload: FilesPayload): Promise<void> => {
    const documentSubtypeName = [
      firstName,
      lastName,
      'uploaded',
      payload.uploadIdentifier,
    ]
      .filter(Boolean)
      .join(' ');

    return api.post(`/documents/notify`, {
      autopayNumber,
      documentSubtypeName,
    });
  };

  const onSubmit = useAsyncCallback(
    async (formData: { [key: string]: { metadata: File } }) => {
      const promises = createFilesPayload(
        formData,
        autopayNumber as string,
        lastName,
      ).map((payload: FilesPayload) =>
        getSignedUrl(payload)
          .then(uploadDocument(payload))
          .then(notifyDocumentUploaded),
      );

      try {
        await Promise.all(promises);

        send('Next', updatedContext);
      } catch (error) {
        const message: string = get(error, '0.message', '');

        if (message.includes('Network Error')) {
          throw [
            'A network error has occurred. Please try again with a stable internet connection.',
          ];
        }

        throw [
          'There was an unexpected error attempting to upload the file. Please try again.',
        ];
      }
    },
  );

  const formValues = useWatch({
    control: methods.control,
  });

  const progress = useMemo(() => {
    const requiredDocumentsAmount = documents.length;
    const uploadedDocumentsAmount =
      Object.values(formValues).filter(Boolean).length;
    return (uploadedDocumentsAmount * 100) / requiredDocumentsAmount;
  }, [documents, formValues]);

  const enhancedProps = useMemo(
    () =>
      merge(
        {},
        { presModel },
        {
          presModel: {
            template: {
              header: {
                onBack,
              },
            },
            form: {
              actions: {
                primary: {
                  handler: methods.handleSubmit(onSubmit.execute),
                  isDisabled: !isValid,
                  isLoading: onSubmit.loading,
                },
                secondary: {
                  handler: onBack,
                },
              },
              globalErrors: onSubmit.error,
            },
            headerBlock: {
              title: label,
              paragraph: description,
              progressBar: {
                progress: uploadProgress || progress,
              },
            },
            info,
          },
        },
      ),
    [
      onSubmit,
      presModel,
      methods,
      label,
      description,
      isValid,
      info,
      onBack,
      progress,
      uploadProgress,
    ],
  );

  return (
    <Fragment>
      <PillowForm {...enhancedProps} methods={methods} />
      {confirmCancelModal}
    </Fragment>
  );
};

Controller.displayName = 'VerificationDocumentUpload.Controller';
export default Controller;
