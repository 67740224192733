import { get } from 'lodash';

import { FormFiledInterface } from '../../../components/shared/Form/interfaces/formFields.interface';
import { componentTypes } from '../../../constants/componentTypes';
import { showPreviousIncome } from './common';
import { unifiedCommonFields } from './unified';

/**
 * Basic configuration for edit primary/previous income forms.
 * Forms are used on account income page and on applicant/co-applicant gates
 */

const currentIncomeFields = unifiedCommonFields('current');
const previousIncomeFields = {
  subtitle: {
    label: 'Previous income',
    hint: 'Our lenders require one year of employment history. Please share your previous employment with us.',
    name: 'subtitle',
    component: componentTypes.SUBTITLE,
  },
  ...unifiedCommonFields('previous'),
};

const formattedPreviousIncomeFields = Object.entries<FormFiledInterface>(
  previousIncomeFields,
).reduce((acc, [key, value]) => {
  return {
    ...acc,
    [`${key}Previous`]: {
      ...value,
      shouldUnregister: true,
      dependsOn: {
        fields: (value.dependsOn?.fields || []).concat('current.startDate'),
        handler: (props: any) => {
          value.dependsOn?.handler && value.dependsOn?.handler(props);
          const { data, setVisible, setValidation } = props;
          const startDate = get(data, 'current.startDate');
          const displayPrevious = showPreviousIncome({ startDate });

          setVisible(displayPrevious);

          if (key === 'startDate') {
            setValidation({
              validate: (v: any) =>
                Date.parse(startDate as string) > Date.parse(v) ||
                'Date should not be greater then start date of current employment',
            });
          }
        },
      },
    },
  };
}, {});

export const editIncomeForm = {
  fields: {
    ...currentIncomeFields,
    ...formattedPreviousIncomeFields,
  },
  actions: {
    primary: {
      label: 'Save',
      testId: 'save',
    },
    secondary: {
      label: 'Cancel',
      testId: 'cancel',
    },
  },
  withConfirmModal: true,
};
