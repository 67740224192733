import PropTypes from 'prop-types';
import { useContext } from 'react';

import { theme } from '../../../common/theme';
import { DynamicBrandingContext } from '../../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { Icon } from '../Icon/Icon';
import { IconTypes } from '../Icon/iconTypes';
import { Container, Input, Label } from './styles';

export const Checkbox = ({
  isDisabled = false,
  isError = false,
  isIndeterminate = false,
  onChange = () => {},
  checked = false,
  testId,
  viewOnly,
}: any) => {
  const {
    branding: { theme: $theme },
  } = useContext(DynamicBrandingContext);
  return (
    <Container>
      <Label
        $isDisabled={isDisabled}
        $isError={isError}
        $isIndeterminate={isIndeterminate}
        $isSelected={checked}
        $viewOnly={viewOnly}
        data-testid={testId}
      >
        <Input
          checked={checked}
          disabled={isDisabled}
          type="checkbox"
          onChange={() => !isDisabled && !viewOnly && onChange(checked)}
        />
        {checked ? (
          <Icon
            color={$theme.colors.successOverlay}
            height={theme.unit[3]}
            name={isIndeterminate ? IconTypes.INDETERMINATE : IconTypes.CONTROL}
          />
        ) : null}
      </Label>
    </Container>
  );
};

Checkbox.propTypes = {
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  testId: PropTypes.string,
  viewOnly: PropTypes.bool,
};
