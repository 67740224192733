import { helpers, theme } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const OuterContainer = styled('div', () => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export const MediaContainer = styled('div', ({ $theme }: any) => {
  return {
    height: '207px',
    position: 'relative',
    backgroundColor: $theme.colors.tertiaryOverlay,
    borderRadius: theme.unit[1],
    margin: [theme.unit[3], theme.unit[3], 0].join(' '),
    padding: theme.unit[4],
  };
});

export const BodyContainer = styled('div', () => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.unit[3],
}));

export const BodyFooter = styled(
  'div',
  ({ $theme }: any) =>
    ({
      ...helpers.flexVerticalCenter,
      justifyContent: 'space-between',
      borderTop: `1px solid ${$theme.colors.secondaryActive}`,
      maxHeight: theme.unit[9],
      padding: theme.unit[3],
      color: $theme.colors.primary,
      textDecoration: 'underline',
      fontWeight: 500,
    } as any),
);

export const Title = styled('div', () => ({
  fontSize: theme.common.font.size.headingLarge,
  fontFamily: theme.common.font.family.Merriweather,
  marginBottom: theme.unit[1],
}));

export const Paragraph = styled('div', ({ $theme }: any) => ({
  fontSize: theme.common.font.size.subTitle,
  color: $theme.colors.primaryActive,
  flexGrow: 1,
}));

export const Subtitle = styled('div', ({ $hasMarginBottom, $theme }: any) => ({
  fontSize: theme.common.font.size.subTitle,
  color: $theme.colors.primaryActive,
  marginBottom: $hasMarginBottom ? theme.unit[2] : 0,
}));

export const Cta = styled('div', () => ({
  marginTop: theme.unit[2],
  display: 'flex',
  flexDirection: 'column',
}));
