import { font } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export enum LinkType {
  PRIMARY = 'PRIMARY',
  PRIMARY_LARGE = 'PRIMARY_LARGE',
}

const commonPrimaryTypeStyles = ($theme: any) => ({
  color: $theme.colors.primary,
  fontWeight: 500,
});

const linkTypeStyles = ($theme: any) => ({
  [LinkType.PRIMARY]: {
    ...commonPrimaryTypeStyles($theme),
  },
  [LinkType.PRIMARY_LARGE]: {
    ...commonPrimaryTypeStyles($theme),
    fontSize: font.size.headingLarge,
  },
});

export const Container = styled(
  'a',
  ({ type, $theme }: { type?: LinkType; $theme?: any }) => ({
    color: $theme.colors.primaryActive,
    fontWeight: 'bold',
    ...(type ? linkTypeStyles($theme)[type] : {}),
  }),
);
