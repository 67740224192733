import { merge } from 'lodash';

type AnyObject = { [keys: string]: any };
type AnyFunc = (...args: any[]) => any;

const defaultOnBack = (send: AnyFunc) => () => {
  send('Previous');
};

const defaultOnSubmit = (send: AnyFunc) => (data: AnyObject) => {
  send('Next', { data });
};

export const withContext =
  ({
    onBack = defaultOnBack,
    populateFields,
    onSubmit = defaultOnSubmit,
    model,
  }: AnyObject) =>
  (...args: any[]) =>
    merge({}, model, {
      template: {
        header: {
          onBack: onBack(...args),
        },
      },
      form: {
        ...(populateFields
          ? {
              fields: populateFields(model.form.fields)(...args),
            }
          : {}),
        actions: {
          primary: {
            handler:
              model?.form?.actions?.primary?.handler || onSubmit(...args),
          },
          ...(model?.form?.actions?.secondary
            ? {
                secondary: {
                  handler:
                    model?.form?.actions?.secondary?.handler || onBack(...args),
                },
              }
            : {}),
        },
      },
    });
