import { useContext, useEffect, useState } from 'react';

import { ModalContext } from '../../providers/context/ModalContextProvider/ModalContext';

export const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);
  const { opened, changeModalState } = useContext(ModalContext);

  // Sync local and global states when closing
  useEffect(() => {
    if (!opened) {
      setIsShowing(false);
    }
  }, [opened]);

  const closeModal = () => {
    setIsShowing(false);
    changeModalState(false);
  };

  const openModal = () => {
    setIsShowing(true);
    changeModalState(true);
  };

  return {
    // is true if any modal is opened
    isShowingAny: opened,
    isShowing,
    open: openModal,
    close: closeModal,
  };
};
