import { font, helpers, theme, unit } from '../../../common/theme';
import { componentTypes } from '../../../constants/componentTypes';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const LoadersContainer = styled('div', () => ({
  flexGrow: 1,
  marginRight: unit[1],
}));

export const LoaderContainer = styled('div', () => ({
  width: '100%',
  ':not(:last-child)': {
    marginBottom: '0.5rem',
  },
}));

const componentStyles = ({ $component, $theme, $checked }: any) => {
  const shared = {
    borderWidth: '1px',
    borderRadius: unit[2],
    ':not(:last-child)': {
      marginBottom: unit[3],
    },
  };

  const COMPONENT_STYLES: Record<string, any> = {
    [componentTypes.SINGLE_SELECT]: {
      borderWidth: $checked ? '1px' : '1px 1px 0',
      ':last-child': {
        borderWidth: '1px',
        borderBottomLeftRadius: unit[2],
        borderBottomRightRadius: unit[2],
      },
      ':first-child': {
        borderTopLeftRadius: unit[2],
        borderTopRightRadius: unit[2],
      },
    },
    [componentTypes.SUMMARY_LIST]: {
      ...shared,
      borderColor: $theme.colors.secondaryOverlay,
      cursor: 'default',
      ':hover': {},
    },
    default: shared,
  };

  return COMPONENT_STYLES[$component] || COMPONENT_STYLES.default;
};

export const ListItemContainer = styled(
  'div',
  ({ onClick, disabled, $checked, $component, $theme }: any) => ({
    cursor: onClick ? 'pointer' : 'default',
    ...helpers.flexVerticalContainer,
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
    borderStyle: 'solid',
    borderColor: $checked
      ? $theme.colors.primary
      : $theme.colors.secondaryOverlay,
    padding: `0 ${unit[3]}`,
    ...(!disabled
      ? {
          ':hover': {
            backgroundColor: $theme.colors.tertiaryOverlay,
          },
        }
      : {
          pointerEvents: 'none',
        }),
    ...componentStyles({ $component, $theme, $checked }),
  }),
);

export const ContentContainer = styled(
  'div',
  () =>
    ({
      ...helpers.flexVerticalCenter,
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      width: '100%',
      position: 'relative',
    } as any),
);

export const RightContentContainer = styled('div', () => ({}));

export const LeftContentContainer = styled(
  'div',
  ({ $isMobile }: any) =>
    ({
      ...helpers.flexVerticalCenter,
      flexWrap: 'nowrap',
      maxWidth: `calc(100% - ${unit[3]})`,
      flexGrow: 1,
      width: '100%',
      padding: `${unit[3]} 0`,
      ...($isMobile ? { justifyContent: 'flex-start' } : {}),
    } as any),
);

export const ImageContainerMobile = styled('div', ({ $theme }: any) => ({
  borderRadius: unit[1],
  backgroundColor: $theme.colors.tertiaryOverlay,
  margin: `${unit[3]} 0 -${unit[3]}`,
  width: '100%',
}));

export const FooterContent = styled('div', ({ $theme }: any) => ({
  height: unit[10],
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTop: `1px solid ${$theme.colors.secondaryActive}`,
  padding: `0 ${unit[3]}`,
  margin: `0 -${unit[3]}`,
}));

export const FooterText = styled('span', ({ $theme }: any) => ({
  textDecoration: 'underline',
  fontWeight: 500,
  color: $theme.colors.primary,
  fontSize: font.size.headingMedium,
}));

export const ImageContainerDesktop = styled('div', () => ({
  marginRight: unit[3],
  width: unit[16],
}));

const overflowStyles = {
  whiteSpace: 'pre',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const TitleContainer = styled(
  'div',
  ({ $largeTitle, disabled, $theme }: any) =>
    ({
      ...($largeTitle
        ? {
            ...theme.fonts.headingLarge,
            fontFamily: font.family.Merriweather,
          }
        : theme.fonts.headingMedium),
      fontWeight: 400,
      textAlign: 'left',
      ...overflowStyles,
      display: 'table-cell',
      ...(disabled
        ? {
            color: $theme.colors.primaryActive,
          }
        : {}),
    } as any),
);

export const SubtitleContainer = styled(
  'div',
  ({ $largeTitle, $theme }: any) =>
    ({
      ...theme.fonts.headingMedium,
      color: $theme.colors.primaryActive,
      textAlign: 'left',
      ...overflowStyles,
      display: 'table-cell',
      fontWeight: 400,
      ...($largeTitle
        ? {
            paddingTop: unit[1],
          }
        : {}),
    } as any),
);

export const TextContainer = styled(
  'div',
  ({ $noImage, $layoutWithFooter }: any) => ({
    ...($layoutWithFooter
      ? {
          paddingTop: unit[3],
        }
      : {
          padding: `${unit[3]} 0`,
        }),
    minWidth: 0,
    width: $noImage ? '100%' : `calc(100% - ${unit[16]})`,
  }),
);

// Hook for text-overflow and width 100%
export const TextOverflowWrapper = styled('div', () => ({
  display: 'table',
  tableLayout: 'fixed',
  width: '100%',
}));

export const BadgeContainer = styled('div', () => ({
  marginRight: unit[3],
}));

export const Highlight = styled('div', ({ $theme }: any) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  backgroundColor: $theme.colors.primary,
  color: $theme.colors.secondary,
  borderRadius: unit[1],
  fontSize: font.size.headingSmall,
  fontWeight: 500,
  padding: `0 ${unit[2]}`,
}));
