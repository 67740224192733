/**
 * withMatchMedia
 *
 * Provides attributes to the child identifying viewport definitions: tablet, mobile, desktop, etc.
 *
 * The media watchers live 'outside' the component so that they are shared and pervasive across the application.
 *
 * The individual components then add listeners to the various break points.
 */

import React from 'react';

import { useMatchMedia } from '../../../hooks/useMatchMedia/useMatchMedia';

type MatchMediaType = {
  isDesktop: boolean | undefined;
  isTablet: boolean | undefined;
  isMobile: boolean | undefined;
};

export const withMatchMedia =
  <P,>(WrappedComponent: React.ComponentType<P & MatchMediaType>) =>
  (props: P) => {
    const matchMediaProps = useMatchMedia();

    return <WrappedComponent {...props} {...matchMediaProps} />;
  };
