import { IconTypes } from './iconTypes';

const IconViewboxMapping: Record<string, string> = {
  [IconTypes.BANK]: '0 0 32 32',
  [IconTypes.VIN]: '0 0 32 32',
  [IconTypes.PLATE]: '0 0 32 32',
  [IconTypes.YMMT]: '0 0 32 32',
  [IconTypes.CL]: '0 0 118 24',
  [IconTypes.TOOLTIP]: '0 0 24 24',
};

export { IconViewboxMapping };
