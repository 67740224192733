import {
  ApplicationType,
  ChecklightSessionStatus,
  RADIX_ACTIVE_STATUS_ORDER,
} from '../constants';
import { ChecklightSession } from '../interfaces';

const statusesForFetchingLoanDecision = [
  ChecklightSessionStatus.SIGN_CONTRACTS,
  ChecklightSessionStatus.REVIEWING_DOCUMENTS,
  ChecklightSessionStatus.FINALIZE_STIPS,
  ChecklightSessionStatus.WAITING_ON_FUNDING,
  ChecklightSessionStatus.FUNDED,
];

const inReviewStatuses = [
  ChecklightSessionStatus.SUBMITTED_TO_LENDER,
  ChecklightSessionStatus.APPROVED_BY_LENDER,
  ChecklightSessionStatus.PREPARING_CONTRACTS,
  ChecklightSessionStatus.SIGN_CONTRACTS,
  ChecklightSessionStatus.REVIEWING_DOCUMENTS,
  ChecklightSessionStatus.FINALIZE_STIPS,
  ChecklightSessionStatus.WAITING_ON_FUNDING,
];

const activeStatuses = [
  ...inReviewStatuses,
  ChecklightSessionStatus.SELECTED_OFFER,
  ChecklightSessionStatus.APPLICANT_COMPLETE,
  ChecklightSessionStatus.CO_APPLICANT_COMPLETE,
];

/**
 * Used to restrict vehicle deletion if it's related Application is in one
 * of those statuses
 */
export const deleteNotAllowedStatuses = [
  ...inReviewStatuses,
  ChecklightSessionStatus.FUNDED,
];

/**
 * Used to distinct active Application in `getChecklightSession` method
 * Add other "inactive" statuses if needed
 */
export const inactiveStatuses = [ChecklightSessionStatus.CANCELLED_CONSUMER];

const invalidStatuses = [
  ChecklightSessionStatus.EXPIRED,
  ChecklightSessionStatus.DUPLICATE,
  ChecklightSessionStatus.CANCELLED,
  ChecklightSessionStatus.CANCELLED_CONSUMER,
  ChecklightSessionStatus.FUNDED,
];

export const postponedForThirtyDaysStatuses = [
  ChecklightSessionStatus.EXPIRED,
  ChecklightSessionStatus.DUPLICATE,
  ChecklightSessionStatus.CANCELLED,
  ChecklightSessionStatus.FUNDED,
];

export const historyStatuses = [
  ...postponedForThirtyDaysStatuses,
  ...inactiveStatuses,
];

export const isApplicationInReview = (
  checklightSession: ChecklightSession,
): boolean => {
  return inReviewStatuses.includes(checklightSession?.status);
};

export const isApplicationActive = (
  checklightSession: ChecklightSession,
): boolean => {
  return activeStatuses.includes(checklightSession?.status);
};

export const isApplicationInHistory = (
  checklightSession: ChecklightSession,
): boolean => {
  return historyStatuses.includes(checklightSession.status);
};

export const isApplicationValid = (
  checklightSession: ChecklightSession,
): boolean => !invalidStatuses.includes(checklightSession.status);

export const isAbleToFetchLoanDecision = (
  checklightSession: ChecklightSession,
): boolean =>
  statusesForFetchingLoanDecision.some(
    (status) => status === checklightSession.status,
  );

export const isRemoveVehicleRestricted = (
  checklightSession: ChecklightSession,
): boolean => {
  return deleteNotAllowedStatuses.includes(checklightSession?.status);
};

export const isKnownSessionStatus = (
  status: ChecklightSessionStatus | string,
): boolean => {
  return status in ChecklightSessionStatus;
};

export const getActiveStatusIndex = (
  status: ChecklightSessionStatus | string,
): number => {
  return RADIX_ACTIVE_STATUS_ORDER.indexOf(status);
};

export const isPurchaseApplicationType = (
  applicationType: string | ApplicationType,
) =>
  [
    ApplicationType.DEALER,
    ApplicationType.PRIVATE_PARTY,
    ApplicationType.PURCHASE,
  ].includes(applicationType as ApplicationType);
