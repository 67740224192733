import { merge } from 'lodash';

import { populateFields } from '../../../../../forms/populateFields';
import { unifiedCommonFields } from '../../../../../utils/helpers/income/unified';
import { Signal } from '../../XState/Model';
import BaseModel from '../common/BaseModel';

const model = (send: any, { context }: any) => {
  const { updatedUser = {} } = context || {};
  const { incomes } = updatedUser;
  const fieldsConfig = unifiedCommonFields('current');

  return merge({}, BaseModel, {
    template: {
      header: {
        onBack: () => send(Signal.Previous),
      },
    },
    form: {
      fields: merge({}, fieldsConfig, populateFields(fieldsConfig, incomes)),
      actions: {
        primary: {
          label: 'Continue',
          testId: 'continue',
          handler: (data: any) => {
            send(Signal.Next, {
              data: {
                incomes: data,
              },
            });
          },
        },
      },
    },
    info: {
      title: 'Applicant employment details',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'At a minimum, we need to know where you have worked for the past year.',
            "Haven't been at your current job for that long? Do not fret, you'll be given the chance to add additional employment information in order to satisfy this requirement.",
          ],
        },
      ],
    },
    headerBlock: {
      title: 'Tell us more about your employment',
      subtitle: ['Applicant', '60% complete'],
      progressBar: {
        progress: 60,
        withContainer: true,
      },
    },
  });
};

export default model;
