import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import { PersonalInformationEdit } from './PersonalInformationEdit';
import { PersonalInformationView } from './PersonalInformationView';

export const PersonalInformation = () => {
  const routes: RouteObject[] = [
    {
      index: true,
      element: <PersonalInformationView />,
    },
    {
      path: 'edit',
      element: <PersonalInformationEdit />,
    },
    {
      path: '*',
      element: <Navigate to="" replace />,
    },
  ];

  return useRoutes(routes);
};
