import { theme, transitions } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

const getColorFromState = (
  disabled: any,
  focused: any,
  error: any,
  success: any,
  $theme: any,
) => {
  if (disabled) {
    return $theme.colors.secondaryOverlay;
  }
  if (error) {
    return $theme.colors.error;
  }
  if (success) {
    return $theme.colors.success;
  }
  return $theme.colors.primaryActive;
};

const getLabelColor = (
  disabled: any,
  focused: any,
  hasValue: any,
  $theme: any,
) => {
  if (disabled) {
    return $theme.colors.secondaryOverlay;
  }
  if (focused) {
    return $theme.colors.primary;
  }
  if (!hasValue) {
    return $theme.colors.primaryActive;
  }

  return $theme.colors.primaryActive;
};

const getInputColor = (
  error: any,
  disabled: any,
  hasValue: any,
  $theme: any,
) => {
  if (disabled) {
    return $theme.colors.secondaryOverlay;
  }
  if (error) {
    return $theme.colors.error;
  }
  if (hasValue) {
    return $theme.colors.infoOverlay;
  }

  return $theme.colors.primaryActive;
};

export const Container = styled('div', ({ $isHidden }: any) => ({
  ...($isHidden
    ? {
        display: 'none',
      }
    : {}),
  width: '100%',
  marginBottom: theme.unit[2],
}));

export const DropdownContainer = styled(
  'div',
  ({ $disabled, $focused, $error, $success, $theme }: any) => ({
    ...transitions.default,
    width: '100%',
    position: 'relative',
    borderBottom: `0.0625rem solid ${getColorFromState(
      $disabled,
      $focused,
      $error,
      $success,
      $theme,
    )}`, // 1px
  }),
);

export const Label = styled(
  'div',
  ({ $disabled, $focused, $hasValue, $theme }: any) => ({
    ...theme.fonts.hint,
    ...transitions.default,
    position: 'absolute',
    pointerEvents: 'none',
    top: $focused || $hasValue ? 0 : theme.unit[3],
    color: getLabelColor($disabled, $focused, $hasValue, $theme),
    ...($focused || $hasValue
      ? {}
      : {
          fontSize: theme.fonts.headingLarge.fontSize,
        }),
  }),
);

export const Hint = styled(
  'div',
  ({ $disabled, $focused, $error, $success, $theme }: any) => ({
    ...theme.fonts.hint,
    color: getColorFromState($disabled, $focused, $error, $success, $theme),
  }),
);

export const Icon = styled('div', ({ $disabled }: any) => ({
  position: 'absolute',
  right: 0,
  bottom: theme.unit[1],
  cursor: $disabled ? 'default' : 'pointer',
  pointerEvents: 'none',
}));

export const Select = styled(
  'select',
  ({ $error, $disabled, $hasValue, $theme }: any) => ({
    ...theme.fonts.headingLarge,
    width: '100%',
    color: getInputColor($error, $disabled, $hasValue, $theme),
    backgroundColor: $theme.colors.primaryOverlay,
    border: 'none',
    outline: 'none',
    marginTop: theme.unit[3],
    paddingBottom: theme.unit[1],
    paddingRight: theme.unit[2],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    boxSizing: 'border-box',
    cursor: $disabled ? 'default' : 'pointer',
  }),
);
