import { ApplicationTaskEnum } from '../../constants/applicationTasks';
import { LoanTaskEnum } from '../../constants/loanTasks';

export const TASK_PREVIEW: {
  [task in ApplicationTaskEnum | LoanTaskEnum]?: string;
} = {
  [LoanTaskEnum.ViewOffers]: 'Your offers are ready',
  [LoanTaskEnum.Applicant]: 'Complete applicant information',
  [LoanTaskEnum.CoApplicant]: 'Complete co-applicant information',
  [LoanTaskEnum.Review]: 'Review and submit',
  // [ApplicationTaskEnum.UploadDocuments]: 'Upload required documents', // Disabled per ticket https://savingsgroup.atlassian.net/browse/CP-3160
};
