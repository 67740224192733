import { unit } from '../../../common/theme';
import { IconPathMapping } from './iconPathMapping';
import { IconTypes } from './iconTypes';
import { IconViewboxMapping } from './iconViewboxMapping';
import { RotationDirections } from './rotationDirections';

export interface IconProps {
  color?: string;
  height?: string | number;
  name: IconTypes;
  rotate?: RotationDirections;
  cursor?: string;
  width?: string | number;
  onClick?: () => void;
}

export const Icon = ({
  color = '#000000',
  height,
  width,
  name,
  rotate,
  cursor,
  ...props
}: IconProps) => {
  const rootStyles = { transition: '0.5s', cursor };
  const rotateStyle = () => {
    switch (rotate) {
      case RotationDirections.DOWN: {
        return { ...rootStyles, transform: 'rotate(-90deg)' };
      }
      case RotationDirections.UP: {
        return { ...rootStyles, transform: 'rotate(90deg)' };
      }
      case RotationDirections.LEFT: {
        return { ...rootStyles, transform: 'rotate(0deg)' };
      }
      case RotationDirections.RIGHT: {
        return { ...rootStyles, transform: 'rotate(180deg)' };
      }
      default:
        return rootStyles;
    }
  };

  return (
    <svg
      height={height || unit[2]}
      style={rotateStyle() || null}
      viewBox={IconViewboxMapping[name] || '0 0 16 16'}
      width={width || height || unit[2]}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill={color} fillRule="evenodd" strokeWidth="1">
        {IconPathMapping[name]}
      </g>
    </svg>
  );
};
