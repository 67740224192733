export const isInEnum = <T extends { [key: string]: string | number }>(
  value: unknown,
  enumType: T,
): value is T[keyof T] => {
  if (typeof value !== 'string' && typeof value !== 'number') {
    return false;
  }

  return (Object.values(enumType) as Array<string | number>).includes(value);
};
