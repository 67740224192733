import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { ApplicationTasks } from '../../../../components/ApplicationTasks/ApplicationTasks';
import { EditPageHeader } from '../../../../components/shared/PageHeader/EditPageHeader';
import { useWorkflowPathname } from '../../../../hooks/useWorkflowPathname/useWorkflowPathname';
import { getBreadcrumbs } from '../breadcrumbs';

const ViewAllTasks = ({ applicationData, availableTasks }: any) => {
  const navigate = useNavigate();
  const workflowName = useWorkflowPathname();

  return (
    <Fragment>
      <EditPageHeader
        breadCrumbs={getBreadcrumbs(workflowName, navigate, applicationData)}
        handleGoBack={() => navigate('..', { state: applicationData })}
        title="All tasks"
      />
      <ApplicationTasks
        availableTasks={availableTasks}
        state={applicationData}
        type="application"
        isList
      />
    </Fragment>
  );
};

export default ViewAllTasks;
