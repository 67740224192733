import i18n from 'i18next';

import { EmploymentTypeEnum } from '../enums/EmploymentTypeEnum';

export const employmentTypeFormat: Record<EmploymentTypeEnum, string> = {
  [EmploymentTypeEnum.ACTIVE_MILITARY]: i18n.t('employmentType.military'),
  [EmploymentTypeEnum.CONTRACTOR]: '',
  [EmploymentTypeEnum.DISABILITY]: i18n.t('employmentType.disability'),
  [EmploymentTypeEnum.FULL_TIME]: i18n.t('employmentType.fullTime'),
  [EmploymentTypeEnum.HOMEMAKER]: i18n.t('employmentType.homemaker'),
  [EmploymentTypeEnum.OTHER]: i18n.t('employmentType.other'),
  [EmploymentTypeEnum.PART_TIME]: i18n.t('employmentType.partTime'),
  [EmploymentTypeEnum.RETIRED]: i18n.t('employmentType.retired'),
  [EmploymentTypeEnum.SELF_EMPLOYED]: i18n.t('employmentType.selfEmployed'),
  [EmploymentTypeEnum.STUDENT]: i18n.t('employmentType.student'),
  [EmploymentTypeEnum.UN_EMPLOYED]: '',
};
