import * as Sentry from '@sentry/react';

import { LoanDocumentType } from '../types/LoanDocumentType';

export const reportURLError =
  (document: LoanDocumentType) =>
  (error: Error): never => {
    Sentry.captureException(error, {
      extra: {
        FileSize: document.file.size,
        FileType: document.file.type,
      },
      tags: { 'document.loan.type': document.type },
    });

    throw error;
  };
