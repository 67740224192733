import { fadeIn, slideInDown } from 'react-animations';

/*
 * 'unit' is an object with size properties.
 */
export const unit = {
  0: '0rem', // 0px
  1: '0.5rem', // 8px
  2: '1rem', // 16px
  3: '1.5rem', // 24px
  4: '2rem', // 32px
  5: '2.5rem', // 40px
  6: '3rem', // 48px
  7: '3.5rem', // 56px
  8: '4rem', // 64px
  9: '4.5rem', // 72px
  10: '5rem', // 80px
  11: '5.5rem', // 88px
  12: '6rem', // 96px
  13: '6.5rem', // 104px
  14: '7rem', // 112px
  15: '7.5rem', // 120px,
  16: '8rem', // 128px,
  17: '8.5rem', // 136px
  18: '9rem', // 144px,
  19: '9.5rem', // 152px
  20: '10rem', // 160
  21: '10.5rem', // 168
  22: '11rem', // 176
  23: '11.5rem', // 184
  24: '12rem', // 192
  25: '12.5rem', // 200
  26: '13rem', // 208
  27: '13.5rem', // 216
  28: '14rem', // 224
  29: '14.5rem', // 232
  30: '15rem', // 240
};

// Colors
export const colors = {
  white: '#ffffff',
  greyLightest: '#f5f7fa',
  greyLighter: '#ebf0f5',
  greyLight: '#dce1e6',
  greyMedium: '#999999',
  grey: '#647382',
  greyDark: '#647382',
  black: '#000000',
  green: '#29cca3',
  darkGreen: '#0c4650',
  red: '#f23148',
  offWhiteBlue: '#bfc8d9',
  transparent: 'rgba(255,255,255,0)',
  whiteTransparentMedium: 'rgba(256,256,256,.50)',
  whiteTransparentLight: 'rgba(256,256,256,.20)',
  blackTransparentMedium: 'rgba(0,0,0,0.65)',
  blackTransparentLight: 'rgba(0,0,0,0.2)',
};

// Fonts
export const font = {
  family: {
    Merriweather: 'Merriweather',
    Montserrat: 'Montserrat',
  },
  size: {
    subTitle: '0.875rem', // 14px
    headingSmall: '0.75rem', // 12px
    headingMedium: '0.875rem', // 14px
    headingLarge: '1rem', // 16px
    paragraph: '0.875rem', // 14px, default
    titleSmall: '1.125rem', // 18px
    title20px: '1.25rem', // 20px
    titleMedium: '1.5rem', // 24px
    titlePreLarge: '2rem', // 32px
    titleLarge: '3rem', // 48px
  },
  lineHeight: {
    paragraph: '1.5rem', // 24px, default
    subTitle: '1.5rem', // 24px
    titleMedium: '1.875rem', // 30px
    titlePreLarge: '3rem', // 48px
  },
};

// Shadows
export const shadows = {
  flat: {
    boxShadow: 'none',
  },
  mid: {
    boxShadow: '0 0.0625rem 0.25rem 0 rgba(99,115,129,0.35)', // 0 1px 4x 0
  },
  high: {
    boxShadow: '0 0.25rem 1rem 0 rgba(99,115,129,0.35)', // 0 4px 16px 0
  },
};

// Border Radii
export const borderRadii = {
  sharp: {
    borderRadius: 'none',
  },
  roundedSlight: {
    borderRadius: '0.25rem',
  },
  roundedMedium: {
    borderRadius: '0.5rem',
  },
  roundedBurly: {
    borderRadius: '1rem',
  },
};

// Transitions
export const transitions = {
  default: {
    transition: '300ms ease all',
  },
};

// Animations
const animationDuration = '300ms';
export const animations = {
  fromTop: {
    animationName: slideInDown,
    animationDuration,
  },
  backgroundFadeIn: {
    animationDuration,
    animationName: fadeIn,
  },
};

// Helpers
export const helpers = {
  flexVerticalCenter: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  flexHorizontalContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  flexVerticalContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  flexRowWrapContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
};
