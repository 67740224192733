import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  LabelContainer,
  NavItemContainer,
  NotificationContainer,
} from './styles';

export const NavigationItem = ({
  label,
  isLast,
  notificationCount,
  route,
  isDisabled,
  external,
}: any) => {
  const location = useLocation();
  const { pathname } = location;

  const isSelected = useMemo(() => pathname.includes(route), [pathname, route]);

  const navItem = (
    <NavItemContainer
      $isLast={isLast}
      $isSelected={isSelected}
      $notificationCount={notificationCount}
    >
      <LabelContainer $isSelected={isSelected}>{label}</LabelContainer>
      {notificationCount ? (
        <NotificationContainer>{notificationCount}</NotificationContainer>
      ) : null}
    </NavItemContainer>
  );

  const additionalStyles = {
    textDecoration: 'none',
    ...(isDisabled ? { pointerEvents: 'none' } : {}),
  } as any;

  return external ? (
    <a href={route} style={additionalStyles}>
      {navItem}
    </a>
  ) : (
    <Link style={additionalStyles} to={route}>
      {navItem}
    </Link>
  );
};

NavigationItem.propTypes = {
  isLast: PropTypes.bool,
  label: PropTypes.string.isRequired,
  notificationCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  route: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  external: PropTypes.bool,
};
