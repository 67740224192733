export const Step = {
  Pillow: 'Pillow',
  Welcome: 'Welcome',
  ApplicationType: 'ApplicationType',
  PurchaseType: 'PurchaseType',
  PrimaryGoal: 'PrimaryGoal',
  PersonalDetails: 'PersonalDetails',
  CheckUserCredentials: 'CheckUserCredentials',
  CoApplicant: 'CoApplicant',
  VehicleIdentity: 'VehicleIdentity',
  SelectVehicleYear: 'SelectVehicleYear',
  SelectVehicleMake: 'SelectVehicleMake',
  SelectVehicleModel: 'SelectVehicleModel',
  FindVehicleByLicensePlate: 'FindVehicleByLicensePlate',
  FindVehicleByVin: 'FindVehicleByVin',
  VehicleConfirm: 'VehicleConfirm',
  Review: 'Review',
  ReviewApplicant: 'ReviewApplicant',
  ReviewHousing: 'ReviewHousing',
  ReviewCoApplicant: 'ReviewCoApplicant',
  ReviewCoApplicantPersonalInfo: 'ReviewCoApplicantPersonalInfo',
  ReviewCoApplicantHousingInfo: 'ReviewCoApplicantHousingInfo',
  PinVerify: 'PinVerify',
  ExitToSignIn: 'ExitToSignIn',
  ExitToOffers: 'ExitToOffers',
  ExitReturningUserToSignIn: 'ExitReturningUserToSignIn',
  ExitToDashboard: 'ExitToDashboard',
};
