import { merge } from 'lodash';
import { useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import TagManager from 'react-gtm-module';
import { FormProvider, useForm } from 'react-hook-form';

import { PillowForm } from '../../../../../components/PillowForm';
import { InfoBlock } from '../../../../../components/shared/InfoBlock/InfoBlock';
import { fields } from '../../../../../forms/review';
import { useAPI } from '../../../../../hooks/useAPI/useAPI';
import { useCurrentUserContext } from '../../../../../hooks/useCurrentUserContext/useCurrentUserContext';
import { Signal } from '../../XState/Model';

const Controller = ({ send, context, presModel }: any) => {
  const [currentUserContext, setCurrentUserContext] = useCurrentUserContext();
  const { currentVehicle } = context;
  const { coApplicant: { coApplicantAnswer = false } = {} } = currentVehicle;

  const methods = useForm();
  const api = useAPI();

  const onSubmit = useAsyncCallback((formData) => {
    const { currentVehicle } = context;

    return api
      .post(`/vehicles/${currentVehicle.id}/application`, formData)
      .then((user) => {
        TagManager.dataLayer({
          dataLayer: {
            event: 'user-submitted',
          },
        });
        setCurrentUserContext({ ...currentUserContext, ...user });
        send(Signal.Next);
      });
  });

  const enhancedProps = useMemo(
    () =>
      merge(
        {},
        { presModel },
        {
          presModel: {
            form: {
              fields: {
                ...(!coApplicantAnswer
                  ? {}
                  : {
                      coApplicantSsn: fields.coApplicantSsn,
                    }),
              },
              actions: {
                primary: {
                  handler: onSubmit.execute,
                  isDisabled: onSubmit.loading,
                },
              },
              globalErrors: onSubmit.error && [
                'Looks like we are experiencing some issues on our side, please try to submit again in few minutes or contact our customer support.',
              ],
            },
          },
        },
      ),
    [onSubmit, presModel, coApplicantAnswer],
  );

  const warningText = (
    <span>
      Your online security is our number one priority. In conjunction with your
      lender, we use SSL for communication and protect your SSN with encryption.
    </span>
  );

  return (
    <FormProvider {...methods}>
      <PillowForm {...enhancedProps} methods={methods}>
        <InfoBlock hasBorder>{warningText}</InfoBlock>
      </PillowForm>
    </FormProvider>
  );
};

export default Controller;
