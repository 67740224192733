import { pick } from 'lodash';

import { FooterLink } from '../../../../../components/PillowForm';
import { PillowType } from '../../../../../components/shared/PillowImage/pillowTypes';
import { fields } from '../../../../../forms/review';

const model = () => ({
  template: {
    header: {
      showClose: false,
      showMenu: true,
    },
    footer: {
      isActive: false,
      links: [FooterLink.ContactUs],
    },
    img: {
      type: PillowType.REVIEW,
    },
  },
  form: {
    fields: {
      ...pick(fields, 'ssn'),
    },
    actions: {
      primary: {
        testId: 'submit',
        label: 'Submit',
      },
    },
  },
  headerBlock: {
    title: 'Verify your SSN',
    subtitle: 'Review',
    progressBar: {
      progress: 100,
      withContainer: true,
    },
  },
  info: {
    title: 'Verify SSN',
    subtitle: 'Help',
    content: [
      {
        copy: [
          'Your online security is our number one priority. In conjunction with your lender, we use SSL for communication and protect your SSN with advanced RSA encryption, the same level of security US banks use.',
        ],
      },
    ],
  },
});

export default model;
