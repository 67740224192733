/* eslint-disable react-refresh/only-export-components */
import { uniqueId } from 'lodash';
import { useMemo } from 'react';
import NumberFormat from 'react-number-format';

import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import { Button } from '../Button/Button';
import { ButtonType } from '../Button/buttonType';
import { Form } from '../Form/Form';
import {
  Block,
  BlockInfo,
  BlockInfoLink,
  BlockInfoText,
  Blocks,
  BlockTitle,
  Container,
  FormContainer,
  PanelLink,
} from './styles';

export enum PanelListType {
  LINK = 'LINK',
  TEXT = 'TEXT',
}
export enum PanelListFormatType {
  NUMBER = 'NUMBER',
  DATE = 'DATE',
}

const PanelListComponent = ({
  isMobile,
  isTablet,
  isDesktop,
  panelList,
  editable,
  onEdit,
  editedItem,
  isDisabled,
  defaultValue = 'Not defined',
  panelHeading,
  isBorderWrapped,
}: {
  isMobile?: boolean;
  isTablet?: boolean;
  isDesktop?: boolean;
  panelList: {
    testId: string;
    panelType: PanelListType;
    panelOptions?: any;
    formatType?: PanelListFormatType;
    formatOptions?: any;
    label: string;
    value?: string | number | null | undefined;
    form?: any;
  }[];
  editable?: boolean;
  onEdit?: (item?: any) => void;
  editedItem?: number;
  isDisabled?: boolean;
  defaultValue?: string;
  panelHeading?: {
    testId?: string;
    label?: string;
    action?: any;
    style?: any;
  };
  isBorderWrapped?: boolean;
}) => {
  const getValue = (value: any) => value ?? defaultValue;
  const getFormat: Record<string, any> = {
    NUMBER: (formatOptions: any, value: any) => (
      <NumberFormat value={getValue(value)} {...formatOptions} />
    ),
    DATE: (formatOptions: any, value: any) =>
      value
        ? new Intl.DateTimeFormat('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: 'UTC',
          }).format(new Date(value))
        : defaultValue,
  };
  const getPanel = (type: any) => {
    const COMPONENTS: Record<string, any> = {
      LINK: BlockInfoLink,
      TEXT: BlockInfoText,
    };

    const BlockInfoComponent = COMPONENTS[type];

    return (
      testId: any,
      panelOptions: any,
      value: any,
      formatType: any,
      formatOptions: any,
    ) => {
      const options = {
        'data-testid': testId,
        $isMobile: isMobile,
        $isTablet: isTablet,
        $editable: editable,
        $isDisabled: isDisabled,
        ...panelOptions,
      };

      return (
        <BlockInfoComponent {...options}>
          {formatType && value
            ? getFormat[formatType](formatOptions, value)
            : getValue(value)}
        </BlockInfoComponent>
      );
    };
  };

  const panelLink = (editMode: any, index: any, testId: any) => (
    <PanelLink
      $editMode={editMode}
      $isDisabled={isDisabled && !editMode}
      data-testid={`${testId}Edit`}
      onClick={() => {
        if (onEdit) {
          onEdit(index);
        }
      }}
    >
      {editMode ? 'Cancel' : 'Edit'}
    </PanelLink>
  );

  const panelHeadingBlock = useMemo(() => {
    if (!panelHeading) {
      return null;
    }
    return (
      <Block
        $hasSidePadding={isBorderWrapped}
        $style={panelHeading.style}
        $isHeader
      >
        <BlockInfo
          $isDekstop={isDesktop}
          $isMobile={isMobile}
          $isTablet={isTablet}
          $isHeader
        >
          <BlockTitle $isDisabled={isDisabled}>{panelHeading.label}</BlockTitle>
        </BlockInfo>
        {panelHeading.action ? (
          <Button
            isGrouped
            {...{
              ...panelHeading.action,
              isDisabled,
              stylingType: ButtonType.GHOST,
            }}
          />
        ) : null}
      </Block>
    );
  }, [
    isMobile,
    isTablet,
    isDesktop,
    isDisabled,
    panelHeading,
    isBorderWrapped,
  ]);

  return (
    <Container>
      <Blocks $isBorderWrapped={isBorderWrapped}>
        {panelHeading ? panelHeadingBlock : null}
        {panelList.map(
          (
            {
              panelType,
              testId,
              panelOptions,
              formatType,
              formatOptions,
              label,
              value,
              form,
            },
            index,
          ) => (
            <Block
              $editMode={editedItem === index}
              $hasSidePadding={isBorderWrapped}
              key={uniqueId('block-')}
            >
              <BlockInfo
                $editMode={editedItem === index}
                $editable={editable}
                $isMobile={isMobile}
                $isTablet={isTablet}
              >
                <BlockTitle
                  $editable={editable}
                  $isDisabled={isDisabled}
                  $isMobile={isMobile}
                >
                  {label}
                </BlockTitle>
                {editedItem === index ? (
                  <FormContainer $isDesktop={isDesktop}>
                    <Form {...{ form }} />
                  </FormContainer>
                ) : (
                  getPanel(panelType)(
                    testId,
                    panelOptions,
                    value,
                    formatType,
                    formatOptions,
                  )
                )}
              </BlockInfo>
              {editable ? panelLink(editedItem === index, index, testId) : null}
            </Block>
          ),
        )}
      </Blocks>
    </Container>
  );
};

export const PanelList = withMatchMedia(PanelListComponent);
