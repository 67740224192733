import { merge } from 'lodash';
import { useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { populateFields } from '../../forms/populateFields';
import { useAPI } from '../../hooks/useAPI/useAPI';
import { useCurrentUserContext } from '../../hooks/useCurrentUserContext/useCurrentUserContext';
import { withMatchMedia } from '../../providers/context/MatchMediaProvider/withMatchMedia';
import { mapGlobalErrors } from '../../utils/pipes/form-global-errors';
import { FormConfirmModal } from '../FormConfirmModal/FormConfirmModal';
import { Form } from '../shared/Form/Form';
import { FormContainer } from './styles';

const PersonalInformationEditComponent = ({
  fieldsConfig,
  isDesktop,
}: {
  fieldsConfig: {
    fields?: any;
    globalErrors?: any;
  };
  isDesktop?: boolean;
}) => {
  const navigate = useNavigate();
  const [currentUserContext, setCurrentUserContext] = useCurrentUserContext();
  const api = useAPI();

  const methods = useForm();

  const updateFields = useAsyncCallback((data) => {
    return api.put('/users/current', data).then((user) => {
      setCurrentUserContext({ ...currentUserContext, ...user });
      navigate('..');
    });
  });

  const sharedFormConfig = useMemo(
    () => ({
      fields: merge(
        {},
        fieldsConfig.fields,
        populateFields(fieldsConfig.fields, currentUserContext),
      ),
      actions: {
        primary: {
          handler: updateFields.execute,
          label: 'Save',
          testId: 'infoSave',
          isDisabled: updateFields.loading,
        },
        secondary: {
          handler: () => navigate('..'),
          label: 'Cancel',
          testId: 'infoCancel',
          isDisabled: updateFields.loading,
        },
      },
      globalErrors: mapGlobalErrors(
        updateFields.error,
        fieldsConfig.globalErrors,
      ),
    }),
    [updateFields, navigate, fieldsConfig, currentUserContext],
  );

  return (
    <FormProvider {...methods}>
      <FormContainer $isDesktop={isDesktop}>
        <Form form={sharedFormConfig} methods={methods} />
      </FormContainer>
      <FormConfirmModal
        formFields={sharedFormConfig.fields}
        methods={methods}
      />
    </FormProvider>
  );
};

export const PersonalInformationEdit = withMatchMedia(
  PersonalInformationEditComponent,
);
