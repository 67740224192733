import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentUserContext } from '../../hooks/useCurrentUserContext/useCurrentUserContext';
import {
  PanelList,
  PanelListFormatType,
  PanelListType,
} from '../shared/PanelList/PanelList';

export const PersonalInformation = () => {
  const [currentUserContext] = useCurrentUserContext();
  const navigate = useNavigate();

  const {
    primaryEmailAddress,
    firstName,
    lastName,
    primaryPhoneNumber,
    personalInfo: { birthDate } = {},
  } = currentUserContext;

  const panelListConfig = useMemo(
    () => [
      {
        testId: 'name',
        panelType: PanelListType.TEXT,
        label: 'Name',
        value: [firstName, lastName].filter((v) => !!v).join(' '),
      },
      {
        testId: 'phone',
        panelType: PanelListType.TEXT,
        label: 'Phone',
        value: primaryPhoneNumber,
        formatType: PanelListFormatType.NUMBER,
        formatOptions: {
          format: '(###) ###-####',
          mask: '_',
          displayType: 'text',
        },
      },
      {
        testId: 'email',
        panelType: PanelListType.TEXT,
        label: 'Email',
        value: primaryEmailAddress,
      },
      {
        testId: 'DOB',
        panelType: PanelListType.TEXT,
        label: 'DOB',
        value: birthDate,
        formatType: PanelListFormatType.DATE,
      },
    ],
    [firstName, lastName, primaryEmailAddress, primaryPhoneNumber, birthDate],
  );

  // Panel index is important when we have more that one panel list on a page, like on income tab
  const onEdit = useCallback(
    (panelIndex: any, itemIndex: any) => {
      navigate('edit', {
        state: { itemIndex },
      });
    },
    [navigate],
  );

  return (
    <PanelList
      defaultValue="Not provided"
      panelList={panelListConfig}
      editable
      onEdit={(itemIndex: any) => onEdit(0, itemIndex)}
    />
  );
};
