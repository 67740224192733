export enum EmploymentTypeEnum {
  ACTIVE_MILITARY = 'ACTIVE_MILITARY',
  CONTRACTOR = 'CONTRACTOR',
  DISABILITY = 'DISABILITY',
  FULL_TIME = 'FULL_TIME',
  HOMEMAKER = 'HOMEMAKER',
  OTHER = 'OTHER',
  PART_TIME = 'PART_TIME',
  RETIRED = 'RETIRED',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  STUDENT = 'STUDENT',
  UN_EMPLOYED = 'UN_EMPLOYED',
}
