import PropTypes from 'prop-types';

import { Container, InnerContainer } from './styles';

export const Radio = ({
  isDisabled = false,
  isError = false,
  selected = false,
}: any) => (
  <Container $isError={isError} $isSelected={selected} disabled={isDisabled}>
    <InnerContainer
      $disabled={isDisabled}
      $isError={isError}
      $isSelected={selected}
    />
  </Container>
);

Radio.propTypes = {
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  selected: PropTypes.bool,
};
