import { isEmpty } from 'lodash';
import { useAsync } from 'react-async-hook';

import { RefinanceOffer } from '../../../../common/interfaces/vehicle/offer.interface';
import { SelectedOfferSummary } from '../../../../components/SelectedOfferSummary/SelectedOfferSummary';
import { ResourcePanel } from '../../../../components/shared/ResourcePanel/ResourcePanel';
import { useAPI } from '../../../../hooks/useAPI/useAPI';

export const ApplicationSummary = ({
  vehicleId,
  autopayNumber,
  openSummary,
}: {
  vehicleId: string;
  autopayNumber: string;
  openSummary: boolean;
}) => {
  const api = useAPI();

  const fetchSelectedOffer = useAsync(
    (vehicleId: string): Promise<RefinanceOffer> =>
      api.get(`/vehicles/${vehicleId}/application/offers/selected`),
    [vehicleId],
  );

  return !isEmpty(fetchSelectedOffer.result) ? (
    <ResourcePanel
      shouldInstantlyOpen={openSummary}
      subtitle="Selected offer details"
      title="Summary"
    >
      <SelectedOfferSummary
        autopayNumber={autopayNumber}
        selectedOfferState={fetchSelectedOffer}
      />
    </ResourcePanel>
  ) : null;
};
