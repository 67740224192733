import { IncomeType } from '../../../common';
import { startDateRegex } from '../../../forms/income';
import { isOlderThan } from '../../pipes/date';

export const showPreviousIncome = (data: { startDate: string }) => {
  const { startDate } = data;
  if (!startDate || !startDateRegex.test(startDate)) {
    return false;
  }
  if (Date.now() < Date.parse(startDate)) {
    return false;
  }
  return !isOlderThan(startDate, 12);
};

export const getIncomePayload: any = (data: any) => {
  const incomes = [
    'current',
    ...(showPreviousIncome(data.current) ? ['previous'] : []),
  ];
  return incomes.reduce((acc, income) => {
    return {
      ...acc,
      [income]: data[income],
    };
  }, {});
};

export const incomeTypeLabels = {
  [IncomeType.PRIMARY_INCOME]: 'Primary',
  [IncomeType.ADDITIONAL_INCOME]: 'Additional',
  [IncomeType.PREVIOUS_INCOME]: 'Previous',
};
