import { isEmpty } from 'lodash';
import { useContext, useState } from 'react';

import { DynamicBrandingContext } from '../../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { addEmptyDefaultDropdownOption } from '../../../utils/listOptions/DropdownPresentationModel';
import { Icon as IconComponent } from '../Icon/Icon';
import { IconTypes } from '../Icon/iconTypes';
import { RotationDirections } from '../Icon/rotationDirections';
import {
  Container,
  DropdownContainer,
  Hint,
  Icon,
  Label,
  Select,
} from './styles';

export const DropdownControl = (props: any) => {
  const {
    name,
    label,
    defaultValue,
    hint,
    validationRules,
    isLoading,
    list,
    options,
    control,
    controller: Controller,
    disabled = false,
    success,
    testId,
    onChange = () => {},
    autoCompleteObj = {},
    isHidden,
    shouldUnregister,
  } = props;

  const {
    branding: { theme: $theme },
  } = useContext(DynamicBrandingContext);

  const getHint = (error: any) => {
    if (error && error.message) {
      return <span>{error.message}</span>;
    }
    if (success) {
      return <span>success</span>;
    }
    if (hint) {
      return <span>hint</span>;
    }
    return <>&nbsp;</>;
  };

  const [focused, setFocused] = useState(false);

  const onFocus = () => {
    setFocused(true);
  };

  const getOptionItems = (currentValue: any) => {
    const items = list || options;
    if (!isLoading && !isEmpty(items)) {
      const itemsWithDefault = items.some(
        ({ value }: any) => value === currentValue,
      )
        ? items
        : addEmptyDefaultDropdownOption(items);

      return itemsWithDefault.map(
        (
          { name: optionName, value, key, label: optionLabel }: any,
          index: any,
        ) => (
          <option
            {...{
              name: optionName,
              value,
            }}
            key={key || value || index}
          >
            {optionLabel || optionName}
          </option>
        ),
      );
    }

    return null;
  };

  return (
    <Container $isHidden={isHidden}>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({
          // eslint-disable-next-line
          field: { onBlur, ref, ...restField },
          fieldState: { error },
        }: any) => (
          <>
            <DropdownContainer
              $disabled={disabled}
              $error={!!error}
              $focused={focused}
              $success={success}
            >
              <Select
                {...restField}
                $disabled={disabled}
                $error={error}
                $hasValue={restField.value}
                autoComplete={autoCompleteObj.autocomplete || 'off'}
                data-testid={testId}
                disabled={disabled || isLoading}
                onBlur={() => {
                  onBlur();
                  setFocused(false);
                }}
                onChange={(v: any) => {
                  restField.onChange(v);
                  onChange(v.target.value);
                }}
                onFocus={onFocus}
              >
                {getOptionItems(restField.value)}
              </Select>
              <Label
                $disabled={disabled}
                $focused={focused}
                $hasValue={restField.value && !isLoading}
              >
                {!isLoading ? label : 'Loading...'}
              </Label>
              <Icon>
                <IconComponent
                  color={
                    disabled
                      ? $theme.colors.disabled
                      : $theme.colors.infoOverlay
                  }
                  name={IconTypes.CARET}
                  rotate={RotationDirections.UP}
                />
              </Icon>
            </DropdownContainer>
            <Hint
              $disabled={disabled}
              $error={!!error}
              $focused={focused}
              $success={success}
              data-testid={`${testId}Error`}
            >
              {getHint(error)}
            </Hint>
          </>
        )}
        rules={validationRules}
        shouldUnregister={shouldUnregister}
      />
    </Container>
  );
};
