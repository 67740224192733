export const Step = {
  PersonalDetails: 'PersonalDetails',
  HousingDetails: 'HousingDetails',
  PreviousAddress: 'PreviousAddress',
  PrimaryIncome: 'PrimaryIncome',
  PrimaryPreviousIncome: 'PrimaryPreviousIncome',
  ReviewIncome: 'ReviewIncome',
  EditPrimaryIncome: 'EditPrimaryIncome',
  AddAdditionalIncome: 'AddAdditionalIncome',
  EditAdditionalIncome: 'EditAdditionalIncome',
  ReviewApplicant: 'ReviewApplicant',
  ReviewPersonalDetails: 'ReviewPersonalDetails',
  ReviewHousingDetails: 'ReviewHousingDetails',
  FinalIncomeReview: 'FinalIncomeReview',
  FinalIncomeReviewAdditionalAdd: 'FinalReviewIncomeAdditionalAdd',
  FinalIncomeReviewAdditionalEdit: 'FinalReviewIncomeAdditionalEdit',
  FinalIncomeReviewPrimaryEdit: 'FinalReviewIncomePrimaryEdit',
  RedirectToCoApplicant: 'RedirectToCoApplicant',
  RedirectToReview: 'RedirectToReview',
  Done: 'Done',
};
