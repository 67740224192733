import { isEmpty, isMatchWith, set } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { ConfirmCancelModal } from '../shared/confirmModal/ConfirmCancelModal';
import { FormFiledInterface } from '../shared/Form/interfaces/formFields.interface';

export const FormConfirmModal = ({
  methods,
  formFields,
  disallowModal,
}: {
  methods: any;
  formFields: { [key: string]: FormFiledInterface };
  disallowModal?: boolean;
}) => {
  const [initialFormValue, setInitialFormValue] = useState<any>();

  const formValue = methods.watch();

  const { isSubmitting } = methods.formState;

  useEffect(() => {
    const defaultFormValue = Object.values(formFields).reduce(
      (acc, field: FormFiledInterface) => {
        set(acc, field.name, field.value);
        return acc;
      },
      {},
    );

    setInitialFormValue(defaultFormValue);
  }, []);

  const formHasChanges = useMemo(() => {
    if (disallowModal || isEmpty(formValue) || !initialFormValue) {
      return false;
    }

    if (isSubmitting) {
      return false;
    }

    return !isMatchWith(
      initialFormValue,
      formValue,
      (oldValue, updatedValue) => {
        // Handle cases when values have undefined and empty string
        // Form populates fields with empty strings and we usually have undefined in context
        if ([oldValue, updatedValue].every((v) => !v)) {
          return true;
        }
      },
    );
  }, [formValue, initialFormValue, isSubmitting, disallowModal]);

  return <ConfirmCancelModal formChanged={formHasChanges} />;
};
