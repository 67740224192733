import i18n from 'i18next';
import {
  filter,
  forEach,
  get as lodashGet,
  isEmpty,
  isString,
  uniqueId,
} from 'lodash';
import { StateCreator } from 'zustand';

import { config } from '../../config';
import { addRandomValueToFilename } from '../../services/document/documentService';
import useAnalyticStore from '../analyticStore';
import useApiStore from '../apiStore';
import useAttributionStore from '../attributionStore';
import useUserStore from '../userStore';
import { LoanDocumentEnum } from './enums/LoanDocumentEnum';
import { getFilePreview } from './functions/getFilePreview';
import { getNextNumber } from './functions/getNextNumber';
import { getSignedUrl } from './functions/getSignedUrl';
import {
  uploadDocument,
  type UploadDocumentReturnType,
} from './functions/uploadDocument';
import { LoanDocumentResponseType } from './types/LoanDocumentResponseType';
import { LoanDocumentStateType } from './types/LoanDocumentStateType';
import { LoanDocumentType } from './types/LoanDocumentType';

export const loanDocumentStore: StateCreator<LoanDocumentStateType> = (
  set,
  get,
) => ({
  fileNameMap: new Map<File, string>(),

  gapDocuments: new Map<string, LoanDocumentType>(),
  ricDocuments: new Map<string, LoanDocumentType>(),
  vscDocuments: new Map<string, LoanDocumentType>(),

  isStartingWorkflow: false,
  isUploadFinished: false,
  isUploading: false,
  isUploadSuccess: false,
  isWorkflowCompleted: false,
  isWorkflowStarted: false,

  mileage: '',

  uploadedDocumentPaths: [],

  uploadedGAPDocuments: [],
  uploadedRICDocuments: [],
  uploadedVSCDocuments: [],

  uploadFailedMessage: '',

  computed: {
    get hasNotProvidedDocuments(): boolean {
      const { gapDocuments, ricDocuments, vscDocuments } = get();

      return (
        gapDocuments.size === 0 &&
        ricDocuments.size === 0 &&
        vscDocuments.size === 0
      );
    },
    get hasProvidedDocuments(): boolean {
      const {
        computed: {
          hasProvidedGAPDocuments,
          hasProvidedRICDocuments,
          hasProvidedVSCDocuments,
        },
      } = get();

      return (
        hasProvidedGAPDocuments ||
        hasProvidedRICDocuments ||
        hasProvidedVSCDocuments
      );
    },
    get hasProvidedGAPDocuments(): boolean {
      return get().gapDocuments.size !== 0;
    },
    get hasProvidedRICDocuments(): boolean {
      return get().ricDocuments.size !== 0;
    },
    get hasProvidedVSCDocuments(): boolean {
      return get().vscDocuments.size !== 0;
    },
    get numberOfGAPDocuments(): number {
      return get().gapDocuments.size;
    },
    get numberOfRICDocuments(): number {
      return get().ricDocuments.size;
    },
    get numberOfVSCDocuments(): number {
      return get().vscDocuments.size;
    },
    get percent(): number {
      let numberOfUploadedDocuments = 0;

      const {
        computed: {
          hasProvidedGAPDocuments,
          hasProvidedRICDocuments,
          hasProvidedVSCDocuments,
        },
      } = get();

      if (hasProvidedGAPDocuments) {
        numberOfUploadedDocuments += 1;
      }

      if (hasProvidedRICDocuments) {
        numberOfUploadedDocuments += 1;
      }

      if (hasProvidedVSCDocuments) {
        numberOfUploadedDocuments += 1;
      }

      // return default amount if no documents are uploaded
      if (numberOfUploadedDocuments === 0) {
        return 15;
      }

      // divide by 4 so we get 25, 50 or 75 instead of 33, 66, 99
      return Math.round((numberOfUploadedDocuments / 4) * 100);
    },
    get providedGAPDocuments(): LoanDocumentType[] {
      return Array.from(get().gapDocuments.values());
    },
    get providedRICDocuments(): LoanDocumentType[] {
      return Array.from(get().ricDocuments.values());
    },
    get providedVSCDocuments(): LoanDocumentType[] {
      return Array.from(get().vscDocuments.values());
    },
  },

  addGAPDocuments(files: File[]): void {
    const { getFileName } = get();

    set((state: LoanDocumentStateType) => {
      const gapDocuments = new Map<string, LoanDocumentType>(
        state.gapDocuments,
      );

      const { isDuplicateGAPFile } = get();

      forEach(
        filter(files, (file: File) => !isDuplicateGAPFile(file)),
        (file: File) => {
          gapDocuments.set(getFileName(file), {
            confirmed: false,
            file,
            preview: getFilePreview(file),
            type: LoanDocumentEnum.GAP_CONTRACT,
            uniqueName: uniqueId('gap-contract-'),
          });
        },
      );

      return { gapDocuments };
    });
  },
  addRICDocuments(files: File[]): void {
    const { getFileName } = get();

    set((state: LoanDocumentStateType) => {
      const ricDocuments = new Map<string, LoanDocumentType>(
        state.ricDocuments,
      );

      const { isDuplicateRICFile } = get();

      forEach(
        filter(files, (file: File) => !isDuplicateRICFile(file)),
        (file: File) => {
          ricDocuments.set(getFileName(file), {
            confirmed: false,
            file,
            preview: getFilePreview(file),
            type: LoanDocumentEnum.RIC,
            uniqueName: uniqueId('retail-contract-'),
          });
        },
      );

      return { ricDocuments };
    });
  },
  addUploadedDocumentPath({
    document,
    path,
  }: UploadDocumentReturnType): LoanDocumentType {
    if (isString(path) && !isEmpty(path)) {
      set({
        uploadedDocumentPaths: [...get().uploadedDocumentPaths, path],
      });
    }

    return document;
  },
  addVSCDocuments(files: File[]): void {
    const { getFileName } = get();

    set((state: LoanDocumentStateType) => {
      const vscDocuments = new Map<string, LoanDocumentType>(
        state.vscDocuments,
      );

      const { isDuplicateVSCFile } = get();

      forEach(
        filter(files, (file: File) => !isDuplicateVSCFile(file)),
        (file: File) => {
          vscDocuments.set(getFileName(file), {
            confirmed: false,
            file,
            preview: getFilePreview(file),
            type: LoanDocumentEnum.SERVICE_CONTRACT,
            uniqueName: uniqueId('service-contract-'),
          });
        },
      );

      return { vscDocuments };
    });
  },
  clearAllDocuments(): void {
    set({
      gapDocuments: new Map<string, LoanDocumentType>(),
      ricDocuments: new Map<string, LoanDocumentType>(),
      vscDocuments: new Map<string, LoanDocumentType>(),
    });
  },
  clearPreview(document: LoanDocumentType): void {
    const { gapDocuments, getFileName, ricDocuments, vscDocuments } = get();

    if (!document.preview) {
      return;
    }

    URL.revokeObjectURL(document.preview);
    document.preview = null;

    if (document.type === LoanDocumentEnum.GAP_CONTRACT) {
      gapDocuments.set(getFileName(document.file), document);
      set({ gapDocuments: new Map(gapDocuments) });
    } else if (document.type === LoanDocumentEnum.RIC) {
      ricDocuments.set(getFileName(document.file), document);
      set({ ricDocuments: new Map(ricDocuments) });
    } else if (document.type === LoanDocumentEnum.SERVICE_CONTRACT) {
      vscDocuments.set(getFileName(document.file), document);
      set({ vscDocuments: new Map(vscDocuments) });
    }
  },
  confirmGAPDocuments(): void {
    const { gapDocuments } = get();

    gapDocuments.forEach((document) => {
      document.confirmed = true;
    });

    set({ gapDocuments: new Map(gapDocuments) });
  },
  confirmRICDocuments(): void {
    const { ricDocuments } = get();

    ricDocuments.forEach((document) => {
      document.confirmed = true;
    });

    set({ ricDocuments: new Map(ricDocuments) });
  },
  confirmVSCDocuments(): void {
    const { vscDocuments } = get();

    vscDocuments.forEach((document) => {
      document.confirmed = true;
    });

    set({ vscDocuments: new Map(vscDocuments) });
  },
  getFileName(file: File): string {
    const { fileNameMap } = get();

    // when using the iPhone camera, the file we end up receiving is always
    // named 'image.jpg', so we need to generate a unique name otherwise we
    // will prevent users from being able to provide multiple pictures
    if (file.name === 'image.jpg') {
      const existingName = fileNameMap.get(file);

      if (existingName) {
        return existingName;
      }

      const newFileName = addRandomValueToFilename(file.name);
      fileNameMap.set(file, newFileName);

      set({ fileNameMap: new Map(fileNameMap) });

      return newFileName;
    }

    return file.name;
  },
  isDuplicateGAPFile(file: File): boolean {
    const { gapDocuments, getFileName, uploadedGAPDocuments } = get();

    const filename = getFileName(file);

    if (gapDocuments.has(filename)) {
      return true;
    }

    return uploadedGAPDocuments.some(({ name }) => name === filename);
  },
  isDuplicateRICFile(file: File): boolean {
    const { getFileName, ricDocuments, uploadedRICDocuments } = get();

    const filename = getFileName(file);

    if (ricDocuments.has(filename)) {
      return true;
    }

    return uploadedRICDocuments.some(({ name }) => name === filename);
  },
  isDuplicateVSCFile(file: File): boolean {
    const { getFileName, vscDocuments, uploadedVSCDocuments } = get();

    const filename = getFileName(file);

    if (vscDocuments.has(filename)) {
      return true;
    }

    return uploadedVSCDocuments.some(({ name }) => name === filename);
  },
  loadGAPDocuments(): Promise<void> {
    return useApiStore
      .getState()
      .radixApi()
      .get<never, LoanDocumentResponseType>(`/2.0/documents/loan`, {
        params: {
          type: LoanDocumentEnum.GAP_CONTRACT,
        },
      })
      .then(({ documents }) => {
        set({ uploadedGAPDocuments: documents });
      });
  },
  loadRICDocuments(): Promise<void> {
    return useApiStore
      .getState()
      .radixApi()
      .get<never, LoanDocumentResponseType>(`/2.0/documents/loan`, {
        params: {
          type: LoanDocumentEnum.RIC,
        },
      })
      .then(({ documents }) => {
        set({ uploadedRICDocuments: documents });
      });
  },
  loadVSCDocuments(): Promise<void> {
    return useApiStore
      .getState()
      .radixApi()
      .get<never, LoanDocumentResponseType>(`/2.0/documents/loan`, {
        params: {
          type: LoanDocumentEnum.SERVICE_CONTRACT,
        },
      })
      .then(({ documents }) => {
        set({ uploadedVSCDocuments: documents });
      });
  },
  removeDocument(document: LoanDocumentType): void {
    const { gapDocuments, getFileName, ricDocuments, vscDocuments } = get();

    const filename = getFileName(document.file);

    if (document.type === LoanDocumentEnum.GAP_CONTRACT) {
      if (gapDocuments.has(filename)) {
        gapDocuments.delete(filename);
        set({ gapDocuments: new Map(gapDocuments) });
      }
    } else if (document.type === LoanDocumentEnum.RIC) {
      if (ricDocuments.has(filename)) {
        ricDocuments.delete(filename);
        set({ ricDocuments: new Map(ricDocuments) });
      }
    } else if (document.type === LoanDocumentEnum.SERVICE_CONTRACT) {
      if (vscDocuments.has(filename)) {
        vscDocuments.delete(filename);
        set({ vscDocuments: new Map(vscDocuments) });
      }
    }
  },
  resetGAPDocuments(): void {
    const { gapDocuments, getFileName } = get();

    gapDocuments.forEach((document) => {
      if (!document.confirmed) {
        gapDocuments.delete(getFileName(document.file));
      }
    });

    set({ gapDocuments: new Map(gapDocuments) });
  },
  resetRICDocuments(): void {
    const { getFileName, ricDocuments } = get();

    ricDocuments.forEach((document) => {
      if (!document.confirmed) {
        ricDocuments.delete(getFileName(document.file));
      }
    });

    set({ ricDocuments: new Map(ricDocuments) });
  },
  resetVSCDocuments(): void {
    const { getFileName, vscDocuments } = get();

    vscDocuments.forEach((document) => {
      if (!document.confirmed) {
        vscDocuments.delete(getFileName(document.file));
      }
    });

    set({ vscDocuments: new Map(vscDocuments) });
  },
  revokePreviews(): void {
    const { gapDocuments, ricDocuments, vscDocuments } = get();

    gapDocuments.forEach((document) => {
      if (document.preview) {
        URL.revokeObjectURL(document.preview);
      }
    });

    ricDocuments.forEach((document) => {
      if (document.preview) {
        URL.revokeObjectURL(document.preview);
      }
    });

    vscDocuments.forEach((document) => {
      if (document.preview) {
        URL.revokeObjectURL(document.preview);
      }
    });
  },
  sendDocumentAnalytics(): void {
    const {
      sendGAPDocumentAnalytics,
      sendRICDocumentAnalytics,
      sendVSCDocumentAnalytics,
    } = get();

    sendGAPDocumentAnalytics();
    sendRICDocumentAnalytics();
    sendVSCDocumentAnalytics();
  },
  sendGAPDocumentAnalytics(): void {
    const {
      computed: { numberOfGAPDocuments },
    } = get();

    if (numberOfGAPDocuments === 0) {
      return;
    }

    useAnalyticStore
      .getState()
      .sendLoanDocumentAnalytics(
        config.loanDocuments.analytics.eventName,
        LoanDocumentEnum.GAP_CONTRACT,
        numberOfGAPDocuments,
      );
  },
  sendNotification(): Promise<void> {
    const {
      computed: { attributionsForLeadChannel },
    } = useAttributionStore.getState();

    return useApiStore
      .getState()
      .radixApi()
      .post('/2.0/documents/loan/notify', {
        attributions: attributionsForLeadChannel,
        files: get().uploadedDocumentPaths,
      })
      .then(() => {})
      .catch(() => {});
  },
  sendRICDocumentAnalytics(): void {
    const {
      computed: { numberOfRICDocuments },
    } = get();

    if (numberOfRICDocuments === 0) {
      return;
    }

    useAnalyticStore
      .getState()
      .sendLoanDocumentAnalytics(
        config.loanDocuments.analytics.eventName,
        LoanDocumentEnum.RIC,
        numberOfRICDocuments,
      );
  },
  sendVSCDocumentAnalytics(): void {
    const {
      computed: { numberOfVSCDocuments },
    } = get();

    if (numberOfVSCDocuments === 0) {
      return;
    }

    useAnalyticStore
      .getState()
      .sendLoanDocumentAnalytics(
        config.loanDocuments.analytics.eventName,
        LoanDocumentEnum.SERVICE_CONTRACT,
        numberOfVSCDocuments,
      );
  },
  setMileage(mileage: string): void {
    set({ mileage });
  },
  setWorkflowAsComplete(): Promise<void> {
    const { mileage } = get();

    return useApiStore
      .getState()
      .radixApi()
      .post('/2.0/documents/loan/complete', { mileage })
      .then(() => {})
      .catch(() => {})
      .finally(() =>
        set({ isWorkflowCompleted: true, isWorkflowStarted: false }),
      );
  },
  setWorkflowAsStarted(): Promise<void> {
    const { isWorkflowStarted, isStartingWorkflow } = get();

    if (isWorkflowStarted || isStartingWorkflow) {
      return Promise.resolve();
    }

    set({ isStartingWorkflow: true, isWorkflowCompleted: false });

    return useApiStore
      .getState()
      .radixApi()
      .post('/2.0/documents/loan/start')
      .then(() => {})
      .catch(() => {})
      .finally(() =>
        set({ isStartingWorkflow: false, isWorkflowStarted: true }),
      );
  },
  uploadDocuments(): Promise<void> {
    set({
      isUploadFinished: false,
      isUploading: true,
      isUploadSuccess: false,
      uploadFailedMessage: '',
    });

    const {
      revokePreviews,
      sendDocumentAnalytics,
      sendNotification,
      setWorkflowAsComplete,
      uploadGAPDocuments,
      uploadRICDocuments,
      uploadVSCDocuments,
    } = get();

    return Promise.all([
      uploadGAPDocuments(),
      uploadRICDocuments(),
      uploadVSCDocuments(),
    ])
      .then(sendDocumentAnalytics)
      .then(revokePreviews)
      .then(setWorkflowAsComplete)
      .then(sendNotification)
      .then(() => set({ isUploadSuccess: true }))
      .catch((error: any) => {
        const code = lodashGet(error, 'code', null);
        const message = lodashGet(error, 'message', '');

        let uploadFailedMessage = i18n.t('LoanDocumentListPage.uploadFailed');

        if (code === 'ECONNABORTED' && message.includes('timeout')) {
          uploadFailedMessage = i18n.t('LoanDocumentListPage.uploadTimeout');
        }

        set({ isUploadSuccess: false, uploadFailedMessage });
      })
      .finally(() => set({ isUploadFinished: true, isUploading: false }));
  },
  uploadGAPDocuments(): Promise<LoanDocumentType[]> {
    const { user } = useUserStore.getState();

    const {
      addUploadedDocumentPath,
      computed: { providedGAPDocuments },
      uploadedGAPDocuments,
    } = get();

    return Promise.all(
      providedGAPDocuments.map((document: LoanDocumentType, index: number) =>
        getSignedUrl()(
          document,
          String(user?.id),
          getNextNumber(index, uploadedGAPDocuments.length),
        )
          .then(uploadDocument(document))
          .then(addUploadedDocumentPath),
      ),
    );
  },
  uploadRICDocuments(): Promise<LoanDocumentType[]> {
    const { user } = useUserStore.getState();

    const {
      addUploadedDocumentPath,
      computed: { providedRICDocuments },
      uploadedRICDocuments,
    } = get();

    return Promise.all(
      providedRICDocuments.map((document: LoanDocumentType, index: number) =>
        getSignedUrl()(
          document,
          String(user?.id),
          getNextNumber(index, uploadedRICDocuments.length),
        )
          .then(uploadDocument(document))
          .then(addUploadedDocumentPath),
      ),
    );
  },
  uploadVSCDocuments(): Promise<LoanDocumentType[]> {
    const { user } = useUserStore.getState();

    const {
      addUploadedDocumentPath,
      computed: { providedVSCDocuments },
      uploadedVSCDocuments,
    } = get();

    return Promise.all(
      providedVSCDocuments.map((document: LoanDocumentType, index: number) =>
        getSignedUrl()(
          document,
          String(user?.id),
          getNextNumber(index, uploadedVSCDocuments.length),
        )
          .then(uploadDocument(document))
          .then(addUploadedDocumentPath),
      ),
    );
  },
});
