export const privacyPoliceLinks = [
  {
    label: 'Privacy Policy',
    key: 'pp',
    url: 'https://www.thesavingsgroup.com/privacy-policy/',
  },
  {
    label: 'Terms of Use',
    key: 'tou',
    url: 'https://www.thesavingsgroup.com/terms-of-use/',
  },
  {
    label: 'ESign Agreement',
    key: 'esa',
    url: 'https://www.autopay.com/esign',
  },
  {
    label: 'Electronic Communication Consent',
    key: 'ecc',
    url: 'https://www.autopay.com/electronic-document',
  },
  {
    label: 'Credit Pull Authorization',
    key: 'cpa',
    url: 'https://www.autopay.com/credit-report-pull-authorization/',
  },
  {
    label: 'here',
    url: 'https://www.autopay.com/electronic-communication-consent/',
  },
];
