import { isNonEmptyString } from '@utils/isNonEmptyString';
import i18n from 'i18next';
import {
  get as lodashGet,
  gt,
  head,
  isEmpty,
  isEqual,
  isObject,
  pick,
  toString,
} from 'lodash';
import { devtools, persist } from 'zustand/middleware';

import { DashboardVehicle } from '../pages/DashboardGaragePage/DashboardVehicle';
import { isInEnum } from '../utils/isInEnum';
import useApiStore from './apiStore';
import useDashboardStore from './dashboardStore';
import useParameterStore from './parameterStore';
import { create, storeDevToolOptions } from './storeManager';

type Vehicle = {
  imageList: Record<string, { url: string; vehicleImageType: string }>[];
  make: string;
  makeId: string;
  model: string;
  modelId: string;
  trim: string;
  trimId: string;
  vin: string;
  year: string;
};

enum VehicleImageType {
  DEFAULT = 'DEFAULT',
  PROFILE = 'PROFILE',
}

type VehicleImage = {
  url: string;
  vehicleImageType: VehicleImageType;
};

type VehicleMake = {
  label: string | undefined;
  value: string | undefined;
};

type VehicleModel = {
  label: string | undefined;
  value: string | undefined;
};

export type VehicleTrim = {
  label: string | undefined;
  value: string | undefined;
};

type VehicleCriteriaValue = {
  plate?: string | null;
  state?: string | null;
  vin?: string | null;
};

export enum ApplicationType {
  LEASE_BUYOUT = 'LEASE_BUYOUT',
  PURCHASE = 'PURCHASE',
  REFINANCE = 'REFINANCE',
}

export enum RefinanceGoal {
  CHECK_OPTIONS = 'CHECK_OPTIONS',
  LOWER_PAYMENT = 'LOWER_PAYMENT',
  LOWER_RATE = 'LOWER_RATE',
}

export enum PurchaseType {
  DEALER = 'DEALER',
  PRIVATE_PARTY = 'PRIVATE_PARTY',
}

export enum SearchVehicle {
  BY_LICENSE_PLATE = 'BY_PLATE',
  BY_MAKE_MODEL = 'BY_MAKE_MODEL',
  BY_VIN = 'BY_VIN',
}

export enum VehicleCondition {
  NEW = 'NEW',
  USED = 'USED',
}

type ApplyStoreComputed = {
  hasValidApplicationType: boolean;
  hasValidPayoffAmount: boolean;
  hasValidPurchasePrice: boolean;
  hasValidPurchaseType: boolean;
  hasValidRefinanceGoal: boolean;
  hasValidVehicleCondition: boolean;
  hasValidVehicleLicensePlate: boolean;
  hasValidVehicleMake: boolean;
  hasValidVehicleMileage: boolean;
  hasValidVehicleModel: boolean;
  hasValidVehicleSearch: boolean;
  hasValidVehicleTrim: boolean;
  hasValidVehicleVin: boolean;
  hasValidVehicleYear: boolean;
  submitPayload: Record<string, unknown>;
  yearMakeModel: string;
};

type ApplyStore = {
  applicationType: ApplicationType | null;
  fetchVehicleFailedMessage: string | null;
  fetchVehicleImageByTrimFailed: boolean;
  fetchVehicleMakesFailedMessage: string | null;
  fetchVehicleModelsFailedMessage: string | null;
  fetchVehicleSuccess: boolean;
  fetchVehicleTrimsFailedMessage: string | null;
  make: VehicleMake | null;
  makes: VehicleMake[];
  mileage: string | null;
  model: VehicleModel | null;
  models: VehicleModel[];
  payoffAmount: string | null;
  plate: string | null;
  purchasePrice: string | null;
  purchaseType: PurchaseType | null;
  refinanceGoal: RefinanceGoal | null;
  searchVehicle: SearchVehicle | null;
  state: string | null;
  submitCompleted: boolean;
  submitFailed: boolean;
  submitFailedKey: string | null;
  trim: VehicleTrim | null;
  trims: VehicleTrim[];
  vehicleCondition: VehicleCondition | null;
  vehicleId: string | null;
  vehicleImage: string | null;
  vin: string | null;
  year: string | null;

  computed: ApplyStoreComputed;

  clear: () => void;
  fetchVehicleBy: (vehicleCriteria: VehicleCriteriaValue) => Promise<void>;
  fetchVehicleImageByTrim: (trim: VehicleTrim | null) => Promise<void>;
  fetchVehicleMakes: () => Promise<void>;
  fetchVehicleModels: () => Promise<void>;
  fetchVehicleTrims: () => Promise<void>;
  resetSubmitValues: () => void;
  setApplicationType: (applicationType: ApplicationType) => void;
  setMakeByValue: (value: string) => void;
  setMileage: (value: string) => void;
  setModelByValue: (value: string) => void;
  setPayoffAmount: (value: string) => void;
  setPurchasePrice: (value: string) => void;
  setPurchaseType: (value: PurchaseType) => void;
  setRefinanceGoal: (value: RefinanceGoal) => void;
  setSearchVehicle: (value: SearchVehicle) => void;
  setTrimByValue: (value: string) => void;
  setVehicleCondition: (value: VehicleCondition) => void;
  setYear: (value: string) => void;
  submit: () => Promise<void>;
};

const keysToPersist = [
  'applicationType',
  'make',
  'mileage',
  'model',
  'payoffAmount',
  'plate',
  'purchasePrice',
  'purchaseType',
  'refinanceGoal',
  'searchVehicle',
  'state',
  'trim',
  'vehicleCondition',
  'vin',
  'year',
];

const useApplyStore = create()(
  devtools(
    persist<ApplyStore>(
      (set, get) => ({
        applicationType: null,
        fetchVehicleFailedMessage: null,
        fetchVehicleImageByTrimFailed: false,
        fetchVehicleMakesFailedMessage: null,
        fetchVehicleModelsFailedMessage: null,
        fetchVehicleSuccess: false,
        fetchVehicleTrimsFailedMessage: null,
        make: null,
        makes: [],
        mileage: null,
        model: null,
        models: [],
        payoffAmount: null,
        plate: null,
        purchasePrice: null,
        purchaseType: null,
        refinanceGoal: null,
        searchVehicle: null,
        state: null,
        submitCompleted: false,
        submitFailed: false,
        submitFailedKey: null,
        trim: null,
        trims: [],
        vehicleCondition: null,
        vehicleId: null,
        vehicleImage: null,
        vin: null,
        year: null,

        computed: {
          get hasValidApplicationType() {
            return isInEnum(get().applicationType, ApplicationType);
          },
          get hasValidPayoffAmount() {
            return gt(Number(get().payoffAmount), 0);
          },
          get hasValidPurchasePrice() {
            return gt(Number(get().purchasePrice), 0);
          },
          get hasValidPurchaseType() {
            return isInEnum(get().purchaseType, PurchaseType);
          },
          get hasValidRefinanceGoal() {
            return isInEnum(get().refinanceGoal, RefinanceGoal);
          },
          get hasValidVehicleCondition() {
            return isInEnum(get().vehicleCondition, VehicleCondition);
          },
          get hasValidVehicleLicensePlate() {
            const { plate, state } = get();

            return [plate, state].every(isNonEmptyString);
          },
          get hasValidVehicleMake() {
            const { make } = get();

            return (
              isObject(make) &&
              isNonEmptyString(make?.label) &&
              isNonEmptyString(make?.value)
            );
          },
          get hasValidVehicleMileage() {
            return gt(Number(get().mileage), 0);
          },
          get hasValidVehicleModel() {
            const { model } = get();

            return (
              isObject(model) &&
              isNonEmptyString(model?.label) &&
              isNonEmptyString(model?.value)
            );
          },
          get hasValidVehicleSearch() {
            return isInEnum(get().searchVehicle, SearchVehicle);
          },
          get hasValidVehicleTrim() {
            const { trim } = get();

            return (
              isObject(trim) &&
              isNonEmptyString(trim?.label) &&
              isNonEmptyString(trim?.value)
            );
          },
          get hasValidVehicleVin() {
            const { vin } = get();

            return isNonEmptyString(vin);
          },
          get hasValidVehicleYear() {
            const { year } = get();

            return gt(Number(year), 0);
          },
          get submitPayload() {
            const {
              applicationType,
              make,
              mileage,
              model,
              payoffAmount,
              plate,
              purchasePrice,
              purchaseType,
              refinanceGoal,
              state,
              trim,
              vehicleCondition,
              vin,
              year,
            } = get();

            const { leadChannelCode } = useParameterStore.getState();

            return {
              // important to prefer `purchaseType` over `applicationType` as the
              // `applicationType` always has a value (REFINANCE or PURCHASE),
              // but the purchase type is a more specific value that we want to
              // use if it's available (DEALER, LEASE_BUYOUT, PRIVATE_PARTY, etc)
              applicationType: purchaseType || applicationType,
              isNew: isEqual(vehicleCondition, VehicleCondition.NEW),
              leadChannelCode,
              make: make?.label,
              mileage: Number(mileage),
              model: model?.label,
              payoffAmount: payoffAmount ? Number(payoffAmount) : null,
              plate,
              province: state,
              purchasePrice: purchasePrice ? Number(purchasePrice) : null,
              refinanceGoal,
              trim: trim?.label,
              trimId: trim?.value,
              vin,
              year: Number(year),
            };
          },
          get yearMakeModel() {
            const { year, make, model } = get();

            const makeLabel = lodashGet(make, 'label');
            const modelLabel = lodashGet(model, 'label');

            return `${year} ${makeLabel} ${modelLabel}`;
          },
        },

        clear: () => {
          set({
            fetchVehicleFailedMessage: null,
            fetchVehicleImageByTrimFailed: false,
            fetchVehicleMakesFailedMessage: null,
            fetchVehicleModelsFailedMessage: null,
            fetchVehicleSuccess: false,
            fetchVehicleTrimsFailedMessage: null,
            makes: [],
            models: [],
            trims: [],
            vehicleImage: null,
          });
        },
        fetchVehicleBy: async (vehicleCriteriaValue: VehicleCriteriaValue) => {
          const { searchVehicle } = get();

          const {
            plate = null,
            state = null,
            vin = null,
          } = vehicleCriteriaValue;

          set({
            fetchVehicleFailedMessage: null,
            fetchVehicleSuccess: false,
            makes: [],
            models: [],
            trims: [],
            vehicleImage: null,
          });

          const radixHttpRequest = useApiStore.getState().radixApi();

          return radixHttpRequest
            .post<never, Vehicle[]>('/vehicles/search', {
              criteria: searchVehicle,
              value: vehicleCriteriaValue,
            })
            .then((vehicles) => {
              const vehicle = head(vehicles);

              const make: VehicleMake = {
                label: lodashGet(vehicle, 'make'),
                value: lodashGet(vehicle, 'makeId'),
              };

              const model: VehicleModel = {
                label: lodashGet(vehicle, 'model'),
                value: lodashGet(vehicle, 'modelId'),
              };

              const trim: VehicleTrim = {
                label: lodashGet(vehicle, 'trim'),
                value: lodashGet(vehicle, 'trimId'),
              };

              const year = lodashGet(vehicle, 'year');

              set({
                fetchVehicleSuccess: true,
                make,
                model,
                plate,
                state,
                trim,
                vin,
                year,
              });
            })
            .catch((error) => {
              const errorStatus = toString(lodashGet(error, 'response.status'));
              const defaultMessage = i18n.t(
                'errors.fetchVehicleBy.defaultMessage',
              );

              set({
                fetchVehicleFailedMessage: lodashGet(
                  i18n.t('errors.fetchVehicleBy.statusMessage'),
                  errorStatus,
                  defaultMessage,
                ),
                make: null,
                model: null,
                plate: null,
                state: null,
                trim: null,
                vin: null,
                year: null,
              });
            });
        },
        fetchVehicleImageByTrim: async (trim: VehicleTrim | null) => {
          const { year, make, model } = get();

          const makeValue = lodashGet(make, 'value');
          const modelValue = lodashGet(model, 'value');
          const trimValue = lodashGet(trim, 'value');
          if (!year || !makeValue || !modelValue || !trimValue) {
            return;
          }

          const radixHttpRequest = useApiStore.getState().radixApi();

          set({ fetchVehicleImageByTrimFailed: false, vehicleImage: null });

          return radixHttpRequest
            .get<never, VehicleImage[]>(
              `vehicles/years/${year}/makes/${makeValue}/models/${modelValue}/trims/${trimValue}/images`,
            )
            .then((images) => {
              const vehicleImage = images.find(
                (image) => image.vehicleImageType === VehicleImageType.DEFAULT,
              );

              const vehicleImageURL = lodashGet(vehicleImage, 'url', null);

              set({
                fetchVehicleImageByTrimFailed: isEmpty(vehicleImageURL),
                vehicleImage: vehicleImageURL,
              });
            })
            .catch(() => set({ fetchVehicleImageByTrimFailed: true }));
        },
        fetchVehicleMakes: async () => {
          const { year } = get();

          set({
            fetchVehicleMakesFailedMessage: null,
            makes: [],
            models: [],
            trims: [],
          });

          if (!year) {
            return;
          }

          const radixHttpRequest = useApiStore.getState().radixApi();

          return radixHttpRequest
            .get<never, VehicleMake[]>(`/vehicles/years/${year}/makes`)
            .then((makes) => set({ makes }))
            .catch(() =>
              set({
                fetchVehicleMakesFailedMessage: i18n.t(
                  'errors.fetchVehicleMakes.defaultMessage',
                ),
              }),
            );
        },
        fetchVehicleModels: async () => {
          const { year, make } = get();

          set({
            fetchVehicleModelsFailedMessage: null,
            models: [],
            trims: [],
          });

          const makeValue = lodashGet(make, 'value');
          if (!year || !makeValue) {
            return;
          }

          const radixHttpRequest = useApiStore.getState().radixApi();

          return radixHttpRequest
            .get<never, VehicleModel[]>(
              `/vehicles/years/${year}/makes/${makeValue}/models`,
            )
            .then((models) => set({ models }))
            .catch(() =>
              set({
                fetchVehicleModelsFailedMessage: i18n.t(
                  'errors.fetchVehicleModels.defaultMessage',
                ),
              }),
            );
        },
        fetchVehicleTrims: async () => {
          const { year, make, model } = get();

          set({ fetchVehicleTrimsFailedMessage: null, trims: [] });

          const makeValue = lodashGet(make, 'value');
          const modelValue = lodashGet(model, 'value');
          if (!year || !makeValue || !modelValue) {
            return;
          }

          const radixHttpRequest = useApiStore.getState().radixApi();

          return radixHttpRequest
            .get<never, VehicleTrim[]>(
              `/vehicles/years/${year}/makes/${makeValue}/models/${modelValue}/trims`,
            )
            .then((trims) => set({ trims }))
            .catch(() =>
              set({
                fetchVehicleTrimsFailedMessage: i18n.t(
                  'errors.fetchVehicleTrims.defaultMessage',
                ),
              }),
            );
        },
        resetSubmitValues() {
          set({
            submitCompleted: false,
            submitFailed: false,
            submitFailedKey: null,
          });
        },
        setApplicationType: (value: ApplicationType) => {
          const { applicationType } = get();

          const applicationTypeChanged = applicationType !== value;

          set({
            applicationType: value,

            ...(applicationTypeChanged && {
              payoffAmount: null,
              purchasePrice: null,
              purchaseType: null,
              refinanceGoal: null,
            }),
          });
        },
        setMakeByValue: (value: string) => {
          const { makes } = get();

          const make = makes.find((make) => make.value === value) || null;

          set({ make, model: null, trim: null });
        },
        setMileage: (value: string) => {
          set({ mileage: value });
        },
        setModelByValue: (value: string) => {
          const { models } = get();

          const model = models.find((model) => model.value === value) || null;

          set({ model, trim: null });
        },
        setPayoffAmount: (value: string) => {
          set({ payoffAmount: value });
        },
        setPurchasePrice: (value: string) => {
          set({ purchasePrice: value });
        },
        setPurchaseType: (value: PurchaseType) => {
          set({ purchaseType: value });
        },
        setRefinanceGoal: (value: RefinanceGoal) => {
          set({ refinanceGoal: value });
        },
        setSearchVehicle: (value: SearchVehicle) => {
          const { searchVehicle } = get();

          const searchVehicleChanged = searchVehicle !== value;

          set({
            searchVehicle: value,

            ...(searchVehicleChanged && {
              make: null,
              model: null,
              plate: null,
              state: null,
              trim: null,
              vin: null,
              year: null,
            }),
          });
        },
        setTrimByValue: (value: string) => {
          const { trims } = get();

          const trim = trims.find((trim) => trim.value === value) || null;

          set({ trim });
        },
        setVehicleCondition: (value: VehicleCondition) => {
          set({ vehicleCondition: value });
        },
        setYear: (year: string) => {
          set({
            make: null,
            model: null,
            trim: null,
            year,
          });
        },
        submit() {
          const {
            computed: { submitPayload },
            resetSubmitValues,
          } = get();

          const radixHttpRequest = useApiStore.getState().radixApi();

          resetSubmitValues();

          return radixHttpRequest
            .post<never, DashboardVehicle>(`/2.0/apply`, submitPayload)
            .then((vehicle: DashboardVehicle) => {
              // Add the new vehicle to the dashboard store
              // so that it appears in the garage without having to refetch
              useDashboardStore.getState().addVehicle(vehicle);

              // Set the vehicle ID so that the user can be redirected to the applicant workflow
              set({ vehicleId: vehicle.id });
            })
            .catch((error) => {
              const errorStatus = toString(lodashGet(error, 'response.status'));

              set({
                submitFailed: true,
                submitFailedKey: lodashGet(
                  i18n.t('errors.apply.statusMessage'),
                  errorStatus,
                )
                  ? `errors.apply.statusMessage.${errorStatus}`
                  : 'errors.apply.defaultMessage',
              });
            })
            .finally(() => set({ submitCompleted: true }));
        },
      }),
      {
        name: 'consumer_portal:apply',
        partialize: (state: ApplyStore) =>
          pick(state, keysToPersist) as ApplyStore,
      },
    ),
    storeDevToolOptions('applyStore'),
  ),
);

export default useApplyStore;
