import { get, merge } from 'lodash';

import { FormFiledInterface } from '../../../../../components/shared/Form/interfaces/formFields.interface';
import { PillowType } from '../../../../../components/shared/PillowImage/pillowTypes';
import { componentTypes } from '../../../../../constants/componentTypes';
import { FormFieldsModelT } from '../../../../../forms/formFieldsModelT';
import { unifiedOptionsList } from '../../../../../forms/housingTypeOptions';
import { populateFields } from '../../../../../forms/populateFields';
import {
  commonFields,
  displayPrevious,
} from '../../../../../utils/helpers/housing-info';
import { withContext } from '../../../../../utils/workflow/withContext';
import { Signal } from '../../XState/Model';
import BaseModel from '../common/BaseModel';

const currentHousingFields = merge({}, commonFields(true), {
  ownershipStatus: {
    options: unifiedOptionsList,
  },
});

const previousHousingFields = commonFields(false);
const previousHousingSubtitle = {
  subtitle: {
    label: 'Previous address',
    hint: 'Our lenders require two years of residence history. Please share your previous residence with us.',
    name: 'subtitle',
    component: componentTypes.SUBTITLE,
  },
};

const formattedPreviousAddressFields: FormFieldsModelT =
  Object.entries<FormFiledInterface>({
    ...previousHousingSubtitle,
    ...previousHousingFields,
  }).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [`${key}Previous`]: {
        ...value,
        shouldUnregister: true,
        dependsOn: {
          fields: ['residences.current.activeStartDate'],
          handler: ({ data, setVisible, setValidation }: any) => {
            const currentStartDate = get(
              data,
              'residences.current.activeStartDate',
            );

            setVisible(displayPrevious(currentStartDate));

            if (key === 'activeStartDate') {
              setValidation({
                validate: (v: any) =>
                  Date.parse(currentStartDate as string) > Date.parse(v) ||
                  'Date should not be greater then start date of current property',
              });
            }
          },
        },
      },
    };
  }, {});

const model = (send: any) =>
  merge({}, BaseModel, {
    template: {
      header: {
        showClose: false,
        showBack: true,
      },
      footer: {
        isActive: false,
      },
      img: {
        type: PillowType.PERSONAL_DATA,
      },
    },
    form: {
      actions: {
        primary: {
          label: 'Save',
          testId: 'save',
        },
        secondary: {
          label: 'Cancel',
          testId: 'cancel',
          handler: () => {
            send(Signal.Previous);
          },
        },
      },
      fields: {
        ...currentHousingFields,
        ...formattedPreviousAddressFields,
      },
      withConfirmModal: true,
    },
    info: {
      title: 'Review housing details',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Does everything look correct? Use this time to correct any mistakes or confirm these details, as accurate information will allow for the smoothest application process possible.',
          ],
        },
      ],
    },
    headerBlock: {
      title: 'Edit your housing details.',
      subtitle: ['Applicant', '100% complete'],
      progressBar: {
        progress: 100,
        withContainer: true,
      },
    },
  });

const populateData =
  (fields: any) =>
  (send: any, { context }: any) => {
    return populateFields(fields, context.updatedUser);
  };

const modelFn = (...args: [any, any]) =>
  withContext({ model: model(args[0]), populateFields: populateData })(...args);

export default modelFn;
