import { merge } from 'lodash';

import { populateFields } from '../../../../../forms/populateFields';
import { editIncomeForm } from '../../../../../utils/helpers/income/editIncomeForm';
import { withContext } from '../../../../../utils/workflow/withContext';
import BaseModel from '../common/BaseModel';

const model = merge({}, BaseModel, {
  form: editIncomeForm,
  info: {
    title: 'Review co-applicant income details',
    subtitle: 'Help',
    content: [
      {
        copy: [
          'Does everything look correct? Use this time to correct any mistakes or confirm these details, as accurate information will allow for the smoothest application process possible.',
        ],
      },
    ],
  },
  headerBlock: {
    title: 'Edit their income details.',
    subtitle: ['Co-Applicant', '80% complete'],
    progressBar: {
      progress: 80,
      withContainer: true,
    },
  },
});

const populateData =
  (fields: any) =>
  (send: any, { context }: any) => {
    return populateFields(fields, context.coApplicant.incomes);
  };

const modelFn = (...args: [any, any]) =>
  withContext({ model, populateFields: populateData })(...args);

export default modelFn;
