/**
 * Uses `ButtonGroup` to render buttons for user interaction, providing the necessary click handlers.
 */
import { isEmpty } from 'lodash';

import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import { ButtonGroup } from '../ButtonGroup/ButtonGroup';
import { ButtonGroupContainer } from './styles';

export interface FormButtonProps {
  label?: string;
  isHidden?: boolean;
  testId?: string;
  handler?: (data?: any) => void;
}

export interface FormButtonsProps {
  actions:
    | {
        primary?: FormButtonProps | undefined;
        secondary?: FormButtonProps | undefined;
      }
    | undefined;
  onAction: any;
  isSticky: boolean | undefined;
  afterContent: HTMLElement;
  isDesktop?: boolean;
  isMobile?: boolean;
}

const generateButtonPresentationModel = ({
  button,
  onAction,
  type,
}: {
  button: any;
  onAction: any;
  type?: string;
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isHidden, label, testId, handler, ...otherProps } = button;

  return {
    children: label,
    type,
    onClick: (event: any) => onAction(handler, type, event),
    testId,
    ...otherProps,
  };
};

export const FormButtonsComponent = ({
  actions = {},
  onAction,
  isSticky,
  afterContent,
  isDesktop,
  isMobile,
}: FormButtonsProps) => {
  if (isEmpty(actions)) {
    return null;
  }

  const primaryButtonConfig = actions.primary;
  const secondaryButtonConfig = actions.secondary;

  const presModel: any = {
    ...(!(primaryButtonConfig && primaryButtonConfig.isHidden)
      ? {
          primaryButton: generateButtonPresentationModel({
            button: primaryButtonConfig,
            onAction,
            type: 'submit',
          }),
        }
      : {}),
  };

  if (secondaryButtonConfig && !secondaryButtonConfig.isHidden) {
    presModel.secondaryButton = generateButtonPresentationModel({
      button: secondaryButtonConfig,
      onAction,
    });
  }

  return (
    <ButtonGroupContainer
      $isDesktop={isDesktop}
      $isMobile={isMobile}
      $isSticky={isSticky}
    >
      <ButtonGroup
        hasContentAfterButton={!!afterContent}
        isFormButtons
        {...presModel}
      />
      {afterContent}
    </ButtonGroupContainer>
  );
};

export const FormButtons = withMatchMedia(FormButtonsComponent);
