import { merge } from 'lodash';

import { componentTypes } from '../../../../../constants/componentTypes';
import {
  DocumentEnum,
  DOCUMENTS_CONFIG,
} from '../../../../../services/document/documentsConfig';
import { isDocumentOptional } from '../../../../../services/document/documentService';
import { Signal, VerificationContext } from '../../XState/Model';
import { BaseModel } from '../BaseModel';
import { documentPanelsConfig } from './panelsConfig';

const model = (send: any, { context }: { context: VerificationContext }) => {
  // filter out optional documents as they are not required and should not be counted
  const uploadedTasksAmount = context.uploadedDocuments.filter((doc) =>
    isDocumentOptional(doc),
  ).length;

  const requiredTasksAmount = context.requiredDocuments.filter((doc) =>
    isDocumentOptional(doc),
  ).length;

  const requiredTasksListSelect = documentPanelsConfig(
    context.requiredDocuments,
    context.uploadedDocuments,
  );

  return merge({}, BaseModel(context), {
    template: {
      header: {
        showClose: false,
        showBack: true,
        onBack: () => {
          send(Signal.Previous);
        },
      },
    },
    form: {
      actions: {
        primary: {
          testId: 'saveAndReturn',
          label: 'Save & return',
          handler: () => {
            send(Signal.Next);
          },
        },
      },
      fields: {
        documents: {
          name: 'documentList',
          component: componentTypes.SINGLE_SELECT,
          options: requiredTasksListSelect,
          largeTitle: true,
          onChange: (selectedVerificationTask: DocumentType) => {
            send(Signal.Edit, { data: { selectedVerificationTask } });
          },
        },
      },
    },
    headerBlock: {
      progressBar: {
        progress: (uploadedTasksAmount * 100) / requiredTasksAmount,
        withContainer: true,
      },
      title: 'Upload verifications',
      subtitle: [
        'Verifications',
        `${uploadedTasksAmount} of ${requiredTasksAmount} received`,
      ],
    },
    info: {
      title: 'Upload verifications',
      content: [
        {
          copy: [
            'In order to continue the processing of your loan, there are a few documents and proofs you will need to provide. Please review this list and start to collect these necessary items. Should you need help with how to provide these verifications, further instruction will be given on each individual document’s tab.',
          ],
        },
        {
          copy: ['Please be prepared to provide the following:'],
        },
        {
          asList: true,
          copy: context.requiredDocuments.map(
            (doc: DocumentEnum) => DOCUMENTS_CONFIG[doc]?.label,
          ),
        },
      ],
    },
  });
};

export default model;
