import { debounce, merge } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import { MappedRefinanceOffer } from '../../../../../common/interfaces/vehicle/offer.interface';
import { PillowForm } from '../../../../../components/PillowForm';
import { componentTypes } from '../../../../../constants/componentTypes';
import {
  OffersInfo,
  OffersSummary,
  OffersTotal,
} from '../../../../common/styles/offer-styles';
import { Step } from '../../XState/Model';
import { defaultInterest, formatOptions, sortOffers } from './utils';

const shouldResetGapOptions = (selectedOffer: any, savedOffer: any) =>
  !savedOffer || !selectedOffer || savedOffer.value !== selectedOffer.value;

const Controller = (props: any) => {
  const { context, send, presModel, stepName } = props;
  const { offers = {} } = context;
  const methods = useForm();

  const {
    offersWithGap,
    selectedOfferOptionId = '0',
    selectedOfferWithGap,
  } = offers;

  const selectedSorting = useWatch({
    control: methods.control,
    name: 'sortBy',
  });

  const formattedOptions = useMemo(() => {
    return formatOptions(
      context.offers?.offersWithoutGap as MappedRefinanceOffer[],
      context.currentVehicle.loan?.paymentAmount || 0,
      context.offers?.interest || defaultInterest,
    );
  }, [context.offers, context.currentVehicle]);

  const sortedOptions = useMemo(() => {
    return sortOffers(formattedOptions, selectedSorting);
  }, [selectedSorting, formattedOptions]);

  const selectedOptionId = useWatch({
    control: methods.control,
    name: 'offers',
    defaultValue: selectedOfferOptionId || '0',
  });

  const downPaymentField = useWatch({
    control: methods.control,
    name: 'downPayment',
  });

  const downPaymentState = methods.getFieldState('downPayment');

  // Reset selected offer when options changes
  useEffect(() => {
    if (downPaymentState.isDirty) {
      methods.setValue('offers', '0');
    }
  }, [formattedOptions]);

  const getOffers = useCallback(
    (downPayment: any) => {
      send('GetOffers', {
        downPayment,
      });
    },
    [send],
  );

  const debouncedGetOffers = useMemo(
    () => debounce(getOffers, 1000),
    [getOffers],
  );

  useEffect(() => {
    if (downPaymentState.isDirty) {
      methods.trigger('downPayment').then((isValid) => {
        if (isValid) {
          debouncedGetOffers(downPaymentField);
        } else {
          debouncedGetOffers.cancel();
        }
      });
    }
  }, [downPaymentField]);

  const buttonsAfterContent = useMemo(() => {
    if (!formattedOptions?.[selectedOptionId]) {
      return null;
    }

    const {
      offer: { amtPayment, term },
    } = formattedOptions[selectedOptionId];

    return (
      amtPayment && (
        <OffersSummary>
          <OffersTotal key="1">{`$${amtPayment.toFixed()}/month`}</OffersTotal>
          <OffersInfo key="2">{term} month term</OffersInfo>
        </OffersSummary>
      )
    );
  }, [selectedOptionId, formattedOptions]);

  const onSubmit = useCallback(() => {
    const { offer, value } = formattedOptions[selectedOptionId];
    const offerWithGap = offersWithGap.find(
      ({ value }: any) => value === offer.value,
    );

    send('Next', {
      offers: {
        ...offers,
        selectedOfferOptionId: value,
        selectedOfferWithoutGap: offer,
        selectedOfferWithGap: offerWithGap,
        ...(shouldResetGapOptions(offerWithGap, selectedOfferWithGap)
          ? {
              selectedGapOptionId: '0',
            }
          : {}),
      },
    });
  }, [
    selectedOptionId,
    formattedOptions,
    send,
    offers,
    offersWithGap,
    selectedOfferWithGap,
  ]);

  const isLoading = useMemo(() => {
    return stepName[Step.SelectOffer] === 'GettingOffers';
  }, [stepName]);

  const enhancedForm = useMemo(() => {
    const mergedFields = merge({}, presModel.form.fields, {
      offers: {
        options: sortedOptions,
        isLoading,
      },
      ...(!sortedOptions.length
        ? {
            noOffers: {
              name: 'noOffers',
              label: 'No offers available for current selection.',
              component: componentTypes.SUBTITLE,
            },
          }
        : {}),
    });

    return {
      ...presModel.form,
      actionsContent: isLoading ? null : buttonsAfterContent,
      fields: mergedFields,
      actions: {
        ...presModel.form.actions,
        primary: {
          ...presModel.form.actions.primary,
          handler: methods.handleSubmit(onSubmit),
          isDisabled: isLoading,
        },
      },
    };
  }, [
    isLoading,
    presModel,
    sortedOptions,
    methods,
    buttonsAfterContent,
    onSubmit,
  ]);

  const enhancedProps = useMemo(
    () => ({
      presModel: {
        ...presModel,
        form: enhancedForm,
        info: {
          ...presModel.info,
          title: 'Select financing',
          content: [
            {
              copy: [
                'These loan options are based on your $0 down payment and goals previously identified. We search within our network of lending partners to bring you the most competitive options in the market. If you have any questions regarding these figures, you can always contact your dedicated loan specialist for more information.',
              ],
            },
          ],
        },
      },
    }),
    [presModel, enhancedForm],
  );

  return (
    <FormProvider {...methods}>
      <PillowForm methods={methods} {...enhancedProps} />
    </FormProvider>
  );
};

Controller.displayName = 'OffersSelect.Controller';
export default Controller;
