import { isEmpty } from 'lodash';
import React, { Fragment, useContext } from 'react';
import Ink from 'react-ink';

import { theme } from '../../../common/theme';
import { DynamicBrandingContext } from '../../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import { generateDOMKey } from '../../../utils/generateDOMKey/generateDOMKey';
import { Loader } from '../Loader/Loader';
import { ButtonIconPosition } from './buttonIconPosition';
import { ButtonType } from './buttonType';
import {
  Container,
  getIconColor,
  getIconSize,
  IconWrapper,
  LoadingOverlay,
  TextContainer,
  TimerWrapper,
} from './styles';

type HtmlButtonType = 'submit' | 'reset' | 'button';

export interface ButtonProps {
  children: string;
  icon?: HTMLElement;
  iconPosition?: string;
  isDisabled?: boolean;
  // Passed in the Button Group so we can define a necessary width value for mobile view only in that specific scenario
  isGrouped?: boolean;
  isLarge?: boolean;
  isLoading?: boolean;
  onClick?: (event?: any) => void;
  type?: HtmlButtonType;
  stylingType?: string;
  testId?: string;
  isFullWidth?: boolean;
  timerDuration?: number;
  isMobile?: boolean;
  order?: number;
  customTextColor?: string;
  name?: string;
  autoFocus?: boolean;
}

const calculateIconSizeAndColorProps = ({
  isDisabled,
  isLoading,
  isLarge,
  type,
  $theme,
}: any) => ({
  size: getIconSize({ isLarge }),
  color: getIconColor({ type, isDisabled, isLoading, $theme }),
});

const getIconScaledAndColored = ({
  icon,
  isDisabled,
  isLoading,
  isLarge,
  type,
  $theme,
}: any) => {
  const { size, color } = calculateIconSizeAndColorProps({
    isDisabled,
    isLoading,
    isLarge,
    type,
    $theme,
  });
  return React.cloneElement(icon, {
    width: size,
    height: size,
    color: isLoading ? theme.common.colors.transparent : color,
  });
};

const generateIconKey = generateDOMKey('icon');

export const ButtonComponent = ({
  children = '\u00a0',
  icon,
  iconPosition = ButtonIconPosition.LEFT,
  isDisabled = false,
  isLarge = false,
  isMobile = false,
  isLoading = false,
  onClick,
  type = 'button' as HtmlButtonType,
  stylingType = ButtonType.PRIMARY,
  isGrouped = false,
  testId,
  timerDuration,
  isFullWidth,
  customTextColor,
  autoFocus,
}: ButtonProps) => {
  const {
    branding: { theme: $theme },
  } = useContext(DynamicBrandingContext);
  const iconKey: any = generateIconKey(children);
  const shouldDisplayIcon = !!icon;
  const iconComponent = !shouldDisplayIcon ? null : (
    <IconWrapper
      $iconPosition={iconPosition}
      $textExists={!isEmpty(children)}
      key={{ iconKey }}
    >
      {getIconScaledAndColored({
        icon,
        isLoading,
        isDisabled,
        isLarge,
        type: stylingType,
        $theme,
      })}
    </IconWrapper>
  );
  const textComponent = (
    <TextContainer $isLoading={isLoading} $isMobile={isMobile}>
      {children}
    </TextContainer>
  );

  const iconAndTextComponents =
    iconPosition === ButtonIconPosition.LEFT
      ? [
          <Fragment key="iconComponent">{iconComponent}</Fragment>,
          <Fragment key="textComponent">{textComponent}</Fragment>,
          timerDuration && (
            <TimerWrapper
              $btnPosition={ButtonIconPosition.LEFT}
              key="timerComponent"
            >{`${timerDuration}s`}</TimerWrapper>
          ),
        ]
      : [
          timerDuration && (
            <TimerWrapper key="timerComponent">{timerDuration}</TimerWrapper>
          ),
          <Fragment key="textComponent">{textComponent}</Fragment>,
          <Fragment key="iconComponent">{iconComponent}</Fragment>,
        ];

  const loadingOverlay = !isLoading ? null : (
    <LoadingOverlay key={iconKey}>
      <Loader
        {...calculateIconSizeAndColorProps({
          isDisabled,
          isLoading,
          isLarge,
          type: stylingType,
          $theme,
        })}
      />
    </LoadingOverlay>
  );

  return (
    <Container
      $customTextColor={customTextColor}
      $isDisabled={isDisabled}
      $isFullWidth={isFullWidth}
      $isGrouped={isGrouped}
      $isLarge={isLarge}
      $isMobile={isMobile}
      $type={stylingType}
      autoFocus={autoFocus}
      data-testid={testId}
      type={type}
      onClick={onClick}
      {...(isDisabled || isLoading ? { disabled: true } : {})}
    >
      {!isDisabled && !isLoading ? <Ink /> : null}
      {loadingOverlay}
      {iconAndTextComponents}
    </Container>
  );
};

export const Button = withMatchMedia(ButtonComponent);
