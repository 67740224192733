import { merge } from 'lodash';

import { componentTypes } from '../../../../../constants/componentTypes';
import { isPurchaseApplication } from '../../../../../utils/applicationType';
import { OffersContext } from '../../../../common/interfaces/offers.interface';
import BaseModel from '../common/BaseModel';

const model = (send: any, { context }: { context: OffersContext }) =>
  merge({}, BaseModel, {
    template: {
      header: {
        showClose: false,
        showMenu: false,
      },
    },
    headerBlock: {
      title: `Pulling up your ${
        isPurchaseApplication(context.currentVehicle) ? 'purchase' : 'refinance'
      } options...`,
      subtitle: ['Offers', '25% complete'],
      progressBar: {
        progress: 25,
        withContainer: true,
      },
    },
    form: {
      fields: {
        loading: {
          name: 'loading',
          isLoading: true,
          component: componentTypes.SINGLE_SELECT,
          loadingContentOptions: {
            isWithImage: true,
          },
          imageOnTopForMobile: true,
        },
      },
      actions: {
        primary: {
          isHidden: true,
        },
      },
    },
  });

export default model;
