import { get } from 'lodash';
import { Fragment, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { config } from '../../config';
import { ApplicationTaskEnum } from '../../constants/applicationTasks';
import { LoanTaskEnum } from '../../constants/loanTasks';
import { useCurrentUserContext } from '../../hooks/useCurrentUserContext/useCurrentUserContext';
import { useModal } from '../../hooks/useModal/useModal';
import { useWorkflowPathname } from '../../hooks/useWorkflowPathname/useWorkflowPathname';
import { ApplicationDataInterface } from '../../pages/Applications/applicationData.interface';
import { TASKS_CONFIG } from '../../services/task/tasksConfig';
import {
  getPrimaryButtonInitialConfig,
  getSecondaryButtonInitialConfig,
} from '../../services/task/taskService';
import { mapModalContent } from '../../utils/component/modal';
import { SelectedOfferContact } from '../SelectedOfferContact/SelectedOfferContact';
import { ActionCard } from '../shared/actionCard/ActionCard';
import { defaultModalButtons, Modal } from '../shared/Modal/Modal';
import { CancelledApplicationUrlFactory } from './CancelledApplicationUrlFactory';
import { CancelledApplicationUrlMapper } from './CancelledApplicationUrlMapper';

export const TaskCard = ({
  task,
  state,
}: {
  task: LoanTaskEnum | ApplicationTaskEnum;
  state: ApplicationDataInterface;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const workflowName = useWorkflowPathname();
  const [currentUser] = useCurrentUserContext();
  const { isShowing, open } = useModal();
  const {
    primaryButton,
    secondaryButton,
    modalContent: modalContentConfig,
    ...taskCardConfig
  } = TASKS_CONFIG[task];
  const modalContent = useMemo(
    () =>
      typeof modalContentConfig === 'function'
        ? modalContentConfig(workflowName, state)
        : modalContentConfig,
    [workflowName, state, modalContentConfig],
  );
  const modalButtons = defaultModalButtons();
  const modalContentTitle = get(modalContent, 'title', '');
  const modalContentBody = mapModalContent(get(modalContent, 'body', []));
  const taskButtons = useMemo(() => {
    return {
      secondaryButton: getSecondaryButtonInitialConfig(
        open,
        secondaryButton.name,
        secondaryButton.children,
      ),
      primaryButton: getPrimaryButtonInitialConfig(
        () => {
          if (primaryButton.fastTrackRedirect) {
            const mapper = new CancelledApplicationUrlMapper();
            const hydratedModel = mapper.toDomain(state, currentUser);

            const redirect =
              primaryButton.fastTrackRedirect[state.branding.brandName];

            const url = CancelledApplicationUrlFactory.create(
              redirect ? redirect : config.fastTrackUrl,
              hydratedModel,
            );

            window.location.replace(url);
          }
          if (!primaryButton.url) {
            return;
          }
          navigate(primaryButton.url[workflowName], {
            state: {
              ...state,
              currentUser,
              navigationSource: location.pathname,
            },
          });
        },
        primaryButton.name,
        primaryButton.label,
        primaryButton.hidden,
      ),
    };
  }, [
    currentUser,
    location.pathname,
    navigate,
    open,
    primaryButton,
    secondaryButton.children,
    secondaryButton.name,
    state,
    workflowName,
  ]);

  return (
    <Fragment>
      <ActionCard {...taskCardConfig} buttonGroup={taskButtons} />
      {isShowing ? (
        <Fragment>
          {task === LoanTaskEnum.Invalid ? (
            <SelectedOfferContact
              applicationBranding={state.branding}
              autopayNumber={get(
                state,
                'currentVehicle.checklightSession.autopayNumber',
              )}
              checklightSession={get(state, 'currentVehicle.checklightSession')}
              vehicleId={get(state, 'currentVehicle.id')}
            />
          ) : (
            <Modal
              buttonGroupPresModel={modalButtons}
              testId="infoPopup"
              title={modalContentTitle}
            >
              {modalContentBody}
            </Modal>
          )}
        </Fragment>
      ) : null}
    </Fragment>
  );
};
