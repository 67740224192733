import { FooterLink } from '../../components/PillowForm';
import { PillowType } from '../../components/shared/PillowImage/pillowTypes';
import { fields } from '../../forms/user';

const model = (rootPath: string) => {
  const isChecklight = rootPath === 'checklight';

  return {
    template: {
      header: {
        showBack: false,
      },
      footer: {
        isActive: false,
        links: [FooterLink.ContactUs],
      },
      img: {
        type: PillowType.ACCOUNT,
      },
      centerContent: true,
      persistImage: true,
    },
    headerBlock: {
      title: `Let's sign you in to your ${
        isChecklight ? 'Checklight ' : ''
      }account.`,
      subtitle: 'Sign in',
      paragraph: '',
    },
    form: {
      actions: {
        primary: {
          testId: 'signIn',
          label: 'Sign in',
          preserveLabel: true,
          preserveTestId: true,
        },
        secondary: {
          label: 'Sign up',
          testId: 'signUp',
          preserveLabel: true,
          preserveTestId: true,
        },
      },
      fields: {
        primaryPhoneNumber: {
          testId: 'primaryPhoneNumber',
          hint: 'We will send you a 6-digit verification code',
          ...fields.primaryPhoneNumber,
        },
      },
    },
  };
};

export default model;
