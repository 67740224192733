import { devtools } from 'zustand/middleware';

import useApiStore from './apiStore';
import { create, storeDevToolOptions } from './storeManager';

type LeadChannelState = {
  branding: Record<string, any>;
  initBranding: (
    leadChannelCode: string | null | undefined,
  ) => Promise<void> | undefined;
  initBrandingFinished: boolean;
};

const useBrandingStore = create()(
  devtools<LeadChannelState>(
    (set) => ({
      branding: {},

      initBranding: (leadChannelCode: string | null | undefined) => {
        if (!leadChannelCode) {
          set({ initBrandingFinished: true });
          return;
        }

        const radixHttpRequest = useApiStore.getState().radixApi();

        return radixHttpRequest
          .get(`/ui-configuration/branding?leadChannelCode=${leadChannelCode}`)
          .then((branding) => {
            set({ branding });
          })
          .finally(() => {
            set({ initBrandingFinished: true });
          });
      },
      initBrandingFinished: false,
    }),
    storeDevToolOptions('brandingStore'),
  ),
);

export default useBrandingStore;
