import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import { Housing } from './Housing';
import { Income } from './Income';
import { PersonalInformation } from './PersonalInformation';

const AccountView = () => {
  const routes: RouteObject[] = [
    {
      index: true,
      element: <Navigate to="Personal" replace />,
    },
    {
      path: 'Personal/*',
      element: <PersonalInformation />,
    },
    {
      path: 'Housing/*',
      element: <Housing />,
    },
    {
      path: 'Income/*',
      element: <Income />,
    },
    {
      path: '*',
      element: <Navigate to="" replace />,
    },
  ];

  return useRoutes(routes);
};

export default AccountView;
