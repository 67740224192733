import { font, unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const Container = styled('div', ({ $theme }: any) => ({
  width: '100%',
  fontSize: unit[2],
  color: $theme.colors.infoOverlay,
}));

export const Blocks = styled('div', ({ $isBorderWrapped, $theme }: any) => ({
  borderTop: `1px solid ${$theme.colors.secondaryOverlay}`,
  borderBottom: `1px solid ${$theme.colors.secondaryOverlay}`,
  ...($isBorderWrapped
    ? {
        borderRadius: unit[2],
        border: `1px solid ${$theme.colors.secondaryOverlay}`,
      }
    : {}),
  overflow: 'hidden',
}));

export const Block = styled(
  'div',
  ({ $isHeader, $editMode, $hasSidePadding, $style, $theme }: any) => ({
    position: 'relative',
    padding: $editMode
      ? `${unit[6]} ${$hasSidePadding ? unit[3] : '0'} ${unit[3]}`
      : `${unit[6]} ${$hasSidePadding ? unit[3] : '0'}`,
    borderBottom: `1px solid ${$theme.colors.secondaryOverlay}`,
    display: 'flex',
    ':last-of-type': {
      border: 0,
    },
    ...($isHeader
      ? {
          alignItems: 'center',
          backgroundColor: $theme.colors.tertiaryOverlay,
          flexWrap: 'wrap',
        }
      : {}),
    ...($style || {}),
  }),
);

export const BlockTitle = styled(
  'span',
  ({ $editable, $isDisabled, $isMobile, $theme }: any) => ({
    marginRight: unit[2],
    fontFamily: font.family.Merriweather,
    ...($editable && !$isMobile ? { width: '330px' } : {}),
    ...($isDisabled ? { color: $theme.colors.primaryActive } : {}),
  }),
);

const blockInfoCommon = (
  $isMobile: any,
  $isTablet: any,
  $editable: any,
  $isDisabled: any,
  $theme: any,
) => ({
  ...($editable ? { flex: 1 } : {}),
  fontSize: font.size.subTitle,
  fontFamily: font.family.Montserrat,
  color:
    $editable && !$isDisabled
      ? $theme.colors.tertiary
      : $theme.colors.primaryActive,
  ...($isMobile || ($isTablet && $editable)
    ? {
        marginTop: unit[1],
      }
    : {}),
});

export const BlockInfoLink = styled(
  'a',
  ({ $isMobile, $isTablet, $editable, $isDisabled, $theme }: any) => ({
    ...blockInfoCommon($isMobile, $isTablet, $editable, $isDisabled, $theme),
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    },
  }),
);

export const BlockInfoText = styled(
  'div',
  ({ $isMobile, $isTablet, $editable, $isDisabled, $theme }: any) => ({
    ...blockInfoCommon($isMobile, $isTablet, $editable, $isDisabled, $theme),
    whiteSpace: 'pre-wrap',
  }),
);

export const BlockInfo = styled(
  'div',
  ({ $isMobile, $isTablet, $editable, $editMode, $isHeader }: any) => ({
    display: 'flex',
    flexGrow: 1,
    ...($isMobile || ($isTablet && $editable) || $editMode
      ? {
          flexDirection: 'column',
        }
      : {}),
    ...($editable
      ? {
          marginRight: unit[2],
        }
      : {
          justifyContent: 'space-between',
        }),
    ...($isHeader && $isMobile
      ? {
          marginBottom: unit[3],
        }
      : {}),
  }),
);

export const PanelLink = styled(
  'div',
  ({ $isDisabled, $editMode, $theme }: any) => ({
    ...($editMode
      ? {
          position: 'absolute',
          right: unit[3],
          top: unit[6],
        }
      : {}),
    fontWeight: 500,
    fontSize: font.size.headingSmall,
    color: $isDisabled ? $theme.colors.primaryActive : $theme.colors.primary,
    cursor: 'pointer',
    ...($isDisabled
      ? { pointerEvents: 'none', textDecoration: 'none' }
      : { pointerEvents: 'auto', textDecoration: 'underline' }),
  }),
);

export const FormContainer = styled('div', ({ $isDesktop }: any) => ({
  margin: [unit[3], 0].join(' '),
  ...($isDesktop ? { maxWidth: '672px' } : {}),
}));
