import { get, merge } from 'lodash';
import { assign, MachineConfig } from 'xstate';

import { isOlderThan } from '../../../../utils/pipes/date';
import { ApplicantContext } from '../../../common/interfaces/applicant.interface';
import { incomeReviewSignals } from '../../../common/Steps/ApplicantIncome/utils';
import { applicantReviewSignals } from '../../../common/Steps/ApplicantReview/utils';
import { isRequiredPreviousIncome } from '../../../common/utils/applicant.utils';
import { Step } from '../steps';

export const Signal = {
  ...incomeReviewSignals,
  ...applicantReviewSignals,
  Next: 'Next',
  Previous: 'Previous',
};

export const workflowName = 'unified-default-applicant';
export const defaultConfiguration: MachineConfig<ApplicantContext, any, any> = {
  id: workflowName,
  initial: Step.PersonalDetails,
  states: {
    [Step.PersonalDetails]: {
      entry: assign((context: any) => {
        if (context.isMapped) {
          return context;
        }
        return {
          ...context,
          isMapped: true,
          updatedUser: merge({}, context.currentUser),
        };
      }),
      on: {
        [Signal.Next]: {
          target: Step.HousingDetails,
          actions: 'patchUser',
        },
      },
    },
    [Step.HousingDetails]: {
      on: {
        [Signal.Next]: [
          {
            target: Step.PreviousAddress,
            actions: 'patchUser',
            cond: (_, { data }: any) => {
              return !isOlderThan(
                get(data, 'residences.current.activeStartDate'),
                24,
              );
            },
          },
          {
            target: Step.PrimaryIncome,
            actions: [
              'patchUser',
              assign((context) => ({
                ...context,
                updatedUser: {
                  ...context.updatedUser,
                  residences: {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    ...context.updatedUser!.residences!,
                    previous: undefined,
                  },
                },
              })),
            ],
          },
        ],
        [Signal.Previous]: Step.PersonalDetails,
      },
    },
    [Step.PreviousAddress]: {
      on: {
        [Signal.Previous]: Step.HousingDetails,
        [Signal.Next]: {
          target: Step.PrimaryIncome,
          actions: 'patchUser',
        },
      },
    },
    [Step.PrimaryIncome]: {
      on: {
        [Signal.Previous]: [
          {
            target: Step.PreviousAddress,
            cond: (newWorkFlowDetails: any) =>
              !isOlderThan(
                get(
                  newWorkFlowDetails,
                  'updatedUser.residences.current.activeStartDate',
                ),
                24,
              ),
          },
          { target: Step.HousingDetails },
        ],
        [Signal.Next]: [
          {
            target: Step.PrimaryPreviousIncome,
            actions: 'patchUser',
            cond: (_, { data: { incomes } }) =>
              isRequiredPreviousIncome(incomes.current),
          },
          {
            target: Step.ReviewIncome,
            actions: [
              'patchUser',
              assign((context) => ({
                ...context,
                updatedUser: {
                  ...context.updatedUser,
                  incomes: {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    ...context.updatedUser!.incomes!,
                    previous: undefined,
                  },
                },
              })),
            ],
          },
        ],
      },
    },
    [Step.PrimaryPreviousIncome]: {
      on: {
        [Signal.Previous]: Step.PrimaryIncome,
        [Signal.Next]: {
          target: Step.ReviewIncome,
          actions: 'patchUser',
        },
      },
    },
    [Step.ReviewIncome]: {
      on: {
        [Signal.EditIncomePrimary]: {
          target: Step.EditPrimaryIncome,
          actions: assign({
            editIncome: (context, { editIncome }: any) => editIncome,
          }),
        },
        [Signal.AddIncome]: Step.AddAdditionalIncome,
        [Signal.EditIncomeAdditional]: {
          target: Step.EditAdditionalIncome,
          actions: assign({
            editIncome: (context, { editIncome }: any) => editIncome,
          }),
        },
        [Signal.Previous]: [
          {
            target: Step.PrimaryPreviousIncome,
            cond: (context) =>
              isRequiredPreviousIncome(
                get(context, 'updatedUser.incomes.current' as any),
              ),
          },
          {
            target: Step.PrimaryIncome,
          },
        ],
        [Signal.Next]: Step.ReviewApplicant,
      },
    },
    [Step.EditPrimaryIncome]: {
      on: {
        [Signal.Next]: {
          target: Step.ReviewIncome,
          actions: assign((context, { data }) => ({
            ...context,
            updatedUser: {
              ...context.updatedUser,
              incomes: {
                ...data,
                additional: context.updatedUser?.incomes?.additional,
              },
            },
          })),
        },
        [Signal.Previous]: Step.ReviewIncome,
      },
    },
    [Step.AddAdditionalIncome]: {
      on: {
        [Signal.Next]: {
          target: Step.ReviewIncome,
          actions: 'updateUser',
        },
        [Signal.Previous]: Step.ReviewIncome,
      },
    },
    [Step.EditAdditionalIncome]: {
      on: {
        [Signal.Next]: {
          target: Step.ReviewIncome,
          actions: 'updateUser',
        },
        [Signal.Previous]: Step.ReviewIncome,
      },
    },
    [Step.ReviewApplicant]: {
      on: {
        [Signal.ReviewApplicant]: Step.ReviewPersonalDetails,
        [Signal.ReviewHousing]: Step.ReviewHousingDetails,
        [Signal.ReviewIncome]: Step.FinalIncomeReview,
        [Signal.Previous]: Step.ReviewIncome,
        [Signal.Next]: [
          {
            target: Step.RedirectToCoApplicant,
            cond: (ctx) => !!ctx.currentVehicle?.coApplicant.coApplicantAnswer,
            actions: [
              assign((context, event: any) => {
                return {
                  ...context,
                  ...event.data,
                };
              }),
              'setApplicantComplete',
            ],
          },
          {
            target: Step.Done,
            // if applicant has already provided SSN, skip review gate
            cond: (ctx) => get(ctx, 'currentUser.personalInfo.hasSSN') === true,
            actions: 'setReviewComplete',
          },
          {
            target: Step.RedirectToReview,
            actions: 'setCoApplicantComplete',
          },
        ],
      },
    },
    [Step.ReviewPersonalDetails]: {
      on: {
        [Signal.Next]: {
          target: Step.ReviewApplicant,
          actions: ['updateUser'],
        },
        [Signal.Previous]: Step.ReviewApplicant,
      },
    },
    [Step.ReviewHousingDetails]: {
      on: {
        [Signal.Next]: {
          target: Step.ReviewApplicant,
          actions: assign((context, { data }) => ({
            ...context,
            updatedUser: {
              ...context.updatedUser,
              ...data,
            },
          })),
        },
        [Signal.Previous]: Step.ReviewApplicant,
      },
    },
    [Step.FinalIncomeReview]: {
      on: {
        [Signal.Next]: Step.ReviewApplicant,
        [Signal.EditIncomePrimary]: {
          target: Step.FinalIncomeReviewPrimaryEdit,
          actions: assign({
            editIncome: (context, { editIncome }: any) => editIncome,
          }),
        },
        [Signal.AddIncome]: Step.FinalIncomeReviewAdditionalAdd,
        [Signal.EditIncomeAdditional]: {
          target: Step.FinalIncomeReviewAdditionalEdit,
          actions: assign({
            editIncome: (context, { editIncome }: any) => editIncome,
          }),
        },
        [Signal.Previous]: Step.ReviewApplicant,
      },
    },
    [Step.FinalIncomeReviewPrimaryEdit]: {
      on: {
        [Signal.Next]: {
          target: Step.FinalIncomeReview,
          actions: assign((context, { data }) => ({
            ...context,
            updatedUser: {
              ...context.updatedUser,
              incomes: {
                ...data,
                additional: context.updatedUser?.incomes?.additional,
              },
            },
          })),
        },
        [Signal.Previous]: Step.FinalIncomeReview,
      },
    },
    [Step.FinalIncomeReviewAdditionalAdd]: {
      on: {
        [Signal.Next]: {
          target: Step.FinalIncomeReview,
          actions: 'updateUser',
        },
        [Signal.Previous]: Step.FinalIncomeReview,
      },
    },
    [Step.FinalIncomeReviewAdditionalEdit]: {
      on: {
        [Signal.Next]: {
          target: Step.FinalIncomeReview,
          actions: 'updateUser',
        },
        [Signal.Previous]: Step.FinalIncomeReview,
      },
    },
    [Step.RedirectToCoApplicant]: {
      type: 'final',
    },
    [Step.RedirectToReview]: {
      type: 'final',
    },
    [Step.Done]: {
      type: 'final',
    },
  },
};

// NOTE: You can paste the content of this file into @see https://xstate.js.org/viz
// and uncomment this line to test the logic manually
// Machine({ ...defaultConfiguration }, { ...defaultServiceOptions });

export default defaultConfiguration;
