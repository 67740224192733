import { FooterLink } from '../../../../../components/PillowForm';
import { PillowType } from '../../../../../components/shared/PillowImage/pillowTypes';

const model = (send: any) => ({
  template: {
    header: {
      showClose: true,
      showBack: false,
      onClose: () => {
        send('ExitToDashboard');
      },
    },
    footer: {
      isActive: false,
      links: [FooterLink.ContactUs],
    },
    img: {
      type: PillowType.NO_OFFERS,
    },
    centerContent: true,
    persistImage: true,
  },
  form: {
    actions: {
      primary: {
        testId: 'continue',
        label: 'Continue',
      },
      secondary: {
        name: 'dashboard',
        label: 'Dashboard',
        testId: 'dashboard',
        handler: () => {
          send('ExitToDashboard');
        },
      },
    },
  },
  headerBlock: {
    title:
      'Unable to qualify you for financing...\nWe may still be able to help!',
    subtitle: ['No offers'],
  },
});

export default model;
