import { theme } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';
import { ButtonIconPosition } from './buttonIconPosition';
import { ButtonType } from './buttonType';

const transition = {
  transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;',
};

const common = {
  ...theme.fonts.button,
  height: theme.unit[7],
};

const getButtonStyles = ({ $type, $theme }: any) => {
  switch ($type) {
    case ButtonType.PRIMARY:
      return primaryStyles($theme);
    case ButtonType.SECONDARY:
      return secondaryStyles($theme);
    case ButtonType.GHOST:
      return ghostStyles($theme);
    default:
      throw new Error(`getButtonStyles: unsupported $type: ${$type}`);
  }
};

const primaryStyles = ($theme: any) => ({
  ...common,
  backgroundColor: $theme.colors.primary,
  color: $theme.colors.secondary,
  ':hover:not([disabled])': {
    background: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)) ${$theme.colors.primary}`,
  },
  ':active:not([disabled])': {
    background: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)) ${$theme.colors.primary}`,
  },
  ':disabled': {
    color: $theme.colors.primaryActive,
    backgroundColor: $theme.colors.disabled,
  },
});

const ghostStyles = ($theme: any) => ({
  ...common,
  backgroundColor: $theme.colors.secondary,
  border: `1px solid ${$theme.colors.secondaryOverlay}`,
  color: $theme.colors.infoOverlay,
  ':hover:not([disabled])': {
    backgroundColor: $theme.colors.tertiaryOverlay,
  },
  ':active:not([disabled])': {
    backgroundColor: $theme.colors.secondaryActive,
  },
  ':disabled': {
    color: $theme.colors.primaryActive,
    backgroundColor: $theme.colors.disabled,
  },
});

const secondaryStyles = ($theme: any) => ({
  ...ghostStyles($theme),
  border: 'none',
  color: $theme.colors.primaryActive,
});

export const getIconColor = ({ type, isDisabled, isLoading, $theme }: any) => {
  if (isDisabled || isLoading) {
    return $theme.colors.primaryActive;
  }
  switch (type) {
    case ButtonType.SECONDARY:
      return $theme.colors.secondaryOverlay;
    case ButtonType.GHOST:
      return $theme.colors.primary;
    case ButtonType.PRIMARY:
    default:
      return $theme.colors.primaryOverlay;
  }
};

export const getIconSize = ({ isLarge }: any) =>
  isLarge ? theme.unit[3] : theme.unit[2];

export const TextContainer = styled(
  'div',
  ({ $isMobile, $isLoading, customTextColor, $theme }: any) => ({
    color: $isLoading
      ? customTextColor || $theme.colors.transparency.fullyTransparent
      : '',
    whiteSpace: 'nowrap',
    overflow: $isMobile ? 'hidden' : '',
    textOverflow: $isMobile ? 'ellipsis' : '',
    maxWidth: $isMobile ? '100%' : '',
  }),
);

export const Container = styled(
  'button',
  ({
    $type,
    $isLarge,
    $isMobile,
    $isGrouped,
    $isFullWidth,
    $customTextColor,
    $isDisabled,
    $theme,
  }: any) =>
    ({
      minWidth: $isMobile && $isGrouped ? '' : '9.75rem', // 156px
      width: ($isMobile && $isGrouped) || $isFullWidth ? '100%' : '',
      position: 'relative', // required for react-ink
      border: 'none',
      borderRadius: '0.25rem', // 4px
      padding: `${theme.unit[1]} ${theme.unit[3]}`, // 8px 24px
      cursor: $isDisabled ? 'not-allowed' : 'pointer',
      ':focus': {
        // TODO: CP-2396 change color if needed
        outline: `2px solid ${$theme.colors.tertiaryOverlay}`,
      },
      alignItems: 'center',
      ...theme.common.helpers.flexHorizontalContainer,
      justifyContent: 'center',
      ...transition,
      ...theme.fonts.paragraph,
      ...($isLarge
        ? {
            fontSize: theme.common.font.size.headingLarge,
            padding: `${theme.unit[2]} ${theme.unit[3]}`, // 16px 24px
          }
        : {}),
      ...getButtonStyles({ $type, $theme }),
      ...($customTextColor ? { color: $customTextColor } : {}),
    } as any),
);

export const IconWrapper = styled(
  'div',
  ({ $iconPosition, $textExists }: any) =>
    ({
      ...theme.common.helpers.flexVerticalCenter,
      ...($textExists
        ? $iconPosition === ButtonIconPosition.LEFT
          ? { marginRight: theme.unit[1] }
          : { marginLeft: theme.unit[1] }
        : {
            height: theme.common.font.lineHeight.paragraph,
          }),
    } as any),
);

export const LoadingOverlay = styled(
  'div',
  ({ $theme }: any) =>
    ({
      ...theme.common.helpers.flexVerticalCenter,
      cursor: 'default',
      position: 'absolute',
      height: '100%',
      margin: 'auto',
      left: '0',
      borderRadius: '0.25rem', // 4px,
      width: '100%',
      backgroundColor: $theme.colors.secondaryOverlay,
    } as any),
);

export const TimerWrapper = styled('span', ($btnPosition: any) => ({
  ...($btnPosition === ButtonIconPosition.LEFT
    ? { marginRight: theme.unit[1] }
    : { marginLeft: theme.unit[1] }),
}));
