import { merge } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { PillowForm } from '../../../../../components/PillowForm';
import { UserAgreement } from '../../../../../components/shared/UserAgreement/UserAgreement';
import { Signal } from '../../../../common/constants/signals';

const Controller = ({ presModel, send, context }: any) => {
  const { coApplicant } = context;
  const { agreesToTerms: cachedAgreementChecked } =
    coApplicant.termsAndConditionsOptIn || {};
  const [agreementChecked, setAgreementChecked] = useState(
    cachedAgreementChecked,
  );
  const [isSubmittedWithoutAgreement, setIsSubmittedWithoutAgreement] =
    useState(false);

  const methods = useForm();

  const {
    formState: { isSubmitted },
  } = methods;

  useEffect(() => {
    if (isSubmitted) {
      setIsSubmittedWithoutAgreement(!agreementChecked);
    }
  }, [isSubmitted, agreementChecked, setIsSubmittedWithoutAgreement]);

  const onSubmit = useCallback(() => {
    if (!agreementChecked) {
      return;
    }
    send(Signal.Next, {
      data: {
        coApplicant: merge({}, coApplicant, {
          termsAndConditionsOptIn: {
            agreesToTerms: agreementChecked,
          },
        }),
      },
    });
  }, [agreementChecked, send, coApplicant]);

  const enhancedModel = useMemo(
    () =>
      merge({}, presModel, {
        form: {
          actions: {
            primary: {
              handler: onSubmit,
            },
          },
        },
      }),
    [onSubmit, presModel],
  );

  return (
    <PillowForm methods={methods} presModel={enhancedModel}>
      <UserAgreement
        agreementChecked={agreementChecked}
        isSubmittedWithoutAgreement={isSubmittedWithoutAgreement}
        setAgreementChecked={setAgreementChecked}
      />
    </PillowForm>
  );
};

export default Controller;
