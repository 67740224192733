import { Navigate } from 'react-router-dom';

import { useWorkflowPathname } from '../../../../../hooks/useWorkflowPathname/useWorkflowPathname';

const ExitToNoOffers = ({ context }: any) => {
  const rootPath = useWorkflowPathname();

  return <Navigate state={context} to={`/${rootPath}/workflow/no-offers`} />;
};

export default ExitToNoOffers;
