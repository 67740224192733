import { IncomeFrequencyEnum } from '../enums/IncomeFrequencyEnum';

export const incomeFrequencyFromAPI = (
  incomeFrequency?: string,
): IncomeFrequencyEnum | null => {
  switch (incomeFrequency) {
    case 'YEARLY':
      return IncomeFrequencyEnum.ANNUALLY;
    case 'MONTHLY':
      return IncomeFrequencyEnum.MONTHLY;
    case 'TWICE_MONTHLY':
      return IncomeFrequencyEnum.SEMI_MONTHLY;
    case 'BI_WEEKLY':
      return IncomeFrequencyEnum.BI_WEEKLY;
    case 'WEEKLY':
      return IncomeFrequencyEnum.WEEKLY;
    default:
      return null;
  }
};
