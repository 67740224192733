import { merge, omit, pick } from 'lodash';

import {
  addressFields,
  getAddressFieldsPaths,
} from '../../common/housing-info';
import { componentTypes } from '../../constants/componentTypes';
import { optionsList as ownershipOptionsList } from '../../forms/housingTypeOptions';
import { startDateRegex } from '../../forms/income';
import { fields } from '../../forms/user';
import { isOlderThan } from '../pipes/date';

export const commonFields = (primary: boolean) => {
  return merge(
    {},
    primary
      ? {
          ownershipStatus: {
            ...fields.ownershipStatus,
            name: 'residences.current.ownershipStatus',
            label: 'Housing type',
            component: componentTypes.DROPDOWN,
            options: merge([], ownershipOptionsList, [
              { label: 'Own' },
              { label: 'Rent from landlord' },
              { label: 'Other' },
            ]),
          },
        }
      : {},
    pick(fields, addressFields),
    addressFields.reduce(
      (acc, field, index) => ({
        ...acc,
        [field]: {
          name: getAddressFieldsPaths(primary)[index],
        },
      }),
      {},
    ),
    primary
      ? {
          monthlyPaymentAmount: {
            ...fields.monthlyPaymentAmount,
            name: 'residences.current.monthlyPaymentAmount',
          },
        }
      : {},
    {
      activeStartDate: {
        ...fields.activeStartDate,
        name: primary
          ? 'residences.current.activeStartDate'
          : 'residences.previous.activeStartDate',
      },
    },
  );
};

export const getFormattedAddressInfo = (housingInfo: any) => {
  const {
    residences: { current, previous },
  } = housingInfo;

  const filteredAddress = {
    ...omit(current.physicalAddress, 'id'),
    ...pick(current, [
      'activeStartDate',
      'ownershipStatus',
      'monthlyPaymentAmount',
    ]),
  };

  const filteredPreviousAddress = {
    ...omit(previous?.physicalAddress, 'id'),
    ...pick(previous, ['activeStartDate']),
  };

  // This mapping is needed in order to successfully compare form values when using form.getValues() method
  const mappedAddress = (address: any) =>
    Object.keys(address).reduce(
      (acc, key) => ({ ...acc, [key]: address[key] || '' }),
      {},
    );

  return [
    mappedAddress(filteredAddress),
    mappedAddress(filteredPreviousAddress),
  ];
};

export const displayPrevious = (primaryStartDate: any) => {
  if (!primaryStartDate) {
    return false;
  }
  const isFutureDate =
    new Date().valueOf() < new Date(primaryStartDate).valueOf();
  if (
    !primaryStartDate ||
    isFutureDate ||
    !startDateRegex.test(primaryStartDate)
  ) {
    return false;
  }
  return !isOlderThan(primaryStartDate, 24);
};
