export enum ApplicationUpdateMessage {
  WELCOME_BACK = 'WELCOME_BACK',
  HAS_APPROVAL = 'HAS_APPROVAL',
  TERMS_UPDATED = 'TERMS_UPDATED',
  EDOCS_SIGNED = 'EDOCS_SIGNED',
  LENDER_FUNDED = 'LENDER_FUNDED',
}

export const applicationUpdateMessageHeaderContent = (
  applicationUpdateMessage: ApplicationUpdateMessage,
  leadChannelDisplayLabel: string | undefined,
) => {
  const defaultContent = {
    title: leadChannelDisplayLabel
      ? `Welcome back,\nfrom ${leadChannelDisplayLabel}!`
      : 'Welcome back!',
    paragraph: 'Login to view your application updates.',
  };

  const headerContent = {
    [ApplicationUpdateMessage.WELCOME_BACK]: {
      title: leadChannelDisplayLabel
        ? `Welcome back,\nfrom ${leadChannelDisplayLabel}!`
        : defaultContent.title,
      paragraph: 'Login to view your application updates.',
    },
    [ApplicationUpdateMessage.HAS_APPROVAL]: {
      title: leadChannelDisplayLabel
        ? `Welcome back,\nfrom ${leadChannelDisplayLabel}!`
        : defaultContent.title,
      paragraph: 'Login to view your application updates.',
    },
    [ApplicationUpdateMessage.TERMS_UPDATED]: {
      title: leadChannelDisplayLabel
        ? `Welcome back,\nfrom ${leadChannelDisplayLabel}!`
        : defaultContent.title,
      paragraph:
        'The terms of your auto loan have been updated. Please log in to review.',
    },
    [ApplicationUpdateMessage.EDOCS_SIGNED]: {
      title: leadChannelDisplayLabel
        ? `Welcome back,\nfrom ${leadChannelDisplayLabel}!`
        : defaultContent.title,
      paragraph:
        'Your eDocuments are complete and currently being reviewed. Please log in to review.',
    },
    [ApplicationUpdateMessage.LENDER_FUNDED]: {
      title: leadChannelDisplayLabel
        ? `Welcome back,\nfrom ${leadChannelDisplayLabel}!`
        : defaultContent.title,
      paragraph:
        'Your loan application has been approved, and we are in the process of transferring the payoff balance. Please log in to review.',
    },
  };

  return headerContent[applicationUpdateMessage] || defaultContent;
};
