import { flow, get, isEmpty, isNull, set, uniqueId } from 'lodash';
import { useEffect, useMemo } from 'react';
import {
  Controller,
  FieldValues,
  useForm,
  UseFormReturn,
} from 'react-hook-form';

import { componentTypes } from '../../../constants/componentTypes';
import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import { DropdownControl } from '../dropdownControl/DropdownControl';
import { ErrorsBlock } from '../ErrorsBlock/ErrorsBlock';
import { FileUploadControl } from '../FileUploadControl/FileUploadControl';
import { FormButtons } from '../FormButtons/FormButtons';
import { HeaderBlock } from '../HeaderBlock/HeaderBlock';
import { ListControl } from '../listControl/ListControl';
import { TextControl } from '../TextControl/TextControl';
import { withDependencies } from '../TextControl/withDependencies';
import { withWarning } from '../TextControl/withWarning';
import { FormInterface } from './interfaces/form.interface';
import { FormContainer } from './styles';

const getFormValue = (fields: any) =>
  Object.values<any>(fields).reduce((acc: any, { value, name }) => {
    set(acc, name, value);
    return acc;
  }, {});

export interface FormProps {
  methods?: UseFormReturn<FieldValues, any>;
  form: FormInterface;
  children?: any;
}

const FormComponent = (props: FormProps) => {
  const { methods: contextForm } = props;
  const defaultForm = useForm();
  const {
    handleSubmit,
    control,
    setError,
    setValue,
    getValues,
    trigger,
    clearErrors,
  } = contextForm || defaultForm;

  const { form, children: afterFormContent } = props;
  const {
    fields = {},
    isStickyActions,
    actions: { primary: { handler: onSubmit = () => {} } = {} } = {},
    actionsContent,
    fieldErrors,
    globalErrors,
    autoFocus,
  } = form;

  useEffect(() => {
    // Trigger validation when form fields changes
    if (form.forceTrigger) {
      handleSubmit(() => {})();
    }
  }, [fields]);

  const hydratedFormFields = useMemo(() => {
    const initialValues = getFormValue(fields);
    return Object.values(fields)
      .map((field: any, i) => {
        if (!field.name) {
          throw new Error('FormControl property "name" is required');
        }
        const addDependencies = (component: any) => {
          const currentValues = getValues();
          const defaultValue = !isEmpty(currentValues)
            ? currentValues
            : initialValues;
          return field.dependsOn
            ? withDependencies({
                dependsOn: field.dependsOn,
                setValue,
                control,
                defaultValue,
              })(component)
            : component;
        };

        switch (true) {
          case componentTypes.TEXT === field.component: {
            const addWarnings = (component: any) => {
              return field.warningValidation
                ? withWarning({ field, setValue, trigger, clearErrors })(
                    component,
                  )
                : component;
            };

            const TextControlComponent = flow([addWarnings, addDependencies])(
              TextControl,
            );

            return (
              <TextControlComponent
                autoCompleteObj={field.autoCompleteObj}
                autoFocus={field.autoFocus || (autoFocus && i === 0)}
                control={control}
                controller={Controller}
                defaultValue={isNull(field.value) ? '' : field.value}
                disabled={field.disabled}
                format={field.format}
                formatOptions={field.formatOptions}
                hint={field.hint}
                isHidden={field.isHidden}
                key={field.name}
                label={field.label}
                name={field.name}
                readOnly={field.readOnly}
                shouldTrimValue={field.shouldTrimValue}
                shouldUnregister={field.shouldUnregister}
                testId={field.testId || field.name}
                type={field.type}
                validationRules={field.validationRules}
                // success={formGood}
              />
            );
          }
          case componentTypes.DROPDOWN === field.component: {
            const DropdownControlComponent = flow([addDependencies])(
              DropdownControl,
            );

            return (
              <DropdownControlComponent
                key={uniqueId('dropdown-')}
                {...field}
                control={control}
                controller={Controller}
                defaultValue={field.value || ''}
                isHidden={field.isHidden}
                testId={field.testId || field.name}
              />
            );
          }
          case [
            componentTypes.RADIO,
            componentTypes.SINGLE_SELECT,
            componentTypes.MULTI_SELECT,
            componentTypes.SUMMARY_LIST,
          ].includes(field.component):
            return (
              <ListControl
                key={uniqueId('list-')}
                {...{ ...field, onActionSubmit: handleSubmit(onSubmit) }}
                control={control}
                controller={Controller}
                isHidden={field.isHidden}
                testId={field.testId || field.name}
              />
            );
          case [
            componentTypes.UPLOAD,
            componentTypes.UPLOAD_REMOVEABLE,
          ].includes(field.component): {
            const { key, ...fieldProps } = field;

            return (
              <FileUploadControl
                key={key || i.toString()}
                {...fieldProps}
                control={control}
                controller={Controller}
                isHidden={field.isHidden}
                testId={field.testId || field.name}
                trigger={trigger}
              />
            );
          }
          case componentTypes.SUBTITLE === field.component: {
            const SubtitleComponent = flow([addDependencies])(HeaderBlock);

            return (
              <SubtitleComponent
                key={uniqueId('subtitle-')}
                paragraph={field.hint}
                title={field.label}
                isForm
                isSecondary
              />
            );
          }
          default:
            return null;
        }
      })
      .filter((e) => e);
  }, [
    fields,
    control,
    handleSubmit,
    onSubmit,
    setValue,
    clearErrors,
    trigger,
    getValues,
    autoFocus,
  ]);

  useEffect(() => {
    if (fieldErrors) {
      Object.entries(fieldErrors).forEach(([name, message]) => {
        if (!message) {
          return;
        }
        setError(name, {
          message: message as string,
          type: 'manual',
        });
      });
    }
    // eslint-disable-next-line
  }, [fieldErrors]);

  const formActions = useMemo(() => {
    // Setting focus to primary button when form has no fields
    // and autoFocus is not already set in the config
    // TODO: Review after CP-2394, CP-2397 are done
    // We can focus only TextField components, so focusing submit button
    // if first field is not text input
    if (
      autoFocus &&
      form.actions?.primary &&
      (!hydratedFormFields.length ||
        get(Object.values(fields), '[0].component') !== componentTypes.TEXT)
    ) {
      if (
        !Object.values(form.actions).some(
          (formAction: object) => 'autoFocus' in formAction,
        )
      ) {
        set(form.actions, 'primary.autoFocus', true);
      }
    }

    return form.actions;
  }, [form.actions, autoFocus, hydratedFormFields, fields]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(onSubmit)();
      }}
    >
      <FormContainer>
        {globalErrors?.length ? (
          <div style={{ marginBottom: '11px' }}>
            <ErrorsBlock errorsList={globalErrors} />
          </div>
        ) : (
          <></>
        )}
        <div>{hydratedFormFields}</div>
      </FormContainer>
      {afterFormContent}
      <FormButtons
        actions={formActions}
        afterContent={actionsContent}
        isSticky={isStickyActions}
        onAction={(handler: any, type: any) => {
          // Submit is handled by <form onSubmit>
          if (type !== 'submit') {
            if (!handler) {
              throw new Error(
                'Expected to find a handler for action: [secondary] but none was found',
              );
            }
            handler();
          }
        }}
      />
    </form>
  );
};

export const Form = withMatchMedia(FormComponent);
