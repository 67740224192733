import { Workflow } from '../../constants/Workflow';

const navigationItems: {
  [workflowName: string]: {
    label: string;
    value?: string;
    route: string;
    external?: boolean;
  }[];
} = {
  [Workflow.checklight]: [
    {
      label: 'Garage',
      value: 'garage',
      route: 'account/dashboard',
    },
    {
      label: 'Applications',
      value: 'applications',
      route: 'account/applications',
    },
    {
      label: 'Account',
      value: 'account',
      route: 'account/details',
    },
  ],
  [Workflow.unified]: [
    {
      label: 'Garage',
      value: 'garage',
      route: 'dashboard',
    },
    {
      label: 'Account',
      value: 'account',
      route: 'details',
    },
  ],
};

export default navigationItems;
