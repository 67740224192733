import { merge } from 'lodash';

import EditPrimaryIncomeModel from '../EditPrimaryIncome/Model';

const mappedModel = (send: any, workflowData: any) =>
  EditPrimaryIncomeModel(send, workflowData);

const modelFn = (send: any, workflowData: any) =>
  merge({}, mappedModel(send, workflowData), {
    headerBlock: {
      subtitle: ['Applicant', '100% complete'],
      progressBar: {
        progress: 100,
        withContainer: true,
      },
    },
  });

export default modelFn;
