import { merge } from 'lodash';

import applicantIcon from '../../../../../assets/Applicant.svg';
import housingIcon from '../../../../../assets/Residence.svg';
import vehicleIcon from '../../../../../assets/Vehicle.svg';
import { componentTypes } from '../../../../../constants/componentTypes';
import { Signal } from '../../../../common/constants/signals';
import {
  AccountContext,
  AccountState,
} from '../../common/unified-workflow-context.interface';
import BaseModel from '../common/BaseModel';

export enum ReviewEntity {
  Applicant = 'Applicant',
  Housing = 'Housing',
  Vehicle = 'Vehicle',
}

const getReviewDetails = (context: AccountContext) => {
  const applicantInfo = {
    key: ReviewEntity.Applicant,
    value: ReviewEntity.Applicant,
    label: 'Applicant',
    image: applicantIcon,
    subtitle: `${context.user?.firstName} ${context.user?.lastName}`,
  };

  const { lineOne, lineTwo, city, province, postalCode } =
    context.user?.residences?.current.physicalAddress || {};

  const housingInfo = {
    key: ReviewEntity.Housing,
    value: ReviewEntity.Housing,
    label: 'Housing',
    image: housingIcon,
    subtitle: [lineOne, lineTwo, city, province, postalCode]
      .filter((v) => !!v)
      .join(', '),
  };

  const { year, make, model } = context.vehicle || {};

  const vehicleInfo = {
    key: ReviewEntity.Vehicle,
    value: ReviewEntity.Vehicle,
    label: 'Vehicle information',
    image: vehicleIcon,
    subtitle: `${year} ${make} ${model}`,
  };

  return [applicantInfo, housingInfo, vehicleInfo];
};

const model = (send: any, { context }: AccountState) => {
  const { coApplicant, vehicle } = context;
  const progress = coApplicant.coApplicantAnswer ? 81 : 92;

  return merge({}, BaseModel, {
    template: {
      header: {
        showBack: true,
        onBack: () => {
          send(Signal.Previous, {
            data: {
              editedVehicle: vehicle,
            },
          });
        },
      },
    },
    headerBlock: {
      progressBar: {
        progress,
        withContainer: true,
      },
      title: 'Does everything look correct?',
      subtitle: ['Account', `${progress}% complete`],
    },
    form: {
      fields: {
        reviewDetails: {
          name: 'reviewDetails',
          component: componentTypes.SUMMARY_LIST,
          cta: 'View',
          options: getReviewDetails(context),
          onChange: (data: ReviewEntity) => {
            send(Signal.Next, { edit: data });
          },
          largeTitle: true,
        },
      },
      actions: {
        primary: {
          testId: 'continue',
          label: 'Continue',
          handler: () => {
            send(Signal.Next);
          },
        },
      },
    },
    info: {
      title: 'Review',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Use this time to correct any mistakes or confirm these details, as accurate information will allow for the smoothest application process possible.',
          ],
        },
      ],
    },
  });
};

export default model;
