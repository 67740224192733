import Cookies from 'js-cookie';
import { isNil } from 'lodash';

export const useContextCookies = () => {
  const getCookie = (name: string): string | undefined => {
    return Cookies.get(name);
  };

  /**
   * Parses out google visitor ID from cookie
   *
   * @returns {string|undefined}
   */
  const getCookieGoogleVisitorId = (): string | undefined => {
    const _ga = getCookie('_ga');
    const __utma = getCookie('__utma');

    if (!isNil(_ga)) {
      const parts = _ga.split('.');
      return `${parts[2]}.${parts[3]}`;
    }

    if (!isNil(__utma)) {
      const parts = __utma.split('.');
      return `${parts[1]}.${parts[2]}`;
    }

    return undefined;
  };

  return { getCookie, getCookieGoogleVisitorId };
};
