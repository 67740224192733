import { Navigate } from 'react-router-dom';

import { useWorkflowPathname } from '../../../../../hooks/useWorkflowPathname/useWorkflowPathname';

const ExitToOffers = ({ context }: any) => {
  const rootPath = useWorkflowPathname();

  return (
    <Navigate
      state={{
        currentVehicle: context.vehicle,
        currentUser: context.user,
      }}
      to={`/${rootPath}/workflow/offers/`}
    />
  );
};

export default ExitToOffers;
