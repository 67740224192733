import AccountHeroImg from '../../../assets/hero.svg';
import VerificationImg from '../../../assets/hero-communication.svg';
import ContractHeroImg from '../../../assets/hero-contract.svg';
import OffersErrorImg from '../../../assets/hero-error.svg';
import ErrorImg from '../../../assets/hero-error-with-smoke.svg';
import NoOffersImg from '../../../assets/hero-meditation.svg';
import ReferenceHeroImg from '../../../assets/hero-reference.svg';
import OffersHeroImg from '../../../assets/hero-with-bags.svg';
import PersonalDataHeroImg from '../../../assets/hero-with-camera.svg';
import ReviewHeroImg from '../../../assets/hero-with-laptop.svg';
import CoHeroImg from '../../../assets/heroes-selfie.svg';
import SuccessHeroImg from '../../../assets/success-hero.svg';
import VehicleHeroImg from '../../../assets/vehicle-search-hero.svg';
import { VehicleImagePillow } from '../vehicleImage/VehicleImagePillow';
import { PillowType } from './pillowTypes';
import { PillowImg } from './styles';

export interface PillowImageProps {
  isLoading?: boolean;
  type?: string;
  imageList?: any[];
}

const pillowTypeToImg: Record<string, any> = {
  [PillowType.VEHICLE_LOOKUP]: VehicleHeroImg,
  [PillowType.ACCOUNT]: AccountHeroImg,
  [PillowType.SUCCESS]: SuccessHeroImg,
  [PillowType.OFFERS]: OffersHeroImg,
  [PillowType.PERSONAL_DATA]: PersonalDataHeroImg,
  [PillowType.REVIEW]: ReviewHeroImg,
  [PillowType.CO_APPLICANT]: CoHeroImg,
  [PillowType.REFERENCE]: ReferenceHeroImg,
  [PillowType.CONTRACT]: ContractHeroImg,
  [PillowType.OFFERS_ERROR]: OffersErrorImg,
  [PillowType.VERIFICATION]: VerificationImg,
  [PillowType.NO_OFFERS]: NoOffersImg,
  [PillowType.ERROR]: ErrorImg,
};

export const PillowImage = ({ type, ...imgProps }: PillowImageProps) => {
  if (type === PillowType.VEHICLE_IMG) {
    return <VehicleImagePillow {...imgProps} />;
  }

  if (type && pillowTypeToImg[type]) {
    return <PillowImg data-testid="pillowImage" src={pillowTypeToImg[type]} />;
  }

  return null;
};
