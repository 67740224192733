import i18n from 'i18next';

import { IncomeTypeEnum } from '../enums/IncomeTypeEnum';

export const additionalIncomeTypeFormat: Record<IncomeTypeEnum, string> = {
  [IncomeTypeEnum.AID_FOR_DEPENDENT_CHILDREN]: i18n.t(
    'incomeType.aidForDependentChildren',
  ),
  [IncomeTypeEnum.CHILD_SUPPORT]: i18n.t('incomeType.childSupport'),
  [IncomeTypeEnum.COST_OF_LIVING_ALLOWANCE]: i18n.t(
    'incomeType.costOfLivingAllowance',
  ),
  [IncomeTypeEnum.DISABILITY]: i18n.t('incomeType.disability'),
  [IncomeTypeEnum.EMPLOYMENT]: i18n.t('incomeType.employment'),
  [IncomeTypeEnum.MILITARY_BASIC_ALLOWANCE_FOR_SUBSISTENCE]: i18n.t(
    'incomeType.militaryBasicAllowanceForSubsistence',
  ),
  [IncomeTypeEnum.MUNICIPAL_BOND_INTEREST]: i18n.t(
    'incomeType.municipalBondInterest',
  ),
  [IncomeTypeEnum.NON_TAXABLE_SOURCES]: i18n.t('incomeType.nonTaxableSources'),
  [IncomeTypeEnum.OTHER]: i18n.t('incomeType.other'),
  [IncomeTypeEnum.PUBLIC_ASSISTANCE]: i18n.t('incomeType.publicAssistance'),
  [IncomeTypeEnum.RENTAL_INCOME]: i18n.t('incomeType.rentalIncome'),
  [IncomeTypeEnum.SECONDARY_EMPLOYMENT]: i18n.t(
    'incomeType.secondaryEmployment',
  ),
  [IncomeTypeEnum.SOCIAL_SECURITY]: i18n.t('incomeType.socialSecurity'),
  [IncomeTypeEnum.SUPPLEMENTAL_SECURITY_INCOME]: i18n.t(
    'incomeType.supplementalSecurityIncome',
  ),
  [IncomeTypeEnum.UNKNOWN]: i18n.t('incomeType.unknown'),
  [IncomeTypeEnum.WORKMANS_COMPENSATION]: i18n.t(
    'incomeType.workmansCompensation',
  ),
};
