import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';

import { ButtonType } from '../Button/buttonType';
import { Modal } from '../Modal/Modal';

export const ConfirmModal = ({
  onConfirm,
  onCancel,
  btnText = 'Confirm',
  title = 'Are you sure you want to proceed?',
  children = 'Unsaved changes will be lost',
  autoClose,
}: any) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    try {
      await onConfirm();
      setLoading(false);
    } catch (e) {
      console.error('An unexpected exception occurred', e);
      setLoading(false);
    }

    if (autoClose) {
      onCancel();
    }
  }, [onConfirm, onCancel, autoClose]);

  const buttonGroup = useMemo(
    () => ({
      primaryButton: {
        name: 'confirm',
        children: btnText,
        isDisabled: loading,
        stylingType: ButtonType.SECONDARY,
        onClick: onSubmit,
        testId: 'confirm',
      },
      secondaryButton: {
        name: 'cancel',
        children: 'Cancel',
        isDisabled: loading,
        stylingType: ButtonType.PRIMARY,
        onClick: onCancel,
        testId: 'cancel',
      },
      rtl: true,
    }),
    [loading, onCancel, onSubmit, btnText],
  );

  return (
    <Modal
      buttonGroupPresModel={buttonGroup}
      subtitle=""
      title={title}
      onClose={onCancel}
    >
      {children}
    </Modal>
  );
};

ConfirmModal.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  btnText: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.string,
  autoClose: PropTypes.bool,
};
