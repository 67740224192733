import { merge, pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';

import { HttpStatusCode } from '../../../../../common';
import { PillowForm } from '../../../../../components/PillowForm';
import { useAPI } from '../../../../../hooks/useAPI/useAPI';

const mapErrorsToFormFields = (apiErrors: any) => {
  const unexpectedErr =
    'There was an unexpected error attempting to fetch vehicle details. Please try again.';
  const modelFragment: any = { fieldErrors: {}, globalErrors: [] };

  apiErrors.forEach((error: any) => {
    if (error.statusCode === HttpStatusCode.NOT_FOUND) {
      modelFragment.globalErrors.push('Vehicle details not found from the VIN');
    } else {
      modelFragment.globalErrors.push(unexpectedErr);
    }
  });
  return modelFragment;
};

const Controller = ({ context, presModel, send }: any) => {
  const api = useAPI();
  const { editedVehicle } = context;
  const { vehicleInformationSource, vin: cachedVin } = editedVehicle;
  const cachedConfirmedVehicleData = pick(editedVehicle, [
    'mileage',
    'trim',
    'trimId',
    'loan.payoffAmount',
  ]);

  const isNewVinCode = useCallback(
    (newVin: string) => newVin !== cachedVin,
    [cachedVin],
  );

  const onSubmit = useAsyncCallback(async ({ vin }) => {
    const fetchedVehicle = await api
      .post('/vehicles/search', { criteria: 'BY_VIN', value: { vin } })
      .then((vehicles: any) => vehicles[0]);

    const { chromeDataVehicleInformation, ...restFetchedVehicleData } =
      fetchedVehicle;
    send('Next', {
      data: {
        editedVehicle: {
          vehicleInformationSource,
          ...chromeDataVehicleInformation,
          ...restFetchedVehicleData,
          ...(isNewVinCode(vin) ? {} : cachedConfirmedVehicleData),
        },
      },
    });
  });

  const formErrors = useMemo(() => {
    if (onSubmit.error) {
      return mapErrorsToFormFields(onSubmit.error);
    }
    return {
      globalErrors: [],
    };
  }, [onSubmit]);

  const enhancedProps = useMemo(
    () =>
      merge(
        {},
        { presModel },
        {
          presModel: {
            form: {
              actions: {
                primary: {
                  isLoading: onSubmit.loading,
                  handler: onSubmit.execute,
                },
              },
              ...formErrors,
            },
          },
        },
      ),
    [onSubmit, presModel, formErrors],
  );

  return <PillowForm {...enhancedProps} />;
};

Controller.displayName = 'VehicleManualFindByVin.Controller';
export default Controller;
