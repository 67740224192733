import { CoapplicantState } from '../CoapplicantState';
import { CoapplicantDto } from '../dto/CoapplicantDto';
import { toCoapplicantStateFromIncomes } from './toCoapplicantStateFromIncomes';
import { toCoapplicantStateFromResidences } from './toCoapplicantStateFromResidences';

export const toCoapplicantState = (
  coapplicant: CoapplicantDto,
): Partial<CoapplicantState> => ({
  ...toCoapplicantStateFromIncomes(coapplicant.income),
  ...toCoapplicantStateFromResidences(coapplicant.residence),

  ...(coapplicant.personalInfo?.birthDate && {
    coapplicantDateOfBirth: coapplicant.personalInfo.birthDate,
  }),

  coapplicantFirstName: coapplicant.firstName || null,
  coapplicantId: coapplicant.id || null,
  coapplicantLastName: coapplicant.lastName || null,
  coapplicantPrimaryEmailAddress: coapplicant.primaryEmailAddress || null,
  coapplicantPrimaryPhoneNumber: coapplicant.primaryPhoneNumber || null,
  coapplicantRelationship: coapplicant.relationship || null,
});
