import applicantIcon from '../../../../assets/Applicant.svg';
import incomeIcon from '../../../../assets/Income.svg';
import housingIcon from '../../../../assets/Residence.svg';
import { getAmountByPayFrequency, PayFrequency } from '../../../../common';
import { UserContextT } from '../../../../providers/context/CurrentUserContext/CurrentUserContextT';
import { ApplicantReviewSignal } from '../../interfaces/applicant.interface';

export const applicantReviewSignals: ApplicantReviewSignal = {
  ReviewApplicant: 'ReviewApplicant',
  ReviewHousing: 'ReviewHousing',
  ReviewIncome: 'ReviewIncome',
};

export const getApplicantDetails = (
  source: UserContextT,
  signal: ApplicantReviewSignal,
) => {
  const getApplicantInfo = {
    key: 'applicant',
    value: signal.ReviewApplicant,
    label: 'Applicant',
    image: applicantIcon,
    largeTitle: true,
    subtitle: `${source.firstName} ${source.lastName}`,
  };

  const { lineOne, lineTwo, city, province, postalCode } =
    source.residences?.current.physicalAddress || {};

  const getAddressInfo = {
    key: 'housing',
    value: signal.ReviewHousing,
    label: 'Housing',
    image: housingIcon,
    largeTitle: true,
    subtitle: [lineOne, lineTwo, city, province, postalCode]
      .filter((v) => !!v)
      .join(', '),
  };

  const { incomes } = source;

  const monthlyIncome = [
    incomes?.current,
    ...(incomes?.additional || []),
  ].reduce((acc: number, { incomeFrequency, amount }: any) => {
    return [amount, incomeFrequency].some((v) => v === undefined)
      ? acc
      : acc +
          (getAmountByPayFrequency(PayFrequency.MONTHLY)(
            incomeFrequency,
            amount,
          ) as any);
  }, 0);

  const getIncomeInfo = {
    key: 'income',
    value: signal.ReviewIncome,
    label: 'Income',
    image: incomeIcon,
    largeTitle: true,
    subtitle: {
      value: monthlyIncome,
      format: {
        thousandSeparator: true,
        prefix: '$',
        suffix: '/month',
      },
    },
  };

  return [getApplicantInfo, getAddressInfo, getIncomeInfo];
};
