import { Workflow } from '../../constants/Workflow';
import { ApplicationDataInterface } from '../../pages/Applications/applicationData.interface';
import { isPurchaseApplication } from '../../utils/applicationType';
import { DocumentFile } from './documentFileConfig';

export interface UploadTaskConfig {
  alwaysAllowUpload?: boolean;
  description: string;
  documents: DocumentFile[];
  info: {
    content: {
      copy: string[];
    }[];
    subtitle: string;
    title: string;
  };
  label: string;
  optional: boolean;
}

export enum DocumentEnum {
  AdditionalDocuments = 'AdditionalDocuments',
  CoApplicantDriverLicense = 'CoApplicantDriverLicense',
  DriverLicense = 'DriverLicense',
  PhotoOfOdometer = 'PhotoOfOdometer',
  PayoffLetter = 'PayoffLetter',
  ProofOfIncome = 'ProofOfIncome',
  ProofOfInsurance = 'ProofOfInsurance',
  RegistrationCard = 'RegistrationCard',
}

export const DOCUMENTS_CONFIG: { [key in DocumentEnum]?: UploadTaskConfig } = {
  [DocumentEnum.DriverLicense]: {
    label: "Driver's License",
    description:
      "Please provide a clear photo or copy of the applicant's state issued Driver License. The applicant will need to submit both the front and back. These documents will need to be valid and up to date.",
    documents: [
      DocumentFile.DRIVERS_LICENSE_FRONT_APPLICANT,
      DocumentFile.DRIVERS_LICENSE_BACK_APPLICANT,
    ],
    info: {
      title: 'Upload document',
      subtitle: 'Help',
      content: [
        {
          copy: [
            "Please provide a clear photo or copy of the applicant's state issued Driver License. The applicant will need to submit both the front and back. These documents will need to be valid and up to date.",
          ],
        },
      ],
    },
    optional: false,
  },
  [DocumentEnum.CoApplicantDriverLicense]: {
    label: "Driver's License or State ID (Co-applicant)",
    description:
      "Please provide a clear photo or copy of the co-applicant's state issued Driver License. The co-applicant will need to submit both the front and back. These documents will need to be valid and up to date.",
    documents: [
      DocumentFile.DRIVERS_LICENSE_FRONT_CO_APPLICANT,
      DocumentFile.DRIVERS_LICENSE_BACK_CO_APPLICANT,
    ],
    info: {
      title: 'Upload document',
      subtitle: 'Help',
      content: [
        {
          copy: [
            "Please provide a clear photo or copy of the co-applicant's state issued Driver License. The co-applicant will need to submit both the front and back. These documents will need to be valid and up to date.",
          ],
        },
      ],
    },
    optional: false,
  },
  [DocumentEnum.ProofOfInsurance]: {
    label: 'Proof of insurance',
    description:
      "Please provide a clear photo or copy of the vehicle you are refinancing's proof of insurance. These documents will need to be valid and up to date.",
    documents: [DocumentFile.PROOF_OF_INSURANCE],
    info: {
      title: 'Upload document',
      subtitle: 'Help',
      content: [
        {
          copy: [
            "Please provide a clear photo or copy of the vehicle you are refinancing's proof of insurance. These documents will need to be valid and up to date.",
          ],
        },
      ],
    },
    optional: false,
  },
  [DocumentEnum.RegistrationCard]: {
    label: 'Registration Card',
    description:
      "Please provide a clear photo or copy of the vehicle you are refinancing's registration card. These documents will need to be valid and up to date.",
    documents: [DocumentFile.REGISTRATION_CARD],
    info: {
      title: 'Upload document',
      subtitle: 'Help',
      content: [
        {
          copy: [
            "Please provide a clear photo or copy of the vehicle you are refinancing's registration card. These documents will need to be valid and up to date.",
          ],
        },
      ],
    },
    optional: false,
  },
  [DocumentEnum.PhotoOfOdometer]: {
    label: 'Photo of Odometer',
    description:
      "Please provide a clear photo of your vehicle's latest odometer reading as it appears currently in your vehicle.",
    documents: [DocumentFile.PHOTO_OF_ODOMETER],
    info: {
      title: 'Upload document',
      subtitle: 'Help',
      content: [
        {
          copy: [
            "Please provide a clear photo of your vehicle's latest odometer reading as it appears currently in your vehicle.",
          ],
        },
      ],
    },
    optional: false,
  },
  [DocumentEnum.ProofOfIncome]: {
    label: 'Proof of Income',
    description:
      'Please provide a clear photo or copy of the proof of income. These documents will need to be valid and up to date.',
    documents: [DocumentFile.PROOF_OF_INCOME],
    info: {
      title: 'Upload document',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Please provide a clear photo or copy of the proof of income. These documents will need to be valid and up to date.',
          ],
        },
      ],
    },
    optional: false,
  },
  [DocumentEnum.PayoffLetter]: {
    label: 'Payoff Letter (optional)',
    description:
      'Please provide a clear photo or copy of your payoff letter. You can request one from your lender without the obligation to follow through paying off the remainder of the loan.',
    documents: [DocumentFile.PAYOFF_LETTER],
    info: {
      title: 'Upload document',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Please provide a clear photo or copy of your payoff letter. You can request one from your lender without the obligation to follow through paying off the remainder of the loan.',
          ],
        },
      ],
    },
    optional: true,
  },
  [DocumentEnum.AdditionalDocuments]: {
    alwaysAllowUpload: true,
    label: 'Additional Documents (optional)',
    description:
      'Please use this to upload any additional documents requested by your loan specialist.',
    documents: [DocumentFile.ADDITIONAL_DOCUMENT],
    info: {
      title: 'Upload document',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Please use this to upload any additional documents requested by your loan specialist.',
          ],
        },
      ],
    },
    optional: true,
  },
};

export const REQUIRED_DOCUMENTS_LIST = {
  [Workflow.checklight]: [
    DocumentEnum.DriverLicense,
    DocumentEnum.ProofOfInsurance,
    DocumentEnum.RegistrationCard,
    DocumentEnum.PhotoOfOdometer,
  ],
  [Workflow.unified]: [
    DocumentEnum.DriverLicense,
    {
      documentName: DocumentEnum.CoApplicantDriverLicense,
      cond: (state: ApplicationDataInterface) =>
        state.currentVehicle.coApplicant?.coApplicantAnswer,
    },
    DocumentEnum.ProofOfInsurance,
    {
      documentName: DocumentEnum.RegistrationCard,
      cond: (state: ApplicationDataInterface) =>
        !isPurchaseApplication(state?.currentVehicle),
    },
    {
      documentName: DocumentEnum.PhotoOfOdometer,
      cond: (state: ApplicationDataInterface) =>
        !isPurchaseApplication(state?.currentVehicle),
    },
    DocumentEnum.AdditionalDocuments,
  ],
};
