import { Fragment, useEffect, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';

import { Modal } from '../components/shared/Modal/Modal';
import { UserAgreement } from '../components/shared/UserAgreement/UserAgreement';
import { useAPI } from '../hooks/useAPI/useAPI';
import { useModal } from '../hooks/useModal/useModal';

const modalButtonConfig = {
  name: 'save',
  children: 'Save',
  testId: 'save',
};

function AgreeToTermsGuard({ vehicleId }: { vehicleId: string }) {
  const { isShowing, open } = useModal();
  const api = useAPI();

  const [agreementChecked, setAgreementChecked] = useState(false);
  const [isSubmittedWithoutAgreement, setIsSubmittedWithoutAgreement] =
    useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmit = useAsyncCallback(async () => {
    await api.post('/users/current/agree-to-terms', {
      termsAndConditionsOptIn: {
        agreesToTerms: true,
      },
      vehicleId,
    });

    window.location.reload();
  });

  useEffect(() => {
    if (isSubmitted) {
      setIsSubmittedWithoutAgreement(!agreementChecked);
    }
  }, [agreementChecked, isSubmitted]);

  const modalButtons = {
    rtl: true,
    primaryButton: {
      isLoading: onSubmit.loading,
      ...modalButtonConfig,
      onClick: async () => {
        if (!isSubmitted) {
          setIsSubmitted(true);
        }
        if (agreementChecked) {
          await onSubmit.execute();
        }
      },
    },
  };

  useEffect(() => {
    open();
  }, []);

  return (
    <Fragment>
      {isShowing && (
        <Modal
          buttonGroupPresModel={modalButtons}
          showClose={false}
          subtitle="Terms"
          testId="agreePopup"
          title="Please agree to terms"
        >
          <UserAgreement
            agreementChecked={agreementChecked}
            isSubmittedWithoutAgreement={isSubmittedWithoutAgreement}
            setAgreementChecked={setAgreementChecked}
          />
        </Modal>
      )}
    </Fragment>
  );
}

export default AgreeToTermsGuard;
