import { theme } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const Container = styled('div', () => ({
  width: '100%',
}));

export const CopyContainer = styled('div', ({ $hasMargin, $theme }: any) => ({
  ...theme.fonts.paragraph,
  color: $theme.colors.primaryActive,
  marginBottom: $hasMargin ? theme.unit[3] : 0,
}));
