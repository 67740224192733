import { merge, pick } from 'lodash';

import { populateFields } from '../../../../../forms/populateFields';
import { fields } from '../../../../../forms/user';
import { withContext } from '../../../../../utils/workflow/withContext';
import { Signal } from '../../XState/Model';
import BaseModel from '../common/BaseModel';

const populateData =
  (fields: any) =>
  (send: any, { context }: any) => {
    return populateFields(fields, context.coApplicant);
  };

const personalDetailsFields = pick(fields, [
  'firstName',
  'lastName',
  'primaryEmailAddress',
  'primaryPhoneNumber',
  'dob',
]);

const model = (send: any) =>
  merge({}, BaseModel, {
    headerBlock: {
      title: 'Edit co-applicant details',
      subtitle: ['Co-applicant', '100% complete'],
      progressBar: {
        progress: 100,
        withContainer: true,
      },
    },
    form: {
      fields: personalDetailsFields,
      actions: {
        primary: {
          label: 'Save',
          testId: 'save',
        },
        secondary: {
          testId: 'cancel',
          label: 'Cancel',
          handler: () => {
            send(Signal.Previous);
          },
        },
      },
      withConfirmModal: true,
    },
    info: {
      title: 'Review co-applicant personal details',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Does everything look correct? Use this time to correct any mistakes or confirm these details, as accurate information will allow for the smoothest application process possible.',
          ],
        },
      ],
    },
  });

const modelFn = (...args: [any, any]) =>
  withContext({ model: model(args[0]), populateFields: populateData })(...args);

export default modelFn;
