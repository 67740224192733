import { Navigate } from 'react-router-dom';

import { useWorkflowPathname } from '../../../../../hooks/useWorkflowPathname/useWorkflowPathname';

const ExitToSignIn = ({ context }: any) => {
  const rootPath = useWorkflowPathname();

  return (
    <Navigate
      state={{
        primaryPhoneNumber: context.user.primaryPhoneNumber,
      }}
      to={`/${rootPath}/sign-in`}
    />
  );
};

export default ExitToSignIn;
