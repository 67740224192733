export const InputFormatter = {
  MONEY: 'MONEY',
  MONEY_MONTHLY_SPACER: 'MONEY_MONTHLY_SPACER',
  MONEY_ANNUALLY_SPACER: 'MONEY_ANNUALLY_SPACER',
  PHONE_NUMBER: 'PHONE_NUMBER',
  MILEAGE: 'MILEAGE',
  PIN: 'PIN',
  /**
   * "0.06" shown as "6 %"
   */
  PERCENT: 'PERCENT',
  /**
   * "12" shown as "12 mo"
   */
  TERM_SHORT: 'TERM_SHORT',
  DATE: 'DATE',
  NUMBER: 'NUMBER',
  SSN: 'SSN',
};
