import { styled } from '../../providers/context/StyletronProvider/withStyletronProvider';

export const ModalWrapper = styled('div', ({ $distanceToModal }: any) => ({
  ...($distanceToModal
    ? {
        position: 'fixed',
        top: `-${$distanceToModal}px`,
        height: '100%',
        width: '100%',
      }
    : {}),
}));
