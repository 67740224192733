/* eslint-disable react-refresh/only-export-components */
import { isEmpty } from 'lodash';
import { useContext, useMemo } from 'react';

import { Branding } from '../../../common/interfaces/branding/branding';
import { DynamicBrandingContext } from '../../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { ApplicationCard } from '../ApplicationCard/ApplicationCard';
import { ButtonType } from '../Button/buttonType';
import { defaultModalButtons, Modal } from '../Modal/Modal';
import { PanelList } from '../PanelList/PanelList';
import { PanelListFormatType, PanelListType } from '../PanelList/PanelList';

export enum ContactUsType {
  DEFAULT = 'DEFAULT',
  LOAN_CONSULTANT = 'LOAN_CONSULTANT',
}

export const ContactUs = ({
  applicationBranding,
  type = ContactUsType.DEFAULT,
  isLoading,
  loanConsultant,
  autopayNumber,
}: {
  applicationBranding?: Branding;
  type?: ContactUsType;
  isLoading?: boolean;
  loanConsultant?: any;
  autopayNumber?: string;
}) => {
  const { branding } = useContext(DynamicBrandingContext);

  // prioritize application branding when provided
  // otherwise default to global branding
  const brandingContact = isEmpty(applicationBranding)
    ? branding
    : applicationBranding;

  const { theme: $theme } = brandingContact;

  const panelListConfig = useMemo(() => {
    const { phoneNumber, emailAddress, availableHours } = brandingContact;

    return [
      {
        testId: 'contactUsPhone',
        panelType: PanelListType.LINK,
        panelOptions: { href: `tel:${phoneNumber}` },
        formatType: PanelListFormatType.NUMBER,
        formatOptions: {
          format: '(###) ###-####',
          displayType: 'text',
        },
        label: 'Phone',
        value: phoneNumber,
      },
      {
        testId: 'contactUsEmail',
        panelType: PanelListType.LINK,
        panelOptions: { href: `mailto:${emailAddress}` },
        label: 'Email',
        value: emailAddress,
      },
      {
        testId: 'availableHours',
        panelType: PanelListType.TEXT,
        label: 'Hours',
        value: availableHours,
      },
    ];
  }, [brandingContact]);

  const modalProps = useMemo(() => {
    if (isLoading) {
      return {
        title: 'Loading details...',
        buttonGroupPresModel: defaultModalButtons(),
        isLoading: true,
      };
    }
    if (type === ContactUsType.LOAN_CONSULTANT) {
      return {
        title: 'Here when you need us',
        subtitle: autopayNumber,
        subtitleColor: $theme.colors.primaryActive,
        paragraph:
          'Your Loan Consultant works with you to find the right loan for your needs and helps you lock the best rate possible.',
        buttonGroupPresModel: defaultModalButtons(),
      };
    }
    return {
      title: 'Contact us',
      subtitle: 'Help',
      subtitleColor: $theme.colors.primaryActive,
      buttonGroupPresModel: {
        primaryButton: {
          name: 'back',
          children: 'Back',
          isDisabled: false,
          testId: 'back',
        },
        rtl: true,
      },
      testId: 'contactUsPopup',
    };
  }, [isLoading, type, $theme, autopayNumber]);

  const modalContent = useMemo(() => {
    if (isLoading) {
      return null;
    }
    if (type === ContactUsType.LOAN_CONSULTANT) {
      const {
        employeePhoneNumber,
        employeeFirstName,
        employeeLastName,
        employeeEmailAddress,
      } = loanConsultant || {};
      const getFirstSymbolOfStr = (str: string | undefined) =>
        !!str ? str.substring(0, 1).toUpperCase() : '';
      const symbolText =
        getFirstSymbolOfStr(employeeFirstName) +
        getFirstSymbolOfStr(employeeLastName);
      const buttonsConfig = !!employeePhoneNumber
        ? [
            {
              children: 'Call',
              stylingType: ButtonType.GHOST,
              onClick: () => {
                window.open(`tel:${employeePhoneNumber}`, '_self');
              },
            },
          ]
        : [];
      return (
        <ApplicationCard
          buttonsConfig={buttonsConfig}
          subtitle={employeeEmailAddress}
          symbol={symbolText}
          title={`${employeeFirstName ?? ''} ${employeeLastName ?? ''}`}
        />
      );
    }
    return <PanelList panelList={panelListConfig} isBorderWrapped />;
  }, [isLoading, type, panelListConfig, loanConsultant]);

  return <Modal {...modalProps}>{modalContent}</Modal>;
};
