import { useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { WorkflowInitialContext } from '../../providers/context/WorkflowInitialContext/WorkflowInitialContext';

/**
 * Put state from location to workflowInitialContext
 * We can't use location directly in gate because it causes rerendering
 */
export const WorkflowHub = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { state, pathname }: any = location;

  const [initialContext, setInitialContext] = useContext(
    WorkflowInitialContext,
  );

  useEffect(() => {
    if (state && (!state.populated || !initialContext)) {
      setInitialContext(state);
      // Adding prop `populated: true` to stop initial context update loop
      navigate(pathname, {
        state: { ...state, populated: true },
        replace: true,
      });
    }
  });

  // This condition forces rendering to wait until initialContext is updated
  if (state && (!state.populated || !initialContext)) {
    return null;
  }

  return <Outlet />;
};
