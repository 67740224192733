import { useCallback, useEffect, useState } from 'react';
import { useBeforeUnload, useBlocker } from 'react-router-dom';

import { ConfirmModal } from './ConfirmModal';

export const ConfirmCancelModal = ({ formChanged, setOpenRef }: any) => {
  const [isShowing, setIsShowing] = useState(false);
  const blocker = useBlocker(formChanged);

  const open = useCallback(() => {
    setIsShowing(true);
  }, [setIsShowing]);
  const close = useCallback(() => {
    setIsShowing(false);
  }, [setIsShowing]);

  const [onConfirmHandler, setOnConfirmHandler] = useState<any>({});

  // Allow to open confirm modal from parent component
  useEffect(() => {
    setOpenRef &&
      setOpenRef({
        open: (handler: any) => {
          setOnConfirmHandler({ handler });
          open();
        },
      });
  }, []);

  // Open confirm modal on back button click
  useEffect(() => {
    if (blocker?.state === 'blocked') {
      open();
    }
  }, [blocker?.state, open]);

  // Show default browser confirm dialog on page reload or closing tab
  useBeforeUnload((e) => {
    e.returnValue = '';
  });

  return isShowing ? (
    <ConfirmModal
      onCancel={() => {
        close();
        blocker?.reset?.();
      }}
      onConfirm={() => {
        close();
        onConfirmHandler?.handler?.();
        blocker?.proceed?.();
      }}
    />
  ) : null;
};
