import { RegisterOptions } from 'react-hook-form';

import { FormFiledInterface } from '../../components/shared/Form/interfaces/formFields.interface';
import { ComponentType } from '../../constants/componentTypes';

export class FormField {
  name!: string;
  // generic label for this field.  We want this for friendly display of the field.
  label?: string;

  // component which renders this field for population
  component?: ComponentType;

  options?: any;
  validationRules?: Pick<
    RegisterOptions,
    | 'required'
    | 'pattern'
    | 'validate'
    | 'min'
    | 'max'
    | 'maxLength'
    | 'minLength'
  >;
  cta?: string;
  format?: string;
  formatOptions?: any;
  value?: any;
  warningValidation?: any;
  hint?: string;
  readOnly?: boolean;
  largeTitle?: boolean;
  autoCompleteObj?: any;
  autoFocus?: boolean;
  defaultValue?: any;
  isHidden?: boolean;
  shouldTrimValue?: boolean;
  dependsOn?: {
    handler: (data: any) => void;
    fields: string[];
  };
  // Unregister field on unmount
  shouldUnregister?: boolean;

  constructor(props: FormFiledInterface) {
    Object.assign(this, props);
  }
}
