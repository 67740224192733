import { theme } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

const getBorderString = (color: any) => `0.125rem solid ${color}`;

const getOuterColorScheme = (isSelected: any, isError: any, $theme: any) => {
  if (isSelected && !isError) {
    return {
      border: getBorderString($theme.colors.primary),
    };
  }
  if (isError && !isSelected) {
    return {
      border: getBorderString($theme.colors.error),
    };
  }
  if (isError && isSelected) {
    return {
      border: getBorderString($theme.colors.error),
    };
  }
  return {
    border: getBorderString($theme.colors.primaryActive),
    ':hover': { border: getBorderString($theme.colors.primary) },
  };
};

export const Container = styled(
  'div',
  ({ $isSelected, $isError, $theme }: any) => {
    const disabledStyles = {
      border: getBorderString($theme.colors.disabled),
      cursor: 'default',
    };
    return {
      ...theme.common.helpers.flexVerticalCenter,
      borderRadius: '50%',
      height: theme.unit[3],
      width: theme.unit[3],
      cursor: 'pointer',
      ':disabled': disabledStyles,
      ...getOuterColorScheme($isSelected, $isError, $theme),
      ':disabled:not([hover])': disabledStyles,
    } as any;
  },
);

const getInnerColorScheme = (
  isSelected: any,
  isError: any,
  disabled: any,
  $theme: any,
) => {
  if (isSelected && !isError && !disabled) {
    return {
      backgroundColor: $theme.colors.primary,
    };
  }
  if (disabled && isSelected) {
    return {
      backgroundColor: $theme.colors.disabled,
      cursor: 'default',
    };
  }
  if (disabled && !isSelected) {
    return {
      cursor: 'default',
    };
  }
  if (isError && isSelected && !disabled) {
    return {
      backgroundColor: $theme.colors.error,
    };
  }
  return {};
};

export const InnerContainer = styled(
  'div',
  ({ $isSelected, $isError, $disabled, $theme }: any) => ({
    borderRadius: '50%',
    height: theme.unit[2],
    width: theme.unit[2],
    cursor: 'pointer',
    ...getInnerColorScheme($isSelected, $isError, $disabled, $theme),
  }),
);
