import { font, unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const OffersSummary = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: unit[7],
}));

export const OffersTotal = styled('div', ({ $theme }: any) => ({
  color: $theme.colors.infoOverlay,
  fontFamily: font.family.Merriweather,
  fontSize: font.size.headingLarge,
}));

export const OffersInfo = styled('div', ({ $theme }: any) => ({
  color: $theme.colors.primaryActive,
  fontSize: font.size.subTitle,
  marginTop: unit[1],
}));
