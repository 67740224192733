import PropTypes from 'prop-types';
import { useState } from 'react';

import { Container } from './styles';
import { PanelTypes } from './types';

export const Panel = ({
  children,
  name = '',
  onClick = () => {},
  readOnly = true,
  selectable = false,
  type = PanelTypes.SHARP,
}: {
  children: any;
  name: any;
  onClick: any;
  readOnly: any;
  selectable: any;
  type: any;
}) => {
  const [selected, setSelected] = useState(false);

  const clickHandler = () => {
    onClick(name);

    if (selectable) {
      setSelected(!selected);
    }
  };

  return (
    <Container
      $readOnly={readOnly}
      $selectable={selectable}
      $selected={selected}
      $type={type}
      onClick={clickHandler}
    >
      {children}
    </Container>
  );
};

Panel.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  selectable: PropTypes.bool,
  type: PropTypes.string,
};
