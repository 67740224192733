import { theme } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';
const containerPadding = ({ $isDesktop, $isTablet }: any) => {
  let p;
  if ($isDesktop) {
    p = theme.unit[14];
  } else {
    p = $isTablet ? theme.unit[6] : theme.unit[3];
  }
  return {
    paddingLeft: p,
    paddingRight: p,
  };
};

const logoLeftPosition = ({ $isDesktop, $isTablet }: any) => {
  if ($isDesktop) {
    return theme.unit[8];
  }
  return $isTablet ? theme.unit[6] : theme.unit[3];
};

const calculateWidth = ({ $isDesktop, $isTablet }: any) => {
  switch (true) {
    case $isDesktop: {
      return `calc(${theme.unit[2]} * 42)`;
    }
    case $isTablet: {
      return `calc(${theme.unit[2]} * 30.5)`;
    }
    default: {
      return `calc(${theme.unit[2]} * 17.5)`;
    }
  }
};

const gridTemplateRows = (isDesktop: any, hasFooter: any) => {
  if (hasFooter) {
    return isDesktop
      ? '5rem auto 6.5rem'
      : 'min-content min-content auto min-content';
  }
  return isDesktop ? '5rem auto min-content' : 'min-content min-content auto';
};

export const MainGridContainer = styled(
  'div',
  ({ $isDesktop, $modalOpened, $height, $hasFooter }: any) => ({
    display: 'grid',
    gridTemplateColumns: $isDesktop ? '50% 50%' : '',
    gridTemplateRows: gridTemplateRows($isDesktop, $hasFooter),
    width: '100%',
    height: $height,
    ...($modalOpened ? { overflow: 'hidden' } : {}),
  }),
);

export const PillowGridContainer = styled(
  'div',
  ({ $isDesktop, $theme }: any) =>
    ({
      backgroundColor: $theme.colors.tertiaryOverlay,
      ...($isDesktop
        ? {
            gridColumn: 1,
            gridRowStart: 1,
            gridRowEnd: 'span 3',
          }
        : {
            gridRow: 2,
          }),
      ...(!$isDesktop ? { padding: 0, minHeight: '20rem' } : {}),
      ...theme.common.helpers.flexVerticalCenter,
      alignItems: 'flexStart',
    } as any),
);

export const HeaderGridContainer = styled(
  'div',
  ({ $isDesktop, $isTablet, $persistImage, $theme }: any) => ({
    gridRow: 1,
    gridColumn: $isDesktop ? 2 : '',
    ...containerPadding({ $isDesktop, $isTablet }),
    ...(!$isDesktop && $persistImage
      ? { backgroundColor: $theme.colors.tertiaryOverlay }
      : {}),
  }),
);

export const StageGridContainer = styled(
  'div',
  ({ $isDesktop, $isTablet, $centerContent, $hasFooter }: any) =>
    ({
      gridRow: $isDesktop ? 2 : 3,
      gridColumn: $isDesktop ? 2 : '',
      ...containerPadding({ $isDesktop, $isTablet }),
      marginBottom: theme.unit[6],
      ...($isDesktop
        ? {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: $centerContent ? 'center' : 'start',
            textAlign: 'left',
          }
        : {}),
      ...(!$hasFooter
        ? {
            paddingBottom: $isDesktop ? theme.unit[13] : theme.unit[19],
          }
        : {}),
    } as any),
);

export const FooterGridContainer = styled('div', ({ $isDesktop }: any) => ({
  gridRow: $isDesktop ? 3 : 4,
  gridColumnStart: $isDesktop ? 2 : '',
  gridColumnEnd: $isDesktop ? 'span 2' : '',
  padding: '0',
  height: theme.unit[13],
}));

export const LogoContainer = styled(
  'div',
  ({ $isDesktop, $isTablet }: any) => ({
    position: 'absolute',
    top: theme.unit[4],
    width: theme.unit[24],
    height: theme.unit[5],
    display: 'flex',
    left: logoLeftPosition({ $isDesktop, $isTablet }),
  }),
);

export const PillowContainer = styled(
  'div',
  ({ $isDesktop, $isTablet }: any) => ({
    width: calculateWidth({ $isDesktop, $isTablet }),
    ...($isDesktop ? { height: '100vh' } : {}),
    display: 'flex',
  }),
);
