import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import { HousingEdit } from './HousingEdit';
import { HousingView } from './HousingView';

export const Housing = () => {
  const routes: RouteObject[] = [
    {
      index: true,
      element: <HousingView />,
    },
    {
      path: 'edit',
      element: <HousingEdit />,
    },
    {
      path: '*',
      element: <Navigate to="" replace />,
    },
  ];

  return useRoutes(routes);
};
