import { theme } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const ImagePlaceholderFitContainer = styled(
  'div',
  ({ $theme }: any) =>
    ({
      ...theme.common.helpers.flexVerticalCenter,
      backgroundColor: $theme.colors.tertiaryOverlay,
      width: '100%',
      paddingTop: '75%' /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) */,
      borderRadius: theme.unit[1],
    } as any),
);
