import { Client as Styletron } from 'styletron-engine-atomic';
import { createStyled, Provider as StyletronProvider } from 'styletron-react';
import { driver, getInitialStyle } from 'styletron-standard';

import { DynamicBrandingContext } from '../DynamicBrandingProvider/DynamicBrandingContext';

export const withStyletronProvider = (WrappedComponent: any) => {
  const engine = new Styletron();

  return () => (
    <StyletronProvider value={engine}>
      <WrappedComponent />
    </StyletronProvider>
  );
};

const wrapper =
  (StyledComponent: any) =>
  ({ forwardedRef, ...props }: any) =>
    (
      <DynamicBrandingContext.Consumer>
        {({ branding: { theme } }) => (
          <StyledComponent
            {...props}
            {...(forwardedRef ? { ref: forwardedRef } : {})}
            $theme={theme}
          />
        )}
      </DynamicBrandingContext.Consumer>
    );

export const styled = createStyled({
  wrapper,
  getInitialStyle,
  driver,
});
