import { PillowType } from '../../../../components/shared/PillowImage/pillowTypes';

const model = {
  template: {
    header: {
      showClose: false,
      showBack: true,
    },
    footer: {
      isActive: false,
    },
    img: {
      type: PillowType.OFFERS,
    },
  },
  form: {
    actions: {
      primary: {
        testId: 'continue',
        label: 'Continue',
        preserveLabel: true,
        preserveTestId: true,
      },
    },
  },
  headerBlock: {
    title: 'Review your new loan details',
    subtitle: ['Offers', '100% complete'],
    progressBar: {
      progress: 100,
      withContainer: true,
    },
  },
  info: {
    title: 'Review your new loan details',
    subtitle: 'Help',
    content: [
      {
        copy: [
          'Final rate is subject to change. Actual monthly savings, if any, may vary based on interest rates, balance, remaining payment terms and other factors.',
        ],
      },
    ],
  },
};

export default model;
