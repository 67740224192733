import { trim } from 'lodash';
import { ChangeEvent, useCallback, useState } from 'react';
import TagManager from 'react-gtm-module';
import NumberFormat from 'react-number-format';

import { dateToFormValue } from '../../../utils/date/date';
import { formValueToDate } from '../../../utils/date/date';
import { InputFormatter } from '../../../utils/input/inputFormatter';
import {
  FloatingLabel,
  FloatingLabelInput,
  Hint,
  InputContainer,
  StyledInput,
} from './styles';

export const TextControl = (props: any) => {
  const {
    name,
    label,
    defaultValue = '',
    hint,
    validationRules,
    format,
    formatOptions = {},
    control,
    controller: Controller,
    disabled,
    readOnly,
    success,
    autoCompleteObj = {},
    autoFocus = false,
    testId,
    type = 'text',
    onBlur: onBlurHandler = () => {},
    isHidden,
    shouldUnregister,
    shouldTrimValue,
  } = props;
  const [focused, setFocused] = useState(false);
  const [inputFocusedTimestamp, setInputFocusedTimestamp] = useState<Date>();
  const [inputEventName] = useState<string>(label || name || testId);

  const getDuration = useCallback(() => {
    const inputFocusTime = Number(inputFocusedTimestamp);
    const inputBlurTime = Number(new Date());

    return (inputBlurTime - inputFocusTime) / 1000;
  }, [inputFocusedTimestamp]);

  const getInputEventPathname = useCallback(() => {
    const urlPath = window.location.pathname;
    return `${urlPath}__${name}`;
  }, [name]);

  const onFocus = () => {
    setFocused(true);
    setInputFocusedTimestamp(new Date());

    TagManager.dataLayer({
      dataLayer: {
        event: 'Input In',
        'Input In': inputEventName,
        Location: getInputEventPathname(),
      },
    });
  };

  const sendInputOutEvent = useCallback(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Input Out',
        'Input Out': inputEventName,
        'Input Duration': getDuration(),
        Location: getInputEventPathname(),
      },
    });
  }, [inputEventName, getDuration, getInputEventPathname]);

  const valueAsNumber =
    (onChange: any) =>
    ({ value }: any) => {
      onChange(value.length ? parseInt(value, 10) : null);
    };

  const getFormatterProps = (
    inputFormatter: any,
    value: any,
    isFocused: any,
    onChange: any,
  ) => {
    const moneyFrequencySpaces = {
      thousandSeparator: true,
      prefix: '$',
      inputMode: 'numeric',
      decimalScale: 0,
      onValueChange: valueAsNumber(onChange),
    };
    switch (inputFormatter) {
      case InputFormatter.MONEY:
        return {
          thousandSeparator: true,
          prefix: '$',
          inputMode: 'numeric',
          decimalScale: 0,
          // Values will be numbers in model
          // See https://react-hook-form.com/advanced-usage#TransformandParse for details
          onValueChange: valueAsNumber(onChange),
        };
      case InputFormatter.MONEY_MONTHLY_SPACER:
        return {
          ...moneyFrequencySpaces,
          suffix: '/month',
        };
      case InputFormatter.MONEY_ANNUALLY_SPACER:
        return {
          ...moneyFrequencySpaces,
          suffix: '/year',
        };
      case InputFormatter.PHONE_NUMBER:
        return {
          format: '(###) ###-####',
          mask: '_',
          inputMode: 'numeric',
          decimalScale: 0,
        };
      case InputFormatter.PIN:
        return {
          format: '#-#-#-#-#-#',
          mask: '_',
          inputMode: 'numeric',
          decimalScale: 0,
        };
      case InputFormatter.MILEAGE:
        return {
          suffix: ' miles',
          thousandSeparator: true,
          inputMode: 'numeric',
          decimalScale: 0,
          onValueChange: valueAsNumber(onChange),
        };
      case InputFormatter.TERM_SHORT:
        return {
          suffix: value > 1 ? ' months' : ' month',
          inputMode: 'numeric',
          decimalScale: 0,
          onValueChange: valueAsNumber(onChange),
        };
      case InputFormatter.PERCENT:
        // Math.round is added to avoid issues with floating point, like
        // 5.15 / 100 = 0.051500000000000004
        return {
          suffix: '%',
          inputMode: 'decimal',
          value: value && Math.round(value * 10000) / 100,
          onValueChange: ({ value }: any) => {
            const formattedValue = value && Math.round(value * 100) / 10000;
            onChange(formattedValue);
          },
        };
      case InputFormatter.DATE:
        return {
          format: formatOptions.displayFormat || '##/##/####',
          mask: '_',
          placeholder: isFocused
            ? formatOptions.placeholder || 'MM/DD/YYYY'
            : '',
          inputMode: 'numeric',
          decimalScale: 0,
          value: dateToFormValue(
            value,
            formatOptions.displayFormat === '##/####',
          ),
          onValueChange: ({ value }: any) =>
            onChange(
              formValueToDate(value, formatOptions.displayFormat === '##/####'),
            ),
        };
      case InputFormatter.NUMBER:
        return {
          inputMode: 'numeric',
          allowLeadingZeros: true,
          decimalScale: 0,
        };
      case InputFormatter.SSN:
        return {
          format: '###-##-####',
          mask: '_',
          inputMode: 'numeric',
          allowLeadingZeros: true,
          decimalScale: 0,
        };
      default:
        return {};
    }
  };

  const getHint = (error: any) => {
    if (error && error.message) {
      return error.message;
    }
    if (success) {
      return success;
    }
    if (hint) {
      return hint;
    }
    return <>&nbsp;</>;
  };

  return (
    <FloatingLabelInput $isHidden={isHidden}>
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({
          field: { onChange, onBlur, value },
          fieldState: { error, invalid },
        }: any) => {
          const sharedProps = {
            name,
            value,
            placeholder: '',
            onFocus,
            $error: error,
            $value: value,
            $disabled: disabled,
            type,
            onBlur: () => {
              onBlur();
              setFocused(false);
              sendInputOutEvent();
              onBlurHandler(value, invalid);
            },
            autoComplete: autoCompleteObj.autocomplete || 'off',
            autoFocus,
            'data-testid': testId,
          };
          return (
            <>
              <InputContainer $disabled={disabled} $error={error}>
                {format ? (
                  <NumberFormat
                    {...sharedProps}
                    customInput={StyledInput}
                    disabled={disabled || readOnly}
                    onValueChange={(values: any) => {
                      onChange(values.value);
                    }}
                    {...getFormatterProps(format, value, focused, onChange)}
                  />
                ) : (
                  <StyledInput
                    {...sharedProps}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      if (shouldTrimValue) {
                        event.target.value = trim(event.target.value);
                      }

                      onChange(event);
                    }}
                  />
                )}
                <FloatingLabel $labeled={focused || !!value || value === 0}>
                  {label}
                </FloatingLabel>
              </InputContainer>
              <Hint
                $disabled={disabled}
                $error={error}
                $labeled={focused || value}
                $success={success}
                data-testid={`${testId}Error`}
              >
                {getHint(error)}
              </Hint>
            </>
          );
        }}
        rules={validationRules}
        shouldUnregister={shouldUnregister}
      />
    </FloatingLabelInput>
  );
};
