import { ChecklightSessionStatus } from '../../common';
import { ApplicationTaskEnum } from '../../constants/applicationTasks';
import { LoanTaskEnum } from '../../constants/loanTasks';
import { Workflow } from '../../constants/Workflow';
import { ApplicationDataInterface } from '../../pages/Applications/applicationData.interface';
import { getRequiredDocuments, isUploaded } from '../document/documentService';
import { getApplicationTasksPerStatus } from '../status/statusService';
import { TASK_PREVIEW } from './taskPreview';

export const getPrimaryButtonInitialConfig = (
  onClick: () => void,
  name: string,
  children: string,
  hidden?: boolean,
) => ({
  name,
  testId: name,
  children,
  onClick,
  order: 1,
  hidden,
});

export const getSecondaryButtonInitialConfig = (
  onClick: () => void,
  name: string,
  children: string,
) => ({
  name,
  testId: name,
  children,
  onClick,
  order: 2,
});

const NO_TASKS_APPLICATION = {
  title: 'No available tasks.',
  body: `We currently have everything needed to progress your application. Should
  your loan specialist require anything additional, they will contact you
  directly. You may also check back here for updates.`,
};
const NO_TASKS_LOAN = {
  title: 'You’re up to date!',
  body: `We’re always looking for new ways to save you money, so make sure to
  check in from time to time.`,
};

export const NO_TASKS_CONTENT: Record<string, any> = {
  application: {
    [Workflow.checklight]: NO_TASKS_APPLICATION,
    [Workflow.unified]: NO_TASKS_LOAN,
  },
  loan: {
    [Workflow.checklight]: NO_TASKS_LOAN,
    [Workflow.unified]: NO_TASKS_LOAN,
  },
};

export const getTaskPreview = (task: LoanTaskEnum | ApplicationTaskEnum) =>
  TASK_PREVIEW[task];
export const getAvailableTasks = (
  applicationData: ApplicationDataInterface | undefined,
  workflowName: Workflow,
  status: ChecklightSessionStatus,
) => {
  if (!applicationData) {
    return [];
  }

  const tasksPerStatus = getApplicationTasksPerStatus(workflowName);

  return (tasksPerStatus[status as ChecklightSessionStatus] || []).filter(
    (task: ApplicationTaskEnum | LoanTaskEnum) => {
      switch (task) {
        case ApplicationTaskEnum.UploadDocuments: {
          const requiredDocuments = getRequiredDocuments(
            workflowName,
            applicationData,
          );
          return requiredDocuments.some(
            (doc) => !isUploaded(applicationData.assets || [], doc),
          );
        }
        case ApplicationTaskEnum.References:
          return applicationData.references.length < 3;
        default:
          return true;
      }
    },
  );
};
