export type FlowDataStorageConfig = {
  encryptionEnabled: boolean;
  LOCAL_STORAGE_KEY: string;
};

/**
 * This helper defines how to save data depending on config
 * @param encryptionEnabled
 * @param LOCAL_STORAGE_KEY
 */
export const useContextLocalStorage = ({
  encryptionEnabled,
  LOCAL_STORAGE_KEY,
}: FlowDataStorageConfig) => {
  const getWorkflowData = (workflowName: string) => {
    let item =
      localStorage.getItem(`${LOCAL_STORAGE_KEY}:${workflowName}`) || '';
    if (encryptionEnabled) {
      item = window.atob(item);
    }
    return JSON.parse(item);
  };

  const saveWorkflowData = (workflowName: string, data: any) => {
    let dataString = JSON.stringify(data, (key, value) => {
      if (key !== 'ssn') {
        return value;
      }
    });
    if (encryptionEnabled) {
      dataString = btoa(dataString);
    }
    localStorage.setItem(`${LOCAL_STORAGE_KEY}:${workflowName}`, dataString);
  };

  const clearWorkflowData = (workflowName: string) => {
    localStorage.removeItem(`${LOCAL_STORAGE_KEY}:${workflowName}`);
  };

  return { getWorkflowData, saveWorkflowData, clearWorkflowData };
};
