import { isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAsync } from 'react-async-hook';

import { unit } from '../../common/theme';
import { Loader } from '../../components/shared/Loader/Loader';
import { config } from '../../config';
import { useContextLocalStorage } from '../../utils/helpers/contextLocalStorage';

export const withDataCaching = (WrappedComponent: any) => {
  return (props: any) => {
    const { getWorkflowData, saveWorkflowData, clearWorkflowData } =
      useContextLocalStorage(config.flowDataStorage);
    const [context, setContext] = useState<any>();
    const previousContextRef = useRef<any>();

    const workflowData = useAsync(getWorkflowData, [props.workflowName]);

    useEffect(() => {
      // Reload data when using back/forward cache
      window.addEventListener('pageshow', (event) => {
        if (event.persisted) {
          workflowData.execute(props.workflowName);
        }
      });
    }, []);

    useEffect(() => {
      if (workflowData.result) {
        previousContextRef.current = workflowData.result;
      }
    }, [workflowData.result]);

    useEffect(() => {
      if (workflowData.loading) {
        return;
      }
      if (!isEqual(previousContextRef.current, context)) {
        previousContextRef.current = context;
        saveWorkflowData(props.workflowName, context);
      }
    }, [props.workflowName, context]);

    const clearContext = useCallback(() => {
      clearWorkflowData(props.workflowName);
    }, [clearWorkflowData, props.workflowName]);

    return workflowData.loading ? (
      <Loader size={unit[6]} fullScreen />
    ) : (
      <WrappedComponent
        {...props}
        cachedContext={workflowData.result}
        clearContext={clearContext}
        context={context}
        setContext={setContext}
      />
    );
  };
};
