import { merge } from 'lodash';

import CommonReviewModel from '../../../../common/Steps/OffersReview/Model';
import BaseModel from '../common/BaseModel';

const model = merge({}, BaseModel, CommonReviewModel, {
  form: {
    actions: {
      primary: {
        testId: 'saveAndContinue',
        label: 'Save & Continue',
      },
    },
  },
});

export default model;
