import { FooterLink } from '../../../components/PillowForm';
import { PillowType } from '../../../components/shared/PillowImage/pillowTypes';

const model = {
  template: {
    img: {
      type: PillowType.SUCCESS,
    },
    header: {
      showClose: true,
      showBack: false,
    },
    footer: {
      isActive: false,
      links: [FooterLink.ContactUs],
    },
    centerContent: true,
    persistImage: true,
  },
  form: {
    actions: {
      primary: {
        label: 'Continue',
      },
      secondary: {
        label: 'Dashboard',
      },
    },
  },
  headerBlock: {
    title: 'Your documents were submitted for review.',
    paragraph:
      "We'll examine your documents to uncover any extra savings. If any are found, your loan specialist will notify you when walking through your refinance options.",
    subtitle: 'Submitted for review',
  },
};

export default model;
