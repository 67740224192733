import { merge } from 'lodash';

import { PillowType } from '../../../../../components/shared/PillowImage/pillowTypes';
import { fields } from '../../../../../forms/vehicle';
import { Signal } from '../../../../common/constants/signals';
import { AccountState } from '../../common/unified-workflow-context.interface';
import BaseModel from '../common/BaseModel';

const model = (send: any, { context: { coApplicant } }: AccountState) => {
  const progress = coApplicant.coApplicantAnswer ? 63 : 68;

  return merge({}, BaseModel, {
    template: {
      header: {
        showBack: true,
        showClose: false,
        onBack: () => {
          send(Signal.Previous);
        },
      },
      footer: {
        isActive: false,
      },
      img: {
        type: PillowType.VEHICLE_LOOKUP,
      },
    },
    headerBlock: {
      title: 'Select vehicle model',
      progressBar: {
        progress,
        withContainer: true,
      },
      subtitle: ['Account', `${progress}% complete`],
    },
    form: {
      actions: {
        primary: {
          isHidden: true,
        },
      },
      fields: {
        modelId: {
          ...fields.modelId,
          name: 'vehicle.modelId',
        },
      },
    },
    info: {
      title: 'Vehicle model',
      subtitle: 'Help',
      content: [
        {
          copy: [
            "The model of your vehicle is referring to the vehicle's name or range of products. If you are unsure, you can always find this information in your vehicle's owner's manual or sales invoice.",
          ],
        },
      ],
    },
  });
};

export default model;
