import PropTypes from 'prop-types';
import { useContext } from 'react';

import { imageType } from '../../../common';
import { unit } from '../../../common/theme';
import { DynamicBrandingContext } from '../../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import {
  defaultImagesByType,
  getImageByType,
} from '../../../utils/component/vehicle';
import { SmoothImage } from '../SmoothImage/SmoothImage';
import {
  AspectRatioContainer,
  InnerContainer,
  OuterContainer,
  PillowImageContainer,
} from './styles';

const VehicleImagePillowComponent = ({
  isDesktop,
  imageList,
  isLoading,
}: any) => {
  const {
    branding: { theme: $theme },
  } = useContext(DynamicBrandingContext);

  return (
    <OuterContainer $isPillow>
      <AspectRatioContainer>
        <InnerContainer>
          <PillowImageContainer $isDesktop={isDesktop}>
            <SmoothImage
              fallbackSrc={defaultImagesByType[imageType.DEFAULT]}
              isLoading={isLoading}
              loaderConfig={{
                size: unit[4],
                color: $theme.colors.secondaryOverlay,
              }}
              src={getImageByType(imageType.DEFAULT)(imageList, false)}
            />
          </PillowImageContainer>
        </InnerContainer>
      </AspectRatioContainer>
    </OuterContainer>
  );
};

VehicleImagePillowComponent.propTypes = {
  isLoading: PropTypes.bool,
  imageList: PropTypes.array,
  isDesktop: PropTypes.bool,
};

export const VehicleImagePillow = withMatchMedia(VehicleImagePillowComponent);
