import calendarIcon from '../../../../../assets/calendar.png';
import chartIcon from '../../../../../assets/chart.png';
import settingsListIcon from '../../../../../assets/settings-list.png';
import { ImageFitContainer } from '../../../../../components/shared/ImageFitContainer/ImageFitContainer';

export const defaultOptions = [
  {
    label: 'Lower monthly payment',
    value: 'LOWER_PAYMENT',
    image: calendarIcon,
  },
  {
    label: 'Paydown other Debts',
    value: 'PAYDOWN_OTHER_DEBTS',
    image: chartIcon,
  },
  {
    label: 'Take someone off the loan',
    value: 'REMOVE_SOMEONE',
    image: settingsListIcon,
  },
  {
    label: 'Just checking my options',
    value: 'CHECKING_OPTIONS',
    image: settingsListIcon,
  },
];

export const addImagesToOptions = (options: any[] = []) =>
  options.map(({ image, ...option }) => ({
    ...option,
    image: <ImageFitContainer src={image || settingsListIcon} />,
  }));
