import { unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const FormElementWrapper = styled('div', ({ $isHidden }: any) => ({
  ...($isHidden
    ? {
        display: 'none',
      }
    : {}),
  marginBottom: unit[2],
}));
