import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { unit } from '../../common/theme';
import { Loader } from '../../components/shared/Loader/Loader';
import { useWorkflowPathname } from '../../hooks/useWorkflowPathname/useWorkflowPathname';

const getStepFromPath = (pathname: string, routes: any): string | undefined => {
  const internalPath = new RegExp(`/([^/]+)$`).exec(pathname)?.[1] || '';
  return Object.keys(routes).find((step) => routes[step] === internalPath);
};

const getBasicPath = (rootPath: string, pathname: string) => {
  return new RegExp(`^(/${rootPath}(/workflow)?/[^/]+)`).exec(pathname)?.[1];
};

const validateContext = (stepValidators: any, cachedContext: any) => {
  if (stepValidators === undefined) {
    return true;
  }
  const arrValidators = Array.isArray(stepValidators)
    ? stepValidators
    : [stepValidators];

  return arrValidators.every((v) => v(cachedContext));
};

export const withRoutes = (WrappedComponent: any) => {
  return (props: any) => {
    const { pathname, state } = useLocation();
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState<string>('');
    const [send, setSend] = useState<any>();
    const MANUAL_TRANSITION_EVENT = 'MANUAL_TRANSITION';

    const [contextCheckCompleted, setContextCheckCompleted] = useState(false);

    const rootPath = useWorkflowPathname();

    const internalStep = getStepFromPath(pathname, props.routes);

    // This effect handles browser's back/forward button click and page refresh
    // Should not listen to currentStep
    useEffect(() => {
      const internalStep = getStepFromPath(pathname, props.routes);

      // Try to sync step in route with step in xState
      // This check will be run at least once because currentStep is initially undefined
      if (internalStep !== currentStep) {
        // Check if context is valid
        const validators = props.gate.steps.find(
          ({ name }: any) => name === internalStep,
        )?.stepValidators;
        const isValid = validateContext(
          validators,
          props.context || props.cachedContext,
        );

        // If invalid context, force navigate to initial step
        if (!isValid) {
          const updatedPath = [
            getBasicPath(rootPath, pathname),
            props.routes[props.gate.initial],
          ].join('/');
          navigate(updatedPath, { state });
          return;
        }

        setContextCheckCompleted(true);

        // Skip if xState is not yet initialized
        if (!currentStep) {
          return;
        }
        // If valid context send event to change xState
        send?.func(MANUAL_TRANSITION_EVENT, { target: internalStep });
      }
    }, [pathname]);

    // Listen to step change and update path
    // Should not listen to pathname
    useEffect(() => {
      if (!currentStep) {
        return;
      }
      const updatedPath = [
        getBasicPath(rootPath, pathname),
        props.routes[currentStep],
      ].join('/');
      if (updatedPath !== pathname) {
        navigate(updatedPath, { state });
      }
    }, [currentStep]);

    // Adding new event listener for transitioning to any state
    const configurationWithTransitionToAny = useMemo(
      () => ({
        ...props.defaultConfiguration,
        on: {
          [MANUAL_TRANSITION_EVENT]: Object.keys(props.routes).map((step) => {
            return {
              target: step,
              cond: (_: any, event: any) => {
                return event.target === step;
              },
            };
          }),
        },
      }),
      [],
    );

    if (!contextCheckCompleted) {
      return <Loader size={unit[6]} fullScreen />;
    }

    return (
      <WrappedComponent
        {...props}
        defaultConfiguration={configurationWithTransitionToAny}
        initialStep={internalStep}
        setCurrentStep={setCurrentStep}
        setSend={setSend}
      />
    );
  };
};
