/* eslint-disable react-refresh/only-export-components */
import { map } from 'lodash';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useModal } from '../../hooks/useModal/useModal';
import { useNavigationLinks } from '../../hooks/useNavigationLinks/useNavigationLinks';
import { Accordion } from '../shared/Accordion/Accordion';
import { ContactUs } from '../shared/ContactUs/ContactUs';
import { CopyBlock } from '../shared/CopyBlock/CopyBlock';
import { Footer } from '../shared/Footer/Footer';
import { Form } from '../shared/Form/Form';
import { Header } from '../shared/Header/Header';
import { HeaderBlock } from '../shared/HeaderBlock/HeaderBlock';
import { Modal } from '../shared/Modal/Modal';
import { PillowImage } from '../shared/PillowImage/PillowImage';
import { RouterLinks } from '../shared/RouterLinks/RouterLinks';
import { TemplatePillowPage } from '../shared/TemplatePillowPage/TemplatePillowPage';
import { FooterLink, GatePresModelInterface } from './gatePresModel.interface';

export const mapModalContent = (content: any) => {
  return map(content, (con: any, index: number) => {
    const CopyComponent = (
      <CopyBlock
        asList={!!con.asList}
        blockIndex={index}
        copy={con.copy}
        identifier="modalText"
        key={index}
      />
    );
    return con.accordion ? (
      <Accordion key={index} title={con.title}>
        {CopyComponent}
      </Accordion>
    ) : (
      CopyComponent
    );
  });
};

export interface GateFormProps {
  presModel: GatePresModelInterface;
  children?: any;
  beforeHeaderContent?: any;
  beforeFormContent?: any;
  methods?: any;
}

export const GateForm = (props: GateFormProps) => {
  const { presModel, children, beforeHeaderContent, beforeFormContent } = props;

  const {
    template,
    info,
    form,
    headerBlock = {
      title: '',
    },
  } = presModel;

  const {
    header: headerTemplateProps,
    footer,
    img,
    centerContent,
    persistImage,
  } = template || {};

  const { isShowing: isShowingInfo, open: openInfo } = useModal();
  const { isShowing: isShowingContactUs, open: openContactUs } = useModal();

  const navigate = useNavigate();

  const isHiddenFooter = footer?.isHidden;

  const modalContent = useMemo(() => {
    if (!info || !isShowingInfo) {
      return null;
    }

    const {
      title,
      subtitle,
      content,
      buttonGroupPresModel = {
        primaryButton: {
          name: 'back',
          children: 'Back',
          isDisabled: false,
          testId: 'back',
        },
        rtl: true,
      },
    } = info;

    return (
      <Modal
        buttonGroupPresModel={buttonGroupPresModel}
        subtitle={subtitle}
        testId="infoPopup"
        title={title}
      >
        {mapModalContent(content)}
      </Modal>
    );
  }, [info, isShowingInfo]);

  const headerInfoProps = info
    ? {
        showInfo: true,
        onInfoHandler: openInfo,
      }
    : {};

  const navConfig = useNavigationLinks({ isWorkflowMenu: true });

  const headerNavigationConfig = useMemo(() => {
    if (!headerTemplateProps?.showMenu) {
      return {};
    }
    return {
      navConfig,
    };
  }, [headerTemplateProps?.showMenu, navConfig]);

  const headerContent = (
    <Header
      {...{
        ...headerTemplateProps,
        ...headerInfoProps,
        ...headerNavigationConfig,
      }}
    />
  );

  const defaultFooterLinks = [FooterLink.Home, FooterLink.ContactUs];

  const footerLinksConfig = {
    [FooterLink.Home]: {
      testId: 'footerHome',
      label: 'Home',
      onClick: () => {
        navigate('/');
      },
    },
    [FooterLink.ContactUs]: {
      testId: 'footerContactUs',
      label: 'Contact us',
      onClick: openContactUs,
    },
  };

  const footerLinks = (footer?.links || defaultFooterLinks).map(
    (link) => footerLinksConfig[link],
  );

  const footerContent = !isHiddenFooter && (
    <Footer {...footer}>
      <RouterLinks {...{ footerLinks }} />
    </Footer>
  );

  const pillowContent = <PillowImage {...img} />;

  return (
    <TemplatePillowPage
      {...{
        headerContent,
        footerContent,
        pillowContent,
        centerContent,
        persistImage,
        LogoContainerOverflow: headerTemplateProps?.showBack,
      }}
    >
      {beforeHeaderContent}
      <HeaderBlock {...{ ...headerBlock, persistImage }} />
      {beforeFormContent}
      <Form {...{ form }} methods={props.methods}>
        {children}
      </Form>
      {modalContent}
      {isShowingContactUs ? <ContactUs /> : null}
    </TemplatePillowPage>
  );
};
