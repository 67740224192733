import { Navigate } from 'react-router-dom';

import { Workflow } from '../../../../../constants/Workflow';
import { useWorkflowPathname } from '../../../../../hooks/useWorkflowPathname/useWorkflowPathname';

const getDashboardPath = (workflow: Workflow) => {
  return `/${workflow}/dashboard/vehicle/application`;
};

const ExitToDashboardController = ({ context }: any) => {
  const rootPath = useWorkflowPathname();

  return <Navigate state={context} to={getDashboardPath(rootPath)} />;
};

export default ExitToDashboardController;
