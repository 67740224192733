import { styled } from 'styletron-react';

import { theme } from '../../common/theme';

export const LeadChannelLogoContainer = styled('img', {
  marginTop: theme.unit[2],
  width: theme.unit[20],
});

export const DecreaseHeaderFormMargin = styled('div', {
  marginBottom: `-${theme.unit[4]}`,
});
