import { FooterLink } from '../../../../../components/PillowForm';
import { PillowType } from '../../../../../components/shared/PillowImage/pillowTypes';

export const BaseModel = {
  template: {
    header: {
      showClose: false,
      showMenu: true,
    },
    footer: {
      isActive: false,
      links: [FooterLink.ContactUs],
    },
    img: {
      type: PillowType.OFFERS,
    },
  },
};

export default BaseModel;
