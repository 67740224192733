import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const OuterContainer = styled('div', ({ $isPillow }: any) => ({
  position: 'relative',
  ...($isPillow
    ? {
        paddingTop: '100%',
        width: '100%',
      }
    : {}),
}));

export const AspectRatioContainer = styled('div', () => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: '0',
  left: '0',
}));

export const InnerContainer = styled('div', () => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const PillowImageContainer = styled('div', ({ $isDesktop }: any) => ({
  width: $isDesktop ? '85%' : '100%',
  position: 'absolute',
}));
