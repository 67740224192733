import { Navigate } from 'react-router-dom';

import { useWorkflowPathname } from '../../../../../hooks/useWorkflowPathname/useWorkflowPathname';

const ExitToVerification = ({ context }: any) => {
  const rootPath = useWorkflowPathname();

  return (
    <Navigate
      state={{
        ...context,
        navigationSource: `/${rootPath}/dashboard/vehicle/application`,
      }}
      to={`/${rootPath}/workflow/verification/`}
    />
  );
};

export default ExitToVerification;
