import { useEffect, useMemo, useRef, useState } from 'react';

import { Loader } from '../Loader/Loader';
import { ImageContainer, LoadingOverlay } from './styles';

export const SmoothImage = ({
  src,
  alt = 'notFound',
  isLoading,
  loaderConfig = {},
  fallbackSrc = '',
  testId = '',
}: any) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isValidSrc, setIsValidSrc] = useState(!!src);
  const currentSrc = useRef(src);

  const loading = useMemo(
    () => isLoading || imageLoaded !== src,
    [isLoading, imageLoaded, src],
  );

  useEffect(() => {
    if (currentSrc.current !== src) {
      setIsValidSrc(!!src);
      currentSrc.current = src;
    }
  }, [src, currentSrc]);

  return (
    <>
      {loading ? (
        <LoadingOverlay>
          <Loader {...loaderConfig} />
        </LoadingOverlay>
      ) : null}
      {isValidSrc ? (
        <ImageContainer
          $hidden={loading}
          alt={alt}
          data-testid={testId}
          src={src}
          onError={() => setIsValidSrc(false)}
          onLoad={() => setImageLoaded(src)}
        />
      ) : (
        <ImageContainer
          $hidden={loading}
          alt={alt}
          data-testid={testId}
          src={fallbackSrc}
          onError={() => setImageLoaded(src)}
          onLoad={() => setImageLoaded(src)}
        />
      )}
    </>
  );
};
