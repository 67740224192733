import { set } from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PillowForm } from '../../../components/PillowForm';
import { useWorkflowPathname } from '../../../hooks/useWorkflowPathname/useWorkflowPathname';
import useLoanDocumentStore, {
  LoanDocumentType,
} from '../../../store/loanDocumentsStore';
import model from './Model';

const Controller = () => {
  const navigate = useNavigate();
  const workflowName = useWorkflowPathname();

  const {
    loanDocuments,
    setSelectedLoanDocument,
    computed: { listRouteAllowed, currentVehicleId },
  } = useLoanDocumentStore();

  const backClicked = () => {
    navigate(`/${workflowName}/dashboard/vehicle/application`, {
      replace: true,
      state: {
        currentVehicle: { id: currentVehicleId },
      },
    });
  };

  const listOptionClicked = (value: LoanDocumentType) => {
    setSelectedLoanDocument(value);

    if (value === LoanDocumentType.RIC) {
      navigate(`/${workflowName}/loan-documents/retail-contract`);
    }

    if (value === LoanDocumentType.SERVICE_CONTRACT) {
      navigate(`/${workflowName}/loan-documents/service-contract`);
    }

    if (value === LoanDocumentType.GAP_CONTRACT) {
      navigate(`/${workflowName}/loan-documents/gap-contract`);
    }
  };

  useEffect(() => {
    if (!listRouteAllowed) {
      navigate(`/${workflowName}/dashboard`);
    }
  }, []);

  set(model, 'template.header.onBack', backClicked);
  set(model, 'form.actions.primary.handler', backClicked);
  set(model, 'form.fields.documents.options', loanDocuments);
  set(model, 'form.fields.documents.onChange', listOptionClicked);

  return <PillowForm presModel={model} />;
};

Controller.displayName = 'LoanDocumentsList.Controller';
export default Controller;
