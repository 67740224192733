import { createContext } from 'react';

import { Branding } from '../../../common/interfaces/branding/branding';
import { defaultChecklightBranding } from './checklight-branding';

export interface DynamicBrandingContextProps {
  branding: Branding;
  isLoading: boolean;
  failedToRetrieveBranding: boolean;
  changeBrandingState: (
    updatedState: Partial<DynamicBrandingContextProps>,
  ) => void;
}

export const defaultContextValue: DynamicBrandingContextProps = {
  isLoading: true,
  branding: defaultChecklightBranding as Branding,
  failedToRetrieveBranding: false,
  // eslint-disable-next-line
  changeBrandingState: (updatedState) => {},
};

export const DynamicBrandingContext = createContext(defaultContextValue);
