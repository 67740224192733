import { merge, pick } from 'lodash';

import { fields } from '../../../../forms/user';
import { applicantBaseModel } from './ApplicantBaseModel';

export const applicantPersonalInfoBaseModel = merge({}, applicantBaseModel, {
  form: {
    fields: {
      ...pick(fields, ['firstName', 'lastName', 'dob']),
    },
  },
});
