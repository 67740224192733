import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import { Tab } from '../tab/Tab';
import { TabDropdown } from '../tabDropdown/TabDropdown';
import { Container, HorizontalSpacer, TabsContainer } from './styles';

const TabSelectComponent = ({
  tabs,
  loading = false,
  disabled = false,
  onChange,
  positionAbsolute,
  isMobile,
  isSeparated,
}: any) => {
  const isTwoTabs = tabs.length === 2 && !isSeparated;
  const displayDropdown = !isSeparated && !isTwoTabs && isMobile;
  return displayDropdown ? (
    <TabDropdown {...{ tabs, disabled, onChange }} />
  ) : (
    <TabsContainer $isTwoTabs={isTwoTabs}>
      <Container $isTwoTabs={isTwoTabs} $positionAbsolute={positionAbsolute}>
        {tabs &&
          tabs.map((tab: any, i: any) => (
            <React.Fragment key={uniqueId('tab-')}>
              <Tab
                disabled={disabled}
                isSelected={tab.isSelected}
                isTwoTabs={isTwoTabs}
                label={tab.label}
                loading={loading}
                value={tab.value}
                onClick={onChange}
              />
              {i < tabs.length - 1 ? (
                <HorizontalSpacer $isTwoTabs={isTwoTabs} />
              ) : null}
            </React.Fragment>
          ))}
      </Container>
    </TabsContainer>
  );
};

TabSelectComponent.propTypes = {
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      isSelected: PropTypes.bool,
    }),
  ).isRequired,
  positionAbsolute: PropTypes.bool,
};

export const TabSelect = withMatchMedia(TabSelectComponent);
