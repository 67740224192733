import { merge, pick } from 'lodash';

import { populateFields } from '../../../../../forms/populateFields';
import { fields } from '../../../../../forms/purchase';
import { Signal } from '../../../../common/constants/signals';
import { AccountState } from '../../common/unified-workflow-context.interface';
import BaseModel from '../common/BaseModel';

const model = (send: any, { context: { user, coApplicant } }: AccountState) => {
  const formFields = pick(fields, ['purchaseType']);
  const savedFields = populateFields(formFields, user);
  const populatedFields = merge({}, formFields, savedFields);
  const progress = coApplicant.coApplicantAnswer ? 27 : 33;

  return merge({}, BaseModel, {
    template: {
      header: {
        showBack: true,
        onBack: () => {
          send(Signal.Previous);
        },
      },
    },
    headerBlock: {
      progressBar: {
        progress,
        withContainer: true,
      },
      title:
        'Please share the details as to what kind of "purchase" you are making.',
      subtitle: ['Account', `${progress}% complete`],
    },
    form: {
      fields: populatedFields,
      actions: {
        primary: {
          testId: 'continue',
          label: 'Continue',
          handler: (data: any) => {
            send(Signal.Next, { data: { user: merge({}, user, data) } });
          },
        },
      },
    },
    info: {
      title: 'Purchase types',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'We use this information to keep the experience relevant to your needs. Your selection will help us tailor your experience to meet your needs.',
          ],
        },
      ],
    },
  });
};

export default model;
