import { PillowForm } from '../../../../../components/PillowForm';
import { Container } from './styles';

const View = (props: any) => {
  const { vehicleName } = props;
  const vehicleIdentity = <Container>{vehicleName}</Container>;

  return <PillowForm {...props} beforeFormContent={vehicleIdentity} />;
};

View.displayName = 'VehicleLookupConfirm.View';
export default View;
