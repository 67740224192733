import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';

import Hamburger from '../../../assets/hamburger.svg?react';
import HamburgerNotification from '../../../assets/hamburger-notification.svg?react';
import { theme } from '../../../common/theme';
import { Workflow } from '../../../constants/Workflow';
import { useWorkflowPathname } from '../../../hooks/useWorkflowPathname/useWorkflowPathname';
import { DynamicBrandingContext } from '../../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import Logo from '../Logo/Logo';
import { RouterLinks } from '../RouterLinks/RouterLinks';
import { SidebarNavigation } from '../sidebarNavigation/SidebarNavigation';
import {
  LogoContainer,
  MenuContainer,
  MenuIconContainer,
  MenuWrapper,
  NavHeader,
  NavIconContainer,
  SidebarFooterContainer,
} from './styles';
import { withContactModal } from './withContactModal';

const MenuComponent = withMatchMedia(
  ({
    collapsedView,
    navConfig: { navItems: navigationItems, footerItems },
    isDesktop,
    isTablet,
    isMobile,
    sidebarContainerId,
  }: any) => {
    const [showMenuContainer, setShowMenuContainer] = useState(false);
    const {
      branding: { theme: $theme },
    } = useContext(DynamicBrandingContext);
    const root = useWorkflowPathname();
    const isChecklight = root === Workflow.checklight;

    const menuRef = useRef<any>();
    const { pathname } = useLocation();

    useEffect(() => {
      // Close menu on navigation
      setShowMenuContainer(false);
    }, [pathname]);

    useEffect(() => {
      const handleClickOutside = (event: any) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setShowMenuContainer(false);
        }
      };

      if (isDesktop && showMenuContainer && collapsedView) {
        document.addEventListener('mousedown', handleClickOutside);
      } else {
        document.removeEventListener('mousedown', handleClickOutside);
      }
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isDesktop, showMenuContainer, collapsedView]);

    const isMenuOpened = useMemo(() => {
      if (!collapsedView && isDesktop) {
        return true;
      }
      return showMenuContainer;
    }, [showMenuContainer, collapsedView, isDesktop]);

    const sidebarRootEl = document.getElementById(sidebarContainerId);

    const menuContent = (
      <MenuContainer
        $collapsedView={collapsedView}
        $isDesktop={isDesktop}
        $isOpened={isMenuOpened}
        $isTablet={isTablet}
      >
        <div>
          <NavHeader $collapsedView={collapsedView} $isDesktop={isDesktop}>
            <LogoContainer
              $isChecklight={isChecklight}
              $isMobile={isMobile}
              $isTablet={isTablet}
            >
              <Logo isSidebarLogo />
            </LogoContainer>
            <NavIconContainer
              $isDesktop={isDesktop}
              onClick={(e: any) => {
                e.stopPropagation();
                setShowMenuContainer(false);
              }}
            >
              <Hamburger fill={theme.colors.primaryOverlay} />
            </NavIconContainer>
          </NavHeader>
          <SidebarNavigation navigationItems={navigationItems} />
        </div>
        <SidebarFooterContainer>
          <RouterLinks {...{ footerLinks: footerItems, type: 'SIDEBAR' }} />
        </SidebarFooterContainer>
      </MenuContainer>
    );

    const OpenMenuHamburger = navigationItems.some(
      (item: any) => item.notificationCount,
    )
      ? HamburgerNotification
      : Hamburger;

    return (
      <MenuWrapper forwardedRef={menuRef}>
        <MenuIconContainer
          $collapsedView={collapsedView}
          $isDesktop={isDesktop}
          data-testid="menuIcon"
          onClick={() => {
            setShowMenuContainer(!showMenuContainer);
          }}
        >
          <OpenMenuHamburger
            fill={
              collapsedView ? $theme.colors.primary : theme.colors.infoOverlay
            }
          />
        </MenuIconContainer>

        {sidebarRootEl ? createPortal(menuContent, sidebarRootEl) : menuContent}
      </MenuWrapper>
    );
  },
);

export const Menu = withContactModal(MenuComponent);
