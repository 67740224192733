/* eslint-disable react-refresh/only-export-components */
import { merge } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  AuthType,
  DeliveryMechanism,
  HttpStatusCode,
  RateSelectedErrors,
} from '../../common';
import { PillowForm } from '../../components/PillowForm';
import { UserAgreement } from '../../components/shared/UserAgreement/UserAgreement';
import { urlParamsError } from '../../constants/urlParamsError';
import { useAPI } from '../../hooks/useAPI/useAPI';
import { useWorkflowPathname } from '../../hooks/useWorkflowPathname/useWorkflowPathname';
import { SearchParamKey } from '../../services/urlParams/urlParams';
import { urlParamHook } from '../../services/urlParams/urlParamsService';
import model from './Model';

export const mapErrorsToFormFields = (apiErrors: any[]) => {
  const modelFragment: {
    fieldErrors: { primaryPhoneNumber?: string };
    globalErrors: string[];
  } = { fieldErrors: {}, globalErrors: [] };
  apiErrors.forEach((error) => {
    if (error.statusCode === HttpStatusCode.UNPROCESSABLE_ENTITY) {
      modelFragment.fieldErrors.primaryPhoneNumber =
        'Please verify your Phone number';
    } else if (
      error.statusCode === HttpStatusCode.FORBIDDEN &&
      error.message.startsWith('Verification attempts exhausted')
    ) {
      modelFragment.fieldErrors.primaryPhoneNumber =
        'Your account has been temporarily locked';
    } else {
      modelFragment.globalErrors.push(
        'There was an unexpected error attempting to send the verification code, please try again later.',
      );
    }
  });
  return modelFragment;
};

const Controller = (props: any) => {
  const location = useLocation();
  const { state } = location;
  const { primaryPhoneNumber } = state || {};
  const navigate = useNavigate();
  const rootPath = useWorkflowPathname();
  const { savedParam: phoneNumberParam } = urlParamHook(
    SearchParamKey.phoneNumber,
  );
  const { savedParam: errorMessage } = urlParamHook<RateSelectedErrors>(
    SearchParamKey.error,
  );
  const { savedParam: collectAgreeToTerms } = urlParamHook(
    SearchParamKey.collectAgreeToTerms,
  );
  const { savedParam: coApplicantCollectAgreeToTerms } = urlParamHook(
    SearchParamKey.coApplicantCollectAgreeToTerms,
  );
  const isDisplayedApplicantAgreeToTerms = collectAgreeToTerms === 'true';
  const isDisplayedCoApplicantAgreeToTerms =
    coApplicantCollectAgreeToTerms === 'true';
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [isSubmittedWithoutAgreement, setIsSubmittedWithoutAgreement] =
    useState(false);
  const methods = useForm();
  const {
    formState: { isSubmitted },
  } = methods;

  const redirectToAccountSignUp = useCallback(
    () =>
      navigate(`/${rootPath}/sign-up`, {
        replace: true,
        state: { invalidPhoneNumber: undefined },
      }),
    [navigate, rootPath],
  );
  const api = useAPI();

  const isDisplayedAgreeToTerms = useMemo(
    () =>
      isDisplayedApplicantAgreeToTerms || isDisplayedCoApplicantAgreeToTerms,
    [isDisplayedApplicantAgreeToTerms, isDisplayedCoApplicantAgreeToTerms],
  );

  const phoneNumber = useMemo(
    () => primaryPhoneNumber || phoneNumberParam,
    [primaryPhoneNumber, phoneNumberParam],
  );

  const onSubmit = useAsyncCallback(
    async ({ primaryPhoneNumber }: { primaryPhoneNumber: string }) => {
      if (isDisplayedAgreeToTerms && !agreementChecked) {
        return;
      }
      try {
        await api.post('pin/deliver', {
          purpose: AuthType.ACCOUNT_SIGN_IN_USING_PIN,
          deliverWith: DeliveryMechanism.SMS,
          phone: primaryPhoneNumber,
        });
        navigate(`/${rootPath}/sign-in/verify/pin`, {
          state: { primaryPhoneNumber },
        });
      } catch (e: any) {
        if (
          e.find((error: any) => error.statusCode === HttpStatusCode.NOT_FOUND)
        ) {
          navigate(`/${rootPath}/sign-up/`, {
            state: { invalidPhoneNumber: true },
          });
        }
        throw mapErrorsToFormFields(e);
      }
    },
  );

  useEffect(() => {
    if (isDisplayedAgreeToTerms) {
      if (isSubmitted) {
        setIsSubmittedWithoutAgreement(!agreementChecked);
      }
    }
  }, [
    isSubmitted,
    agreementChecked,
    setIsSubmittedWithoutAgreement,
    isDisplayedAgreeToTerms,
  ]);

  const enhancedProps = useMemo(
    () =>
      merge(
        {},
        { presModel: model(rootPath) },
        {
          presModel: {
            template: {
              header: {
                onClose: redirectToAccountSignUp,
                showClose: !onSubmit.loading,
              },
            },
            form: {
              globalErrors: [urlParamsError[errorMessage]],
              actions: {
                primary: {
                  handler: onSubmit.execute,
                  isDisabled: onSubmit.loading,
                },
                secondary: {
                  handler: redirectToAccountSignUp,
                  isDisabled: onSubmit.loading,
                },
              },
              fields: {
                primaryPhoneNumber: {
                  disabled: onSubmit.loading,
                  value: phoneNumber,
                },
              },
              ...onSubmit.error,
            },
          },
        },
        props,
        onSubmit.loading
          ? {
              presModel: {
                form: {
                  fields: {
                    primaryPhoneNumber: {
                      hint: 'Delivering verification code...',
                    },
                  },
                },
              },
            }
          : {},
      ),
    [
      redirectToAccountSignUp,
      onSubmit,
      props,
      rootPath,
      phoneNumber,
      errorMessage,
    ],
  );

  return (
    <PillowForm {...enhancedProps} methods={methods}>
      {isDisplayedAgreeToTerms && (
        <UserAgreement
          agreementChecked={agreementChecked}
          isSubmittedWithoutAgreement={isSubmittedWithoutAgreement}
          setAgreementChecked={setAgreementChecked}
        />
      )}
    </PillowForm>
  );
};

Controller.displayName = 'SignIn.Controller';
export default Controller;
