import PropTypes from 'prop-types';

import { Container } from './styles';

export const Link = ({ children, ...attr }: Record<any, any>) => {
  return <Container {...attr}>{children}</Container>;
};

Link.propTypes = {
  attr: PropTypes.shape({
    href: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
    type: PropTypes.string,
  }),
};

export { LinkType } from './styles';
