import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { HousingInfoEdit as EditComponent } from '../../../components/HousingInfoEdit/HousingInfoEdit';
import { EditPageHeader } from '../../../components/shared/PageHeader/EditPageHeader';

export const HousingEdit = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <EditPageHeader
        breadCrumbs={['Account', 'Housing']}
        handleGoBack={() => navigate('..')}
        title="Edit your housing details."
      />
      <EditComponent />
    </Fragment>
  );
};
