import { Fragment, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { IncomeType } from '../../common';
import { ADDITIONAL_INCOME } from '../../constants/choices/checklight';
import { useCurrentUserContext } from '../../hooks/useCurrentUserContext/useCurrentUserContext';
import { incomeTypeLabels } from '../../utils/helpers/income/common';
import { Link, LinkType } from '../shared/link/link';
import { PanelList, PanelListType } from '../shared/PanelList/PanelList';
import { currencyFormatOptions, getIncomeValue } from './config';
import { LinkWrapper } from './styles';

export const Income = () => {
  const [currentUserContext] = useCurrentUserContext();
  const navigate = useNavigate();

  const { current, additional = [] } = currentUserContext.incomes || {};

  const panelListConfig = useMemo(() => {
    const currentIncomeConfig = {
      id: current?.id,
      testId: `${IncomeType.PRIMARY_INCOME}-0`,
      panelType: PanelListType.TEXT,
      label: incomeTypeLabels[IncomeType.PRIMARY_INCOME],
      value: !current?.amount ? null : getIncomeValue(current),
      ...currencyFormatOptions(current?.employerName || ''),
    };

    const additionalIncomePanels = additional.map((income, index) => ({
      id: income.id,
      testId: `${IncomeType.ADDITIONAL_INCOME}-${index + 1}`,
      panelType: PanelListType.TEXT,
      label: incomeTypeLabels[IncomeType.ADDITIONAL_INCOME],
      value: !income.amount ? null : getIncomeValue(income),
      ...currencyFormatOptions(
        ADDITIONAL_INCOME.find((i) => i.value === income.additionalIncomeType)
          ?.label ||
          income.employerName ||
          '',
      ),
    }));

    return [currentIncomeConfig].concat(additionalIncomePanels);
  }, [current, additional]);

  const onEdit = useCallback(
    (panelIndex: any) => {
      navigate('edit', {
        state: {
          id: panelListConfig[panelIndex].id,
          type:
            panelIndex === 0
              ? IncomeType.PRIMARY_INCOME
              : IncomeType.ADDITIONAL_INCOME,
        },
      });
    },
    [navigate, panelListConfig],
  );

  return (
    <Fragment>
      <PanelList
        defaultValue="Not provided"
        panelList={panelListConfig}
        editable
        onEdit={(itemIndex: number) => onEdit(itemIndex)}
      />
      <LinkWrapper>
        <Link
          type={LinkType.PRIMARY}
          onClick={() =>
            navigate('add', {
              state: { type: IncomeType.ADDITIONAL_INCOME },
            })
          }
        >
          + Add new income
        </Link>
      </LinkWrapper>
    </Fragment>
  );
};
