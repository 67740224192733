import { forIn, get } from 'lodash';

export class CancelledApplicationUrlModel {
  public addressLine1 = '';
  public addressLine2 = '';
  public city = '';
  public email = '';
  public firstName = '';
  public income = '';
  public lastName = '';
  public leadChannel = '';
  public mobileNumber = '';
  public state = '';
  public vehicleMake = '';
  public vehicleModel = '';
  public vehicleTrim = '';
  public vehicleYear = '';
  public zip = '';

  get fastTrackSearchParams(): Record<string, string> {
    const searchParams: Record<string, string> = {};

    forIn(this.fastTrackKeyMap, (value, key) => {
      if (get(this, key)) {
        searchParams[value] = get(this, key);
      }
    });

    return searchParams;
  }

  get fastTrackKeyMap(): Record<string, string> {
    return {
      addressLine1: 'address_line_1',
      addressLine2: 'address_line_2',
      city: 'city',
      email: 'email',
      firstName: 'first_name',
      income: 'annual_income',
      lastName: 'last_name',
      leadChannel: 'leadChannel',
      mobileNumber: 'mobile_number',
      state: 'state',
      vehicleMake: 'vehicle_make',
      vehicleModel: 'vehicle_model',
      vehicleTrim: 'vehicle_trim',
      vehicleYear: 'vehicle_year',
      zip: 'zip',
    };
  }
}
