import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useWorkflowPathname } from '../hooks/useWorkflowPathname/useWorkflowPathname';

/**
 * Redirect to the dashboard if no state
 * This guard is to restrict access to pages by direct link
 */
const StateGuard = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const { state }: any = location;
  const [isValid, setValid] = useState<boolean>();
  const navigate = useNavigate();
  const workflowRootPath = useWorkflowPathname();

  useEffect(() => {
    if (!state) {
      navigate('/' + workflowRootPath);
    }
    setValid(Boolean(state));
  }, []);
  return isValid ? children : null;
};

export default StateGuard;
