import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { UserVehicle } from '../../common/interfaces/vehicle/vehicle.interface';
import { DashboardGrid } from '../../components/shared/dashboardGrid/dashboardGrid';
import { MainLayoutHeaderTitle } from '../../components/shared/MainLayoutStyles/styles';
import { Workflow } from '../../constants/Workflow';
import { useCurrentUserContext } from '../../hooks/useCurrentUserContext/useCurrentUserContext';
import { useWorkflowPathname } from '../../hooks/useWorkflowPathname/useWorkflowPathname';
import { getApplicationTasksPerStatus } from '../../services/status/statusService';
import { getTaskPreview } from '../../services/task/taskService';
import { HeaderContainer, VehicleTitle } from './styles';

const Dashboard = () => {
  const [currentUserContext] = useCurrentUserContext();
  const navigate = useNavigate();
  const workflowName = useWorkflowPathname();

  const onVehicleView = useCallback(
    (vehicle: any) => {
      navigate('vehicle', {
        state: {
          currentVehicle: vehicle,
        },
      });
    },
    [navigate],
  );

  const tasksPerStatus = getApplicationTasksPerStatus(workflowName);

  const { userVehicles = [] } = currentUserContext;

  const getVehicleTasksPreview = useCallback(
    (vehicle: UserVehicle) => {
      const DEFAULT_CTA = 'View';

      if (workflowName === Workflow.checklight) {
        return DEFAULT_CTA;
      }
      const currentApplicationStatus = vehicle.checklightSessions[0]?.status;
      const openTasks = tasksPerStatus[currentApplicationStatus] || [];

      if (openTasks.length > 1) {
        return `${openTasks.length} tasks available`;
      }
      const taskPreview = getTaskPreview(openTasks[0]);

      return taskPreview || DEFAULT_CTA;
    },
    [tasksPerStatus, workflowName],
  );

  const userVehicleList = userVehicles.map((v) => {
    const image = (
      (v.imageList || []).find(
        (img: any) => img.vehicleImageType === 'DEFAULT',
      ) || {}
    ).url;

    return {
      title: `${v.year} ${v.make} ${v.model}`,
      subtitle: v.trim,
      image,
      footerContent: <VehicleTitle>{getVehicleTasksPreview(v)}</VehicleTitle>,
      onClick: () => onVehicleView({ ...v, image }),
    };
  });

  return (
    <Fragment>
      <HeaderContainer>
        <MainLayoutHeaderTitle>Garage</MainLayoutHeaderTitle>
      </HeaderContainer>
      <DashboardGrid items={userVehicleList} />
    </Fragment>
  );
};

export default Dashboard;
