import { get } from 'lodash';

import { ApplicationDataInterface } from '../../pages/Applications/applicationData.interface';
import { UserContextT } from '../../providers/context/CurrentUserContext/CurrentUserContextT';
import { CancelledApplicationUrlModel } from './CancelledApplicationUrlModel';

export class CancelledApplicationUrlMapper {
  public toDomain(
    state: ApplicationDataInterface,
    user: UserContextT,
  ): CancelledApplicationUrlModel {
    const model = new CancelledApplicationUrlModel();

    model.addressLine1 = get(
      user,
      'residences.current.physicalAddress.lineOne',
      '',
    );
    model.addressLine2 = get(
      user,
      'residences.current.physicalAddress.lineTwo',
      '',
    );
    model.city = get(user, 'residences.current.physicalAddress.city', '');
    model.email = get(user, 'primaryEmailAddress', '');
    model.firstName = get(user, 'firstName', '');
    model.income = String(get(user, 'incomes.current.amount', ''));
    model.lastName = get(user, 'lastName', '');
    model.leadChannel = this.defaultLeadChannel(
      get(state, 'branding.brandName', ''),
    );
    model.mobileNumber = get(user, 'primaryPhoneNumber', '');
    model.state = get(user, 'residences.current.physicalAddress.province', '');
    model.vehicleMake = get(state, 'currentVehicle.make', '');
    model.vehicleModel = get(state, 'currentVehicle.model', '');
    model.vehicleTrim = get(state, 'currentVehicle.trim', '');
    model.vehicleYear = get(state, 'currentVehicle.year', '');
    model.zip = get(user, 'residences.current.physicalAddress.postalCode', '');

    return model;
  }

  private defaultLeadChannel(brandName: string): string {
    if (brandName === 'RateGenius') {
      return 'RATEGENIUS_FAST_TRACK_API';
    }

    if (brandName === 'Tresl') {
      return 'TRESL_FAST_TRACK_API';
    }

    return 'AUTOPAY_FAST_TRACK_API';
  }
}
