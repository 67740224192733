import i18n from 'i18next';
import { cloneDeep, compact } from 'lodash';

import { ProgressStep, ProgressStepName } from './applicationProgress';

const COMPLETE_APPLICATION_STATUSES = ['NEW_LEAD', 'WORKING_LEAD'];

const SUBMITTED_TO_LENDER_STATUSES = [
  'RETURN_FROM_UW',
  'UW_PROCESSING',
  'APPLICATION_COMPLETE',
  'APPLICATION_COMPLETE_PORTAL',
  'APPLICATION_AT_LENDER',
];

const APPROVED_BY_LENDER_STATUSES = ['APPROVED_BY_LENDER'];

const PREPARING_CONTRACTS_STATUSES = ['CONTRACT_REQUESTED', 'RECONTRACTING'];

const SIGNING_CONTRACTS_STATUSES = [
  'RETURNED_BY_CONTRACTING',
  'DOCS_WITH_CUSTOMER',
  'EDOCS_SIGNED',
];

const REVIEWING_DOCUMENTS_STATUSES = [
  'EDOCS_SIGNED_TRACKING',
  'QA_HOLD',
  'RETURNED_BY_QA',
  'ARCHIVE',
];

const FINALIZING_STIPS_STATUSES = ['QA'];

const WAITING_ON_FUNDING_STATUSES = [
  'AT_LENDER_FOR_FUNDING',
  'AT_LENDER_FUNDING_PENDING',
  'FUNDING_DELAYED',
  'FUNDING_HOLD',
  'DEAL_RETURNED_BY_LENDER',
];

const LENDER_FUNDED_STATUSES = [
  'LENDER_FUNDED',
  'LENDER_FUNDED_PENDING',
  'AUTOPAY_FUNDED',
  'TITLE_QA',
  'PAYOFF_SENT',
  'PERFECTING_TITLE_AUTOPAY',
  'PERFECTING_TITLE_DEALER',
];

/**
 * An object that maps each step in the application progress to its corresponding details.
 * Each key in the object is a an enum value representing a step in the progress.
 * The value for each key contains:
 *
 * - `applicationStatuses`: an array of application statuses that correspond to this step
 * - `completed`: a boolean indicating whether this step has been completed
 * - `description`: a localized string that describes this step
 * - `title`: a localized string that provides a title for this step
 */
const steps: Record<ProgressStepName, ProgressStep> = {
  [ProgressStepName.NEW_APPLICATION]: {
    applicationStatuses: [],
    completed: false,
    description: i18n.t('applicationProgress.newApplication.description'),
    title: i18n.t('applicationProgress.newApplication.title'),
  },
  [ProgressStepName.COMPLETE_APPLICATION]: {
    applicationStatuses: COMPLETE_APPLICATION_STATUSES,
    completed: false,
    description: i18n.t('applicationProgress.completeApplication.description'),
    title: i18n.t('applicationProgress.completeApplication.title'),
  },
  [ProgressStepName.SUBMITTED_TO_LENDER]: {
    applicationStatuses: SUBMITTED_TO_LENDER_STATUSES,
    completed: false,
    description: i18n.t('applicationProgress.submittedToLender.description'),
    title: i18n.t('applicationProgress.submittedToLender.title'),
  },
  [ProgressStepName.APPROVED_BY_LENDER]: {
    applicationStatuses: APPROVED_BY_LENDER_STATUSES,
    completed: false,
    description: i18n.t('applicationProgress.approvedByLender.description'),
    title: i18n.t('applicationProgress.approvedByLender.title'),
  },
  [ProgressStepName.PREPARING_CONTRACTS]: {
    applicationStatuses: PREPARING_CONTRACTS_STATUSES,
    completed: false,
    description: i18n.t('applicationProgress.preparingContracts.description'),
    title: i18n.t('applicationProgress.preparingContracts.title'),
  },
  [ProgressStepName.SIGNING_CONTRACTS]: {
    applicationStatuses: SIGNING_CONTRACTS_STATUSES,
    completed: false,
    description: i18n.t('applicationProgress.signingContracts.description'),
    title: i18n.t('applicationProgress.signingContracts.title'),
  },
  [ProgressStepName.REVIEWING_DOCUMENTS]: {
    applicationStatuses: REVIEWING_DOCUMENTS_STATUSES,
    completed: false,
    description: i18n.t('applicationProgress.reviewingDocuments.description'),
    title: i18n.t('applicationProgress.reviewingDocuments.title'),
  },
  [ProgressStepName.FINALIZING_STIPS]: {
    applicationStatuses: FINALIZING_STIPS_STATUSES,
    completed: false,
    description: i18n.t('applicationProgress.finalizingStips.description'),
    title: i18n.t('applicationProgress.finalizingStips.title'),
  },
  [ProgressStepName.WAITING_ON_FUNDING]: {
    applicationStatuses: WAITING_ON_FUNDING_STATUSES,
    completed: false,
    description: i18n.t('applicationProgress.waitingOnFunding.description'),
    title: i18n.t('applicationProgress.waitingOnFunding.title'),
  },
  [ProgressStepName.LENDER_FUNDED]: {
    applicationStatuses: LENDER_FUNDED_STATUSES,
    completed: false,
    description: i18n.t('applicationProgress.lenderFunded.description'),
    title: i18n.t('applicationProgress.lenderFunded.title'),
  },
};

/**
 *  Represents the sequence of steps in the application progress.
 *  The order of the elements in the array determines the order of the steps in the application progress.
 */
const progressSteps: ProgressStep[] = [
  steps[ProgressStepName.NEW_APPLICATION],
  steps[ProgressStepName.COMPLETE_APPLICATION],
  steps[ProgressStepName.SUBMITTED_TO_LENDER],
  steps[ProgressStepName.APPROVED_BY_LENDER],
  steps[ProgressStepName.PREPARING_CONTRACTS],
  steps[ProgressStepName.SIGNING_CONTRACTS],
  steps[ProgressStepName.REVIEWING_DOCUMENTS],
  steps[ProgressStepName.FINALIZING_STIPS],
  steps[ProgressStepName.WAITING_ON_FUNDING],
  steps[ProgressStepName.LENDER_FUNDED],
];

/**
 * Retrieves the progress steps based on the application status.
 * If the application status is provided, the function marks the previous steps as completed
 * and marks the current step as completed if it is the last step.
 * If the application status is not provided, the function returns all the progress steps.
 *
 * @param applicationStatus - The current application status.
 * @returns An array of progress steps.
 */
export const getStepsByApplicationStatus = (
  applicationStatus: string | null | undefined,
): ProgressStep[] => {
  const steps = cloneDeep(progressSteps);

  if (!applicationStatus) {
    return steps;
  }

  // find the current step index
  const currentStepIndex = steps.findIndex((step) =>
    step.applicationStatuses.includes(applicationStatus),
  );

  // mark all previous steps as completed
  if (currentStepIndex !== -1) {
    steps.slice(0, currentStepIndex).forEach((step) => {
      step.completed = true;
    });
  }

  // current step is the last step. mark the last step as completed
  if (currentStepIndex === steps.length - 1) {
    steps[steps.length - 1].completed = true;
  }

  return steps;
};

/**
 * Retrieves the preview steps based on the application status.
 * If the application status is provided, the function marks the previous step as completed
 * and returns the current step and the next step.
 * If the application status is not provided, the function returns an empty array.
 *
 * @param applicationStatus - The current application status.
 * @returns An array of preview steps.
 */
export const getPreviewStepsByApplicationStatus = (
  applicationStatus: string | null | undefined,
): ProgressStep[] => {
  const steps = cloneDeep(progressSteps);

  if (!applicationStatus) {
    return [];
  }

  // find the current step index
  const currentStepIndex = steps.findIndex((step) =>
    step.applicationStatuses.includes(applicationStatus),
  );

  // current step could not be found
  if (currentStepIndex === -1) {
    return [];
  }

  const prevStep = currentStepIndex > 0 ? steps[currentStepIndex - 1] : null;
  const currentStep = steps[currentStepIndex];
  const nextStep =
    currentStepIndex < steps.length - 1 ? steps[currentStepIndex + 1] : null;

  // assign previous step before current to be completed
  if (prevStep) {
    prevStep.completed = true;
  }

  // current step is the last step. mark the last step as completed
  if (currentStepIndex === steps.length - 1) {
    currentStep.completed = true;
  }

  return compact([prevStep, currentStep, nextStep]);
};
