import { forIn } from 'lodash';

import { CancelledApplicationUrlModel } from './CancelledApplicationUrlModel';

export class CancelledApplicationUrlFactory {
  static create(url: string, model: CancelledApplicationUrlModel): string {
    const redirectUrl = new URL('unified', url);

    forIn(model.fastTrackSearchParams, (value: string, key: string): void => {
      redirectUrl.searchParams.append(key, value);
    });

    return redirectUrl.href;
  }
}
