export enum TaskName {
  APPLICANT = 'APPLICANT',
  VERIFICATIONS = 'VERIFICATIONS',
  COMPLETE = 'COMPLETE',
  CANCELED = 'CANCELED',
}

export type Task = {
  actionTitle: string;
  description: string;
  destination: string | null;
  inactive: boolean;
  title: string;
  setDestination: (pathname: string) => void;
};
