import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import { PlaceholderLoader } from '../placeholderLoader/PlaceholderLoader';
import {
  ContentContainer,
  FooterContent,
  ImageContainerDesktop,
  ImageContainerMobile,
  LeftContentContainer,
  ListItemContainer,
  LoaderContainer,
  LoadersContainer,
  TextContainer,
} from './styles';

const LoadingContent = () => (
  <LoadersContainer>
    <LoaderContainer>
      <PlaceholderLoader />
    </LoaderContainer>
    <LoaderContainer>
      <PlaceholderLoader width="50%" />
    </LoaderContainer>
  </LoadersContainer>
);

const ListItemLoadingComponent = ({
  image,
  isMobile = false,
  imageOnTopForMobile,
}: any) => {
  const layoutWithFooter = isMobile && imageOnTopForMobile;
  const imageOnDesktop = Boolean(image && !isMobile);

  return (
    <ListItemContainer data-testid="ListItemLoading" disabled>
      {isMobile && imageOnTopForMobile && image ? (
        <ImageContainerMobile>{image}</ImageContainerMobile>
      ) : null}
      <ContentContainer>
        <LeftContentContainer $isMobile={isMobile}>
          {imageOnDesktop ? (
            <ImageContainerDesktop>{image}</ImageContainerDesktop>
          ) : null}
          <TextContainer
            $layoutWithFooter={layoutWithFooter}
            $noImage={!imageOnDesktop}
          >
            <LoadingContent />
          </TextContainer>
        </LeftContentContainer>
      </ContentContainer>
      {layoutWithFooter ? (
        <FooterContent>
          <LoaderContainer>
            <PlaceholderLoader width="50%" />
          </LoaderContainer>
        </FooterContent>
      ) : null}
    </ListItemContainer>
  );
};

export const ListItemLoading = withMatchMedia(ListItemLoadingComponent);
