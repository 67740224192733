import { theme } from '../../common/theme';
import { styled } from '../../providers/context/StyletronProvider/withStyletronProvider';

export const HeaderContainer = styled('div', ({ $theme }: any) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexShrink: 0,
  borderBottom: `1px solid ${$theme.colors.secondaryOverlay}`,
  margin: `${theme.unit[11]} 0 ${theme.unit[6]}`,
  paddingBottom: theme.unit[6],
}));

export const VehicleTitle = styled('span', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: 'calc(100% - 30px)',
});
