import PropTypes from 'prop-types';

import { BackgroundOverlay, Container, LabelContainer } from './styles';

export const Tab = ({
  disabled = false,
  loading = false,
  isSelected,
  label,
  onClick,
  value,
  isTwoTabs,
}: any) => {
  const handleClick = () => onClick(value);
  // const [hoverRef, hovered] = useHover();
  return (
    <Container
      $isSelected={isSelected}
      $isTwoTabs={isTwoTabs}
      $loading={loading}
      disabled={loading || disabled}
      onClick={handleClick}
      // ref={hoverRef}
    >
      <BackgroundOverlay
        $disabled={disabled}
        $isSelected={isSelected}
        // $hovered={hovered}
      />
      <LabelContainer>{label}</LabelContainer>
    </Container>
  );
};

Tab.propTypes = {
  disabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  isTwoTabs: PropTypes.bool,
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};
