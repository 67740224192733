import { useContext, useMemo } from 'react';

import ChecklightSidebarLogo from '../../../assets/checklight-sidebar-logo.svg';
import LogoDefault from '../../../assets/logo-default.svg';
import SidebarLogoDefault from '../../../assets/sidebar-logo-default.svg';
import { ImageAsset } from '../../../common/interfaces/branding/branding';
import { unit } from '../../../common/theme';
import { Workflow } from '../../../constants/Workflow';
import { useWorkflowPathname } from '../../../hooks/useWorkflowPathname/useWorkflowPathname';
import { DynamicBrandingContext } from '../../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { Icon } from '../Icon/Icon';
import { IconTypes } from '../Icon/iconTypes';
import { LogoContainer } from './styles';

const getMediaUrl = (file: ImageAsset): string => {
  return file?.url || '';
};

const Logo = ({ isSidebarLogo }: { isSidebarLogo?: boolean }) => {
  const {
    branding: { logo, sidebarLogo },
  } = useContext(DynamicBrandingContext);
  const rootPath = useWorkflowPathname();
  const isChecklight = rootPath === Workflow.checklight;

  const defaultLogo = useMemo(
    () => (isSidebarLogo ? SidebarLogoDefault : LogoDefault),
    [isSidebarLogo],
  );

  const logoUrl = useMemo(() => {
    if (isSidebarLogo && sidebarLogo) {
      return getMediaUrl(sidebarLogo);
    }
    return getMediaUrl(logo);
  }, [isSidebarLogo, sidebarLogo, logo]);

  if (isChecklight) {
    if (isSidebarLogo) {
      return <LogoContainer src={ChecklightSidebarLogo} />;
    }
    return <Icon height={unit[3]} name={IconTypes.CL} width={unit[15]} />;
  }

  return (
    <LogoContainer
      src={logoUrl || defaultLogo}
      onError={(e: any) => (e.target.src = defaultLogo)}
    />
  );
};

export default Logo;
