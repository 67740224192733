import { merge } from 'lodash';

import { populateFields } from '../../../../forms/populateFields';
import { UserIncomes } from '../../../../providers/context/CurrentUserContext/CurrentUserContextT';
import { getPreviousIncomeValidateFn } from '../common/utils';

export const populatePreviousIncomesInfo = (fields: any, data: any) => {
  return merge({}, populateFields(fields, data), {
    startDate: {
      validationRules: {
        validate: getPreviousIncomeValidateFn(data.incomes.current.startDate),
      },
    },
  });
};

export const onSubmit =
  (initialIncomes: UserIncomes) => (send: any) => (data: any) => {
    const incomes = {
      previous: {
        ...initialIncomes.previous,
        ...data.incomes.previous,
      },
    };
    send('Next', {
      data: {
        incomes,
      },
    });
  };
