export const vehicleProducts = {
  GAP: 'GAP',
  INSURANCE: 'INSURANCE',
};

export function yearList(length = 10) {
  const startYear = new Date().getFullYear() + 1;
  return [...Array(length + 2)].map((v, i) => `${startYear - i}`);
}

export enum VehicleValues {
  GOOD = 'GOOD',
  AVERAGE = 'AVERAGE',
  POOR = 'POOR',
}

// DEFAULT for 3/4 front image, PROFILE for side-view image
export enum imageType {
  DEFAULT = 'DEFAULT',
  PROFILE = 'PROFILE',
}

export const MILEAGE_PER_MONTH = 1000;
