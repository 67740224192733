export type YesOrNo = 'YES' | 'NO';

export const YES: YesOrNo = 'YES';

export const NO: YesOrNo = 'NO';

export const yesOrNoOptions = [YES, NO];

export const yesOrNoMap: Record<YesOrNo, boolean> = {
  NO: false,
  YES: true,
};
