import { LoanTaskEnum } from '../../../constants/loanTasks';
import { Gate } from '../../common/interfaces/gate.interface';
import { OffersContext } from '../../common/interfaces/offers.interface';
import GapController from '../../common/Steps/OffersGap/Controller';
import { defaultServiceOptions } from '../../common/utils/offers.utils';
import { getOffers } from './services';
import { Step } from './steps';
import ExitToApplicant from './Steps/ExitToApplicant/Controller';
import ExitToDashboard from './Steps/ExitToDashboard/Controller';
import ExitToNoOffers from './Steps/ExitToNoOffers/Controller';
import LookupModel from './Steps/Lookup/Model';
import GapModel from './Steps/OffersGap/Model';
import ReviewController from './Steps/OffersReview/Controller';
import ReviewModel from './Steps/OffersReview/Model';
import SelectController from './Steps/Select/Controller';
import SelectModel from './Steps/Select/Model';
import defaultConfiguration from './XState/Model';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const states = defaultConfiguration.states!;

export const OFFERS_GATE: Gate<OffersContext> = {
  name: 'unified-default-offers',
  initial: Step.Lookup,
  showRoutes: true,
  cacheData: false,
  accessibleForTask: LoanTaskEnum.ViewOffers,
  serviceOptions: {
    ...defaultServiceOptions,
    services: (api: any) => ({
      getOffers: (ctx: OffersContext) => getOffers(ctx, api),
    }),
  },
  steps: [
    {
      name: Step.Lookup,
      state: states[Step.Lookup],
      model: LookupModel,
      path: '',
    },
    {
      name: Step.SelectOffer,
      state: states[Step.SelectOffer],
      model: SelectModel,
      controller: SelectController,
      path: 'select',
      stepValidators: (context: OffersContext) => !!context?.offers,
    },
    {
      name: Step.Gap,
      state: states[Step.Gap],
      model: GapModel,
      controller: GapController,
      path: 'gap',
      stepValidators: (context: OffersContext) => !!context?.offers,
    },
    {
      name: Step.Review,
      state: states[Step.Review],
      model: ReviewModel,
      controller: ReviewController,
      path: 'review',
      stepValidators: (context: OffersContext) => !!context?.offers,
    },
    {
      name: Step.ExitToDashboard,
      state: states[Step.ExitToDashboard],
      controller: ExitToDashboard,
    },
    {
      name: Step.ExitToApplicantGate,
      state: states[Step.ExitToApplicantGate],
      controller: ExitToApplicant,
    },
    {
      name: Step.ExitToNoOffers,
      state: states[Step.ExitToNoOffers],
      controller: ExitToNoOffers,
    },
  ],
};
