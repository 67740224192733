import { theme, transitions, unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

const getHintColor = (labeled: any, error: any, success: any, $theme: any) => {
  if (error) {
    return $theme.colors.error;
  }
  if (success) {
    return $theme.colors.success;
  }
  if (labeled) {
    return $theme.colors.primary;
  }
  return $theme.colors.primaryActive;
};

const getInputColor = (
  error: any,
  disabled: any,
  hasValue: any,
  $theme: any,
) => {
  if (disabled) {
    return $theme.colors.disabled;
  }
  if (error) {
    return $theme.colors.error;
  }
  if (hasValue) {
    return $theme.colors.infoOverlay;
  }
  return $theme.colors.primaryActive;
};

export const FloatingLabelInput = styled('div', ({ $isHidden }: any) => ({
  ...($isHidden
    ? {
        display: 'none',
      }
    : {}),
  width: '100%',
  marginBottom: unit[2],
}));

export const FloatingLabel = styled('div', ({ $labeled, $theme }: any) => ({
  ...theme.fonts.hint,
  ...transitions.default,
  position: 'absolute',
  pointerEvents: 'none',
  top: $labeled ? 0 : theme.unit[3],
  color: $theme.colors.primaryActive,
  ...($labeled ? {} : { fontSize: theme.fonts.headingLarge.fontSize }),
  cursor: 'text',
  'pointer-events': 'none',
}));

export const InputContainer = styled('div', ({ $error, $theme }: any) => ({
  ...transitions.default,
  width: '100%',
  position: 'relative',
  borderBottom: `0.0625rem solid ${
    $error ? $theme.colors.error : $theme.colors.primaryActive
  }`, // 1px
}));

export const Hint = styled(
  'div',
  ({ $labeled, $error, $success, $theme }: any) => ({
    ...theme.fonts.hint,
    color: getHintColor($labeled, $error, $success, $theme),
  }),
);

export const StyledInput = styled(
  'input',
  ({ $error, $disabled, $value, $theme }: any) => ({
    ...theme.fonts.headingLarge,
    width: '100%',
    color: getInputColor($error, $disabled, $value, $theme),
    backgroundColor: $theme.colors.primaryOverlay,
    border: 'none',
    outline: 'none',
    marginTop: theme.unit[3],
    paddingBottom: theme.unit[1],
    paddingRight: theme.unit[2],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    boxSizing: 'border-box',
    WebkitTextFillColor: getInputColor($error, $disabled, $value, $theme),
    opacity: 1,
    '::placeholder': {
      color: $theme.colors.primaryActive,
      WebkitTextFillColor: $theme.colors.primaryActive,
    },
  }),
);
