import { merge } from 'lodash';
import { useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { populateFields } from '../../../../forms/populateFields';
import { useAPI } from '../../../../hooks/useAPI/useAPI';
import { useCurrentUserContext } from '../../../../hooks/useCurrentUserContext/useCurrentUserContext';
import { withMatchMedia } from '../../../../providers/context/MatchMediaProvider/withMatchMedia';
import { editIncomeForm } from '../../../../utils/helpers/income/editIncomeForm';
import { FormConfirmModal } from '../../../FormConfirmModal/FormConfirmModal';
import { Form } from '../../../shared/Form/Form';
import { FormContainer } from './styles';

const EditPrimaryIncomeComponent = ({ isDesktop }: { isDesktop?: boolean }) => {
  const navigate = useNavigate();
  const [currentUserContext, setCurrentUserContext] = useCurrentUserContext();
  const api = useAPI();
  const methods = useForm();

  const updateUserData = useAsyncCallback((data) => {
    return api
      .put('/users/current', {
        incomes: {
          ...data,
          additional: currentUserContext.incomes?.additional,
        },
      })
      .then((user: any) => {
        setCurrentUserContext({ ...currentUserContext, ...user });
        navigate('..');
      });
  });

  const enhancedForm = useMemo(() => {
    return {
      ...editIncomeForm,
      fields: merge(
        {},
        editIncomeForm.fields,
        populateFields(editIncomeForm.fields, currentUserContext.incomes),
      ),
      actions: {
        ...editIncomeForm.actions,
        primary: {
          ...editIncomeForm.actions.primary,
          handler: updateUserData.execute,
          isDisabled: updateUserData.loading,
        },
        secondary: {
          ...editIncomeForm.actions.secondary,
          handler: () => navigate('..'),
          isDisabled: updateUserData.loading,
        },
      },
      globalErrors: updateUserData.error,
    };
  }, [
    updateUserData.execute,
    updateUserData.loading,
    updateUserData.error,
    currentUserContext,
    navigate,
  ]);

  return (
    <FormContainer $isDesktop={isDesktop}>
      <Form form={enhancedForm} methods={methods} />
      <FormConfirmModal formFields={enhancedForm.fields} methods={methods} />
    </FormContainer>
  );
};

export const EditPrimaryIncome = withMatchMedia(EditPrimaryIncomeComponent);
