import licensePlateIcon from '../../../../../assets/Vehicle-License-Plate.svg';
import makeModelIcon from '../../../../../assets/Vehicle-Make-Model.svg';
import vinIcon from '../../../../../assets/Vehicle-Vin-Number.svg';

export const VehicleInformationSource = {
  Vin: 'Vin',
  MakeAndModel: 'MakeAndModel',
  LicensePlate: 'LicensePlate',
};

export const optionsList = (includeLicensePlateSearch?: boolean) => [
  {
    value: VehicleInformationSource.MakeAndModel,
    label: 'Make & Model',
    testId: VehicleInformationSource.MakeAndModel,
    image: makeModelIcon,
  },
  ...(includeLicensePlateSearch
    ? [
        {
          value: VehicleInformationSource.LicensePlate,
          label: 'License Plate',
          testId: VehicleInformationSource.LicensePlate,
          image: licensePlateIcon,
        },
      ]
    : []),
  {
    value: VehicleInformationSource.Vin,
    label: 'VIN Lookup',
    testId: VehicleInformationSource.Vin,
    image: vinIcon,
  },
];
