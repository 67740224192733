import { FooterLink } from '../../components/PillowForm';
import { PillowType } from '../../components/shared/PillowImage/pillowTypes';
import { fields } from '../../forms/user';

const model = {
  template: {
    header: {
      showBack: false,
      showClose: false,
    },
    footer: {
      isActive: false,
      links: [FooterLink.ContactUs],
    },
    img: {
      type: PillowType.ACCOUNT,
    },
    centerContent: true,
    persistImage: true,
  },
  headerBlock: {
    title: '',
    paragraph: '',
  },
  form: {
    actions: {
      primary: {
        testId: 'signIn',
        label: 'Confirm',
      },
      secondary: {
        label: 'Sign up',
        testId: 'signUp',
      },
    },
    fields: {
      primaryPhoneNumber: {
        testId: 'primaryPhoneNumber',
        hint: 'We will send you a 6-digit verification code',
        ...fields.primaryPhoneNumber,
      },
    },
  },
};

export default model;
