import { TabsWrapper } from './styles';
import { TabSelect } from './TabSelect';

export const Tabs = ({
  tabs,
  selectedTab,
  navigate,
  siblingRouteDepth = '../../',
  isSeparated,
  isPositionAbsolute = true,
}: {
  tabs: string[];
  selectedTab: string | undefined;
  navigate: any;
  siblingRouteDepth?: string;
  isSeparated?: any;
  isPositionAbsolute?: any;
}) => {
  const tabsWithSelected = tabs.map((item, index) => ({
    label: item,
    value: index,
    isSelected: item === selectedTab,
  }));

  return (
    <TabsWrapper>
      <TabSelect
        isSeparated={isSeparated}
        positionAbsolute={isPositionAbsolute}
        tabs={tabsWithSelected}
        onChange={(tab: number) => {
          navigate(siblingRouteDepth + tabsWithSelected[tab].label);
        }}
      />
    </TabsWrapper>
  );
};
