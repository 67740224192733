import { merge } from 'lodash';

import { Signal } from '../../../../common/constants/signals';
import { AccountContext } from '../../common/unified-workflow-context.interface';
import BaseModel from '../common/BaseModel';

type AnyFunc = (...args: any[]) => any;

const model = (send: AnyFunc, { context }: { context: AccountContext }) =>
  merge({}, BaseModel, {
    template: {
      header: {
        showBack: false,
      },
      centerContent: true,
      persistImage: true,
    },
    form: {
      actions: {
        primary: {
          testId: 'getStarted',
          label: 'Get started',
          handler: () => {
            send(Signal.Next);
          },
        },
        secondary: {
          name: 'signIn',
          label: 'Sign In',
          testId: 'signIn',
          handler: () => {
            send(Signal.ExitToSignIn);
          },
        },
      },
      globalErrors: [
        ...(context.invalidPhoneNumber
          ? [
              'Sorry, we were not able to verify your phone number. Please start by creating an account.',
            ]
          : []),
      ],
    },
    headerBlock: {
      title: "Welcome! Let's start by creating your account.",
      subtitle: ['Sign Up', '2 mins'],
    },
  });

export default model;
