export enum PillowType {
  VEHICLE_LOOKUP = 'VEHICLE_LOOKUP',
  VEHICLE_IMG = 'VEHICLE_IMG',
  SUCCESS = 'SUCCESS',
  ACCOUNT = 'ACCOUNT',
  OFFERS = 'OFFERS',
  OFFERS_ERROR = 'OFFERS_ERROR',
  PERSONAL_DATA = 'PERSONAL_DATA',
  REVIEW = 'REVIEW',
  CO_APPLICANT = 'CO_APPLICANT',
  REFERENCE = 'REFERENCE',
  CONTRACT = 'CONTRACT',
  VERIFICATION = 'VERIFICATION',
  NO_OFFERS = 'NO_OFFERS',
  ERROR = 'ERROR',
}
