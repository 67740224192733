import { merge } from 'lodash';

import { Form } from '../../../../../forms/types/Form';
import { RadioFormField } from '../../../../../forms/types/RadioFormField';
import { SubtitleFormField } from '../../../../../forms/types/SubtitleFormField';
import { TextFromField } from '../../../../../forms/types/TextFormField';
import { fields } from '../../../../../forms/user';
import { Signal } from '../../../../common/constants/signals';
import { AccountState } from '../../common/unified-workflow-context.interface';
import BaseModel from '../common/BaseModel';
import { optionsList } from './options';

const model = (send: any, { context: { user, coApplicant } }: AccountState) => {
  const accountNameFields = {
    firstName: new TextFromField(fields.firstName),
    lastName: new TextFromField(fields.lastName),
    subtitle: new SubtitleFormField({
      label: 'Will you be adding a co-applicant to the loan?',
      name: 'subtitle',
    }),
    coApplicantAnswer: new RadioFormField({
      name: 'coApplicantAnswer',
      options: optionsList,
      defaultValue: false,
      largeTitle: true,
    }),
  };
  const form = new Form({
    fields: accountNameFields,
    actions: {
      primary: {
        handler: (data: any) => {
          const { coApplicantAnswer, ...userData } = data;
          send(Signal.Next, {
            data: {
              user: merge({}, user, userData),
              coApplicant: {
                ...(coApplicantAnswer ? coApplicant : {}),
                coApplicantAnswer,
              },
            },
          });
        },
      },
    },
    autoFocus: true,
  });

  form.populateFields({
    ...user,
    ...coApplicant,
  });
  return merge({}, BaseModel, {
    template: {
      header: {
        showBack: true,
        onBack: () => {
          send(Signal.Previous);
        },
      },
    },
    headerBlock: {
      progressBar: {
        progress: 9,
        withContainer: true,
      },
      title: 'Who are we helping today?',
      subtitle: ['Account', '9% complete'],
    },
    form,
    info: {
      title: 'Applicant name',
      subtitle: 'Help',
      content: [
        {
          copy: [
            "By providing us with the most basic and correct info, we'll be able to provide you with the most accurate quotes, keep up-to-date on your current loan, and even notify you in the future should something even better come available.",
          ],
        },
      ],
    },
  });
};

export default model;
