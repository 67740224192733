import {
  AGREEMENT_STATUSES,
  RELATIONSHIP_STATUS_CHOICES,
} from '../constants/choices/checklight';
import { componentTypes } from '../constants/componentTypes';
import type { FormFieldsModelT } from './formFieldsModelT';

export const fields: FormFieldsModelT = {
  coApplicantRelationship: {
    label: 'Relationship to applicant',
    name: 'relationship',
    component: componentTypes.DROPDOWN,
    options: RELATIONSHIP_STATUS_CHOICES,
    validationRules: {
      required: 'Co-applicant relationship is required',
    },
  },
  sameAsApplicant: {
    label: 'Same as applicant',
    name: 'residences.sameAsApplicant',
    component: componentTypes.DROPDOWN,
    options: [
      { label: 'Yes', value: AGREEMENT_STATUSES.YES },
      { label: 'No', value: AGREEMENT_STATUSES.NO },
    ],
    validationRules: {
      required: 'Co-applicant relationship is required',
    },
  },
};
