import { theme } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const Loader = styled(
  'div',
  ({ $height = theme.unit[2], $width = '100%', $theme }: any) => {
    const bgColor = $theme.colors.tertiaryOverlay;
    const shimmerColor = $theme.colors.secondaryActive;

    return {
      height: $height,
      width: $width,
      backgroundColor: bgColor,
      backgroundImage: `linear-gradient(left, ${bgColor} 0%, ${shimmerColor} 20%, ${bgColor} 40%, ${bgColor} 100%)`,
      backgroundSize: '200% 100%',
      borderRadius: '0.125rem', // 2px
      animationDuration: '1s',
      animationIterationCount: 'infinite',
      animationName: {
        from: {
          backgroundPosition: '100% 0',
        },
        to: {
          backgroundPosition: '-100% 0',
        },
      },
    };
  },
);
