import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import { ButtonGroup, ButtonGroupProps } from '../ButtonGroup/ButtonGroup';
import {
  ButtonGroupContainer,
  Card,
  CardDescription,
  CardTitle,
  ContentContainer,
  Image,
  ImageContainer,
} from './styles';

const ActionCardComponent = ({
  isDesktop,
  isMobile,
  isTablet,
  isVerticallyAligned,
  title,
  description,
  buttonGroup,
  imageSrc,
}: {
  isDesktop?: boolean;
  isMobile?: boolean;
  isTablet?: boolean;
  isVerticallyAligned?: boolean;
  title: string;
  description: string;
  buttonGroup: ButtonGroupProps;
  imageSrc?: string;
}) => {
  return (
    <Card
      $isMobile={isMobile}
      $isVerticallyAligned={isVerticallyAligned}
      data-testid="actionCard"
    >
      <ImageContainer
        $isMobile={isMobile}
        $isTablet={isTablet}
        $isVerticallyAligned={isVerticallyAligned}
      >
        {imageSrc && <Image src={imageSrc} />}
      </ImageContainer>
      <ContentContainer $isMobile={isMobile}>
        <CardTitle>{title}</CardTitle>
        <CardDescription title={description}>{description}</CardDescription>

        <ButtonGroupContainer
          $isDesktop={isDesktop}
          $isVerticallyAligned={isVerticallyAligned || isMobile}
        >
          <ButtonGroup
            {...{
              ...buttonGroup,
              isVerticallyAligned: isVerticallyAligned || isMobile,
            }}
          />
        </ButtonGroupContainer>
      </ContentContainer>
    </Card>
  );
};

export const ActionCard = withMatchMedia(ActionCardComponent);
