import { PillowType } from '../../../../components/shared/PillowImage/pillowTypes';
import { componentTypes } from '../../../../constants/componentTypes';

const model = {
  template: {
    header: {
      showBack: true,
      showClose: false,
    },
    footer: {
      isHidden: true,
    },
    img: {
      type: PillowType.OFFERS,
    },
  },
  form: {
    isStickyActions: true,
    fields: {
      offers: {
        name: 'gap',
        component: componentTypes.RADIO,
        largeTitle: true,
        imageOnTopForMobile: true,
      },
    },
    actions: {
      primary: {
        testId: 'select',
        label: 'Select',
        preserveLabel: true,
        preserveTestId: true,
      },
    },
  },
  info: {
    title: 'Consider GAP coverage',
    subtitle: 'Help',
    content: [
      {
        copy: [
          'Guaranteed Asset Protection, also known as GAP, covers the financial gap between the actual cash value of a vehicle and the payoff amount of the loan or lease in the event of a total loss.',
        ],
      },
      {
        asList: true,
        copy: [
          'Covers the vehicles insurance deductibles up to $1,000',
          'Protection for vehicles valued up to $100,000',
          'Covers losses up to $50,000',
        ],
      },
    ],
  },
};

export default model;
