import { useCallback, useMemo } from 'react';

import { componentTypes } from '../../../constants/componentTypes';
import { ListItemSelect } from '../listItemSelect/ListItemSelect';
import { FormElementWrapper } from './styles';

export const ListControl = (props: any) => {
  const {
    name,
    defaultValue = '',
    // hint,
    validationRules,
    onChange = () => {},
    control,
    controller: Controller,
    value,
    onActionSubmit,
    isHidden,
    ...otherProps
    // success,
  } = props;

  const multiSelect = useMemo(
    () => otherProps.component === componentTypes.MULTI_SELECT,
    [otherProps],
  );
  const handleChange = useCallback(
    (v: any, restField: any) => {
      let newValue = v;
      if (multiSelect) {
        const currentValue = new Set(restField.value || []);
        if (currentValue.has(v)) {
          currentValue.delete(v);
        } else {
          currentValue.add(v);
        }
        newValue = Array.from(currentValue);
      }
      restField.onChange(newValue);
      if (typeof onChange === 'string' && onChange === 'submit') {
        onActionSubmit();
        return;
      }
      onChange(newValue);
    },
    [multiSelect, onChange, onActionSubmit],
  );

  return (
    <FormElementWrapper $isHidden={isHidden}>
      <Controller
        control={control}
        defaultValue={value !== undefined ? value : defaultValue}
        name={name}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        render={({ field: { ref, ...restField } }: any) => (
          <>
            <ListItemSelect
              {...restField}
              {...otherProps}
              onChange={(v) => handleChange(v, restField)}
            />
          </>
        )}
        rules={validationRules}
      />
    </FormElementWrapper>
  );
};
