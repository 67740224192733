import { merge } from 'lodash';

import EditAdditionalIncomeModel from '../EditAdditionalIncome/Model';

const model = merge({}, EditAdditionalIncomeModel, {
  headerBlock: {
    subtitle: ['Co-Applicant', '100% complete'],
    progressBar: {
      progress: 100,
      withContainer: true,
    },
  },
});

export default model;
