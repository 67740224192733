import { Fragment } from 'react';

import { unit } from '../../../common/theme';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { MainLayoutHeaderTitle } from '../MainLayoutStyles/styles';
import { Tabs } from '../tabSelect/Tabs';
import { HeaderContainer } from './styles';

export const PageHeader = ({
  navigate,
  breadCrumbs,
  title,
  tabs,
  selectedTab,
  isSeparatedTabs,
  tabsPositionAbsolute,
  tabsSiblingRouteDepth,
  hideBorderBottom = true,
}: {
  navigate: any;
  breadCrumbs?: string[];
  title: string;
  tabs: any;
  selectedTab: string;
  isSeparatedTabs?: boolean;
  tabsPositionAbsolute?: boolean;
  tabsSiblingRouteDepth?: string;
  hideBorderBottom?: boolean;
}) => {
  return (
    <Fragment>
      <HeaderContainer $hideBorderBottom={hideBorderBottom}>
        {breadCrumbs && <Breadcrumbs pagesList={breadCrumbs} />}
        <MainLayoutHeaderTitle style={{ marginTop: unit[1] }}>
          {title}
        </MainLayoutHeaderTitle>
      </HeaderContainer>
      <Tabs
        isPositionAbsolute={tabsPositionAbsolute}
        isSeparated={isSeparatedTabs}
        navigate={navigate}
        selectedTab={selectedTab}
        siblingRouteDepth={tabsSiblingRouteDepth}
        tabs={tabs}
      />
    </Fragment>
  );
};
