export enum Filename {
  DRIVERS_LICENSE_FRONT = 'DRIVERS_LICENSE_FRONT',
  DRIVERS_LICENSE_BACK = 'DRIVERS_LICENSE_BACK',
  PHOTO_OF_ODOMETER = 'PHOTO_OF_ODOMETER',
  PROOF_OF_INSURANCE = 'PROOF_OF_INSURANCE',
  PROOF_OF_INCOME = 'PROOF_OF_INCOME',
  VEHICLE_REGISTRATION = 'VEHICLE_REGISTRATION',
  PAYOFF_LETTER = 'PAYOFF_LETTER',
  ADDITIONAL_DOCUMENT = 'ADDITIONAL_DOCUMENT',
}

export enum DocumentFile {
  DRIVERS_LICENSE_FRONT_APPLICANT = 'DRIVERS_LICENSE_FRONT_APPLICANT',
  DRIVERS_LICENSE_FRONT_CO_APPLICANT = 'DRIVERS_LICENSE_FRONT_CO_APPLICANT',
  DRIVERS_LICENSE_BACK_APPLICANT = 'DRIVERS_LICENSE_BACK_APPLICANT',
  DRIVERS_LICENSE_BACK_CO_APPLICANT = 'DRIVERS_LICENSE_BACK_CO_APPLICANT',
  PROOF_OF_INCOME = 'PROOF_OF_INCOME',
  PROOF_OF_INSURANCE = 'PROOF_OF_INSURANCE',
  REGISTRATION_CARD = 'REGISTRATION_CARD',
  PHOTO_OF_ODOMETER = 'PHOTO_OF_ODOMETER',
  PAYOFF_LETTER = 'PAYOFF_LETTER',
  ADDITIONAL_DOCUMENT = 'ADDITIONAL_DOCUMENT',
}

interface DocumentFileConfigInterface {
  documentSubType: string;
  documentSubtypeName: string;
  label: string;
  filename: Filename;
  assetDescription: string;
  uploadIdentifier: string;
  gtm?: {
    event: string;
    description: string;
  };
}

export const DOCUMENTS_FILE_CONFIG: Record<
  DocumentFile,
  DocumentFileConfigInterface
> = {
  [DocumentFile.DRIVERS_LICENSE_FRONT_APPLICANT]: {
    documentSubType: 'DRIVERS_LICENSE_ONE',
    documentSubtypeName: "Driver's License 1",
    label: 'Front of ID',
    filename: Filename.DRIVERS_LICENSE_FRONT,
    assetDescription: `APEX Asset - APPLICANT - ${Filename.DRIVERS_LICENSE_FRONT}`,
    uploadIdentifier: 'Front of ID',
    gtm: {
      event: 'uploaded-license-front',
      description: 'Uploaded driver’s license to CP',
    },
  },
  [DocumentFile.DRIVERS_LICENSE_FRONT_CO_APPLICANT]: {
    documentSubType: 'DRIVERS_LICENSE_ONE',
    documentSubtypeName: "Driver's License 2",
    label: 'Front of ID',
    filename: Filename.DRIVERS_LICENSE_FRONT,
    assetDescription: `APEX Asset - CO_APPLICANT - ${Filename.DRIVERS_LICENSE_FRONT}`,
    uploadIdentifier: 'Co-applicant Front of ID',
    gtm: {
      event: 'uploaded-license-front-co-applicant',
      description: 'Uploaded driver’s license to CP',
    },
  },
  [DocumentFile.DRIVERS_LICENSE_BACK_APPLICANT]: {
    documentSubType: 'DRIVERS_LICENSE_TWO',
    documentSubtypeName: 'Other',
    label: 'Back of ID',
    filename: Filename.DRIVERS_LICENSE_BACK,
    assetDescription: `APEX Asset - APPLICANT - ${Filename.DRIVERS_LICENSE_BACK}`,
    uploadIdentifier: 'Back of ID',
    gtm: {
      event: 'uploaded-license-back',
      description: 'Uploaded driver’s license to CP',
    },
  },
  [DocumentFile.DRIVERS_LICENSE_BACK_CO_APPLICANT]: {
    documentSubType: 'DRIVERS_LICENSE_TWO',
    documentSubtypeName: 'Other',
    label: 'Back of ID',
    filename: Filename.DRIVERS_LICENSE_BACK,
    assetDescription: `APEX Asset - CO_APPLICANT - ${Filename.DRIVERS_LICENSE_BACK}`,
    uploadIdentifier: 'Co-applicant Back of ID',
    gtm: {
      event: 'uploaded-license-back-co-applicant',
      description: 'Uploaded driver’s license to CP',
    },
  },
  [DocumentFile.PROOF_OF_INCOME]: {
    documentSubType: 'PROOF_OF_INCOME_ONE',
    documentSubtypeName: 'Proof of Income 1',
    label: 'Proof of Income',
    filename: Filename.PROOF_OF_INCOME,
    assetDescription: `APEX Asset - APPLICANT - ${Filename.PROOF_OF_INCOME}`,
    uploadIdentifier: 'Proof of Income',
  },
  [DocumentFile.PROOF_OF_INSURANCE]: {
    documentSubType: 'PROOF_OF_INSURANCE',
    documentSubtypeName: 'Proof of Insurance',
    label: 'Proof of Insurance',
    filename: Filename.PROOF_OF_INSURANCE,
    assetDescription: `APEX Asset - APPLICANT - ${Filename.PROOF_OF_INSURANCE}`,
    uploadIdentifier: 'Proof of Insurance',
    gtm: {
      event: 'uploaded-insurance',
      description: 'Uploaded proof of insurance to CP',
    },
  },
  [DocumentFile.REGISTRATION_CARD]: {
    documentSubType: 'COPY_OF_VEHICLE_REGISTRATION',
    documentSubtypeName: 'Copy of Vehicle Registration',
    label: 'Registration Card',
    filename: Filename.VEHICLE_REGISTRATION,
    assetDescription: `APEX Asset - APPLICANT - ${Filename.VEHICLE_REGISTRATION}`,
    uploadIdentifier: 'Registration Card',
    gtm: {
      event: 'uploaded-registration',
      description: 'Uploaded copy of registration card to CP',
    },
  },
  [DocumentFile.PHOTO_OF_ODOMETER]: {
    documentSubType: 'ODOMETER_READING',
    documentSubtypeName: 'Odometer Reading',
    label: 'Photo of Odometer',
    filename: Filename.PHOTO_OF_ODOMETER,
    assetDescription: `APEX Asset - APPLICANT - ${Filename.PHOTO_OF_ODOMETER}`,
    uploadIdentifier: 'Photo of Odometer',
    gtm: {
      event: 'uploaded-odometer',
      description: 'Uploaded odometer disclosure to CP',
    },
  },
  [DocumentFile.PAYOFF_LETTER]: {
    documentSubType: 'OTHER_PROVIDED_DOCUMENT',
    documentSubtypeName: 'Other',
    label: 'Payoff Letter',
    filename: Filename.PAYOFF_LETTER,
    assetDescription: `APEX Asset - APPLICANT - ${Filename.PAYOFF_LETTER}`,
    uploadIdentifier: 'Payoff Letter',
  },
  [DocumentFile.ADDITIONAL_DOCUMENT]: {
    documentSubType: 'OTHER_PROVIDED_DOCUMENT',
    documentSubtypeName: 'Other',
    label: 'Additional Documents',
    filename: Filename.ADDITIONAL_DOCUMENT,
    get assetDescription() {
      return `APEX Asset - APPLICANT - ${
        Filename.ADDITIONAL_DOCUMENT
      }_${Date.now()}`;
    },
    uploadIdentifier: 'Additional Documents',
    gtm: {
      event: 'uploaded-additional',
      description: 'Uploaded additional documents to CP',
    },
  },
};
