import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { isApplicationActive } from '../../common';
import { DynamicBrandingContext } from '../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { useCurrentUserContext } from '../useCurrentUserContext/useCurrentUserContext';
import { useWorkflowPathname } from '../useWorkflowPathname/useWorkflowPathname';
import navigationItems from './model';

export const useNavigationLinks = ({
  isWorkflowMenu,
}: { isWorkflowMenu?: boolean } = {}) => {
  const [currentUserContext] = useCurrentUserContext();
  const navigate = useNavigate();
  const rootPath = useWorkflowPathname();

  const { branding } = useContext(DynamicBrandingContext);

  const numberOfApplications = useMemo(() => {
    const { userVehicles = [] } = currentUserContext;
    return userVehicles.reduce((acc, vehicle) => {
      const session = vehicle.checklightSessions?.[0];
      return acc + (session && isApplicationActive(session) ? 1 : 0);
    }, 0);
  }, [currentUserContext]);

  const navItems = useMemo(() => {
    const internalRoutes = (navigationItems[rootPath] || []).map((item) => {
      return {
        ...item,
        route: `/${rootPath}/${item.route}`,
        ...(item.value === 'applications'
          ? {
              notificationCount: numberOfApplications,
            }
          : {}),
      };
    });

    const configurableRoutes = branding?.navigation?.map((item: any) => ({
      ...item,
      // Contentful links are external by default
      external: item.external !== undefined ? item.external : true,
    }));

    return internalRoutes.concat(configurableRoutes || []);
  }, [numberOfApplications, branding, rootPath]);

  const footerItems = [
    ...(!isWorkflowMenu
      ? [
          {
            testId: 'sidebarContactUs',
            label: 'Contact us',
          },
        ]
      : []),
    {
      testId: 'sidebarSignOut',
      label: 'Sign out',
      onClick: () =>
        navigate('/' + rootPath + '/sign-out', {
          state: {
            manually: true,
          },
        }),
    },
  ];

  return { navItems, footerItems };
};
