import { Navigate, NavigateProps, useLocation } from 'react-router-dom';

/**
 * Wrapper for React Router Navigate which saves location.state
 * @param props NavigateProps
 * @returns Navigate
 */
export const NavigateWithState = (props: NavigateProps) => {
  const location = useLocation();

  return <Navigate {...props} state={location.state} />;
};
