import lowestInterestRate from '../../../assets/Lowest-Interest-rate.svg';
import lowestMonthlyPayment from '../../../assets/Lowest-Monthly-Payment.svg';
import options from '../../../assets/Options3.svg';
import { UnifiedWorkflowDynamicConfiguration } from '../../assembly/UnifiedDefaultSignUp/common/unified-workflow-context.interface';

export const unifiedWorkflowDefaultConfiguration: UnifiedWorkflowDynamicConfiguration =
  {
    primaryGoalsList: [
      {
        image: lowestMonthlyPayment,
        label: 'Lower monthly payment',
        value: 'LOWER_PAYMENT',
      },
      {
        image: lowestInterestRate,
        label: 'Paydown other Debts',
        value: 'PAYDOWN_OTHER_DEBTS',
      },
      {
        image: options,
        label: 'Take someone off the loan',
        value: 'REMOVE_SOMEONE',
      },
      {
        image: options,
        label: 'Just checking my options',
        value: 'CHECKING_OPTIONS',
      },
    ],
    showEstimatedPayoffAmount: true,
  };
