import { pick } from 'lodash';
import { Fragment, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  dobErrors,
  emailErrors,
} from '../../../components/PersonalInformationEdit/config';
import { EditPhoneNumber } from '../../../components/PersonalInformationEdit/EditPhoneNumber';
import { PersonalInformationEdit as EditComponent } from '../../../components/PersonalInformationEdit/PersonalInformationEdit';
import { EditPageHeader } from '../../../components/shared/PageHeader/EditPageHeader';
import { lsPrefix } from '../../../config';
import { fields } from '../../../forms/user';

const fieldsConfig = [
  {
    fields: pick(fields, ['firstName', 'lastName']),
  },
  {},
  {
    fields: pick(fields, ['primaryEmailAddress']),
    globalErrors: emailErrors,
  },
  {
    fields: pick(fields, ['dob']),
    globalErrors: dobErrors,
  },
];

export const PersonalInformationEdit = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const itemIndex = location.state?.itemIndex;

  const personalDataTypeLsKey = `${lsPrefix}:editPersonalDataType`;
  const personalDataType = useMemo(
    () =>
      itemIndex ??
      parseInt(localStorage.getItem(personalDataTypeLsKey) as string),
    [itemIndex, personalDataTypeLsKey],
  );

  useEffect(() => {
    if (personalDataType >= 0) {
      localStorage.setItem(personalDataTypeLsKey, personalDataType);
    } else {
      navigate('..');
    }
    return () => {
      localStorage.removeItem(personalDataTypeLsKey);
    };
  }, []);

  return (
    <Fragment>
      <EditPageHeader
        breadCrumbs={['Account', 'Personal']}
        handleGoBack={() => navigate('..')}
        title="Edit your personal details."
      />
      {personalDataType >= 0 &&
        (personalDataType === 1 ? (
          <EditPhoneNumber />
        ) : (
          <EditComponent fieldsConfig={fieldsConfig[personalDataType]} />
        ))}
    </Fragment>
  );
};
