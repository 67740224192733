export const DEFAULT_PREVIOUS_INCOME_MONTHS_AT = 6;
export const DEFAULT_PREVIOUS_RESIDENCE_MONTHS_AT = 12;

export const HALF_YEAR_IN_MONTHS_AS_NUMBER = 6;

export const HALF_YEAR_IN_MONTHS_AS_STRING =
  HALF_YEAR_IN_MONTHS_AS_NUMBER.toString();

export const ONE_YEAR_IN_MONTHS_AS_NUMBER = 12;

export const ONE_YEAR_IN_MONTHS_AS_STRING =
  ONE_YEAR_IN_MONTHS_AS_NUMBER.toString();

export const TWO_YEARS_IN_MONTHS_AS_NUMBER = 24;

export const TWO_YEARS_IN_MONTHS_AS_STRING =
  TWO_YEARS_IN_MONTHS_AS_NUMBER.toString();
