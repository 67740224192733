import LiveApplicationHeroImg from '../../assets/hero-communication.svg';
import HeroCommunicationImg from '../../assets/hero-communication.svg';
import NoOffersHeroImg from '../../assets/hero-meditation.svg';
import HeroPaintingImg from '../../assets/hero-painting.svg';
import OfferSelectHeroImg from '../../assets/hero-with-bags.svg';
import ApplicantHeroImg from '../../assets/hero-with-camera.svg';
import ReviewHeroImg from '../../assets/hero-with-laptop.svg';
import CoApplicantHeroImg from '../../assets/heroes-selfie.svg';
import { ApplicationTaskEnum } from '../../constants/applicationTasks';
import { LoanTaskEnum } from '../../constants/loanTasks';
import { Workflow } from '../../constants/Workflow';
import { ApplicationDataInterface } from '../../pages/Applications/applicationData.interface';
import { DOCUMENTS_CONFIG } from '../document/documentsConfig';
import { getRequiredDocuments } from '../document/documentService';

interface ModalContent {
  title: string;
  body: {
    asList?: boolean;
    copy: string[];
  }[];
}

const BrandedUrls: Record<string, string | undefined> = {
  AUTOPAY: import.meta.env.VITE_APP_FASTTRACK_URL_AUTOPAY,
  RateGenius: import.meta.env.VITE_APP_FASTTRACK_URL_RATEGENIUS,
  Tresl: import.meta.env.VITE_APP_FASTTRACK_URL_TRESL,
};

export const TASKS_CONFIG: {
  [task in LoanTaskEnum | ApplicationTaskEnum]: {
    primaryButton: {
      name: string;
      label: string;
      url?: {
        [x: string]: string;
      };
      fastTrackRedirect?: typeof BrandedUrls;
      hidden?: boolean;
    };
    secondaryButton: {
      name: string;
      children: string;
    };
    imageSrc: any;
    title: string;
    description: string;
    modalContent?:
      | ModalContent
      | ((
          workflowName: Workflow,
          state: ApplicationDataInterface,
        ) => ModalContent);
  };
} = {
  [LoanTaskEnum.ViewOffers]: {
    primaryButton: {
      name: 'viewOffers',
      label: 'View offers',
      url: {
        [Workflow.unified]: `/unified/workflow/offers/`,
      },
    },
    secondaryButton: {
      name: 'learnMore',
      children: 'Learn More',
    },
    imageSrc: OfferSelectHeroImg,
    title: 'You have new auto loan options available',
    description:
      "Utilizing our network of 180 lender profiles, we deliver the best offers available. Filter and review according to what's most important to you. Monthly payment? Rate? Even term length. You choose what's best for you.",
    modalContent: {
      title: 'Why have I received offers?',
      body: [
        {
          copy: [
            'We use all the available information you have previously provided, combined with our network of 180 lender profiles, to deliver the best offers available.',
            "From there you’ll be able to filter and review according to what's most important to you. Lowest monthly payment? Rate? Even term length. You choose what's best for you.",
          ],
        },
      ],
    },
  },
  [LoanTaskEnum.Applicant]: {
    primaryButton: {
      name: 'resume',
      label: 'Resume',
      url: {
        [Workflow.unified]: `/unified/workflow/applicant/`,
      },
    },
    secondaryButton: {
      name: 'learnMore',
      children: 'Learn More',
    },
    imageSrc: ApplicantHeroImg,
    title: 'Let’s complete our application',
    description:
      'You’re nearly there. Let’s collect any remaining information needed to complete your application.',
    modalContent: {
      title: 'What else do we need?',
      body: [
        {
          copy: [
            "There is a minimum criterion to provide to consider an application complete. Let's hop back into the application and see what gaps we need to fill to complete this part of the process.",
          ],
        },
      ],
    },
  },
  [LoanTaskEnum.CoApplicant]: {
    primaryButton: {
      name: 'resume',
      label: 'Resume',
      url: {
        [Workflow.unified]: `/unified/workflow/co-applicant/`,
      },
    },
    secondaryButton: {
      name: 'learnMore',
      children: 'Learn More',
    },
    imageSrc: CoApplicantHeroImg,
    title: 'Tell us about your co-applicant',
    description:
      'You’ve selected to have a co-applicant, let’s make sure we have everything we need from them as well.',
    modalContent: {
      title: 'What do you need to know about my co-applicant?',
      body: [
        {
          copy: [
            "We only need basic residential and financial information, in addition to their contact information. Let's get this added to your application and then review for accuracy. We’re almost there.",
            'Why do you need a co-applicant? Co-applicants can increase your chances for loan approval, and often lead to more favorable loan terms.',
          ],
        },
      ],
    },
  },
  [LoanTaskEnum.Review]: {
    primaryButton: {
      name: 'resume',
      label: 'Resume',
      url: {
        [Workflow.unified]: `/unified/workflow/review/`,
      },
    },
    secondaryButton: {
      name: 'learnMore',
      children: 'Learn More',
    },
    imageSrc: ReviewHeroImg,
    title: 'Let’s review',
    description:
      'Looks like we’ve got everything we need to submit your application to the lender, but let’s perform one last review for accuracy.',
    modalContent: {
      title: 'Measure twice cut once.',
      body: [
        {
          copy: [
            "Mistakes can always be fixed, but let's double check beforehand and avoid them if we can!",
          ],
        },
      ],
    },
  },
  [LoanTaskEnum.NoOffers]: {
    primaryButton: {
      name: 'getStarted',
      label: 'Get started',
      url: {
        [Workflow.unified]: `/unified/workflow/no-offers/`,
      },
    },
    secondaryButton: {
      name: 'learnMore',
      children: 'Learn More',
    },
    imageSrc: NoOffersHeroImg,
    title: 'Unable to qualify for financing',
    description:
      "Let's quickly review your information for correctness and see if we can provide anything else to ensure you qualify to receive the best offer possible.",
    modalContent: {
      title: 'Why might I have not received an offer?',
      body: [
        {
          copy: [
            'There are many variables that go into figuring out the best offer possible. From the vehicle’s value, personal credit profile, even an accurate residential history can affect whether an offer is delivered.',
            "Let's review what we have on record, correct any mistakes and fill any gaps. This will ensure we have the best chance of receiving the most accurate, and best offers available.",
            'And what if we still don’t receive any offers? We’ll try again in 30 days, situations often change, and we’ll keep you in the loop on any improvements we discover!',
          ],
        },
      ],
    },
  },
  [LoanTaskEnum.Invalid]: {
    primaryButton: {
      name: 'newApplication',
      label: 'New Application',
      fastTrackRedirect: BrandedUrls,
    },
    secondaryButton: {
      name: 'contactUs',
      children: 'Contact us',
    },
    imageSrc: HeroCommunicationImg,
    title: 'This application is no longer active',
    description:
      'It appears your application has expired or been canceled. If you feel you are reaching this in error please contact your loan specialist using the information below.',
  },
  [LoanTaskEnum.LiveApplication]: {
    primaryButton: {
      name: 'dashboard',
      label: 'Dashboard',
    },
    secondaryButton: {
      name: 'learnMore',
      children: 'Learn More',
    },
    imageSrc: LiveApplicationHeroImg,
    title: 'Your application is live!',
    description:
      'This part of the process can take a few hours (or less in most circumstances), so let’s start collecting verifications needed to finalize and complete your loan.',
    modalContent: {
      title: 'Your application is live!',
      body: [
        {
          copy: [
            'You can always visit your application dashboard to keep track of any needs that might come up. This will be your home base for the remainder of the loan process!',
          ],
        },
      ],
    },
  },
  [ApplicationTaskEnum.UploadDocuments]: {
    primaryButton: {
      name: 'completeTasks',
      label: 'Complete tasks',
      url: {
        [Workflow.unified]: `/unified/workflow/verification/`,
      },
    },
    secondaryButton: {
      name: 'learnMore',
      children: 'Learn More',
    },
    imageSrc: LiveApplicationHeroImg,
    title: 'Please upload documents',
    description:
      'We have some required documents we need you to provide. Follow the button below to upload and submit.',
    modalContent: (workflowName: Workflow, state: ApplicationDataInterface) => {
      const requiredDocuments = getRequiredDocuments(workflowName, state).map(
        (doc) => DOCUMENTS_CONFIG[doc]?.label,
      );
      return {
        title: 'Please upload documents',
        body: [
          {
            copy: [
              'To progress your application, your loan specialist has requested the following information:',
            ],
          },
          {
            asList: true,
            copy: requiredDocuments as string[],
          },
        ],
      };
    },
  },
  [ApplicationTaskEnum.SignContracts]: {
    primaryButton: {
      name: 'completeTasks',
      label: 'Complete tasks',
      url: {},
    },
    secondaryButton: {
      name: 'learnMore',
      children: 'Learn More',
    },
    imageSrc: HeroPaintingImg,
    title: 'Review and sign',
    description:
      'We have some documents that require your signature. Follow the button below to review and submit.',
    modalContent: {
      title: 'Signatures',
      body: [
        {
          copy: [
            'To progress your application, your loan specialist has requested the following information: Sign the documents via DocuSign text.',
          ],
        },
      ],
    },
  },
  [ApplicationTaskEnum.References]: {
    primaryButton: {
      name: 'completeTasks',
      label: 'Complete tasks',
    },
    secondaryButton: {
      name: 'learnMore',
      children: 'Learn More',
    },
    imageSrc: LiveApplicationHeroImg,
    title: 'Provide a couple of your personal references',
    description:
      'The lender has requested you provide some personal references. These references can be friends, family, or co-workers, and cannot live at your address.',
    modalContent: {
      title: 'Provide a couple of your personal references',
      body: [
        {
          copy: [
            'To progress your application, your loan specialist has requested the following information: Personal references.',
          ],
        },
      ],
    },
  },
};
