import { pick } from 'lodash';
import { devtools, persist } from 'zustand/middleware';

import { create, storeDevToolOptions } from '../storeManager';
import { loanDocumentStore } from './loanDocumentStore';
import { LoanDocumentStateType } from './types/LoanDocumentStateType';

const keysToPersist = ['isWorkflowStarted'];

const useNewLoanDocumentStore = create()(
  devtools(
    persist<LoanDocumentStateType>(loanDocumentStore, {
      name: 'consumer_portal:loanDocuments',
      partialize: (state: LoanDocumentStateType) =>
        pick(state, keysToPersist) as LoanDocumentStateType,
    }),
    storeDevToolOptions('newLoanDocumentStore'),
  ),
);

export default useNewLoanDocumentStore;
