import TagManager, { TagManagerArgs } from 'react-gtm-module';

import { config } from '../../config';

// Keys from ./.gitlab-ci.yml (TARGET_ENVIRONMENT)
const tagManagerArgs: { [key: string]: TagManagerArgs } = {
  dev: {
    gtmId: 'GTM-WG66XZF',
    auth: 'YmGMxVAiACUK6N65X918nw',
    preview: 'env-5',
  },
  qa: {
    gtmId: 'GTM-WG66XZF',
    auth: 'YmGMxVAiACUK6N65X918nw',
    preview: 'env-5',
  },
  stg: {
    gtmId: 'GTM-WG66XZF',
    auth: 'Fi73Yii0y8UzwsvEv1zFQg',
    preview: 'env-4',
  },
  prod: {
    gtmId: 'GTM-WG66XZF',
  },
};

export const initializeGoogleTagManager = () => {
  const targetEnvironment = config.targetEnvironment;

  if (!targetEnvironment || !(targetEnvironment in tagManagerArgs)) {
    return;
  }

  TagManager.initialize(tagManagerArgs[targetEnvironment]);
};
