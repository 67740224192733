import { get } from 'lodash';
import { Fragment, useContext, useMemo } from 'react';
import { useAsync } from 'react-async-hook';
import { useOutletContext, useRoutes } from 'react-router-dom';

import { ApplicationAsset } from '../../../common/interfaces/applications/asset.interface';
import { ReferenceInterface } from '../../../common/interfaces/applications/reference.interface';
import { Branding } from '../../../common/interfaces/branding/branding';
import { unit } from '../../../common/theme';
import { ErrorsBlock } from '../../../components/shared/ErrorsBlock/ErrorsBlock';
import { Loader } from '../../../components/shared/Loader/Loader';
import { useAPI } from '../../../hooks/useAPI/useAPI';
import { useWorkflowPathname } from '../../../hooks/useWorkflowPathname/useWorkflowPathname';
import { DynamicBrandingContext } from '../../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import { getAvailableTasks } from '../../../services/task/taskService';
import { ApplicationDataInterface } from '../applicationData.interface';
import { ApplicationPageContextInterface } from '../applicationPageContext.interface';
import { NotificationModals } from '../NotificationModals/NotificationModals';
import { ErrorWrapper } from './styles';
import ViewAllTasks from './ViewAllTasks/ViewAllTasks';
import { ViewApplication } from './ViewApplication/ViewApplication';

const WithLoader = ({ isLoading, children }: any) => {
  if (isLoading) {
    return <Loader size={unit[6]} fullScreen />;
  }
  return children;
};
const ApplicationDetailsComponent = ({
  isDesktop,
}: {
  isDesktop?: boolean;
}) => {
  const {
    currentVehicle,
    fetchVehicleError,
    openSummary,
  }: ApplicationPageContextInterface = useOutletContext();

  const workflowName = useWorkflowPathname();
  const { id: vehicleId } = currentVehicle || {};
  const { branding: defaultBranding } = useContext(DynamicBrandingContext);
  const status = currentVehicle?.checklightSession?.status;
  const leadChannelCode = get(
    currentVehicle,
    'checklightSession.leadChannelCode',
  );

  const api = useAPI();
  const applicationDetails = useAsync(
    (vehicleId: string) =>
      api.get<
        any,
        {
          references: ReferenceInterface[];
          assets: { asset: ApplicationAsset[] };
        }
      >(`/vehicles/${vehicleId}/application/details`),
    [vehicleId],
  );

  const applicationBranding = useAsync(
    (leadChannelCode: string | undefined) =>
      api.get<any, Branding>(
        `/ui-configuration/branding?leadChannelCode=${leadChannelCode}`,
      ),
    [leadChannelCode],
  );

  const applicationData: ApplicationDataInterface | undefined = useMemo(() => {
    if (
      applicationDetails.loading ||
      !applicationDetails.result ||
      applicationBranding.loading
    ) {
      return;
    }

    const { assets, references } = applicationDetails.result;
    const branding = applicationBranding.result || defaultBranding;

    return {
      assets: assets?.asset,
      references,
      currentVehicle,
      branding,
    };
  }, [
    applicationDetails,
    applicationBranding,
    currentVehicle,
    defaultBranding,
  ]);

  const availableTasks = useMemo(() => {
    return getAvailableTasks(applicationData, workflowName, status);
  }, [applicationData, workflowName, status]);

  const routes = [
    {
      index: true,
      element: (
        <WithLoader isLoading={applicationDetails.loading}>
          <NotificationModals currentVehicle={currentVehicle}>
            {applicationData ? (
              <ViewApplication
                applicationData={applicationData}
                availableTasks={availableTasks}
                fetchVehicleError={fetchVehicleError}
                openSummary={openSummary}
              />
            ) : (
              <Fragment />
            )}
          </NotificationModals>
          <Fragment>
            {applicationDetails.error && (
              <ErrorWrapper $isDesktop={isDesktop}>
                <ErrorsBlock
                  errorsList={[
                    'An unexpected exception occurred while attempting load the users vehicle details',
                  ]}
                />
              </ErrorWrapper>
            )}
          </Fragment>
        </WithLoader>
      ),
    },
    {
      path: 'Tasks',
      element: (
        <WithLoader isLoading={applicationDetails.loading}>
          <ViewAllTasks
            applicationData={applicationData}
            availableTasks={availableTasks}
          />
        </WithLoader>
      ),
    },
  ];

  return useRoutes(routes);
};

export const ApplicationDetails = withMatchMedia(ApplicationDetailsComponent);
