import { font, theme } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const Container = styled('div', ({ $isModal, $isSecondary }: any) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  textAlign: 'left',
  marginBlockEnd: $isModal ? '' : $isSecondary ? theme.unit[4] : theme.unit[6],
}));

const titleSharedStyles = ({ $color, $isMobile, $isModal, $theme }: any) => ({
  ...theme.fonts.titleMedium,
  color: $color || $theme.colors.infoOverlay,
  ...($isModal ? { fontSize: theme.unit[3], lineHeight: theme.unit[4] } : {}),
  marginBlockStart: theme.unit[1],
  marginBlockEnd: $isModal ? theme.unit[3] : '',
  ...($isMobile
    ? {
        fontSize: font.size.titleMedium,
        lineHeight: `${font.lineHeight.titleMedium}`,
      }
    : {}),
});

export const Title = styled('h3', (params: any) => ({
  ...titleSharedStyles(params),
  whiteSpace: 'pre-wrap',
}));

export const SecondaryTitle = styled(
  'h4',
  ({ $color, $isMobile, $isModal, $theme }: any) => {
    return {
      ...titleSharedStyles({ $color, $isMobile, $isModal, $theme }),
      fontSize: font.size.titleSmall,
      lineHeight: font.lineHeight.titleMedium,
      margin: 0,
    };
  },
);

export const Subtitle = styled('div', ({ $color, $isModal, $theme }: any) => ({
  ...theme.fonts.subTitle,
  color: $color || $theme.colors.primary,
  ...($isModal ? { fontWeight: 'normal' } : { marginTop: theme.unit[3] }),
}));

export const Paragraph = styled(
  'div',
  ({ $isModal, $theme, $isForm }: any) => ({
    ...theme.fonts.paragraph,
    color: $theme.colors.primaryActive,
    ...($isModal
      ? {
          marginBottom: theme.unit[3],
        }
      : $isForm
      ? {
          marginTop: theme.unit[0],
        }
      : {
          marginTop: theme.unit[2],
        }),
  }),
);
