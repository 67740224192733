import { merge } from 'lodash';

import { PillowType } from '../../../../../components/shared/PillowImage/pillowTypes';
import { fields } from '../../../../../forms/vehicle';
import { Signal } from '../../../../common/constants/signals';
import { AccountState } from '../../common/unified-workflow-context.interface';
import BaseModel from '../common/BaseModel';

const model = (
  send: any,
  { context: { coApplicant, editedVehicle } }: AccountState,
) => {
  const { year: cachedYear, vehicleInformationSource } = editedVehicle || {};
  const progress = coApplicant.coApplicantAnswer ? 63 : 68;

  return merge({}, BaseModel, {
    template: {
      header: {
        showBack: true,
        showClose: false,
        onBack: () => {
          send(Signal.Previous);
        },
      },
      footer: {
        isActive: false,
      },
      img: {
        type: PillowType.VEHICLE_LOOKUP,
      },
    },
    headerBlock: {
      title: 'Select vehicle year',
      progressBar: {
        progress,
        withContainer: true,
      },
      subtitle: ['Account', `${progress}% complete`],
    },
    form: {
      actions: {
        primary: {
          isHidden: true,
        },
      },
      fields: {
        year: {
          ...fields.year,
          name: 'vehicle.year',
          onChange: (year: any) => {
            send(Signal.Next, {
              data: {
                editedVehicle: {
                  ...(year === cachedYear
                    ? editedVehicle
                    : { vehicleInformationSource }),
                  year,
                },
              },
            });
          },
        },
      },
    },
    info: {
      title: 'Vehicle year',
      subtitle: 'Help',
      content: [
        {
          copy: [
            "Most lenders only refinance vehicles that are less than 10 years old. If you are unsure, you can always find this information in your vehicle's owner's manual or sales invoice.",
          ],
        },
      ],
    },
  });
};

export default model;
