import { mergeWith, set } from 'lodash';
import { assign } from 'xstate';

import { ChecklightSessionStatus } from '../../../common';
import { CoApplicantContext } from '../interfaces/co-applicant.interface';
import { populatePreviousIncomesInfo } from '../Steps/ApplicantEmploymentPreviousInfo/utils';

export const defaultServiceOptions = {
  actions: {
    updateContext: assign<CoApplicantContext>((context, event: any) => {
      return {
        ...context,
        ...event.data,
      };
    }),
    patchUser: assign<CoApplicantContext>({
      coApplicant: (context, { data }: any) => {
        return mergeWith(
          {
            ...context.coApplicant,
          },
          data,
          (oldValue, newValue, key) => {
            // Do no deep merge incomes
            if (key === 'incomes') {
              return { ...oldValue, ...newValue };
            }
          },
        );
      },
    }),
    updateUser: assign<CoApplicantContext>({
      coApplicant: (context, { data }: any) => {
        return {
          ...context.coApplicant,
          ...data,
        };
      },
    }),
    setCoApplicantComplete: assign<CoApplicantContext>((context) =>
      set(
        context,
        'currentVehicle.checklightSession.status',
        ChecklightSessionStatus.CO_APPLICANT_COMPLETE,
      ),
    ),
    setReviewComplete: assign<CoApplicantContext>((context) =>
      set(
        context,
        'currentVehicle.checklightSession.status',
        ChecklightSessionStatus.SUBMITTED_TO_LENDER,
      ),
    ),
  },
};

export const getPreviousIncomeFieldValues =
  (fields: any) =>
  (send: any, { context }: any) =>
    populatePreviousIncomesInfo(fields, context.coApplicant);
