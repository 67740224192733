import { componentTypes } from '../../constants/componentTypes';
import { FormField } from './FormField';

export class TextFromField extends FormField {
  component = componentTypes.TEXT;

  // constructor(props: any) {
  //   super(props);
  // }
}
