// Theme for Checklight 2.0

import * as common from './commonStyles';

export { common };
/*
 * 'unit' is a function that takes a multiplier as its argument.
 *
 * It multiplies the multiplier against the multiplicand '0.5rem'
 * and returns a string in the form of '<product>rem'.
 *
 * So, 'unit[3]' would return '1.5rem', 'unit[2]' would return '1rem'
 */
export const unit = common.unit;

export const scene = {
  mobileMargin: unit[2],
  desktopMargin: unit[5],
};

// Colors
export const colors = {
  primary: common.colors.darkGreen,
  primaryOverlay: common.colors.white,
  primaryActive: common.colors.grey,
  disabled: common.colors.greyLight,
  secondary: common.colors.white,
  secondaryActive: common.colors.greyLighter,
  secondaryOverlay: common.colors.greyLight,
  tertiary: common.colors.greyDark,
  tertiaryOverlay: common.colors.greyLightest,
  error: common.colors.red,
  infoOverlay: common.colors.black,
  success: common.colors.green,
  successOverlay: common.colors.white,
  rateBorder: common.colors.offWhiteBlue,
  rateHeading: common.colors.greyMedium,
  rateInfo: common.colors.black,
  transparency: {
    fullyTransparent: common.colors.transparent,
    whiteTransparentMedium: common.colors.whiteTransparentMedium,
    whiteTransparentLight: common.colors.whiteTransparentLight,
    blackTransparentLight: common.colors.blackTransparentLight,
    blackTransparentMedium: common.colors.blackTransparentMedium,
  },
};

// Fonts
export const fontDefaults = {
  fontFamily: common.font.family.Montserrat,
  fontSize: common.font.size.paragraph,
  lineHeight: common.font.lineHeight.paragraph,
};

export const fonts = {
  body: {
    ...fontDefaults,
  },
  paragraph: {
    ...fontDefaults,
  },
  smallTitle: {
    ...fontDefaults,
    fontWeight: '500',
  },
  titleMedium: {
    ...fontDefaults,
    fontFamily: common.font.family.Merriweather,
    fontWeight: 'normal',
    fontSize: common.font.size.titlePreLarge,
    lineHeight: common.font.lineHeight.titlePreLarge,
  },
  headingMedium: {
    ...fontDefaults,
    fontWeight: '500',
    marginTop: '0',
    marginBottom: '0',
  },
  headingLarge: {
    ...fontDefaults,
    fontFamily: common.font.family.Montserrat,
    fontWeight: 500,
    fontSize: common.font.size.headingLarge,
    lineHeight: common.font.lineHeight.paragraph,
    marginTop: '0',
    marginBottom: '0',
  },
  subTitle: {
    ...fontDefaults,
    fontFamily: common.font.family.Montserrat,
    fontWeight: 500,
    fontSize: common.font.size.subTitle,
    lineHeight: common.font.lineHeight.subTitle,
  },
  button: {
    ...fontDefaults,
    fontFamily: common.font.family.Montserrat,
    fontSize: common.font.size.headingMedium,
    fontWeight: 500,
  },
  hint: {
    fontFamily: common.font.family.Montserrat,
    fontWeight: 500,
    fontSize: common.font.size.headingSmall,
    lineHeight: common.font.lineHeight.subTitle,
  },
};

export const dimensions = {
  sidebarWidth: '24rem', // 384px
  stageWidth: '42rem', // 672px
  mainContainerMaxWidth: '66rem', // 1056px
  headerHeight: '5rem', // 80px
};

export const media = {
  desktopMinWidth: '1100px',
  mobileMaxWidth: '576px',
  tabletMinWidth: '576px',
  desktopSmallMinWidth: '900px',
  desktopLargeMinWidth: '1224px',
};

// values in listLayouts are dependent on each other. If the size of the vehicle image changes,
// it must be ensured that the 'shortenedDivider' values are updated such that everything 'lines-up' visually
export const listLayouts = {
  shortenedDivider: {
    marginLeft: '121px',
    [`@media (max-width: ${media.mobileMaxWidth})`]: {
      marginLeft: '111px',
    },
  },
  vehicleSizing: {
    height: '72px',
  },
};

export const zIndexMap = {
  sidebar: 100,
  modal: 200,
};
