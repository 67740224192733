import { useMemo } from 'react';

/**
 * Closure Function implements countdown timer with control actions
 * @param initialValue Number
 * @param stateSetter Function callback will be called every second with current timer value
 * @returns {{startTimer: startTimer, stopTimer: stopTimer}}
 */
export const useCountdownTimer = (
  initialValue = 0,
  stateSetter: { (value: number): void; (arg0: number): void },
) =>
  useMemo(() => {
    let intervalId: ReturnType<typeof setInterval>;
    let secondsLeft: number;

    const stopTimer = () => {
      if (intervalId) {
        clearInterval(intervalId);
        stateSetter(0);
      }
    };

    const startTimer = () => {
      secondsLeft = initialValue;
      intervalId = setInterval(() => {
        secondsLeft -= 1;
        if (secondsLeft === 0) {
          stopTimer();
        }
        stateSetter(secondsLeft);
      }, 1000);
      stateSetter(initialValue);
    };

    return {
      startTimer,
      stopTimer,
    };
  }, []);
