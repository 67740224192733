import { theme } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const Container = styled('div', () => ({
  width: '100%',
}));

export const ControlContainer = styled('div', ({ $theme }: any) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: $theme.colors.secondary,
  marginBottom: theme.unit[3],
}));

export const Title = styled('div', ({ $theme }: any) => ({
  color: $theme.colors.infoOverlay,
}));

export const Control = styled('div', ({ $theme }: any) => ({
  color: $theme.colors.infoOverlay,
  marginLeft: 'auto',
  padding: `0 ${theme.unit[1]}`,
  cursor: 'pointer',
}));

export const Content = styled('div', ({ $isOpen }: any) => ({
  display: $isOpen ? 'block' : 'none',
}));

export const Divider = styled('div', ({ $theme }: any) => ({
  borderBottom: `1px solid ${$theme.colors.secondaryOverlay}`,
  width: '100%',
  marginTop: theme.unit[1],
  marginBottom: theme.unit[1],
}));
