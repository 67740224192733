import { uniqueId } from 'lodash';

import { Container, CopyContainer } from './styles';

export const CopyBlock = ({
  asList = false,
  copy,
  identifier,
  blockIndex,
}: any) => {
  return (
    <Container data-testid={`disclaimerText-${blockIndex}`}>
      {copy.map((c: any) => (
        <CopyContainer
          $hasMargin={!asList}
          key={uniqueId(`${identifier || 'list'}-copy-`)}
        >
          {asList ? '- ' : ''}
          {c}
        </CopyContainer>
      ))}
    </Container>
  );
};
