import { get } from 'lodash';

import { AGREEMENT_STATUSES } from '../../../../constants/choices/checklight';
import { ResidencePhysicalAddress } from '../../../../providers/context/CurrentUserContext/CurrentUserContextT';
import { AccountContext } from './unified-workflow-context.interface';

export const getCoApplicantAddress = (
  context: AccountContext,
): { physicalAddress: ResidencePhysicalAddress } => {
  const { coApplicant, user } = context;
  const dataSource =
    coApplicant.sameAsApplicant === AGREEMENT_STATUSES.YES ? user : coApplicant;
  return get(dataSource, 'residences.current' as any);
};
