export const modalContent = [
  {
    title: 'How does it work?',
    copy: [
      'Let’s say your vehicle is totaled in an accident. You owe $20,000 but your car is only worth $15,000. GAP insurance will cover any remaining balance owed on the loan.',
    ],
  },
  {
    title: 'What are the Benefits?',
    asList: true,
    copy: [
      'Covers insurance deductibles up to $1,000',
      'No payoff burden in the event of theft or wreck',
      'Never pay out of pocket for owning more than you car is worth.',
    ],
  },
];
