export enum DeliveryMechanism {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}

export enum AuthType {
  // NONE = 'NONE',
  ACCOUNT_SIGN_IN_USING_PIN = 'ACCOUNT_SIGN_IN_USING_PIN',
  ACCOUNT_SIGN_IN_USING_SESSION = 'ACCOUNT_SIGN_IN_USING_SESSION',
  ACCOUNT_REGISTRATION_USING_PIN = 'ACCOUNT_REGISTRATION_USING_PIN',
  // ACCOUNT_SIGN_IN_USING_MAGIC_LINK = 'ACCOUNT_SIGN_IN_USING_MAGIC_LINK',
  APPLICATION_ACTIVATION_USING_PIN = 'APPLICATION_ACTIVATION_USING_PIN',
  EDIT_PHONE_USING_PIN = 'EDIT_PHONE_USING_PIN',
  EDIT_DATA_USING_PIN = 'EDIT_DATA_USING_PIN',
}

export const authTypesBypassingPinAttempts = [
  AuthType.ACCOUNT_REGISTRATION_USING_PIN,
  AuthType.EDIT_PHONE_USING_PIN,
];

export enum PermanentOrganization {
  AUTOPAY = 'AUTOPAY',
  CHECKLIGHT = 'CHECKLIGHT',
  CONSUMER_PORTAL = 'CONSUMER_PORTAL',
}

export type PinRequest = {
  phone: string;
  email?: string;
  purpose?: AuthType;
  deliverWith?: string;
};

export type PinAuthenticateRequest = {
  pin: string;
  phone: string;
  authType?: AuthType;
  organization?: string;
};
