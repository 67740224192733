import { merge } from 'lodash';

import { applicantPersonalInfoBaseModel } from '../common/ApplicantPersonalInfoModel';

const model = merge({}, applicantPersonalInfoBaseModel, {
  info: {
    title: 'Applicant personal details',
    content: [
      {
        copy: [
          'Providing us with correct name, age, and contact information will ensure the entire loan process is conducted as smoothly as possible.',
        ],
      },
    ],
  },
});

export default model;
