import { assign, MachineConfig } from 'xstate';

import { UserVehicle } from '../../../../common/interfaces/vehicle/vehicle.interface';
import { Workflow } from '../../../../constants/Workflow';
import { DocumentEnum } from '../../../../services/document/documentsConfig';
import {
  getRequiredDocuments,
  getRequiredDocumentsToCompleteApplication,
  getUploadedDocuments,
} from '../../../../services/document/documentService';
import { Step } from '../steps';

const Signal = {
  Next: 'Next',
  Stop: 'Stop',
  Start: 'Start',
  Previous: 'Previous',
  Edit: 'Edit',
  Exit: 'Exit',
  ExitToDashboard: 'ExitToDashboard',
};

export interface VerificationContext {
  currentVehicle: UserVehicle;
  selectedVerificationTask?: DocumentEnum;
  requiredDocuments: DocumentEnum[];
  uploadedDocuments: DocumentEnum[];
  requiredDocumentsToCompleteApplication: DocumentEnum[];
  workflow: Workflow;
}

const defaultServiceOptions = {
  actions: {
    updateContext: assign((context: any, event: any) => {
      return {
        ...context,
        ...event.data,
      };
    }),
  },
};

export const Routes = {
  [Step.VerificationLookupPillow]: '',
  [Step.VerificationDocumentsList]: 'list',
  [Step.VerificationDocumentUpload]: 'upload',
};

const optionalDocuments = [
  DocumentEnum.PayoffLetter,
  DocumentEnum.AdditionalDocuments,
];

const workflowName = 'DefaultVerificationWorkflow';
const defaultConfiguration: MachineConfig<VerificationContext, any, any> = {
  id: workflowName,
  initial: Step.VerificationLookupPillow,
  states: {
    [Step.VerificationLookupPillow]: {
      on: {
        [Signal.Exit]: Step.Exit,
        [Signal.ExitToDashboard]: Step.ExitToDashboard,
        [Signal.Next]: Step.VerificationDocumentsList,
      },
      entry: assign((context: any) => {
        if ('requiredDocuments' in context) {
          return context;
        }

        const requiredDocuments = getRequiredDocuments(
          Workflow.unified,
          context,
        );
        const requiredDocumentsToCompleteApplication =
          getRequiredDocumentsToCompleteApplication(
            requiredDocuments,
            optionalDocuments,
          );
        return {
          ...context,
          requiredDocumentsToCompleteApplication,
          requiredDocuments,
          uploadedDocuments: getUploadedDocuments(
            requiredDocuments,
            context.assets || [],
          ),
          workflow: Workflow.unified,
        };
      }),
    },
    [Step.VerificationDocumentsList]: {
      on: {
        [Signal.Previous]: Step.VerificationLookupPillow,
        [Signal.Edit]: {
          target: Step.VerificationDocumentUpload,
          actions: ['updateContext'],
        },
        [Signal.Next]: [
          {
            target: Step.Exit,
          },
        ],
      },
    },
    [Step.VerificationDocumentUpload]: {
      on: {
        [Signal.Previous]: Step.VerificationDocumentsList,
        [Signal.Next]: {
          actions: ['updateContext'],
          target: Step.VerificationDocumentsList,
        },
      },
    },
    [Step.Exit]: {
      type: 'final',
    },
    [Step.ExitToDashboard]: {
      type: 'final',
    },
  },
};

// NOTE: You can paste the content of this file into @see https://xstate.js.org/viz
// and uncomment this line to test the logic manually
// Machine({ ...defaultConfiguration }, { ...defaultServiceOptions });

export {
  Step,
  Signal,
  workflowName,
  defaultConfiguration,
  defaultServiceOptions,
};

export default defaultConfiguration;
