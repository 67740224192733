export enum ApplicationType {
  REFINANCE = 'REFINANCE',
  PURCHASE = 'PURCHASE',
  PERSONAL_SECURED_LOAN = 'PERSONAL_SECURED_LOAN',
  DEALER = 'DEALER',
  PRIVATE_PARTY = 'PRIVATE_PARTY',
  LEASE_BUYOUT = 'LEASE_BUYOUT',
  RE_F_AND_I = 'RE_F_AND_I',
}

export enum LosLoanType {
  REFINANCE = 'Refinance',
  PURCHASE = 'Purchase',
  DEALER = 'Purchase',
  PRIVATE_PARTY = 'Purchase',
  LEASE_BUYOUT = 'Purchase',
  PERSONAL_SECURED_LOAN = 'Personal Secured Loan',
}

export enum LosLoanSubType {
  DEALER = 'Dealer',
  PRIVATE_PARTY = 'Private Party',
  LEASE_BUYOUT = 'Lease Buyout',
}
