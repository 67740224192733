import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { Income as ViewComponent } from '../../../components/Income/Income';
import { PageHeader } from '../../../components/shared/PageHeader/PageHeader';
import { TABS } from '../constants';

export const IncomeView = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <PageHeader
        breadCrumbs={['Account']}
        navigate={navigate}
        selectedTab="Income"
        tabs={TABS}
        tabsSiblingRouteDepth="../"
        title="Account"
      />
      <ViewComponent />
    </Fragment>
  );
};
