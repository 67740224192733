import { pick } from 'lodash';

const propsToPick = [
  'firstName',
  'lastName',
  'incomes',
  'personalInfo',
  'residences',
];

export const prepareApplicantPayload = (updatedUser: any) => {
  const payload = pick(updatedUser, propsToPick);

  return payload;
};
