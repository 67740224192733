import * as Sentry from '@sentry/react';
import { debounce, merge } from 'lodash';

import { componentTypes } from '../../../../../constants/componentTypes';
import {
  DocumentFile,
  DOCUMENTS_FILE_CONFIG,
} from '../../../../../services/document/documentFileConfig';
import {
  DocumentEnum,
  DOCUMENTS_CONFIG,
} from '../../../../../services/document/documentsConfig';
import { Signal, VerificationContext } from '../../XState/Model';
import { BaseModel } from '../BaseModel';

type WithRequiredProperty<Type, Key extends keyof Type> = Type & {
  [Property in Key]-?: Type[Property];
};

const MAX_FILE_SIZE = 25e6; // 25MB

// debounce is needed here because the validations are running multiple times
// for the same file and we only want to report the first error (leading)
const reportError = debounce(
  (file: File, doc: DocumentFile, message: string) => {
    Sentry.captureException(new Error(message), {
      extra: {
        Document: DOCUMENTS_FILE_CONFIG[doc].label,
        FileSize: file.size,
        FileType: file.type || 'unknown',
        MaximumFileSize: MAX_FILE_SIZE,
      },
      tags: { 'document.type': doc },
    });
  },
  undefined, // wait time, leaving as default
  { leading: true },
);

const model = (
  send: any,
  {
    context,
  }: {
    context: WithRequiredProperty<
      VerificationContext,
      'selectedVerificationTask'
    >;
  },
) =>
  merge({}, BaseModel(context), {
    template: {
      header: {
        showClose: false,
        showBack: true,
      },
    },
    form: {
      fields: DOCUMENTS_CONFIG[
        context.selectedVerificationTask as DocumentEnum
      ]?.documents.reduce((acc: any, doc: DocumentFile) => {
        return {
          ...acc,
          [doc]: {
            name: doc,
            label: DOCUMENTS_FILE_CONFIG[doc].label,
            component: componentTypes.UPLOAD,
            gtm: DOCUMENTS_FILE_CONFIG[doc].gtm,
            validationRules: {
              required: true,
              validate: {
                format: (payload: { metadata: File }) => {
                  if (!/jpeg|png|pdf/.test(payload.metadata.type)) {
                    reportError(
                      payload.metadata,
                      doc,
                      'User selected a file format which is not currently supported',
                    );

                    return 'Uploaded file format is not supported.';
                  }

                  return true;
                },
                size: (payload: { metadata: File }) => {
                  if (payload.metadata.size > MAX_FILE_SIZE) {
                    reportError(
                      payload.metadata,
                      doc,
                      'User selected a file size which is greater than the maximum allowed',
                    );

                    return 'Uploaded file size exceeds 25MB.';
                  }

                  return true;
                },
              },
            },
          },
        };
      }, {}),
      actions: {
        primary: {
          testId: 'submit',
          label: 'Submit',
          handler: () => {
            send(Signal.Next);
          },
        },
        secondary: {
          testId: 'cancel',
          label: 'Cancel',
        },
      },
    },
    headerBlock: {
      progressBar: {
        withContainer: true,
      },
      subtitle: ['Verifications'],
    },
  });

export default model;
