import PropTypes from 'prop-types';

import fallbackVehicleImage from '../../../assets/car-shadow-dark.png';
import { theme } from '../../../common/theme';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { IconTypes } from '../Icon/iconTypes';
import { RotationDirections } from '../Icon/rotationDirections';
import { Panel } from '../panel/Panel';
import { PanelTypes } from '../panel/types';
import { SmoothImage } from '../SmoothImage/SmoothImage';
import {
  BodyContainer,
  BodyFooter,
  Cta,
  MediaContainer,
  OuterContainer,
  Paragraph,
  Subtitle,
  Title,
} from './styles';

export const Card = ({
  image,
  onClick = () => {},
  bodyContent,
  footerContent,
  title,
  subtitle,
  cta,
}: any) => (
  <Panel
    readOnly={false}
    type={PanelTypes.ROUNDED_MEDIUM}
    onClick={!cta ? onClick : () => {}}
  >
    <OuterContainer>
      <MediaContainer>
        <SmoothImage fallbackSrc={fallbackVehicleImage} src={image || null} />
      </MediaContainer>
      <BodyContainer>
        <Title>{title}</Title>
        {subtitle ? (
          <Subtitle $hasMarginBottom={!!bodyContent}>{subtitle}</Subtitle>
        ) : null}
        <Paragraph>{bodyContent}</Paragraph>
        {cta ? (
          <Cta>
            <Button onClick={onClick}>{cta}</Button>
          </Cta>
        ) : null}
      </BodyContainer>
      {footerContent ? (
        <BodyFooter>
          {footerContent}
          <Icon
            height={theme.unit[3]}
            name={IconTypes.ARROW}
            rotate={RotationDirections.RIGHT}
          />
        </BodyFooter>
      ) : null}
    </OuterContainer>
  </Panel>
);

Card.propTypes = {
  bodyContent: PropTypes.any,
  footerContent: PropTypes.any,
  isLargeDesktop: PropTypes.any,
  isMobile: PropTypes.bool,
  image: PropTypes.any,
  onClick: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cta: PropTypes.string,
};
