import { FooterLink } from '../../../../../components/PillowForm';
import { PillowType } from '../../../../../components/shared/PillowImage/pillowTypes';

export const BaseModel = {
  template: {
    header: {
      showClose: false,
    },
    footer: {
      isActive: false,
      links: [FooterLink.ContactUs],
    },
    img: {
      type: PillowType.ACCOUNT,
    },
  },
};

export default BaseModel;
