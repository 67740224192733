import { theme } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const ImageContainer = styled(
  'div',
  () =>
    ({
      ...theme.common.helpers.flexVerticalCenter,
      width: '100%',
      paddingTop: '75%' /* 4:3 Aspect Ratio (divide 3 by 4 = 0.75) */,
      borderRadius: theme.unit[1],
      position: 'relative',
      overflow: 'hidden',
    } as any),
);

export const Image = styled('img', {
  maxWidth: '100%',
  maxHeight: '100%',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
});
