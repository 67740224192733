import { theme } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

const getBorderString = (color: any) => `0.125rem solid ${color}`;

const getColorScheme = (
  isSelected: any,
  isError: any,
  isViewOnly: any,
  $theme: any,
) => {
  if (isViewOnly) {
    const border = getBorderString($theme.colors.primary);
    return isSelected
      ? {
          backgroundColor: $theme.colors.primary,
          border,
        }
      : {
          border,
        };
  }
  if (isSelected && !isError) {
    return {
      backgroundColor: $theme.colors.primary,
      border: getBorderString($theme.colors.primary),
      ':disabled': { backgroundColor: $theme.colors.disabled },
    };
  }
  if (isError && !isSelected) {
    return {
      border: getBorderString($theme.colors.error),
    };
  }
  if (isError && isSelected) {
    return {
      backgroundColor: $theme.colors.error,
      border: getBorderString($theme.colors.error),
      ':disabled': { backgroundColor: $theme.colors.disabled },
    };
  }
  return {
    border: getBorderString($theme.colors.primaryActive),
    ':hover': { border: getBorderString($theme.colors.primaryActive) },
  };
};

export const Container = styled('div', () => ({
  position: 'relative',
}));

export const Input = styled('input', () => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  margin: '0',
  opacity: '0',
}));

export const Label = styled(
  'label',
  ({ $isSelected, $isError, $viewOnly, $theme, $isDisabled }: any) => {
    const disabledStyles = {
      border: getBorderString($theme.colors.disabled),
      cursor: 'default',
    };

    return {
      ...theme.common.helpers.flexVerticalCenter,
      flexWrap: '',
      borderRadius: '0.25rem',
      height: theme.unit[3],
      width: theme.unit[3],
      cursor: 'pointer',
      pointerEvents: $viewOnly ? 'none' : 'default',
      position: 'relative',
      ...getColorScheme($isSelected, $isError, $viewOnly, $theme),
      ...($isDisabled
        ? {
            ...disabledStyles,
            ':not([hover])': disabledStyles,
          }
        : {}),
      ':has(input:focus)': {
        ':before': {
          content: '""',
          display: 'block',
          width: 'calc(100% + 4px)',
          height: 'calc(100% + 4px)',
          border: `2px solid ${$theme.colors.transparency.whiteTransparentMedium}`, // TODO: CP-2337 Change color if needed
          borderRadius: '0.25rem',
          position: 'absolute',
          left: '-2px',
          top: '-2px',
          zIndex: 1,
        },
      },
    } as any;
  },
);
