import { useState } from 'react';

import { WorkflowInitialContext } from './WorkflowInitialContext';

export const withWorkflowInitialContextProvider = (WrappedComponent: any) => {
  return (props: any) => {
    const initialWorkflowState = useState<any>();

    return (
      <WorkflowInitialContext.Provider value={initialWorkflowState}>
        <WrappedComponent {...props} />
      </WorkflowInitialContext.Provider>
    );
  };
};
