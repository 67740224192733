export enum IncomeTypeEnum {
  AID_FOR_DEPENDENT_CHILDREN = 'AID_FOR_DEPENDENT_CHILDREN',
  CHILD_SUPPORT = 'CHILD_SUPPORT',
  COST_OF_LIVING_ALLOWANCE = 'COST_OF_LIVING_ALLOWANCE',
  DISABILITY = 'DISABILITY',
  EMPLOYMENT = 'EMPLOYMENT',
  MILITARY_BASIC_ALLOWANCE_FOR_SUBSISTENCE = 'MILITARY_BASIC_ALLOWANCE_FOR_SUBSISTENCE',
  MUNICIPAL_BOND_INTEREST = 'MUNICIPAL_BOND_INTEREST',
  NON_TAXABLE_SOURCES = 'NON_TAXABLE_SOURCES',
  OTHER = 'OTHER',
  PUBLIC_ASSISTANCE = 'PUBLIC_ASSISTANCE',
  RENTAL_INCOME = 'RENTAL_INCOME',
  SECONDARY_EMPLOYMENT = 'SECONDARY_EMPLOYMENT',
  SOCIAL_SECURITY = 'SOCIAL_SECURITY',
  SUPPLEMENTAL_SECURITY_INCOME = 'SUPPLEMENTAL_SECURITY_INCOME',
  UNKNOWN = 'UNKNOWN',
  WORKMANS_COMPENSATION = 'WORKMANS_COMPENSATION',
}
