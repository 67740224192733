import {
  Branding,
  ImageAsset,
} from '../../common/interfaces/branding/branding';

const getFaviconUrl = (favicon: ImageAsset): string => {
  return favicon?.url;
};

const setFavicon = (branding: Branding) => {
  const faviconUrl = getFaviconUrl(branding?.favicon);
  if (faviconUrl) {
    let faviconLink: HTMLLinkElement | null =
      document.querySelector(`link[rel~="icon"]`);
    if (!faviconLink) {
      faviconLink = document.createElement('link');
      faviconLink.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(faviconLink);
    }
    faviconLink.href = faviconUrl;
  }
};

const setAppTitle = (branding: Branding) => {
  const { applicationTitle = '' } = branding;
  let title: HTMLTitleElement | null = document.querySelector('title');
  if (!title) {
    title = document.createElement('title');
    document.getElementsByTagName('head')[0].appendChild(title);
  }
  title.textContent = applicationTitle;
};

export const setDynamicAppInfo = (branding: Branding) => {
  setFavicon(branding);
  setAppTitle(branding);
};
