import { useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Workflow } from '../../../../constants/Workflow';
import { useAPI } from '../../../../hooks/useAPI/useAPI';
import { useCurrentUserContext } from '../../../../hooks/useCurrentUserContext/useCurrentUserContext';
import { useWorkflowPathname } from '../../../../hooks/useWorkflowPathname/useWorkflowPathname';
import { withMatchMedia } from '../../../../providers/context/MatchMediaProvider/withMatchMedia';
import { additionalIncomeFields as checklightIncomeFields } from '../../../../utils/helpers/income/checklight';
import { additionalIncomeFields as unifiedIncomeFields } from '../../../../utils/helpers/income/unified';
import { FormConfirmModal } from '../../../FormConfirmModal/FormConfirmModal';
import { Form } from '../../../shared/Form/Form';
import { FormContainer } from './styles';

const fields: Record<string, any> = {
  [Workflow.checklight]: checklightIncomeFields,
  [Workflow.unified]: unifiedIncomeFields,
};

const AddAdditionalIncomeComponent = ({
  isDesktop,
}: {
  isDesktop?: boolean;
}) => {
  const [currentUserContext, setCurrentUserContext] = useCurrentUserContext();
  const api = useAPI();
  const navigate = useNavigate();
  const { incomes: { additional: allIncomes = [] } = {} } = currentUserContext;
  const methods = useForm();
  const root = useWorkflowPathname();
  const additionalIncomeFields = fields[root];

  const updateUserData = useAsyncCallback((data) => {
    const updatedIncome = allIncomes.concat({ incomeType: 'OTHER', ...data });
    return api
      .put('/users/current', {
        incomes: {
          ...currentUserContext.incomes,
          additional: updatedIncome,
        },
      })
      .then((user) => {
        setCurrentUserContext({
          ...currentUserContext,
          ...user,
        });
        navigate('..');
      });
  });

  const submitBtn = useMemo(
    () => ({
      handler: updateUserData.execute,
      label: 'Save',
      testId: 'nameSave',
      isDisabled: updateUserData.loading,
    }),
    [updateUserData],
  );

  const incomeForm = useMemo(() => {
    return {
      fields: additionalIncomeFields,
      actions: {
        primary: {
          ...submitBtn,
        },
      },
      globalErrors: updateUserData.error,
    };
  }, [submitBtn, updateUserData, additionalIncomeFields]);

  return (
    <FormContainer $isDesktop={isDesktop}>
      <Form form={incomeForm} methods={methods} />
      <FormConfirmModal formFields={incomeForm.fields} methods={methods} />
    </FormContainer>
  );
};

export const AddAdditionalIncome = withMatchMedia(AddAdditionalIncomeComponent);
