import { Container } from './styles';

export interface FooterProps {
  isActive?: boolean;
  children: any;
}

export const Footer = ({ children, isActive = true }: FooterProps) => {
  return <Container $isActive={isActive}>{children}</Container>;
};
