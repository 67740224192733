import { merge, omit } from 'lodash';
import { useContext, useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { FormProvider, useForm } from 'react-hook-form';

import { PillowForm } from '../../../../components/PillowForm';
import { InfoBlock } from '../../../../components/shared/InfoBlock/InfoBlock';
import { PanelList } from '../../../../components/shared/PanelList/PanelList';
import { useAPI } from '../../../../hooks/useAPI/useAPI';
import { DynamicBrandingContext } from '../../../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { withMatchMedia } from '../../../../providers/context/MatchMediaProvider/withMatchMedia';
import { Signal } from '../../constants/signals';
import { offerPanelsConfig, protectionPanelsConfig } from './panelsConfig';
import {
  FormMarginFix,
  InfoBlockContainer,
  InfoText,
  PanelMargin,
  PanelTitle,
} from './styles';

const Controller = (props: any) => {
  const {
    branding: { theme: $theme },
  } = useContext(DynamicBrandingContext);
  const methods = useForm();
  const { context, send, presModel } = props;
  const { offers = {}, currentVehicle } = context;
  const {
    selectedGapOptionId,
    selectedOfferWithoutGap,
    selectedOfferWithGap,
    downPayment,
  } = offers;
  const api = useAPI();

  // We should display an offer without gap if there is no available the one with gap
  const offerToDisplay = useMemo(
    () =>
      selectedGapOptionId === '0'
        ? selectedOfferWithGap || selectedOfferWithoutGap
        : selectedOfferWithoutGap,
    [selectedOfferWithGap, selectedOfferWithoutGap, selectedGapOptionId],
  );

  const offerPanels = useMemo(
    () => offerPanelsConfig(offerToDisplay),
    [offerToDisplay],
  );

  const protectionPanels = useMemo(
    () => protectionPanelsConfig(offerToDisplay),
    [offerToDisplay],
  );

  const saveOffer = useAsyncCallback(() => {
    const { amtGapMonthly } = offerToDisplay;
    return api
      .post(`/vehicles/${currentVehicle.id}/application/offers/selected`, {
        ...omit(offerToDisplay, 'value'),
        downPayment,
        amtGapMonthly,
      })
      .then(() => {
        send('Next');
      });
  });

  const enhancedProps = useMemo(
    () =>
      merge(
        {},
        { presModel },
        {
          presModel: {
            template: {
              header: {
                onBack: () => {
                  send(Signal.Previous);
                },
              },
            },
            form: {
              actions: {
                primary: {
                  handler: methods.handleSubmit(saveOffer.execute),
                  isDisabled: saveOffer.loading,
                },
              },
              globalErrors: saveOffer.error,
            },
          },
        },
      ),
    [presModel, methods, saveOffer, send],
  );

  const infoText = (
    <InfoText>
      Final rate is subject to change. Actual monthly savings, if any, may vary
      based on interest rates, balance, remaining payment terms and other
      factors.
    </InfoText>
  );

  return (
    <FormProvider {...methods}>
      <PillowForm {...enhancedProps}>
        <FormMarginFix />
        <PanelList defaultValue="-" panelList={offerPanels} isBorderWrapped />
        <PanelMargin />
        <PanelTitle>Protection details</PanelTitle>
        <PanelList
          defaultValue="-"
          panelList={protectionPanels}
          isBorderWrapped
        />
        <InfoBlockContainer>
          <InfoBlock
            customBorderColor={$theme.colors.secondaryOverlay}
            hasBorder
          >
            {infoText}
          </InfoBlock>
        </InfoBlockContainer>
      </PillowForm>
    </FormProvider>
  );
};

Controller.displayName = 'OffersReview.Controller';

const ControllerWithMatchMedia = withMatchMedia(Controller);
export default ControllerWithMatchMedia;
