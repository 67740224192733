import { borderRadii, font, theme, unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const DropdownContainer = styled('div', ({ $theme }: any) => ({
  width: '100%',
  position: 'relative',
  backgroundColor: $theme.colors.secondary,
  border: `1px solid ${$theme.colors.secondaryOverlay}`,
  ...borderRadii.roundedSlight,
}));

export const DropdownElement = styled(
  'select',
  ({ disabled, $theme }: any) => ({
    width: '100%',
    padding: `${unit[2]} ${unit[3]}`,
    border: 'none',
    color: disabled ? $theme.colors.disabled : $theme.colors.infoOverlay,
    backgroundColor: $theme.colors.secondary,
    ...theme.fonts.smallTitle,
    ...borderRadii.roundedSlight,
  }),
);

export const DropdownCaret = styled('div', () => ({
  position: 'absolute',
  right: unit[3],
  bottom: font.size.headingSmall,
  pointerEvents: 'none',
}));
