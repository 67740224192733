/**
 * Vehicle Util
 */
import carShadowDarkImg from '../../assets/car-shadow-dark.png';
import carShadowProfileImg from '../../assets/car-shadow-profile.png';
import { imageType } from '../../common';

export const defaultImagesByType: Record<string, any> = {
  [imageType.PROFILE]: carShadowProfileImg,
  [imageType.DEFAULT]: carShadowDarkImg,
};

/**
 * imageListToTypeUrlMap
 *
 * transforms our image list from radix into an object with the "image type" as key and url as value.  ie:
 *
 * {
 *   [imageType.PROFILE]: 'https://urltosomeimage.com',
 *
 * }
 */
const imageListToTypeUrlMap = (imageList = []) =>
  imageList.reduce(
    (acc: any, radixImageObject: any) => ({
      ...acc,
      [radixImageObject.vehicleImageType]: radixImageObject.url,
    }),
    {},
  );
/**
 * getImageByType
 *
 * Returns the vehicle image by type, or a default image for that given type.
 */
export const getImageByType =
  (type: any) =>
  (imageList: any, includeDefault = true) => {
    const imagesByType = imageListToTypeUrlMap(imageList);
    if (imagesByType[type]) {
      return imagesByType[type];
    }
    if (includeDefault) {
      return defaultImagesByType[type];
    }
    return undefined;
  };

/**
 * yearList
 *
 * Returns a list of Years descending from next year to this year - the length
 *
 * The year we want needs to be the "length" + 2.  As this year and next year are a part of the length count
 *
 * @length {Number}
 * @param  {Array}
 */
export function yearList(length = 10) {
  const startYear = new Date().getFullYear() + 1;
  return [...Array(length + 2)].map((v, i) => startYear - i);
}

export default {
  getImageByType,
  yearList,
};
