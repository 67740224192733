import { Navigate } from 'react-router-dom';

import { useWorkflowPathname } from '../../../../../hooks/useWorkflowPathname/useWorkflowPathname';

const RedirectToReview = ({ context }: any) => {
  const rootPath = useWorkflowPathname();

  return <Navigate state={context} to={`/${rootPath}/workflow/review/`} />;
};

export default RedirectToReview;
