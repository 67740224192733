import { isObject } from 'lodash';
import { useCallback } from 'react';
import { isElement } from 'react-dom/test-utils';

import { componentTypes } from '../../../constants/componentTypes';
import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import { Badge } from '../badge/Badge';
import { FormattedText } from '../formattedText/FormattedText';
import { ImageFitContainer } from '../ImageFitContainer/ImageFitContainer';
import {
  BadgeContainer,
  ContentContainer,
  FooterContent,
  FooterText,
  Highlight,
  ImageContainerDesktop,
  ImageContainerMobile,
  LeftContentContainer,
  ListItemContainer,
  RightContentContainer,
  SubtitleContainer,
  TextContainer,
  TextOverflowWrapper,
  TitleContainer,
} from './styles';

const ListItemImage = ({ image }: any) => {
  if (typeof image === 'string') {
    return <ImageFitContainer src={image} />;
  }

  return image;
};

const ListItemComponent = ({
  value,
  title,
  subtitle,
  image,
  rightContent,
  disabled,
  onChange = () => {},
  testId,
  isMobile = false,
  imageOnTopForMobile,
  checked,
  mobileFooterText,
  component,
  largeTitle = false,
  badge,
  highlight,
  showFooterOnMobile,
}: any) => {
  const layoutWithFooter =
    isMobile && (imageOnTopForMobile || showFooterOnMobile);
  const imageOnDesktop = Boolean(image && !isMobile);

  const handleClick = useCallback(() => {
    if (component === componentTypes.SUMMARY_LIST) {
      return;
    }
    onChange(value);
  }, [component, value, onChange]);

  const handleEditClick = useCallback(() => {
    if (component !== componentTypes.SUMMARY_LIST) {
      return;
    }
    onChange(value);
  }, [component, value, onChange]);

  return (
    <ListItemContainer
      $checked={checked}
      $component={component}
      data-selected={checked}
      data-testid={testId}
      disabled={disabled}
      onClick={handleClick}
    >
      {isMobile && imageOnTopForMobile && image ? (
        <ImageContainerMobile>
          <ListItemImage image={image} />
        </ImageContainerMobile>
      ) : null}
      <ContentContainer>
        <LeftContentContainer $isMobile={isMobile}>
          {imageOnDesktop ? (
            <ImageContainerDesktop>
              <ListItemImage image={image} />
            </ImageContainerDesktop>
          ) : null}
          <TextContainer
            $layoutWithFooter={layoutWithFooter}
            $noImage={!imageOnDesktop}
          >
            <TextOverflowWrapper>
              <TitleContainer $largeTitle={largeTitle} disabled={disabled}>
                {title}
              </TitleContainer>
            </TextOverflowWrapper>
            {subtitle ? (
              <TextOverflowWrapper>
                <SubtitleContainer $largeTitle={largeTitle}>
                  {isObject(subtitle) && !isElement(subtitle) ? (
                    <FormattedText format={(subtitle as any).format}>
                      {(subtitle as any).value}
                    </FormattedText>
                  ) : (
                    subtitle
                  )}
                </SubtitleContainer>
              </TextOverflowWrapper>
            ) : null}
          </TextContainer>
        </LeftContentContainer>
        {badge && !layoutWithFooter ? (
          <BadgeContainer>
            <Badge label={badge} />
          </BadgeContainer>
        ) : null}
        {!disabled && !layoutWithFooter ? (
          <RightContentContainer onClick={handleEditClick}>
            {rightContent}
          </RightContentContainer>
        ) : null}
      </ContentContainer>
      {layoutWithFooter ? (
        <FooterContent>
          {badge ? (
            <Badge label={badge} />
          ) : (
            <FooterText>{mobileFooterText}</FooterText>
          )}
          {rightContent}
        </FooterContent>
      ) : null}
      {highlight ? <Highlight>{highlight}</Highlight> : null}
    </ListItemContainer>
  );
};

export const ListItem = withMatchMedia(ListItemComponent);
