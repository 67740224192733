import { Navigate } from 'react-router-dom';

import { useWorkflowPathname } from '../../../../../hooks/useWorkflowPathname/useWorkflowPathname';

const RedirectToCoApplicant = ({ context }: any) => {
  const rootPath = useWorkflowPathname();

  return (
    <Navigate
      state={{ ...context, isMapped: false }}
      to={`/${rootPath}/workflow/co-applicant/`}
    />
  );
};

export default RedirectToCoApplicant;
