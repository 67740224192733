import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentUserContext } from '../../hooks/useCurrentUserContext/useCurrentUserContext';
import { useWorkflowPathname } from '../../hooks/useWorkflowPathname/useWorkflowPathname';
import {
  availableSearchParams,
  SearchParamKey,
} from '../../services/urlParams/urlParams';
import {
  urlParamHook,
  urlParamsConfig,
} from '../../services/urlParams/urlParamsService';

export const UrlParamsProcessors = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const [currentUserContext] = useCurrentUserContext();
  const { id: authenticatedUserId } = currentUserContext;
  const workflowRootPath = useWorkflowPathname();
  const navigate = useNavigate();

  useEffect(() => {
    const { savedParam: id, removeParam } = urlParamHook(
      SearchParamKey.vehicleId,
    );
    if (id && authenticatedUserId) {
      removeParam();
      navigate(`/${workflowRootPath}/dashboard/vehicle/application`, {
        replace: true,
        state: {
          currentVehicle: { id },
          openSummary: true,
        },
      });
    }
  }, [authenticatedUserId, navigate, workflowRootPath]);

  useEffect(() => {
    if (authenticatedUserId) {
      availableSearchParams.forEach((param) => {
        if (urlParamsConfig[param]?.removeAfterAuth) {
          const { savedParam, removeParam } = urlParamHook(param);
          savedParam && removeParam();
        }
      });
    }
  }, [authenticatedUserId]);

  return children;
};
