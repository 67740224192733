export const phoneNumberFormatter = (
  phoneNumber: string | number,
): string | null => {
  if (phoneNumber) {
    const phone =
      typeof phoneNumber === 'string' ? phoneNumber : phoneNumber.toString();
    const carrier = phone.substring(0, 3);
    const part2 = phone.substring(3, 6);
    const part3 = phone.substring(6);
    return `+1 (${carrier}) ${part2}-${part3}`;
  }
  return null;
};
