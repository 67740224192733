import { theme } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const Container = styled('div', ({ $theme }: any) => ({
  ...theme.fonts.paragraph,
  width: '100%',
  borderRadius: theme.unit[1],
  border: `1px solid ${$theme.colors.error}`,
  color: $theme.colors.error,
  padding: theme.unit[3],
}));

export const Row = styled('div', () => ({
  ':not(:last-child)': {
    marginBottom: theme.unit[1],
  },
}));
