import { merge } from 'lodash';

import { populateFields } from '../../../../../forms/populateFields';
import { withContext } from '../../../../../utils/workflow/withContext';
import CommonPersonalDetailsModel from '../../../../common/Steps/ApplicantPersonalInfo/Model';
import BaseModel from '../common/BaseModel';

const model = merge({}, CommonPersonalDetailsModel, BaseModel, {
  template: {
    header: {
      showBack: false,
    },
  },
  headerBlock: {
    title: 'Please confirm your personal details',
    subtitle: ['Applicant', '20% complete'],
    progressBar: {
      progress: 20,
    },
  },
});

const populateData =
  (fields: any) =>
  (send: any, { context }: any) => {
    return populateFields(fields, context.updatedUser);
  };

const modelFn = (...args: [any, any]) =>
  withContext({ model, populateFields: populateData })(...args);

export default modelFn;
