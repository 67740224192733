import { font, unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const Container = styled(
  'div',
  ({
    $isMobile,
    $theme,
  }: {
    $isMobile: boolean | undefined;
    $theme?: any;
  }) => ({
    padding: `${unit[6]} ${unit[3]}`,
    borderRadius: unit[2],
    border: `1px solid ${$theme.colors.secondaryOverlay}`,
    display: 'flex',
    ...($isMobile
      ? {
          flexDirection: 'column',
        }
      : {
          justifyContent: 'space-between',
        }),
  }),
);

export const Info = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
}));

export const Symbol = styled(
  'div',
  ({
    $isDisabled,
    $theme,
  }: {
    $isDisabled: boolean | undefined;
    $theme?: any;
  }) => ({
    width: unit[7],
    height: unit[7],
    backgroundColor: $isDisabled
      ? $theme.colors.disabled
      : $theme.colors.secondaryActive,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: font.family.Montserrat,
    fontSize: font.size.headingLarge,
    fontWeight: '500',
    color: $isDisabled ? $theme.colors.primaryActive : $theme.colors.primary,
    marginBottom: unit[3],
    textTransform: 'uppercase',
  }),
);

export const Title = styled(
  'div',
  ({
    $isDisabled,
    $theme,
  }: {
    $isDisabled: boolean | undefined;
    $theme?: any;
  }) => ({
    fontFamily: font.family.Merriweather,
    fontSize: font.size.headingLarge,
    color: $isDisabled
      ? $theme.colors.primaryActive
      : $theme.colors.infoOverlay,
    marginBottom: unit[1],
  }),
);

export const Subtitle = styled('div', ({ $theme }: any) => ({
  fontFamily: font.family.Montserrat,
  color: $theme.colors.primaryActive,
  fontSize: font.size.headingMedium,
}));

export const Controls = styled(
  'div',
  ({ $isMobile }: { $isMobile: boolean | undefined }) => ({
    display: 'flex',
    gap: unit[3],
    ...($isMobile
      ? {
          marginTop: unit[3],
          flexDirection: 'column-reverse',
        }
      : {}),
  }),
);
