import { Navigate } from 'react-router-dom';

import { useCurrentUserContext } from '../hooks/useCurrentUserContext/useCurrentUserContext';
import { useWorkflowPathname } from '../hooks/useWorkflowPathname/useWorkflowPathname';

function RequireContext({ children }: { children: JSX.Element }) {
  const [currentUserContext] = useCurrentUserContext();
  const workflowRootPath = useWorkflowPathname();

  if (!currentUserContext.id) {
    return <Navigate to={'/' + workflowRootPath} replace />;
  }
  return children;
}

export default RequireContext;
