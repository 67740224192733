import { uniqueId } from 'lodash';

import { ApplicationTaskEnum } from '../../constants/applicationTasks';
import { useWorkflowPathname } from '../../hooks/useWorkflowPathname/useWorkflowPathname';
import { ApplicationDataInterface } from '../../pages/Applications/applicationData.interface';
import { NO_TASKS_CONTENT } from '../../services/task/taskService';
import { InfoBlock } from '../shared/InfoBlock/InfoBlock';
import { Tasks } from './styles';
import { TaskCard } from './TaskCard';

export const ApplicationTasks = ({
  isList,
  availableTasks,
  state,
  type,
}: {
  isList?: boolean;
  availableTasks: any;
  state: ApplicationDataInterface;
  type: 'application' | 'loan';
}) => {
  const workflowName = useWorkflowPathname();
  const noTasksContent = NO_TASKS_CONTENT[type][workflowName];

  return availableTasks?.length ? (
    <Tasks>
      {isList ? (
        availableTasks.map((task: ApplicationTaskEnum) => (
          <TaskCard key={uniqueId('task-')} state={state} task={task} />
        ))
      ) : (
        <TaskCard state={state} task={availableTasks[0]} />
      )}
    </Tasks>
  ) : (
    <InfoBlock title={noTasksContent.title} disableMargin>
      <span>{noTasksContent.body}</span>
    </InfoBlock>
  );
};
