import { isEmpty, toString } from 'lodash';

import { ApplicantState } from '../ApplicantState';
import { UserResidencesDto } from '../dto/UserResidencesDto';
import { ownershipStatusToResidenceType } from './ownershipStatusToResidenceType';
import { reduceResidenceMonthsAt } from './reduceResidenceMonthsAt';

export const toApplicantStateFromResidences = (
  residences: UserResidencesDto,
): Partial<ApplicantState> => {
  const state: Partial<ApplicantState> = {};

  if (isEmpty(residences)) {
    return state;
  }

  if (residences.current) {
    state.currentResidenceCity = residences.current.physicalAddressCity || null;
    state.currentResidenceId = residences.current.id || null;
    state.currentResidenceLineOne =
      residences.current.physicalAddressLineOne || null;
    state.currentResidenceLineTwo =
      residences.current.physicalAddressLineTwo || null;
    state.currentResidenceMonthlyPaymentAmount =
      toString(residences.current.monthlyPaymentAmount) || null;
    state.currentResidenceMonthsAt = toString(
      reduceResidenceMonthsAt(residences.current.monthsAtResidence),
    );
    state.currentResidencePostalCode =
      residences.current.physicalAddressPostalCode || null;
    state.currentResidenceState =
      residences.current.physicalAddressProvince || null;
    state.currentResidenceType = ownershipStatusToResidenceType(
      residences.current.ownershipStatus,
    );
  }

  if (residences.previous) {
    state.previousResidenceCity =
      residences.previous.physicalAddressCity || null;
    state.previousResidenceId = residences.previous.id || null;
    state.previousResidenceLineOne =
      residences.previous.physicalAddressLineOne || null;
    state.previousResidenceLineTwo =
      residences.previous.physicalAddressLineTwo || null;
    state.previousResidencePostalCode =
      residences.previous.physicalAddressPostalCode || null;
    state.previousResidenceState =
      residences.previous.physicalAddressProvince || null;
  }

  return state;
};
