import { set } from 'lodash';
import { assign, MachineConfig } from 'xstate';

import { ChecklightSessionStatus } from '../../../../common';
import { UserVehicle } from '../../../../common/interfaces/vehicle/vehicle.interface';

const Signal = {
  Next: 'Next',
  Stop: 'Stop',
  Start: 'Start',
  Previous: 'Previous',
};

const Step = {
  ReviewSsn: 'ReviewSsn',
  Done: 'Done',
};

export const Routes = {
  [Step.ReviewSsn]: 'review-ssn',
};

export interface ReviewContext {
  currentVehicle: UserVehicle;
  ssn?: string;
  coApplicantSsn?: string;
}

const workflowName = 'unified-default-review';
const defaultConfiguration: MachineConfig<ReviewContext, any, any> = {
  id: workflowName,
  initial: Step.ReviewSsn,
  states: {
    [Step.ReviewSsn]: {
      on: {
        [Signal.Next]: {
          target: Step.Done,
          actions: assign((context) =>
            set(
              context,
              'currentVehicle.checklightSession.status',
              ChecklightSessionStatus.SUBMITTED_TO_LENDER,
            ),
          ),
        },
      },
    },
    [Step.Done]: {
      type: 'final',
    },
  },
};

// NOTE: You can paste the content of this file into @see https://xstate.js.org/viz
// and uncomment this line to test the logic manually
// Machine({ ...defaultConfiguration }, { ...defaultServiceOptions });

export { Step, Signal, workflowName, defaultConfiguration };

export default defaultConfiguration;
