import { animations, theme } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

const getBackdropColumns = (isDesktop: any, isTablet: any) => {
  if (isDesktop) {
    // this is the template grid so we can overlay the modal in the right place
    // return `${theme.dimensions.sidebarWidth} minmax(0, 12rem) ${theme.dimensions.stageWidth} auto`;

    // display modal centered
    return `0 auto minmax(auto, ${theme.dimensions.stageWidth}) auto`;
  }
  if (isTablet) {
    return 'auto 87.5% auto';
  }

  return '';
};

const getModalColumn = (isDesktop: any, isTablet: any) => {
  if (isDesktop) {
    return 3;
  }
  if (isTablet) {
    return 2;
  }

  return '';
};

export const ModalBackdrop = styled(
  'div',
  ({ $isDesktop, $isTablet, $isMobile, $height, $theme }: any) => ({
    display: !$isMobile ? 'grid' : 'block',
    gridTemplateColumns: getBackdropColumns($isDesktop, $isTablet),
    gridTemplateRows: !$isMobile ? 'auto min-content auto' : '',
    backgroundColor: $isMobile
      ? $theme.colors.primaryOverlay
      : $theme.colors.transparency.blackTransparentMedium,
    width: '100%',
    height: $height,
    overflow: 'auto',
    position: 'fixed',
    top: 0,
    ...(!$isMobile ? animations.backgroundFadeIn : {}),
    zIndex: theme.zIndexMap.modal,
  }),
);

export const ModalContainer = styled(
  'div',
  ({ $isDesktop, $isTablet, $isMobile, $theme }: any) => ({
    display: 'grid',
    gridRow: !$isMobile ? 2 : '',
    gridColumn: getModalColumn($isDesktop, $isTablet),
    gridTemplateRows: 'auto 1fr auto',
    ...(!$isMobile ? { maxHeight: '100vh', ...animations.fromTop } : {}),
    backgroundColor: $theme.colors.primaryOverlay,
    borderRadius: !$isMobile ? theme.unit[2] : '',
    cursor: 'default',
    width: '100%',
    textAlign: 'left',
  }),
);

export const HeaderContainer = styled('div', ({ $isMobile }: any) => ({
  gridRow: 1,
  padding: $isMobile ? `0 ${theme.unit[3]}` : `0 ${theme.unit[6]}`,
}));

export const CloseContainer = styled('div', () => ({
  height: '5rem',
}));

export const ModalBody = styled('div', ({ $isMobile, $theme }: any) => ({
  gridRow: 2,
  color: $theme.colors.primaryActive,
  overflow: 'auto',
  padding: $isMobile
    ? `0 ${theme.unit[3]} ${theme.unit[3]}`
    : `0 ${theme.unit[6]} ${theme.unit[3]}`,
  ...theme.fonts.paragraph,
  fontWeight: '500',
}));

export const ButtonGroupContainer = styled('div', ({ $isMobile }: any) => ({
  gridRow: 3,
  padding: $isMobile
    ? `${theme.unit[3]} ${theme.unit[3]}`
    : `${theme.unit[3]} ${theme.unit[6]} ${theme.unit[6]}`,
}));

export const ImageBackground = styled(
  'div',
  ({ $isMobile, $theme }: any) =>
    ({
      backgroundColor: $theme.colors.secondaryActive,
      margin: `-${theme.unit[10]} -${
        $isMobile ? theme.unit[3] : theme.unit[6]
      } ${theme.unit[5]}`,
      ...($isMobile
        ? {}
        : { borderRadius: [theme.unit[2], theme.unit[2], 0, 0].join(' ') }),
      height: '334px',
      ...theme.common.helpers.flexVerticalCenter,
    } as any),
);

export const ImageContainer = styled('div', {
  width: '300px',
  position: 'relative',
});

export const HeaderBlockContainer = styled(
  'div',
  ({ $showBorder, $extendedMarginBottom, $theme }: any) => ({
    ...($showBorder
      ? {
          borderBottom: `1px solid ${$theme.colors.secondaryOverlay}`,
          paddingBottom: theme.unit[3],
        }
      : {}),
    marginBottom: $extendedMarginBottom ? theme.unit[6] : '',
  }),
);

export const ModalContentTitle = styled('div', ({ $theme }: any) => ({
  ...theme.fonts.headingLarge,
  fontWeight: 600,
  marginBottom: theme.unit[1],
  color: $theme.colors.infoOverlay,
}));

export const LoadingContainer = styled('div', () => ({
  marginBottom: `-${theme.unit[4]}`,
}));
