import { OwnershipStatus } from '../../../common';
import { ResidenceTypeEnum } from '../enums/ResidenceTypeEnum';

export const residenceTypeToOwnershipStatus = (
  residenceType: ResidenceTypeEnum | null,
): OwnershipStatus => {
  switch (residenceType) {
    case ResidenceTypeEnum.OWN:
      return OwnershipStatus.OWN;
    case ResidenceTypeEnum.RENT:
      return OwnershipStatus.RENT;
    default:
      return OwnershipStatus.UNKNOWN;
  }
};
