import { merge } from 'lodash';
import { useCallback, useMemo } from 'react';

import { EmploymentType } from '../../../../../common';
import { PillowForm } from '../../../../../components/PillowForm';

const Controller = (props: any) => {
  const { presModel, send, context: workflowDetails } = props;
  const { updatedUser = {} } = workflowDetails || {};

  const onSubmit = useCallback(
    (updatedIncome: any) => {
      send('Next', {
        updatedUser: {
          ...updatedUser,
          incomes: {
            ...updatedUser.incomes,
            additional: (updatedUser.incomes.additional || []).concat({
              ...updatedIncome,
              incomeType: EmploymentType.OTHER,
            }),
          },
        },
      });
    },
    [updatedUser, send],
  );

  const onBack = useCallback(() => {
    send('Previous');
  }, [send]);

  const enhancedProps = useMemo(
    () =>
      merge(
        {},
        { presModel },
        {
          presModel: {
            template: {
              header: {
                onBack,
              },
            },
            form: {
              actions: {
                primary: {
                  handler: onSubmit,
                },
              },
            },
          },
        },
      ),
    [onSubmit, presModel, onBack],
  );

  return <PillowForm {...enhancedProps} />;
};

Controller.displayName = 'ApplicantIncomeAdd.Controller';
export default Controller;
