import { useModal } from '../../../hooks/useModal/useModal';
import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import { use100vh } from '../../../utils/use100vh';
import Logo from '../Logo/Logo';
import {
  FooterGridContainer,
  HeaderGridContainer,
  LogoContainer,
  MainGridContainer,
  PillowContainer,
  PillowGridContainer,
  StageGridContainer,
} from './styles';

const TemplatePillowPageComponent = ({
  headerContent,
  footerContent,
  pillowContent,
  isDesktop,
  isTablet,
  isMobile,
  children,
  centerContent,
  persistImage,
  LogoContainerOverflow,
}: {
  headerContent: any;
  footerContent: any;
  pillowContent: any;
  isDesktop?: boolean;
  isTablet?: boolean;
  isMobile?: boolean;
  children: any;
  centerContent?: boolean;
  persistImage?: boolean;
  LogoContainerOverflow?: boolean;
}) => {
  const { isShowingAny } = useModal();

  return (
    <MainGridContainer
      $hasFooter={!!footerContent}
      $height={use100vh()}
      $isDesktop={isDesktop}
      $modalOpened={isShowingAny}
    >
      <HeaderGridContainer
        $isDesktop={isDesktop}
        $isMobile={isMobile}
        $isTablet={isTablet}
        $persistImage={persistImage}
      >
        {headerContent}
      </HeaderGridContainer>
      {isDesktop || persistImage ? (
        <PillowGridContainer $isDesktop={isDesktop}>
          {isDesktop || !LogoContainerOverflow ? (
            <LogoContainer $isDesktop={isDesktop} $isTablet={isTablet}>
              <Logo />
            </LogoContainer>
          ) : null}
          <PillowContainer $isDesktop={isDesktop} $isTablet={isTablet}>
            {pillowContent}
          </PillowContainer>
        </PillowGridContainer>
      ) : null}
      <StageGridContainer
        $centerContent={centerContent}
        $hasFooter={!!footerContent}
        $isDesktop={isDesktop}
        $isTablet={isTablet}
      >
        {children}
      </StageGridContainer>
      {footerContent ? (
        <FooterGridContainer $isDesktop={isDesktop} $isTablet={isTablet}>
          {footerContent}
        </FooterGridContainer>
      ) : null}
    </MainGridContainer>
  );
};

export const TemplatePillowPage = withMatchMedia(TemplatePillowPageComponent);
