import { merge } from 'lodash';

import { populateFields } from '../populateFields';

class Action {
  testId?: string;
  label?: string;
  handler?: (data: any) => void;

  constructor(props: { testId?: string; label?: string; handler?: any }) {
    this.testId = props.testId;
    this.label = props.label;
    this.handler = props.handler;
  }
}

class PrimaryAction extends Action {
  constructor(props: any) {
    super(props);
    this.testId = this.testId || 'continue';
    this.label = this.label || 'Continue';
  }
}

class SecondaryAction extends Action {
  constructor(props: any) {
    super(props);
    this.testId = this.testId || 'back';
    this.label = this.label || 'Back';
  }
}

interface Actions {
  primary?: Action;
  secondary?: Action;
}

export class Form {
  fields: any;
  actions: Actions;
  isStickyActions: any;
  actionsContent: any;
  fieldErrors: any;
  globalErrors: any;
  formReset: any;
  formSet: any;
  autoFocus?: boolean;

  constructor(props: { fields: any; actions?: Actions; autoFocus?: boolean }) {
    // Support object and array. Keys are not important
    this.fields = Object.values(props.fields);
    this.actions = this.mapActions(props.actions || {});
    this.autoFocus = props.autoFocus;
  }

  public populateFields(source: any) {
    this.fields = merge([], this.fields, populateFields(this.fields, source));
  }

  private mapActions(actions: Actions) {
    const result: Actions = {};
    if ('primary' in actions) {
      result.primary = new PrimaryAction(actions.primary);
    }
    if ('secondary' in actions) {
      result.secondary = new SecondaryAction(actions.secondary);
    }
    return result;
  }
}
