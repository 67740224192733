import { HttpStatusCode } from '../HttpStatusCodeEnum';
import HttpError from './HttpError';

export class NotImplementedError extends HttpError {
  constructor(message = 'Not Implemented') {
    super(message);
    this.statusCode = HttpStatusCode.NOT_IMPLEMENTED;
    this.publicErrorMessage = message;
  }
}
