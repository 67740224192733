/* eslint-disable react-refresh/only-export-components */
import PropTypes from 'prop-types';

import { theme } from '../../../common/theme';
import { componentTypes } from '../../../constants/componentTypes';
import { Checkbox } from '../checkbox/Checkbox';
import { Icon } from '../Icon/Icon';
import { IconTypes } from '../Icon/iconTypes';
import { RotationDirections } from '../Icon/rotationDirections';
import { ImagePlaceholderFitContainer } from '../imagePlaceholder/ImagePlaceholder';
import { ListItem } from '../listItem/ListItem';
import { ListItemLoading } from '../listItem/ListItemLoading';
import { PanelLink } from '../PanelList/styles';
import { Radio } from '../radio/Radio';

const LoadingContent = ({ isWithImage }: any) => {
  const imageProps = isWithImage
    ? {
        image: <ImagePlaceholderFitContainer />,
        imageOnTopForMobile: true,
      }
    : {};
  return (
    <>
      {Array.from({ length: 3 }, (v, i) => (
        <ListItemLoading key={i} {...imageProps} />
      ))}
    </>
  );
};

LoadingContent.propTypes = {
  isMultiline: PropTypes.bool,
  isWithImage: PropTypes.bool,
};

export const ListItemSelect = ({
  testId,
  list,
  options,
  largeTitle = false,
  onChange = () => {},
  isLoading,
  loadingContentOptions,
  component,
  value,
  cta,
}: any) => {
  const listItems = options || list || [];
  const multiSelect = component === componentTypes.MULTI_SELECT;
  const checked = (listItemValue: any) => {
    const currentValue = multiSelect ? value : [value];
    return currentValue.includes(listItemValue);
  };

  const rightContent = (listItem: any) => {
    const rightContentMap: any = {
      [componentTypes.MULTI_SELECT]: (
        <Checkbox checked={checked(listItem.value)} testId={listItem.testId} />
      ),
      [componentTypes.RADIO]: <Radio selected={listItem.value === value} />,
      [componentTypes.SINGLE_SELECT]: (
        <Icon
          height={theme.unit[3]}
          name={IconTypes.ARROW}
          rotate={RotationDirections.RIGHT}
        />
      ),
      [componentTypes.SUMMARY_LIST]: <PanelLink>{cta || 'View'}</PanelLink>,
      [componentTypes.UPLOAD]: (
        <PanelLink>{listItem.documentInfo ? 'Edit' : 'Upload'}</PanelLink>
      ),
      [componentTypes.UPLOAD_REMOVEABLE]: (
        <PanelLink>{listItem.documentInfo ? 'Remove' : 'Upload'}</PanelLink>
      ),
    };

    return rightContentMap[component];
  };

  const testIdProp = !multiSelect ? { 'data-testid': testId } : undefined;
  return (
    <div {...testIdProp}>
      {isLoading && <LoadingContent {...loadingContentOptions} />}
      {!isLoading &&
        listItems.map(({ testId: listItemTestId, ...item }: any) => (
          <ListItem
            badge={item.badge}
            checked={checked(item.value)}
            component={component}
            disabled={item.disabled}
            highlight={item.highlight}
            image={item.image}
            imageOnTopForMobile={item.imageOnTopForMobile}
            key={item.key || item.value}
            largeTitle={item.largeTitle || largeTitle}
            mobileFooterText={item.mobileFooterText}
            rightContent={rightContent(item)}
            showFooterOnMobile={item.showFooterOnMobile}
            subtitle={item.subtitle}
            testId={listItemTestId || item.key || item.value}
            title={item.title || item.label || item.name}
            value={item.value}
            onChange={onChange}
          />
        ))}
    </div>
  );
};

export const listItemSelectPropTypes = {
  largeTitle: PropTypes.bool,
  imageOnTopForMobile: PropTypes.bool,
  dividerLeftMargin: PropTypes.number,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      onChange: PropTypes.func,
      value: PropTypes.any.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      image: PropTypes.any,
    }),
  ),
  isLoading: PropTypes.bool,
  loadingContentOptions: PropTypes.shape({
    isWithImage: PropTypes.bool,
  }),
  onChange: PropTypes.func,
  component: PropTypes.string,
  value: PropTypes.any,
};

ListItemSelect.propTypes = listItemSelectPropTypes;
