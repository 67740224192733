import { useContext, useEffect } from 'react';

import image from '../../assets/heroes-cheering.svg';
import { useModal } from '../../hooks/useModal/useModal';
import { DynamicBrandingContext } from '../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { SearchParamKey } from '../../services/urlParams/urlParams';
import { urlParamHook } from '../../services/urlParams/urlParamsService';
import { Modal } from '../shared/Modal/Modal';

export const GlobalMigrateUserModal = () => {
  const { open, close, isShowing } = useModal();
  const {
    branding: { theme: $theme },
  } = useContext(DynamicBrandingContext);

  const { savedParam: migrateUser, removeParam: removeMigrateUser } =
    urlParamHook(SearchParamKey.migrateUser);

  const shouldDisplayMigrateUser = migrateUser === 'true';

  const modalButtons = {
    rtl: true,
    primaryButton: {
      name: 'close',
      children: 'Close',
      testId: 'confirm',
    },
  };

  useEffect(() => {
    if (shouldDisplayMigrateUser) {
      open();
    }
  });

  if (isShowing) {
    return (
      <Modal
        buttonGroupPresModel={modalButtons}
        modalImage={{
          src: image,
        }}
        paragraph="We've updated you to the latest version of the customer portal. In this new version you have access to more features and support regarding your loan application. Please use your phone number to sign into your account."
        subtitle="Welcome"
        subtitleColor={$theme.colors.primaryActive}
        title="New version is now available"
        onClose={() => {
          close();
          removeMigrateUser();
        }}
      />
    );
  }

  return null;
};
