import {
  ADDITIONAL_INCOME as additionalIncomeChoices,
  EmploymentType,
  PayFrequency,
  RelationshipType,
} from '../../common';

export const ADDITIONAL_INCOME = additionalIncomeChoices;

export const INCOME_TYPE_CHOICES = [
  {
    value: EmploymentType.FULL_TIME,
    label: 'Employed (W2)',
  },
  {
    value: EmploymentType.SELF_EMPLOYED,
    label: 'Self-employed (1099)',
  },
  {
    value: EmploymentType.OTHER,
    label: 'Retired / Unemployed (other)',
  },
];

export const INCOME_FREQUENCY_CHOICES = [
  {
    label: 'Annually',
    value: PayFrequency.YEARLY,
  },
  {
    label: 'Bi-Weekly',
    value: PayFrequency.BI_WEEKLY,
  },
  {
    label: 'Monthly',
    value: PayFrequency.MONTHLY,
  },
  {
    label: 'Semi-Monthly',
    value: PayFrequency.TWICE_MONTHLY,
  },
];

export const RELATIONSHIP_STATUS_CHOICES = [
  {
    label: 'Spouse',
    value: RelationshipType.SPOUSE,
  },
  {
    label: 'Relative',
    value: RelationshipType.RELATIVE,
  },
  {
    label: 'Co-worker',
    value: RelationshipType.CO_WORKER,
  },
  {
    label: 'Friend',
    value: RelationshipType.FRIEND,
  },
  {
    label: 'Other',
    value: RelationshipType.OTHER,
  },
];

export const AGREEMENT_STATUSES = {
  YES: 'YES',
  NO: 'NO',
};
