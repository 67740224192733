import { unit } from '../../../../common/theme/index';
import { styled } from '../../../../providers/context/StyletronProvider/withStyletronProvider';

export const FormMarginFix = styled('div', {
  marginTop: `-${unit[4]}`,
});

export const FormContainer = styled('div', ({ $isDesktop }: any) => ({
  ...($isDesktop ? { maxWidth: '672px' } : {}),
}));
