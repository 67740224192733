import { Fragment, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { IncomeType } from '../../../common';
import { EditAdditionalIncome } from '../../../components/Incomes/common/EditAdditionalIncome/EditAdditionalIncome';
import { EditPrimaryIncome as UnifiedEditPrimaryIncome } from '../../../components/Incomes/Unified/EditPrimaryIncome/EditPrimaryIncome';
import { EditPageHeader } from '../../../components/shared/PageHeader/EditPageHeader';
import { lsPrefix } from '../../../config';

export const IncomeEdit = () => {
  const location = useLocation();
  const { state }: any = location;
  const { type: incomeTypeFromState } = state || {};
  const navigate = useNavigate();
  const incomeTypeLsKey = `${lsPrefix}:editIncomeType`;

  const incomeType = useMemo(
    () => incomeTypeFromState ?? localStorage.getItem(incomeTypeLsKey),
    [incomeTypeFromState, incomeTypeLsKey],
  );

  useEffect(() => {
    if (incomeType) {
      localStorage.setItem(incomeTypeLsKey, incomeType);
    } else {
      navigate('..');
    }
    return () => {
      localStorage.removeItem(incomeTypeLsKey);
    };
  }, []);

  return (
    <Fragment>
      <EditPageHeader
        breadCrumbs={['Account', 'Income']}
        handleGoBack={() => navigate('..')}
        title="Edit your income details."
      />
      {!!incomeType &&
        (incomeType === IncomeType.PRIMARY_INCOME ? (
          <UnifiedEditPrimaryIncome />
        ) : (
          <EditAdditionalIncome />
        ))}
    </Fragment>
  );
};
