import { font, unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const BadgeContainer = styled('div', ({ $theme }: any) => ({
  backgroundColor: $theme.colors.tertiaryOverlay,
  borderRadius: unit[2],
  height: unit[4],
  width: 'max-content',
  padding: `0 ${unit[2]}`,
  fontWeight: 500,
  fontSize: font.size.headingSmall,
  color: $theme.colors.primary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
