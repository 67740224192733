import { unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const ErrorWrapper = styled(
  'div',
  ({ $isDesktop }: { $isDesktop?: boolean }) => ({
    marginTop: unit[3],
    ...($isDesktop
      ? {}
      : {
          marginLeft: unit[6],
          marginRight: unit[6],
        }),
  }),
);
