import { unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

const setGridGap = (isMobile: any, singleButton: any, isVertical: any) => {
  if (singleButton) {
    return '';
  }
  if (isVertical) {
    return { gridRowGap: unit[1] };
  }
  return { gridColumnGap: isMobile ? unit[2] : unit[3] };
};

const setGridDirection = (
  isMobile: any,
  isVerticallyAligned: any,
  singleButton: any,
  hasContentAfterButton: any,
) => {
  if (isVerticallyAligned) {
    return {
      gridTemplateRows: `repeat(${singleButton ? '1' : '2'}, minmax(0, 1fr))`,
    };
  }
  if (isMobile && !isVerticallyAligned) {
    return {
      gridTemplateColumns: hasContentAfterButton
        ? '100%'
        : 'repeat(2, minmax(0, 1fr))',
    };
  }
  return { gridAutoColumns: 'min-content' };
};

// This used function was causing lint errors, I've commented it out for now.
// const setGridTemplateColumns = (isMobile, singleButton) => {
// 	if (singleButton && isMobile) {
// 		return '47.5%';
// 	}
// 	return isMobile ? '47.5% 47.5%' : '';
// };

export const Container = styled(
  'div',
  ({
    $isMobile,
    $singleButton,
    $rtl,
    $isVerticallyAligned,
    $hasContentAfterButton,
  }: any) => ({
    display: 'grid',
    gridAutoFlow: 'column',
    ...setGridGap($isMobile, $singleButton, $isVerticallyAligned),
    ...setGridDirection(
      $isMobile,
      $isVerticallyAligned,
      $singleButton,
      $hasContentAfterButton,
    ),
    width: '100%',
    direction: $rtl ? 'rtl' : 'ltr',
  }),
);

// Needed solely to create divs for the grid
export const ButtonDiv = styled('div', ({ name, $order }: any) => ({
  name,
  key: name,
  ...($order ? { order: $order } : {}),
}));
