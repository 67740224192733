import { useCallback, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';

export const withDependencies =
  ({ setValue, control, dependsOn, defaultValue }: any) =>
  (FormControl: any) => {
    return (props: any) => {
      const { validationRules: initialValidationRules } = props;
      const [validationRules, setValidationRules] = useState(
        initialValidationRules,
      );
      const [isVisible, setVisible] = useState(true);
      const { fields, handler } = dependsOn;

      const relatedValues = useWatch({
        control,
        name: fields,
        defaultValue,
      });

      const setValidation = useCallback(
        (data: any) => {
          setValidationRules({
            ...initialValidationRules,
            ...data,
          });
        },
        [initialValidationRules],
      );

      const setCurrentFieldValue = useCallback(
        (value: any) => {
          setValue(props.name, value);
        },
        [props.name],
      );

      useEffect(() => {
        const formattedValues = fields.reduce(
          (acc: any, field: any, index: any) => ({
            ...acc,
            [field]: relatedValues[index],
          }),
          {},
        );

        handler({
          data: formattedValues,
          setValue,
          setVisible,
          setValidation,
          isVisible,
          setCurrentFieldValue,
        });
      }, [
        setValidation,
        props,
        relatedValues,
        fields,
        handler,
        isVisible,
        setCurrentFieldValue,
      ]);

      return isVisible ? (
        <FormControl {...{ ...props, validationRules }} />
      ) : null;
    };
  };
