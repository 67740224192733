import { get } from 'lodash';

import { componentTypes } from '../../../constants/componentTypes';
import { fields, fields as incomeFields } from '../../../forms/income';

export const commonFields = (type: 'current' | 'previous') => {
  const incomeTypeName = [type, 'incomeType'].join('.');
  const config: any = {
    ...(type === 'previous'
      ? {
          subtitle: {
            label: 'Previous income',
            name: 'subtitle',
            component: componentTypes.SUBTITLE,
          },
        }
      : {}),
    incomeType: fields.incomeType,
    employerName: {
      ...fields.employerName,
      shouldUnregister: true,
      dependsOn: {
        fields: [incomeTypeName],
        handler: ({ data, setVisible }: any) => {
          const incomeType = get(data, incomeTypeName);
          setVisible(incomeType !== 'OTHER');
        },
      },
    },
    jobTitle: {
      ...fields.jobTitle,
      shouldUnregister: true,
      dependsOn: {
        fields: [incomeTypeName],
        handler: ({ data, setVisible }: any) => {
          const incomeType = get(data, incomeTypeName);
          setVisible(incomeType !== 'OTHER');
        },
      },
    },
    additionalIncomeType: {
      ...fields.additionalIncomeType,
      label: 'Income subtype',
      shouldUnregister: true,
      dependsOn: {
        fields: [incomeTypeName],
        handler: ({ data, setVisible }: any) => {
          const incomeType = get(data, incomeTypeName);
          setVisible(incomeType === 'OTHER');
        },
      },
    },
    amount: {
      ...fields.amount,
      label: 'Gross income',
    },
    incomeFrequency: fields.incomeFrequency,
    startDate: fields.startDate,
  };

  return Object.keys(config).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        ...config[key],
        name: [type, config[key].name].join('.'),
      },
    }),
    {},
  );
};

export const additionalIncomeFields = {
  additionalIncomeType: incomeFields.additionalIncomeType,
  amount: {
    ...incomeFields.amount,
    label: 'Income amount',
  },
  incomeFrequency: incomeFields.incomeFrequency,
};
