import { merge } from 'lodash';

import { unifiedCommonFields } from '../../../../../utils/helpers/income/unified';
import { withContext } from '../../../../../utils/workflow/withContext';
import { onSubmit } from '../../../../common/Steps/ApplicantEmploymentPreviousInfo/utils';
import { getPreviousIncomeFieldValues } from '../../../../common/utils/applicant.utils';
import BaseModel from '../common/BaseModel';

const model = () => {
  const config = unifiedCommonFields('previous');
  const fields = Object.keys(config).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        ...config[key],
        name: ['incomes', config[key].name].join('.'),
      },
    }),
    {},
  );
  return merge({}, BaseModel, {
    form: {
      fields,
      actions: {
        primary: {
          label: 'Continue',
          testId: 'continue',
        },
      },
    },
    headerBlock: {
      title: 'Tell us about your previous employer',
      subtitle: ['Applicant', '60% complete'],
      progressBar: {
        progress: 60,
        withContainer: true,
      },
    },
    info: {
      title: 'Applicant previous employer',
      content: [
        {
          copy: [
            'Please add additional employment information in order to satisfy our one-year requirement.',
          ],
        },
      ],
    },
  });
};

const modelFn = (send: any, { context }: any) =>
  withContext({
    model: model(),
    populateFields: getPreviousIncomeFieldValues,
    onSubmit: onSubmit(context.updatedUser.incomes),
  })(send, { context });

export default modelFn;
