import { get } from 'lodash';
import { useAsync } from 'react-async-hook';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { UserVehicle } from '../../common/interfaces/vehicle/vehicle.interface';
import { unit } from '../../common/theme';
import { Loader } from '../../components/shared/Loader/Loader';
import AgreeToTermsGuard from '../../guards/AgreeToTermsGuard';
import { useAPI } from '../../hooks/useAPI/useAPI';
import { ApplicationPageContextInterface } from '../Applications/applicationPageContext.interface';

export const VehicleRoot = () => {
  const location = useLocation();
  const api = useAPI();
  const { state } = location as { state: any };

  const fetchVehicle = useAsync<UserVehicle>(() => {
    if (!state?.currentVehicle) {
      throw new Error('An error occurred');
    }

    return api.get(`/vehicles/${state.currentVehicle.id}`);
  }, []);

  if (!state?.currentVehicle) {
    return <Navigate to=".." replace />;
  }

  const vehicleId = get(fetchVehicle, 'result.id');
  const vehicleRequiresTerms =
    get(fetchVehicle, 'result.checklightSession.requiresTerms') === true;

  return fetchVehicle.loading ? (
    <Loader size={unit[6]} fullScreen />
  ) : vehicleId && vehicleRequiresTerms ? (
    <AgreeToTermsGuard vehicleId={vehicleId} />
  ) : (
    <Outlet
      context={
        {
          currentVehicle: {
            ...state?.currentVehicle,
            ...fetchVehicle?.result,
          },
          fetchVehicleError: fetchVehicle.error,
          openSummary: state?.openSummary && fetchVehicle.result?.isConfirmed,
        } as ApplicationPageContextInterface
      }
    />
  );
};
