import { merge } from 'lodash';

import { fields } from '../../../../../forms/user';
import { Signal } from '../../../../common/constants/signals';
import { AccountContext } from '../../common/unified-workflow-context.interface';
import { Step } from '../../steps';
import BaseModel from '../common/BaseModel';

const model = (
  send: any,
  { context, matches }: { context: AccountContext; matches: any },
) => {
  return merge({}, BaseModel, {
    template: {
      header: {
        showBack: true,
        onBack: () => send('Previous'),
      },
      centerContent: true,
    },
    headerBlock: {
      title: 'Are you a returning user?',
      paragraph: '',
    },
    form: {
      actions: {
        primary: {
          testId: 'signIn',
          label: 'Sign in',
          isLoading: matches(`${Step.CheckUserCredentials}.VerifyUser`),
          handler: () => send(Signal.Next),
        },
        secondary: {
          label: 'Back',
          testId: 'back',
          handler: () => send(Signal.Previous),
        },
      },
      fields: {
        ...(context.userVerificationError?.phone
          ? {
              primaryPhoneNumber: {
                ...fields.primaryPhoneNumber,
                testId: 'primaryPhoneNumber',
                value: context.user.primaryPhoneNumber,
                disabled: true,
                hint: 'An account with this Phone number is already registered. Click below to sign in',
              },
            }
          : {}),
        ...(context.userVerificationError?.email
          ? {
              primaryEmailAddress: {
                ...fields.primaryEmailAddress,
                testId: 'primaryEmailNumber',
                value: context.user.primaryEmailAddress,
                disabled: true,
                hint: 'An account with this Email address is already registered. Click below to sign in',
              },
            }
          : {}),
      },
      forceTrigger: true,
    },
  });
};

export default model;
