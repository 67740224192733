import { merge } from 'lodash';

import { withContext } from '../../../../../utils/workflow/withContext';
import ReviewIncomeModel from '../ReviewIncome/Model';

const model = (...args: [any, any]) => {
  return merge({}, ReviewIncomeModel(...args), {
    headerBlock: {
      title: 'Review Income',
      subtitle: ['Applicant', '100% complete'],
      progressBar: {
        progress: 100,
        withContainer: true,
      },
    },
  });
};

const modalFn = (...args: [any, any]) =>
  withContext({ model: model(...args) })(...args);

export default modalFn;
