import { MappedRefinanceOffer } from '../../../../common/interfaces/vehicle/offer.interface';
import {
  PanelListFormatType,
  PanelListType,
} from '../../../../components/shared/PanelList/PanelList';

export const offerPanelsConfig = (offer: MappedRefinanceOffer) => {
  const { amtPayment, pctAPR, term } = offer;
  return [
    {
      key: 0,
      testId: 'payment',
      panelType: PanelListType.TEXT,
      label: 'Payment',
      value: amtPayment.toFixed(),
      formatType: PanelListFormatType.NUMBER,
      formatOptions: {
        thousandSeparator: true,
        prefix: '$',
        displayType: 'text',
      },
    },
    {
      key: 1,
      testId: 'apr',
      panelType: PanelListType.TEXT,
      label: 'APR',
      value: parseFloat((pctAPR * 100).toFixed(2)),
      formatType: PanelListFormatType.NUMBER,
      formatOptions: {
        suffix: '%',
        displayType: 'text',
      },
    },
    {
      key: 2,
      testId: 'term',
      panelType: PanelListType.TEXT,
      label: 'Term',
      value: term,
      formatType: PanelListFormatType.NUMBER,
      formatOptions: {
        suffix: ' months',
        displayType: 'text',
      },
    },
  ];
};

export const protectionPanelsConfig = ({
  amtGapMonthly,
}: MappedRefinanceOffer) => [
  {
    key: 0,
    testId: 'gap',
    panelType: PanelListType.TEXT,
    label: 'GAP Coverage',
    ...(amtGapMonthly
      ? {
          formatType: PanelListFormatType.NUMBER,
          value: amtGapMonthly.toFixed(),
          formatOptions: {
            thousandSeparator: true,
            prefix: '$',
            displayType: 'text',
          },
        }
      : {
          value: 'No coverage',
        }),
  },
];
