import { VehicleInformationSource } from '../Steps/VehicleIdentity/options';
import { VehicleContext } from './unified-workflow-context.interface';

export const userWantsToAddVehicleByVin = ({
  vehicleInformationSource,
}: VehicleContext) => vehicleInformationSource === VehicleInformationSource.Vin;

export const userWantsToAddVehicleByLicensePlate = ({
  vehicleInformationSource,
}: VehicleContext) =>
  vehicleInformationSource === VehicleInformationSource.LicensePlate;

export const userWantsToAddVehicleByYearMakeAndModel = ({
  vehicleInformationSource,
}: VehicleContext) =>
  vehicleInformationSource === VehicleInformationSource.MakeAndModel;
