import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCurrentUserContext } from '../../hooks/useCurrentUserContext/useCurrentUserContext';
import { UserResidences } from '../../providers/context/CurrentUserContext/CurrentUserContextT';
import { PanelList, PanelListType } from '../shared/PanelList/PanelList';

export const HousingInfo = () => {
  const navigate = useNavigate();
  const [currentUserContext] = useCurrentUserContext();
  const {
    residences: {
      current: {
        physicalAddress: {
          lineOne = '',
          lineTwo = '',
          city = '',
          province = '',
          postalCode = '',
        } = {},
      } = {},
    } = {} as UserResidences,
  } = currentUserContext;

  const panelListConfig = useMemo(
    () => [
      {
        testId: 'housingInfo',
        panelType: PanelListType.TEXT,
        label: 'Current',
        value:
          [lineOne, lineTwo, city, province, postalCode]
            .filter((v) => !!v)
            .join(', ') || null,
      },
    ],
    [lineOne, lineTwo, city, postalCode, province],
  );

  return (
    <PanelList
      defaultValue="Not provided"
      panelList={panelListConfig}
      editable
      onEdit={() => navigate('edit')}
    />
  );
};
