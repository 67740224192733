/**
 * Constants relating to workflows
 */

export enum EmploymentType {
  ACTIVE_MILITARY = 'ACTIVE_MILITARY',
  CONTRACTOR = 'CONTRACTOR',
  FULL_TIME = 'FULL_TIME',
  PART_TIME = 'PART_TIME',
  RETIRED = 'RETIRED',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  UN_EMPLOYED = 'UN_EMPLOYED',
  OTHER = 'OTHER',
  STUDENT = 'STUDENT',
  HOMEMAKER = 'HOMEMAKER',
  DISABILITY = 'DISABILITY',
}

export const CHECKLIGHT_LEAD_CHANNEL = 'CHECKLIGHT';
