import { ApplicationTaskEnum } from '../../../constants/applicationTasks';
import { Gate } from '../../common/interfaces/gate.interface';
import { Step } from './steps';
import ExitController from './Steps/Exit/Controller';
import ExitToDashboardController from './Steps/ExitToDashboard/Controller';
import VerificationDocumentsListModel from './Steps/VerificationDocumentsList/Model';
import VerificationDocumentUpload from './Steps/VerificationDocumentUpload';
import VerificationDocumentUploadModel from './Steps/VerificationDocumentUpload/Model';
import VerificationLookupPillowModel from './Steps/VerificationLookupPillow/Model';
import defaultConfiguration, {
  defaultServiceOptions,
  VerificationContext,
} from './XState/Model';
import { ContextValidators } from './XState/validators';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const states = defaultConfiguration.states!;

export const VERIFICATION_GATE: Gate<VerificationContext> = {
  name: 'unified-default-verification',
  initial: Step.VerificationLookupPillow,
  showRoutes: true,
  cacheData: true,
  serviceOptions: defaultServiceOptions,
  accessibleForTask: ApplicationTaskEnum.UploadDocuments,
  steps: [
    {
      name: Step.VerificationLookupPillow,
      state: states[Step.VerificationLookupPillow],
      model: VerificationLookupPillowModel,
      path: '',
    },
    {
      name: Step.VerificationDocumentsList,
      state: states[Step.VerificationDocumentsList],
      model: VerificationDocumentsListModel,
      path: 'list',
      stepValidators: ContextValidators[Step.VerificationDocumentsList],
    },
    {
      name: Step.VerificationDocumentUpload,
      state: states[Step.VerificationDocumentUpload],
      model: VerificationDocumentUploadModel,
      controller: VerificationDocumentUpload,
      path: 'upload',
      stepValidators: ContextValidators[Step.VerificationDocumentUpload],
    },
    {
      name: Step.Exit,
      state: states[Step.Exit],
      controller: ExitController,
    },
    {
      name: Step.ExitToDashboard,
      state: states[Step.ExitToDashboard],
      controller: ExitToDashboardController,
    },
  ],
};
