import { Container, Title } from './styles';

export interface InfoBlockProps {
  isError?: boolean;
  title?: string;
  disableMargin?: boolean;
  contentBeforeTitle?: JSX.Element;
  children: any;
  hasBorder?: boolean;
  isErrorBorder?: boolean;
  customBorderColor?: string;
}

export const InfoBlock = ({
  contentBeforeTitle,
  isError,
  title,
  disableMargin,
  children,
  hasBorder,
  isErrorBorder,
  customBorderColor,
}: InfoBlockProps) => {
  return (
    <Container
      $customBorderColor={customBorderColor}
      $disableMargin={disableMargin}
      $hasBorder={hasBorder}
      $hasTitle={!!title}
      $isError={isError}
      $isErrorBorder={isErrorBorder}
    >
      {contentBeforeTitle}
      {!!title ? <Title>{title}</Title> : null}
      {children}
    </Container>
  );
};
