export enum componentTypes {
  DROPDOWN = 'DROPDOWN',
  INFO = 'INFO',
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  TEXT = 'TEXT',
  RADIO = 'RADIO',
  SUMMARY_LIST = 'SUMMARY_LIST',
  UPLOAD = 'UPLOAD',
  UPLOAD_REMOVEABLE = 'UPLOAD_REMOVEABLE',
  SUBTITLE = 'SUBTITLE',
}

export type ComponentType = keyof typeof componentTypes;
