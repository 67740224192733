import { merge, pick } from 'lodash';

import { IconTypes } from '../../../../../components/shared/Icon/iconTypes';
import { PillowType } from '../../../../../components/shared/PillowImage/pillowTypes';
import { populateFields } from '../../../../../forms/populateFields';
import { fields } from '../../../../../forms/vehicle';
import { Signal } from '../../../../common/constants/signals';
import { AccountState } from '../../common/unified-workflow-context.interface';
import BaseModel from '../common/BaseModel';

const model = (
  send: any,
  { context: { editedVehicle, coApplicant } }: AccountState,
) => {
  const searchByPlateFields = pick(fields, ['plate', 'province']);
  const savedFields = populateFields(searchByPlateFields, editedVehicle);
  const populatedFields = merge({}, searchByPlateFields, savedFields);
  const progress = coApplicant.coApplicantAnswer ? 63 : 68;

  return merge({}, BaseModel, {
    template: {
      header: {
        showBack: true,
        onBack: () => {
          send(Signal.Previous);
        },
      },
      img: {
        type: PillowType.VEHICLE_LOOKUP,
      },
    },
    headerBlock: {
      title: 'Enter license plate',
      icon: {
        props: {
          name: IconTypes.TOOLTIP,
          height: 24,
        },
      },
      progressBar: {
        progress,
        withContainer: true,
      },
      subtitle: ['Account', `${progress}% complete`],
    },
    form: {
      actions: {
        primary: {
          testId: 'search',
          label: 'Search',
        },
      },
      fields: populatedFields,
    },
    info: {
      title: 'Search using your Vehicle’s License Plate ',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Find your vehicle using the attached license plate number. You can find this in your insurance paperwork, or vehicle registration.',
          ],
        },
      ],
    },
  });
};

export default model;
