import { UNIFIED_EMPLOYMENT_TYPE_CHOICES } from '../../../../../constants/choices/unified';
import {
  UserIncome,
  UserIncomes,
} from '../../../../../providers/context/CurrentUserContext/CurrentUserContextT';
import {
  getAdditionalIncomes,
  getPrimaryIncomeWithoutLabel,
} from '../../../../common/Steps/ApplicantIncome/utils';

const getPrimaryIncomeLabel = (primaryIncome: UserIncome) => {
  const { employerName, incomeType } = primaryIncome;
  if (employerName) {
    return employerName;
  }
  return (
    (UNIFIED_EMPLOYMENT_TYPE_CHOICES.find((v) => v.value === incomeType) || {})
      .label || 'Other'
  );
};

const getPrimaryIncome = (primaryIncome: UserIncome) => {
  const primaryIncomeLabel = getPrimaryIncomeLabel(primaryIncome);

  return {
    ...getPrimaryIncomeWithoutLabel(primaryIncome),
    label: primaryIncomeLabel,
    largeTitle: true,
  };
};

export const getIncomesList = (
  incomes: UserIncomes & { current: UserIncome },
) => {
  const {
    current: primaryIncome,
    additional: additionalIncomes = [],
  }: UserIncomes & { current: UserIncome } = incomes;

  const primary = getPrimaryIncome(primaryIncome);

  const additional = getAdditionalIncomes(additionalIncomes).map((income) => ({
    ...income,
    largeTitle: true,
  }));

  return [primary, ...additional];
};
