import addCoApplicant from '../../../../../assets/Co-Applicant-Plus.svg';
import noCoApplicant from '../../../../../assets/No_Co_Applicant.svg';

export const optionsList = [
  {
    value: true,
    label: 'Add co-applicant',
    testId: 'true',
    image: addCoApplicant,
  },
  {
    value: false,
    label: 'No co-applicant',
    testId: 'false',
    image: noCoApplicant,
  },
];
