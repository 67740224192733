import { merge, pick } from 'lodash';

import { AGREEMENT_STATUSES } from '../../../../../constants/choices/checklight';
import { fields as coApplicantFields } from '../../../../../forms/co-applicant';
import { FormFieldsModelT } from '../../../../../forms/formFieldsModelT';
import { populateFields } from '../../../../../forms/populateFields';
import { fields } from '../../../../../forms/user';
import { withContext } from '../../../../../utils/workflow/withContext';
import BaseModel from '../common/BaseModel';

const populateData =
  (fields: any) =>
  (send: any, { context }: any) => {
    return populateFields(fields, context.coApplicant);
  };

const personalDetailsRawFields: FormFieldsModelT = {
  ...fields,
  ...coApplicantFields,
  sameAsApplicant: {
    ...coApplicantFields.sameAsApplicant,
    name: 'sameAsApplicant',
    value: AGREEMENT_STATUSES.YES,
  },
};

const personalDetailsFields = pick(personalDetailsRawFields, [
  'coApplicantRelationship',
  'firstName',
  'lastName',
  'primaryEmailAddress',
  'primaryPhoneNumber',
  'dob',
]);

const model = merge({}, BaseModel, {
  template: {
    header: {
      showBack: false,
    },
  },
  form: {
    fields: personalDetailsFields,
    actions: {
      primary: {
        testId: 'continue',
        label: 'Continue',
      },
    },
  },
  headerBlock: {
    title: 'Please fill in Co-applicant personal details',
    subtitle: ['Co-applicant', '20% complete'],
    progressBar: {
      progress: 20,
    },
  },
  info: {
    title: 'Co-applicant personal details',
    subtitle: 'Help',
    content: [
      {
        copy: [
          "It's very important we get this part right. Providing us with correct name, age, and contact information will ensure the entire loan process is conducted as smoothly as possible.",
        ],
      },
    ],
  },
});

const modelFn = (...args: [any, any]) =>
  withContext({ model, populateFields: populateData })(...args);

export default modelFn;
