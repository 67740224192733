import { theme, unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

const getTextColor = (loading: any, { colors }: any) =>
  loading ? colors.disabled : colors.secondary;
const getOverlayColor = (loading: any, { colors }: any) =>
  loading ? colors.disabled : colors.primaryOverlay;

const activeStyles = ({ $loading, $theme }: any) => {
  return {
    backgroundColor: $theme.colors.primary,
    color: $theme.colors.secondary,
    ':disabled:not([hover])': {
      backgroundColor: $theme.colors.disabled,
      color: getTextColor($loading, $theme),
    },
    ':focus': {
      backgroundColor: $theme.colors.primary,
    },
    ':disabled': {
      backgroundColor: $theme.colors.disabled,
      color: getTextColor($loading, $theme),
    },
  };
};

const inactiveStyles = ({ $loading, $isTwoTabs, $theme }: any) => {
  return {
    border: `1px solid ${
      $isTwoTabs ? $theme.colors.primaryOverlay : $theme.colors.secondaryOverlay
    }`,
    padding: '0.67rem 1.44rem',
    color: $theme.colors.infoOverlay,
    ':disabled:not([hover])': {
      backgroundColor: getOverlayColor($loading, $theme),
      color: $theme.colors.secondary,
      border: `1px solid ${
        $isTwoTabs ? $theme.colors.primaryOverlay : $theme.colors.disabled
      }`,
    },
    ':disabled': {
      color: getTextColor($loading, $theme),
      backgroundColor: getOverlayColor($loading, $theme),
    },
    ':hover:not([disabled])': {
      backgroundColor: $theme.colors.tertiaryOverlay,
    },
    ':focus': {
      border: `1px solid ${$theme.colors.primary}`,
      padding: '0.67rem 1.44rem',
      backgroundColor: $theme.colors.primaryOverlay,
    },
  };
};

const hoveredStyles = ($theme: any) => ({
  backgroundColor: $theme.colors.transparency.blackTransparentLight,
});

export const BackgroundOverlay = styled(
  'div',
  ({ $hovered, $isSelected, $disabled, $theme }: any) => ({
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderRadius: unit[3],
    padding: '0.75rem 1.5rem',
    top: '0',
    left: '0',
    ...($hovered && !$disabled && $isSelected ? hoveredStyles($theme) : {}),
    zIndex: 10,
  }),
);

export const LabelContainer = styled('div', () => ({
  width: '100%',
  position: 'relative',
  zIndex: 50,
}));

export const Container = styled(
  'button',
  ({ $isSelected, $loading, $isTwoTabs, $theme }: any) => ({
    width: unit[19],
    height: unit[7],
    position: 'relative',
    ...theme.fonts.headingMedium,
    cursor: 'pointer',
    padding: '0.75rem 1.5rem',
    whiteSpace: 'nowrap',
    borderRadius: unit[3],
    ...($isSelected
      ? activeStyles({ $loading, $theme })
      : inactiveStyles({ $loading, $isTwoTabs, $theme })),
    ':disabled': {
      cursor: 'not-allowed',
    },
  }),
);
