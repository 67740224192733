import { isEmpty } from 'lodash';
import { useContext } from 'react';

import { Branding } from '../../common/interfaces/branding/branding';
import { config } from '../../config';
import { DynamicBrandingContext } from '../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { setDynamicAppInfo } from '../../utils/setDynamicAppInfo/setDynamicAppInfo';

export const useBranding = () => {
  const { branding, changeBrandingState, failedToRetrieveBranding, isLoading } =
    useContext(DynamicBrandingContext);
  const initializeBranding = async (
    api: any,
    leadChannelCode?: string | undefined,
  ) => {
    let brandingState: Branding = branding;
    let fetchedBranding: Branding;
    try {
      const defaultBranding = await api.get('/ui-configuration/branding');

      // if the default brand from Contentful could not be found
      // fallback to hardcoded branding values
      if (!isEmpty(defaultBranding)) {
        brandingState = defaultBranding;
      }

      if (leadChannelCode) {
        try {
          fetchedBranding = await api.get(
            `/ui-configuration/branding?leadChannelCode=${leadChannelCode}`,
          );
        } catch (error) {
          // return default branding state, if leadChannelCode not found
          // these values will be reset in localStorage when the frontEnd loads (AuthorizedGuard.tsx)
          return changeBrandingState({
            branding: {
              ...brandingState,
              id: config.branding.defaultId,
            },
            isLoading: false,
            failedToRetrieveBranding: true,
          });
        }

        /**
         * We want to show the all of the default branding styling aside from:
         * id - used for the welcome email styling based on the lead channel branding
         * brandName - used for the lead channel branding name on agree to terms component
         */
        brandingState = {
          ...defaultBranding,
          id: fetchedBranding.id,
          brandName: fetchedBranding.brandName,
        };
      }

      changeBrandingState({
        branding: brandingState,
        isLoading: false,
      });
    } catch (e: any) {
      console.error('Failed to load branding, using default', e);
    } finally {
      changeBrandingState({ isLoading: false });
      setDynamicAppInfo(brandingState);
    }
  };

  return {
    initializeBranding,
    branding,
    failedToRetrieveBranding,
    isLoading,
  };
};
