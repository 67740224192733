import i18n from 'i18next';

import { IncomeFrequencyEnum } from '../enums/IncomeFrequencyEnum';

export const additionalIncomeDurationFormat: Record<
  IncomeFrequencyEnum,
  string
> = {
  [IncomeFrequencyEnum.ANNUALLY]: i18n.t('incomeFrequency.annually'),
  [IncomeFrequencyEnum.BI_WEEKLY]: i18n.t('incomeFrequency.biWeekly'),
  [IncomeFrequencyEnum.MONTHLY]: i18n.t('incomeFrequency.monthly'),
  [IncomeFrequencyEnum.SEMI_MONTHLY]: i18n.t('incomeFrequency.semiMonthly'),
  [IncomeFrequencyEnum.WEEKLY]: i18n.t('incomeFrequency.weekly'),
};
