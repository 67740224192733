import {
  MappedRefinanceOffer,
  RefinanceOffersResponse,
} from '../../../../../../common/interfaces/vehicle/offer.interface';

export const mapOffers = (
  offers: RefinanceOffersResponse[],
  withGap: boolean,
) =>
  offers.reduce<MappedRefinanceOffer[]>(
    (acc, { term, options }) => [
      ...acc,
      ...options
        .filter(({ amtGapMonthly, amtGap }) =>
          [amtGapMonthly, amtGap].every((gapValue) =>
            withGap ? gapValue > 0 : !gapValue,
          ),
        )
        .map((offer) => ({ ...offer, term })),
    ],
    [],
  );

export const addIdsToOffers = (offers: RefinanceOffersResponse[]) =>
  offers.reduce<RefinanceOffersResponse[]>(
    (acc, { term, options }, index) => [
      ...acc,
      {
        term,
        options: options.map((offer) => ({
          ...offer,
          value: index.toString(),
        })),
      },
    ],
    [],
  );
