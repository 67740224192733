/* eslint-disable no-console */
import hoistStatics from 'hoist-non-react-statics';
import { PureComponent } from 'react';

import { getDisplayName } from '../../../utils/component/component';
import CurrentUserContext from './CurrentUserContext';
import defaultUserContext from './defaultUserContext';

/**
 * withCurrentUserContextProvider - The CurrentUserContext provides access to contextual information about the current
 * user, including authentication/authorization details. For the context to work, there must be a context provider
 * in the tree. When a component expresses that it needs to use the `useCurrentUserContext` hook, or a component
 * wants to use `withCurrentUserContext` to wrap a context consumer around their component. It searches up the tree
 * until it finds the first context provider that matches the same context type. In this case `CurrentUserContext`
 *
 * Under test, we often wrap the application in context providers that override the default behavior, for example
 * to test how the application behaves for different types of users etc...
 *
 * This component is used to wrap the entire ChecklightClientApplication in a CurrentUserContext provider.
 * - index.jsx - `@autopay/client-checklight-2_0/index.jsx` this provides global access to the context from any
 * component. It does NOT mean that all the components will re-render if the context changes, it just means that
 * any component can subscribe, by either using the hook, or by using the wrapper mentioned above.
 *
 * @example
 * export default flow([
 * 	withCurrentUserContext,
 * ])(SignInVerifyPinView);
 *
 * @see https://reactjs.org/docs/context.html#contextprovider
 * @param WrappedComponent - A new component with a context provider wrapped around it.
 * @returns {*}
 */
export const withCurrentUserContextProvider = (WrappedComponent: any) => {
  const CurrentUserContextProvider = class extends PureComponent {
    constructor(props: any) {
      super(props);
      const setCurrentUserContext = (newUserContext = defaultUserContext) => {
        // console.info('setCurrentUserContext -> <CurrentUserContext.Provider /> updating currentUserContext ->', newUserContext);
        this.setState({ value: [newUserContext, setCurrentUserContext] });
      };

      const [newUserContext] = defaultUserContext;
      this.state = {
        value: [newUserContext, setCurrentUserContext],
      };
    }
    static displayName = `withCurrentUserContextProvider(${getDisplayName(
      WrappedComponent,
    )})`;
    static wrappedComponent = WrappedComponent;
    static contextType = CurrentUserContext;

    render() {
      return (
        <CurrentUserContext.Provider value={(this.state as any).value}>
          <WrappedComponent {...this.props} />
        </CurrentUserContext.Provider>
      );
    }
  };

  return hoistStatics(CurrentUserContextProvider, WrappedComponent);
};

withCurrentUserContextProvider.contextType = CurrentUserContext;
