import { cloneDeep, merge, pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useAsync } from 'react-async-hook';

import { PillowForm } from '../../../../../components/PillowForm';
import { useAPI } from '../../../../../hooks/useAPI/useAPI';

const errorMessage =
  'An unexpected exception occurred while attempting to load the vehicle models';

const Controller = ({ send, presModel, context }: any) => {
  const api = useAPI();
  const { editedVehicle } = context;

  const loadModels = useAsync(
    ({ year, makeId }: any): Promise<any> =>
      api.get(`/vehicles/years/${year}/makes/${makeId}/models`),
    [editedVehicle],
  );

  const getVehicleData = useCallback(
    (modelId: string) =>
      modelId === editedVehicle.modelId
        ? editedVehicle
        : pick(editedVehicle, [
            'vehicleInformationSource',
            'year',
            'make',
            'makeId',
          ]),
    [editedVehicle],
  );

  const onSubmit = useCallback(
    (modelId: any) => {
      const { label: model } =
        loadModels.result?.find(({ value }: any) => modelId === value) || {};
      send('Next', {
        data: {
          editedVehicle: {
            ...getVehicleData(modelId),
            modelId,
            model,
          },
        },
      });
    },
    [send, loadModels, getVehicleData],
  );

  const enhancedProps = useMemo(
    () =>
      cloneDeep(
        merge(
          {},
          { presModel },
          {
            presModel: {
              form: {
                fields: {
                  modelId: {
                    isLoading: loadModels.loading,
                    options: loadModels.result,
                    onChange: onSubmit,
                  },
                },
                globalErrors: loadModels.error && [errorMessage],
              },
            },
          },
        ),
      ),
    [presModel, onSubmit, loadModels],
  );

  return <PillowForm {...enhancedProps} />;
};

Controller.displayName = 'VehicleManualModelSelection.Controller';
export default Controller;
