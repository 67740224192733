import { isMatch } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useForm } from 'react-hook-form';

import { ImageListItemInterface } from '../../common/interfaces/vehicle/imageListItem.interface';
import { UserVehicle } from '../../common/interfaces/vehicle/vehicle.interface';
import { VehicleUpdateInterface } from '../../common/interfaces/vehicle/vehicleUpdate.interface';
import { componentTypes } from '../../constants/componentTypes';
import { fields } from '../../forms/vehicle';
import { useAPI } from '../../hooks/useAPI/useAPI';
import { Form } from '../shared/Form/Form';
import { Modal } from '../shared/Modal/Modal';

interface MakeModelTrimResponse {
  label: string;
  value: string;
}

export const VehicleConfirmation = ({
  currentVehicle,
}: {
  currentVehicle: UserVehicle;
}) => {
  const api = useAPI();
  const methods = useForm();

  const resetFields = useCallback(
    (fields: string[]) => {
      fields.forEach((fieldName) => {
        methods.resetField(fieldName, { keepError: true });
      });
    },
    [methods],
  );

  const loadMakes = useAsyncCallback<MakeModelTrimResponse[]>((year) => {
    resetFields([fields.makeId.name, fields.modelId.name, fields.trimId.name]);

    return api.get(`/vehicles/years/${year}/makes`);
  });
  const loadModels = useAsyncCallback<MakeModelTrimResponse[]>((make) => {
    resetFields([fields.modelId.name, fields.trimId.name]);

    const year = methods.getValues(fields.year.name);
    return api.get(`/vehicles/years/${year}/makes/${make}/models`);
  });
  const loadTrims = useAsyncCallback<MakeModelTrimResponse[]>((model) => {
    resetFields([fields.trimId.name]);

    const [year, make] = methods.getValues([
      fields.year.name,
      fields.makeId.name,
    ]);
    return api.get(
      `/vehicles/years/${year}/makes/${make}/models/${model}/trims`,
    );
  });

  const formSubmit = useAsyncCallback(async (updatedVehicle) => {
    const { makeId, modelId, trimId, year } = updatedVehicle;
    const { label: make } =
      loadMakes.result?.find(({ value }) => makeId === value) || {};
    const { label: model } =
      loadModels.result?.find(({ value }) => modelId === value) || {};
    const { label: trim } =
      loadTrims.result?.find(({ value }) => trimId === value) || {};

    const isChanged = !isMatch(currentVehicle, { make, model, trim, year });

    if (isChanged) {
      const imageList = await api.get<
        ImageListItemInterface[],
        ImageListItemInterface[]
      >(
        `/vehicles/years/${year}/makes/${makeId}/models/${modelId}/trims/${trimId}/images`,
      );

      const updatedVehiclePayload: VehicleUpdateInterface = {
        make,
        model,
        trim,
        trimId,
        year,
        imageList,
        isConfirmed: true,
      };

      await api.put(`/vehicles/${currentVehicle.id}`, updatedVehiclePayload);
    } else {
      await api.post(`/vehicles/${currentVehicle.id}/confirm`);
    }
  });

  useEffect(() => {
    if (formSubmit.status === 'success') {
      window.location.reload();
    }
  }, [formSubmit]);

  const apiErrors = useMemo(() => {
    return [loadMakes, loadModels, loadTrims, formSubmit].reduce<Error[]>(
      (acc, { error }) => {
        return error ? acc.concat(error) : acc;
      },
      [],
    );
  }, [loadMakes, loadModels, loadTrims, formSubmit]);

  const modalButtons = {
    rtl: true,
    primaryButton: {
      children: 'Confirm',
      isLoading: formSubmit.loading,
      onClick: methods.handleSubmit(formSubmit.execute),
      testId: 'confirm',
    },
  };

  const formModel = useMemo(
    () => ({
      fields: {
        year: {
          ...fields.year,
          component: componentTypes.DROPDOWN,
          label: 'Select year',
          largeTitle: false,
          validationRules: {
            required: 'Year is required',
          },
          onChange: loadMakes.execute,
        },
        makeId: {
          ...fields.makeId,
          component: componentTypes.DROPDOWN,
          isLoading: loadMakes.loading,
          label: 'Select make',
          largeTitle: false,
          onChange: loadModels.execute,
          options: loadMakes.result,
          validationRules: {
            required: 'Make is required',
          },
        },
        modelId: {
          ...fields.modelId,
          component: componentTypes.DROPDOWN,
          isLoading: loadModels.loading,
          label: 'Select model',
          largeTitle: false,
          onChange: loadTrims.execute,
          options: loadModels.result,
          validationRules: {
            required: 'Model is required',
          },
        },
        trimId: {
          ...fields.trimId,
          isLoading: loadTrims.loading,
          label: 'Select trim',
          largeTitle: false,
          options: loadTrims.result,
        },
      },
      globalErrors: apiErrors,
    }),
    [loadMakes, loadModels, loadTrims, apiErrors],
  );

  return (
    <Modal
      buttonGroupPresModel={modalButtons}
      paragraph="To deliver you the most accurate rates, please confirm we have the correct vehicle information before continuing."
      showClose={false}
      subtitle="Vehicle"
      title="Please confirm your vehicle details"
    >
      <Form form={formModel} methods={methods} />
    </Modal>
  );
};
