import { uniqueId } from 'lodash';
import { useContext } from 'react';

import { DynamicBrandingContext } from '../../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { Checkbox } from '../checkbox/Checkbox';
import { InfoBlock } from '../InfoBlock/InfoBlock';
import { Link } from '../link/link';
import { privacyPoliceLinks } from './privacy-police-links';
import { AgreementLabel, CheckboxContainer } from './styles';

const redirectLinks = privacyPoliceLinks.map(({ url, label }) => (
  <Link key={uniqueId('link-')} onClick={() => window.open(url, '_blank')}>
    {label}
  </Link>
));

export const UserAgreement = ({
  isSubmittedWithoutAgreement,
  agreementChecked,
  setAgreementChecked,
}: {
  isSubmittedWithoutAgreement: boolean;
  agreementChecked: boolean;
  setAgreementChecked: (value: boolean) => void;
}) => {
  const {
    branding: { brandName },
  } = useContext(DynamicBrandingContext);

  const [ppLink, touLink] = redirectLinks;

  const infoBlockContent = (
    <CheckboxContainer>
      <Checkbox
        checked={agreementChecked}
        isError={isSubmittedWithoutAgreement}
        onChange={(checked) => setAgreementChecked(!checked)}
      />
      <AgreementLabel $isError={isSubmittedWithoutAgreement}>
        Agree to terms
      </AgreementLabel>
    </CheckboxContainer>
  );

  return (
    <InfoBlock
      contentBeforeTitle={infoBlockContent}
      isErrorBorder={isSubmittedWithoutAgreement}
      hasBorder
    >
      <span data-testid="agreementText">
        By submitting this application, you acknowledge that you have read and
        agree to our {touLink} and {ppLink}; that you are at least 18 years of
        age and that all the information provided is true, correct and complete
        and you grant permission to {brandName} and/or its Lenders to obtain a
        copy of your credit report.
      </span>
    </InfoBlock>
  );
};
