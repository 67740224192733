import { FooterLink } from '../../../components/PillowForm';
import { PillowType } from '../../../components/shared/PillowImage/pillowTypes';
import { componentTypes } from '../../../constants/componentTypes';

const model = {
  template: {
    img: {
      type: PillowType.VERIFICATION,
    },
    header: {
      showClose: false,
      showBack: true,
    },
    footer: {
      isActive: false,
      links: [FooterLink.ContactUs],
    },
  },
  form: {
    actions: {
      primary: {
        label: 'Back',
      },
    },
    fields: {
      documents: {
        name: 'documentList',
        largeTitle: true,
        component: componentTypes.SINGLE_SELECT,
      },
    },
  },
  headerBlock: {
    title: 'Another way to Save',
    subtitle: 'Loan documents',
    paragraph:
      "Tell us what you purchased at the dealer, and we will see if you can save money on your protection products. Upload the relevant documents for the products you purchased at the dealer, and we'll find you savings!",
    progressBar: {
      progress: 0,
      withContainer: true,
    },
  },
  info: {
    title: 'Dealer Documents',
    subtitle: 'Loan documents',
    content: [
      {
        copy: [
          'We are always looking for more ways to save you money. If you purchased a GAP policy, extended service contract (warranty), or other protection products from your dealer. Upload that information and we will tell you if you qualify for additional savings. The average qualified customer gets over $1,300 cash back when they upload their documents and completes their refinance.',
          'The dealership must give you a copy of your loan and protection documents at the time you purchased your car. If you have misplaced your documents, contact your selling dealership to retrieve them. Feel free to upload all of your dealership documents, and we will find your savings!',
        ],
      },
    ],
  },
};

export default model;
