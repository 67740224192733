import { assign, MachineConfig } from 'xstate';

import { OffersContext } from '../../../common/interfaces/offers.interface';

export const Signal = {
  Next: 'Next',
  Previous: 'Previous',
  ExitToDashboard: 'ExitToDashboard',
};

export const Step = {
  Lookup: 'Lookup',
  SelectOffer: 'SelectOffer',
  Gap: 'Gap',
  Review: 'Review',
  ExitToDashboard: 'ExitToDashboard',
  ExitToApplicantGate: 'ExitToApplicantGate',
  ExitToNoOffers: 'ExitToNoOffers',
};

export const Routes = {
  [Step.Lookup]: '',
  [Step.SelectOffer]: 'select',
  [Step.Gap]: 'gap',
  [Step.Review]: 'review',
};

export const ContextValidators = {};

export const workflowName = 'unified-default-offers';
export const defaultConfiguration: MachineConfig<OffersContext, any, any> = {
  id: workflowName,
  initial: Step.Lookup,
  states: {
    [Step.Lookup]: {
      initial: 'GettingOffers',
      states: {
        NoOffers: {},
        GettingOffers: {
          invoke: {
            src: 'getOffers',
            onDone: [
              {
                target: `#${workflowName}.${Step.SelectOffer}`,
                cond: (ctx, event) => {
                  return event.data.offersWithoutGap?.length;
                },
                actions: assign({
                  offers: (context, { data }: any) => ({
                    ...context.offers,
                    ...data,
                  }),
                }),
              },
              {
                target: `#${workflowName}.${Step.ExitToNoOffers}`,
                actions: 'setNoOffers',
              },
            ],
            onError: {
              target: `#${workflowName}.${Step.ExitToNoOffers}`,
              actions: 'setNoOffers',
            },
          },
        },
      },
      on: {
        [Signal.ExitToDashboard]: Step.ExitToDashboard,
      },
    },
    [Step.SelectOffer]: {
      on: {
        [Signal.Next]: {
          target: Step.Gap,
          actions: 'updateOffers',
        },
      },
      initial: 'Idle',
      states: {
        Idle: {
          on: {
            GetOffers: {
              target: 'GettingOffers',
              actions: assign({
                downPayment: (ctx, event) => event.downPayment,
              }),
            },
          },
        },
        GettingOffers: {
          invoke: {
            src: 'getOffers',
            onDone: {
              target: 'Idle',
              actions: assign<OffersContext>({
                offers: (context, { data }: any) => ({
                  ...context.offers,
                  ...data,
                }),
              }) as any,
            },
            onError: {
              target: `#${workflowName}.${Step.ExitToNoOffers}`,
            },
          },
        },
      },
    },
    [Step.Gap]: {
      on: {
        [Signal.Previous]: Step.SelectOffer,
        [Signal.Next]: {
          actions: 'updateOffers',
          target: Step.Review,
        },
      },
    },
    [Step.Review]: {
      on: {
        [Signal.Next]: {
          target: Step.ExitToApplicantGate,
          actions: 'setSelectedOffer',
        },
        [Signal.Previous]: Step.Gap,
      },
    },
    [Step.ExitToDashboard]: {
      type: 'final',
    },
    [Step.ExitToApplicantGate]: {
      type: 'final',
    },
    [Step.ExitToNoOffers]: {
      type: 'final',
    },
  },
};

// NOTE: You can paste the content of this file into @see https://xstate.js.org/viz
// and uncomment this line to test the logic manually
// Machine({ ...defaultConfiguration }, { ...defaultServiceOptions });

export default defaultConfiguration;
