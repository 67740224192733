import { NavigateOptions } from 'react-router-dom';
import { devtools } from 'zustand/middleware';

import { create, storeDevToolOptions } from './storeManager';

type LazyNavigateOptions = NavigateOptions & {
  withPostExecute?: () => void;
};

type LazyState = {
  isLazyNavigating: boolean;
  lazyNavigateOptions: LazyNavigateOptions | null;
  lazyNavigatePath: string | null;

  lazyNavigate: (
    pathname: string,
    options?: LazyNavigateOptions | null,
  ) => void;
  lazyNavigateFinished: () => void;
  setIsLazyNavigating: (isLazyNavigating: boolean) => void;
};

const useLazyStore = create()(
  devtools<LazyState>(
    (set) => ({
      isLazyNavigating: false,
      lazyNavigateOptions: null,
      lazyNavigatePath: null,

      lazyNavigate: (
        pathname: string,
        options?: LazyNavigateOptions | null,
      ) => {
        set({
          isLazyNavigating: true,
          lazyNavigateOptions: options,
          lazyNavigatePath: pathname,
        });
      },
      lazyNavigateFinished: () => {
        set({
          isLazyNavigating: false,
          lazyNavigateOptions: null,
          lazyNavigatePath: null,
        });
      },
      setIsLazyNavigating: (isLazyNavigating: boolean) => {
        set({ isLazyNavigating });
      },
    }),
    storeDevToolOptions('lazyStore'),
  ),
);

export default useLazyStore;
