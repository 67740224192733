import { font, unit } from '../../../../common/theme';
import { styled } from '../../../../providers/context/StyletronProvider/withStyletronProvider';

const linkStyles = ($theme: any) => ({
  color: $theme.colors.primary,
  fontSize: font.size.headingMedium,
  fontFamily: font.family.Montserrat,
  cursor: 'pointer',
  textDecoration: 'underline',
});

export const Link = styled('span', ({ $theme }: any) => ({
  ...linkStyles($theme),
}));

export const Description = styled('p', ({ $theme }: any) => ({
  marginTop: `-${unit[3]}`,
  marginBottom: unit[3],
  color: $theme.colors.primaryActive,
  position: 'relative',
  zIndex: '2',
  fontFamily: font.family.Montserrat,
}));

export const ModalLauncher = styled('p', ({ $theme }: any) => ({
  ...linkStyles($theme),
  fontWeight: '500',
  marginBottom: unit[6],
}));

export const ModalImage = styled('img', () => ({
  marginTop: `-${unit[3]}`,
  marginBottom: unit[3],
  width: '100%',
}));
