import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useNavigate } from 'react-router-dom';

import fallbackVehicleImage from '../../assets/car-shadow-dark.png';
import { isRemoveVehicleRestricted } from '../../common';
import { useAPI } from '../../hooks/useAPI/useAPI';
import { useCurrentUserContext } from '../../hooks/useCurrentUserContext/useCurrentUserContext';
import { useModal } from '../../hooks/useModal/useModal';
import { useWorkflowPathname } from '../../hooks/useWorkflowPathname/useWorkflowPathname';
import { DynamicBrandingContext } from '../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { ButtonType } from '../shared/Button/buttonType';
import { ErrorsBlock } from '../shared/ErrorsBlock/ErrorsBlock';
import { Modal } from '../shared/Modal/Modal';
import { ErrorWrapper } from './styles';

const RemoveVehicleComponent = ({ vehicle = {} }: any) => {
  const api = useAPI();
  const { checklightSession } = vehicle;
  const navigate = useNavigate();
  const { close } = useModal();
  const {
    branding: { theme: $theme },
  } = useContext(DynamicBrandingContext);
  const rootPath = useWorkflowPathname();
  const [currentUserContext, setCurrentUserContext] = useCurrentUserContext();

  const canDelete = useMemo(
    () => !isRemoveVehicleRestricted(checklightSession),
    [checklightSession],
  );

  const onSubmit = useAsyncCallback(async () => {
    await api.delete(`/vehicles/${vehicle.id}`);
    setCurrentUserContext({
      ...currentUserContext,
      userVehicles: currentUserContext?.userVehicles?.filter(
        ({ id }) => id !== vehicle.id,
      ),
    });
    close();
    navigate('/' + rootPath);
  });

  const buttonGroup = useMemo(
    () => ({
      ...(canDelete
        ? {
            primaryButton: {
              name: 'remove',
              children: 'Remove',
              isDisabled: onSubmit.loading,
              stylingType: ButtonType.SECONDARY,
              onClick: onSubmit.execute,
              testId: 'remove',
            },
          }
        : {}),
      secondaryButton: {
        name: 'cancel',
        children: 'Cancel',
        isDisabled: onSubmit.loading,
        stylingType: ButtonType.PRIMARY,
        onClick: close,
        testId: 'cancel',
      },
      rtl: true,
    }),
    [close, onSubmit, canDelete],
  );

  const modalImage = useMemo(
    () => ({
      src: vehicle.image,
      fallbackSrc: fallbackVehicleImage,
    }),
    [vehicle.image],
  );

  return (
    <Modal
      buttonGroupPresModel={buttonGroup}
      modalImage={modalImage}
      subtitle={`${vehicle.make} ${vehicle.model}`}
      subtitleColor={$theme.colors.primaryActive}
      title={
        canDelete
          ? 'Are you sure you want to remove this vehicle?'
          : 'Vehicle cannot be removed'
      }
    >
      {canDelete
        ? 'Removing this vehicle from your dashboard will delete it, and all of its records. Are you sure you want to continue?'
        : 'Sorry, vehicle cannot be removed as an application process has been started already. Please, contact AUTOPAY team for any help.'}

      {onSubmit.error && (
        <ErrorWrapper>
          <ErrorsBlock errorsList={onSubmit.error as any} />
        </ErrorWrapper>
      )}
    </Modal>
  );
};

RemoveVehicleComponent.propTypes = {
  vehicle: PropTypes.shape({
    id: PropTypes.string,
    make: PropTypes.string,
    model: PropTypes.string,
    image: PropTypes.any,
  }),
};

export const RemoveVehicle = RemoveVehicleComponent;
