import { merge, pick } from 'lodash';

import { fields } from '../../../../../forms/applicant';
import {
  applicantReviewSignals,
  getApplicantDetails,
} from '../../../../common/Steps/ApplicantReview/utils';
import BaseModel from '../common/BaseModel';

const model = (send: any, { context }: any) =>
  merge({}, BaseModel, {
    form: {
      fields: merge({}, pick(fields, 'applicantDetails'), {
        applicantDetails: {
          options: getApplicantDetails(
            context.updatedUser,
            applicantReviewSignals,
          ),
          onChange: send,
        },
      }),
      actions: {
        primary: {
          label: 'Save & Continue',
          testId: 'continue',
        },
      },
    },
    headerBlock: {
      title: 'Review applicant details',
      subtitle: ['Applicant', '100% complete'],
      progressBar: {
        progress: 100,
        withContainer: true,
      },
    },
    info: {
      title: 'Review applicant details',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Does everything look correct? Use this time to correct any mistakes or confirm these details, as accurate information will allow for the smoothest application process possible.',
          ],
        },
      ],
    },
  });

export default model;
