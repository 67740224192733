import { merge } from 'lodash';

import coApplicantIcon from '../../../../../assets/Co-Applicant.svg';
import housingIcon from '../../../../../assets/Residence.svg';
import { componentTypes } from '../../../../../constants/componentTypes';
import { Signal } from '../../../../common/constants/signals';
import { getCoApplicantAddress } from '../../common/applicant.utils';
import {
  AccountContext,
  AccountState,
} from '../../common/unified-workflow-context.interface';
import BaseModel from '../common/BaseModel';

export enum ReviewCoApplicantEntity {
  Personal = 'Personal',
  Housing = 'Housing',
}

const getReviewDetails = (context: AccountContext) => {
  const { coApplicant } = context;

  const applicantInfo = {
    key: ReviewCoApplicantEntity.Personal,
    value: ReviewCoApplicantEntity.Personal,
    label: 'Co-applicant',
    image: coApplicantIcon,
    subtitle: `${coApplicant?.firstName} ${coApplicant?.lastName}`,
  };

  const {
    physicalAddress: { lineOne, lineTwo, city, province, postalCode },
  } = getCoApplicantAddress(context);

  const housingInfo = {
    key: ReviewCoApplicantEntity.Housing,
    value: ReviewCoApplicantEntity.Housing,
    label: 'Housing',
    image: housingIcon,
    subtitle: [lineOne, lineTwo, city, province, postalCode]
      .filter((v) => !!v)
      .join(', '),
  };

  return [applicantInfo, housingInfo];
};

const model = (send: any, { context }: AccountState) => {
  return merge({}, BaseModel, {
    template: {
      header: {
        showBack: true,
        onBack: () => {
          send(Signal.Previous);
        },
      },
    },
    headerBlock: {
      progressBar: {
        progress: 90,
        withContainer: true,
      },
      title: 'Review Сo-applicant details',
      subtitle: ['Account', '90% complete'],
    },
    form: {
      fields: {
        reviewDetails: {
          name: 'reviewDetails',
          component: componentTypes.SUMMARY_LIST,
          cta: 'View',
          options: getReviewDetails(context),
          onChange: (data: ReviewCoApplicantEntity) => {
            send(Signal.Next, { edit: data });
          },
          largeTitle: true,
        },
      },
      actions: {
        primary: {
          testId: 'continue',
          label: 'Continue',
          handler: () => {
            send(Signal.Next);
          },
        },
      },
    },
    info: {
      title: 'Review',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Does everything look correct? Use this time to correct any mistakes or confirm these details, as accurate information will allow for the smoothest application process possible.',
          ],
        },
      ],
    },
  });
};

export default model;
