import { Fragment } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { GlobalMigrateUserModal } from '../components/GlobalMigrateUserModal/GlobalMigrateUserModal';
import AuthorizedGuard from '../guards/AuthorizedGuard';
import CmsGuard from '../guards/CmsGuard';
import LazyGuard from '../guards/LazyGuard';
import { LoanDocumentGuard } from '../guards/LoanDocumentGuard';
import RequireAuth from '../guards/RequireAuth';
import RequireContext from '../guards/RequireContext';
import SessionGuard from '../guards/SessionGuard';
import StateGuard from '../guards/StateGuard';
import UIRevampCleanupGuard from '../guards/UIRevampCleanupGuard';
import SetupParamsGuard from '../guards/UrlParams/SetupParamsGuard';
import { UrlParamsProcessors } from '../guards/UrlParams/UrlParamsProcessors';
import WorkflowGuard from '../guards/WorkflowGuard';
import AccountDetails from '../pages/Account/Account';
import ActivationPage from '../pages/Activation';
import { ApplicationDetails } from '../pages/Applications/ApplicationDetails';
import ApplicationUpdatePage from '../pages/ApplicationUpdate';
import DashboardView from '../pages/Dashboard';
import LoanDocumentsList from '../pages/LoanDocuments/LoanDocumentsList';
import LoanDocumentsSubmitted from '../pages/LoanDocuments/LoanDocumentsSubmitted';
import LoanDocumentsUpload from '../pages/LoanDocuments/LoanDocumentsUpload';
import { MainLayout } from '../pages/MainLayout/MainLayout';
import RateUpdatePage from '../pages/RateUpdate';
import SignInView from '../pages/SignIn';
import SignInVerifyPinView from '../pages/SignInVerifyPin';
import SignOutView from '../pages/SignOut';
import { VehicleRoot } from '../pages/Vehicle/VehicleRoot';
import useApplicantStore from '../store/applicant/useApplicantStore';
import useCoapplicantStore from '../store/coapplicant/useCoapplicantStore';
import { Workflow } from '../store/workflowStore';
import { VERIFICATION_GATE } from '../workflow/assembly/DefaultVerification/gate';
import { APPLICANT_GATE } from '../workflow/assembly/UnifiedDefaultApplicant/gate';
import { CO_APPLICANT_GATE } from '../workflow/assembly/UnifiedDefaultCoApplicant/gate';
import { NO_OFFERS_GATE } from '../workflow/assembly/UnifiedDefaultNoOffers/gate';
import { OFFERS_GATE } from '../workflow/assembly/UnifiedDefaultOffers/gate';
import { REVIEW_GATE } from '../workflow/assembly/UnifiedDefaultReview/gate';
import { SIGN_UP_GATE } from '../workflow/assembly/UnifiedDefaultSignUp/gate';
import { GateRoot } from '../workflow/common/Gate/Gate';
import { WorkflowHub } from '../workflow/common/WorkflowHub';
import { NavigateWithState } from './NavigateWithState';

const unifiedInternalRoutes = [
  {
    path: 'sign-up/*',
    element: (
      <AuthorizedGuard>
        <WorkflowHub />
      </AuthorizedGuard>
    ),
    children: [
      {
        path: '*',
        index: true,
        element: <GateRoot gate={SIGN_UP_GATE} />,
      },
    ],
  },
  {
    path: 'sign-in',
    element: (
      <AuthorizedGuard>
        <SignInView />
      </AuthorizedGuard>
    ),
  },
  {
    path: 'sign-in/activation',
    element: (
      <AuthorizedGuard>
        <ActivationPage />
      </AuthorizedGuard>
    ),
  },

  {
    path: 'sign-in/rate-update',
    element: (
      <AuthorizedGuard>
        <RateUpdatePage />
      </AuthorizedGuard>
    ),
  },
  {
    path: 'sign-in/application-update',
    element: (
      <AuthorizedGuard>
        <ApplicationUpdatePage />
      </AuthorizedGuard>
    ),
  },
  {
    path: 'sign-in/verify/pin',
    element: (
      <AuthorizedGuard>
        <StateGuard>
          <SignInVerifyPinView />
        </StateGuard>
      </AuthorizedGuard>
    ),
  },
  {
    path: 'sign-out',
    element: <SignOutView />,
  },
  {
    path: 'loan-documents',
    element: (
      <RequireAuth>
        <UrlParamsProcessors>
          <RequireContext>
            <Outlet />
          </RequireContext>
        </UrlParamsProcessors>
      </RequireAuth>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="list" />,
      },
      {
        path: 'list',
        element: <LoanDocumentsList />,
      },
      {
        path: 'retail-contract',
        element: <LoanDocumentsUpload />,
      },
      {
        path: 'service-contract',
        element: <LoanDocumentsUpload />,
      },
      {
        path: 'gap-contract',
        element: <LoanDocumentsUpload />,
      },
      {
        path: 'submitted',
        element: <LoanDocumentsSubmitted />,
      },
    ],
  },
  {
    path: 'workflow',
    element: (
      <RequireAuth>
        <WorkflowHub />
      </RequireAuth>
    ),
    children: [
      {
        path: 'applicant/*',
        element: (
          <RequireContext>
            <GateRoot gate={APPLICANT_GATE} />
          </RequireContext>
        ),
      },
      {
        path: 'co-applicant/*',
        element: (
          <RequireContext>
            <GateRoot gate={CO_APPLICANT_GATE} />
          </RequireContext>
        ),
      },
      {
        path: 'offers/*',
        element: (
          <RequireContext>
            <GateRoot gate={OFFERS_GATE} />
          </RequireContext>
        ),
      },
      {
        path: 'review/*',
        element: (
          <RequireContext>
            <GateRoot gate={REVIEW_GATE} />
          </RequireContext>
        ),
      },
      {
        path: 'verification/*',
        element: (
          <RequireContext>
            <GateRoot gate={VERIFICATION_GATE} />
          </RequireContext>
        ),
      },
      {
        path: 'no-offers/*',
        element: (
          <RequireContext>
            <GateRoot gate={NO_OFFERS_GATE} />
          </RequireContext>
        ),
      },
    ],
  },
  {
    path: '',
    element: (
      <RequireAuth>
        <UrlParamsProcessors>
          <MainLayout />
        </UrlParamsProcessors>
      </RequireAuth>
    ),
    children: [
      {
        path: '',
        index: true,
        element: <Navigate to="dashboard" replace />,
      },
      {
        path: 'dashboard/*',
        children: [
          {
            index: true,
            element: <DashboardView />,
          },
          {
            path: 'vehicle/*',
            element: (
              <RequireContext>
                <StateGuard>
                  <VehicleRoot />
                </StateGuard>
              </RequireContext>
            ),
            children: [
              {
                path: '*',
                index: true,
                element: <NavigateWithState to="application" replace />,
              },
              {
                path: 'application/*',
                element: <ApplicationDetails />,
              },
            ],
          },
          {
            path: '*',
            element: <Navigate to="" replace />,
          },
        ],
      },
      {
        path: 'details/*',
        element: (
          <RequireContext>
            <AccountDetails />
          </RequireContext>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="" replace />,
  },
];

export const routes = [
  {
    path: '',
    element: (
      <SetupParamsGuard>
        <Fragment>
          <Outlet />
          <GlobalMigrateUserModal />
        </Fragment>
      </SetupParamsGuard>
    ),
    children: [
      {
        path: 'sign-in',
        isPublicRoute: true,
        element: (
          <CmsGuard>
            <SessionGuard>
              <LazyGuard>
                <Outlet />
              </LazyGuard>
            </SessionGuard>
          </CmsGuard>
        ),
        children: [
          {
            index: true,
            async lazy() {
              const SignInPage = await import('../pages/SignInPage/SignInPage');
              return { Component: SignInPage.default };
            },
          },
          {
            path: 'activation',
            async lazy() {
              return { Component: <div>Sign In Activation Page</div> };
            },
          },
          {
            path: 'pin-verify',
            async lazy() {
              const SignInPinVerifyPage = await import(
                '../pages/SignInPinVerifyPage/SignInPinVerifyPage'
              );
              return { Component: SignInPinVerifyPage.default };
            },
          },
        ],
      },
      {
        path: 'sign-up',
        isPublicRoute: true,
        element: (
          <CmsGuard>
            <SessionGuard>
              <LazyGuard>
                <Outlet />
              </LazyGuard>
            </SessionGuard>
          </CmsGuard>
        ),
        children: [
          {
            index: true,
            async lazy() {
              const SignUpPage = await import('../pages/SignUpPage/SignUpPage');
              return { Component: SignUpPage.default };
            },
          },
          {
            path: 'pin-verify',
            async lazy() {
              const SignUpPinVerifyPage = await import(
                '../pages/SignUpPinVerifyPage/SignUpPinVerifyPage'
              );
              return { Component: SignUpPinVerifyPage.default };
            },
          },
          {
            path: 'returning-user',
            async lazy() {
              const SignUpReturningUserPage = await import(
                '../pages/SignUpReturningUserPage/SignUpReturningUserPage'
              );
              return { Component: SignUpReturningUserPage.default };
            },
          },
        ],
      },
      {
        path: 'sign-out',
        isPublicRoute: true,
        element: (
          <CmsGuard>
            <LazyGuard>
              <Outlet />
            </LazyGuard>
          </CmsGuard>
        ),
        children: [
          {
            index: true,
            async lazy() {
              const SignOutPage = await import(
                '../pages/SignOutPage/SignOutPage'
              );
              return { Component: SignOutPage.default };
            },
          },
        ],
      },
      {
        path: 'dashboard',
        element: (
          <CmsGuard>
            <SessionGuard>
              <LazyGuard>
                <Outlet />
              </LazyGuard>
            </SessionGuard>
          </CmsGuard>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/dashboard/garage" />,
          },
          {
            path: 'garage',
            async lazy() {
              const DashboardGaragePage = await import(
                '../pages/DashboardGaragePage/DashboardGaragePage'
              );
              return { Component: DashboardGaragePage.default };
            },
          },
          {
            path: 'application/:vehicleId',
            async lazy() {
              const DashboardApplicationPage = await import(
                '../pages/DashboardApplicationPage/DashboardApplicationPage'
              );
              return { Component: DashboardApplicationPage.default };
            },
          },
        ],
      },
      {
        path: 'apply',
        element: (
          <WorkflowGuard workflow={Workflow.APPLY}>
            <CmsGuard>
              <SessionGuard>
                <LazyGuard>
                  <Outlet />
                </LazyGuard>
              </SessionGuard>
            </CmsGuard>
          </WorkflowGuard>
        ),
        children: [
          {
            index: true,
            async lazy() {
              const ApplyPage = await import(
                '../pages/Apply/ApplyPage/ApplyPage'
              );
              return { Component: ApplyPage.default };
            },
          },
          {
            path: 'application-type',
            async lazy() {
              const ApplyApplicationTypePage = await import(
                '../pages/Apply/ApplyApplicationTypePage/ApplyApplicationTypePage'
              );
              return { Component: ApplyApplicationTypePage.default };
            },
          },
          {
            path: 'refinance-goal',
            async lazy() {
              const ApplyRefinanceGoalPage = await import(
                '../pages/Apply/ApplyRefinanceGoalPage/ApplyRefinanceGoalPage'
              );
              return { Component: ApplyRefinanceGoalPage.default };
            },
          },
          {
            path: 'purchase-type',
            async lazy() {
              const ApplyPurchaseTypePage = await import(
                '../pages/Apply/ApplyPurchaseTypePage/ApplyPurchaseTypePage'
              );
              return { Component: ApplyPurchaseTypePage.default };
            },
          },
          {
            path: 'vehicle-condition',
            async lazy() {
              const ApplyVehicleConditionPage = await import(
                '../pages/Apply/ApplyVehicleConditionPage/ApplyVehicleConditionPage'
              );
              return { Component: ApplyVehicleConditionPage.default };
            },
          },
          {
            path: 'search-vehicle',
            async lazy() {
              const ApplySearchVehiclePage = await import(
                '../pages/Apply/ApplySearchVehiclePage/ApplySearchVehiclePage'
              );
              return { Component: ApplySearchVehiclePage.default };
            },
          },
          {
            path: 'search-vin',
            async lazy() {
              const ApplySearchVinPage = await import(
                '../pages/Apply/ApplySearchVinPage/ApplySearchVinPage'
              );
              return { Component: ApplySearchVinPage.default };
            },
          },
          {
            path: 'search-plate',
            async lazy() {
              const ApplySearchPlatePage = await import(
                '../pages/Apply/ApplySearchPlatePage/ApplySearchPlatePage'
              );
              return { Component: ApplySearchPlatePage.default };
            },
          },
          {
            path: 'search-year',
            async lazy() {
              const ApplySearchYearPage = await import(
                '../pages/Apply/ApplySearchYearPage/ApplySearchYearPage'
              );
              return { Component: ApplySearchYearPage.default };
            },
          },
          {
            path: 'search-make',
            async lazy() {
              const ApplySearchMakePage = await import(
                '../pages/Apply/ApplySearchMakePage/ApplySearchMakePage'
              );
              return { Component: ApplySearchMakePage.default };
            },
          },
          {
            path: 'search-model',
            async lazy() {
              const ApplySearchModelPage = await import(
                '../pages/Apply/ApplySearchModelPage/ApplySearchModelPage'
              );
              return { Component: ApplySearchModelPage.default };
            },
          },
          {
            path: 'confirm-vehicle',
            async lazy() {
              const ApplyConfirmVehiclePage = await import(
                '../pages/Apply/ApplyConfirmVehiclePage/ApplyConfirmVehiclePage'
              );
              return { Component: ApplyConfirmVehiclePage.default };
            },
          },
        ],
      },
      {
        path: 'applicant/:vehicleId',
        element: (
          <WorkflowGuard workflow={Workflow.APPLICANT}>
            <CmsGuard>
              <SessionGuard>
                <LazyGuard>
                  <Outlet />
                </LazyGuard>
              </SessionGuard>
            </CmsGuard>
          </WorkflowGuard>
        ),
        children: [
          {
            index: true,
            lazy: () =>
              import('../pages/Applicant/ApplicantPage/ApplicantPage'),
          },
          {
            path: 'residence-type',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantResidenceTypePage/ApplicantResidenceTypePage'
              ),
          },
          {
            path: 'residence-details',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantResidenceDetailsPage/ApplicantResidenceDetailsPage'
              ),
          },
          {
            path: 'residence-duration',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantResidenceDurationPage/ApplicantResidenceDurationPage'
              ),
          },
          {
            path: 'previous-residence',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantPreviousResidencePage/ApplicantPreviousResidencePage'
              ),
          },
          {
            path: 'employment-status',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantEmploymentStatusPage/ApplicantEmploymentStatusPage'
              ),
          },
          {
            path: 'employment-type',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantEmploymentTypePage/ApplicantEmploymentTypePage'
              ),
          },
          {
            path: 'unemployment-type',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantUnemploymentTypePage/ApplicantUnemploymentTypePage'
              ),
          },
          {
            path: 'employment-details',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantEmploymentDetailsPage/ApplicantEmploymentDetailsPage'
              ),
          },
          {
            path: 'unemployment-details',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantUnemploymentDetailsPage/ApplicantUnemploymentDetailsPage'
              ),
          },
          {
            path: 'employment-duration',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantEmploymentDurationPage/ApplicantEmploymentDurationPage'
              ),
          },
          {
            path: 'previous-employment',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantPreviousEmploymentPage/ApplicantPreviousEmploymentPage'
              ),
          },
          {
            path: 'additional-income',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantAdditionalIncomePage/ApplicantAdditionalIncomePage'
              ),
          },
          {
            path: 'income-details',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantIncomeDetailsPage/ApplicantIncomeDetailsPage'
              ),
          },
          {
            path: 'add-coapplicant',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantAddCoapplicantPage/ApplicantAddCoapplicantPage'
              ),
          },
          {
            path: 'coapp-personal-relationship',
            loader: () =>
              Promise.all([
                useApplicantStore.getState().loadApplicant(),
                useCoapplicantStore.getState().loadCoapplicant(),
              ]),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantRelationshipPage/CoapplicantRelationshipPage'
              ),
          },
          {
            path: 'coapp-personal-details',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantPersonalDetailsPage/CoapplicantPersonalDetailsPage'
              ),
          },
          {
            path: 'coapp-residence-relationship',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantResidenceRelationshipPage/CoapplicantResidenceRelationshipPage'
              ),
          },
          {
            path: 'coapp-same-residence',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantSameResidencePage/CoapplicantSameResidencePage'
              ),
          },
          {
            path: 'coapp-different-residence-type',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantDifferentResidenceTypePage/CoapplicantDifferentResidenceTypePage'
              ),
          },
          {
            path: 'coapp-different-residence',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantDifferentResidencePage/CoapplicantDifferentResidencePage'
              ),
          },
          {
            path: 'coapp-residence-duration',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantResidenceDurationPage/CoapplicantResidenceDurationPage'
              ),
          },
          {
            path: 'coapp-previous-residence',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantPreviousResidencePage/CoapplicantPreviousResidencePage'
              ),
          },
          {
            path: 'coapp-employment-status',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantEmploymentStatusPage/CoapplicantEmploymentStatusPage'
              ),
          },
          {
            path: 'coapp-employment-type',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantEmploymentTypePage/CoapplicantEmploymentTypePage'
              ),
          },
          {
            path: 'coapp-unemployment-type',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantUnemploymentTypePage/CoapplicantUnemploymentTypePage'
              ),
          },
          {
            path: 'coapp-employment-details',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantEmploymentDetailsPage/CoapplicantEmploymentDetailsPage'
              ),
          },
          {
            path: 'coapp-unemployment-details',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantUnemploymentDetailsPage/CoapplicantUnemploymentDetailsPage'
              ),
          },
          {
            path: 'coapp-employment-duration',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantEmploymentDurationPage/CoapplicantEmploymentDurationPage'
              ),
          },
          {
            path: 'coapp-previous-employment',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantPreviousEmploymentPage/CoapplicantPreviousEmploymentPage'
              ),
          },
          {
            path: 'coapp-additional-income',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantAdditionalIncomePage/CoapplicantAdditionalIncomePage'
              ),
          },
          {
            path: 'coapp-income-details',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantIncomeDetailsPage/CoapplicantIncomeDetailsPage'
              ),
          },
          {
            path: 'edit-personal',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantEditPersonalPage/ApplicantEditPersonalPage'
              ),
          },
          {
            path: 'coapp-edit-personal',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantEditPersonalPage/CoapplicantEditPersonalPage'
              ),
          },
          {
            path: 'edit-residence',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantEditResidencePage/ApplicantEditResidencePage'
              ),
          },
          {
            path: 'coapp-edit-residence',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantEditResidencePage/CoapplicantEditResidencePage'
              ),
          },
          {
            path: 'edit-employment',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantEditEmploymentPage/ApplicantEditEmploymentPage'
              ),
          },
          {
            path: 'coapp-edit-employment',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantEditEmploymentPage/CoapplicantEditEmploymentPage'
              ),
          },
          {
            path: 'edit-income',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantEditIncomePage/ApplicantEditIncomePage'
              ),
          },
          {
            path: 'coapp-edit-income',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantEditIncomePage/CoapplicantEditIncomePage'
              ),
          },
          {
            path: 'review-applicant',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantReviewPage/ApplicantReviewPage'
              ),
          },
          {
            path: 'coapp-review-applicant',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Coapplicant/CoapplicantReviewPage/CoapplicantReviewPage'
              ),
          },
          {
            path: 'submit-application',
            loader: () => useApplicantStore.getState().loadApplicant(),
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantSubmitPage/ApplicantSubmitPage'
              ),
          },
          {
            path: 'application-success',
            lazy: () =>
              import(
                '../pages/Applicant/ApplicantSuccessPage/ApplicantSuccessPage'
              ),
          },
        ],
      },
      {
        path: 'loan-documents',
        element: (
          <CmsGuard>
            <SessionGuard>
              <LoanDocumentGuard>
                <LazyGuard>
                  <Outlet />
                </LazyGuard>
              </LoanDocumentGuard>
            </SessionGuard>
          </CmsGuard>
        ),
        children: [
          {
            index: true,
            async lazy() {
              const LoanDocumentPage = await import(
                '../pages/LoanDocument/LoanDocumentPage/LoanDocumentPage'
              );
              return { Component: LoanDocumentPage.default };
            },
          },
          {
            path: 'list',
            async lazy() {
              const LoanDocumentListPage = await import(
                '../pages/LoanDocument/LoanDocumentListPage/LoanDocumentListPage'
              );
              return { Component: LoanDocumentListPage.default };
            },
          },
          {
            path: 'ric-upload',
            async lazy() {
              const LoanDocumentUploadRICPage = await import(
                '../pages/LoanDocument/LoanDocumentUploadRICPage/LoanDocumentUploadRICPage'
              );
              return { Component: LoanDocumentUploadRICPage.default };
            },
          },
          {
            path: 'vsc-upload',
            async lazy() {
              const LoanDocumentUploadVSCPage = await import(
                '../pages/LoanDocument/LoanDocumentUploadVSCPage/LoanDocumentUploadVSCPage'
              );
              return { Component: LoanDocumentUploadVSCPage.default };
            },
          },
          {
            path: 'gap-upload',
            async lazy() {
              const LoanDocumentUploadGAPPage = await import(
                '../pages/LoanDocument/LoanDocumentUploadGAPPage/LoanDocumentUploadGAPPage'
              );
              return { Component: LoanDocumentUploadGAPPage.default };
            },
          },
          {
            path: 'document-upload',
            async lazy() {
              const LoanDocumentUploadAllPage = await import(
                '../pages/LoanDocument/LoanDocumentUploadAllPage/LoanDocumentUploadAllPage'
              );
              return { Component: LoanDocumentUploadAllPage.default };
            },
          },
          {
            path: 'current-mileage',
            async lazy() {
              const LoanDocumentMileagePage = await import(
                '../pages/LoanDocument/LoanDocumentMileagePage/LoanDocumentMileagePage'
              );
              return { Component: LoanDocumentMileagePage.default };
            },
          },
          {
            path: 'success',
            async lazy() {
              const LoanDocumentSuccessPage = await import(
                '../pages/LoanDocument/LoanDocumentSuccessPage/LoanDocumentSuccessPage'
              );
              return { Component: LoanDocumentSuccessPage.default };
            },
          },
        ],
      },
      {
        path: 'unified',
        element: (
          <UIRevampCleanupGuard>
            <Outlet />
          </UIRevampCleanupGuard>
        ),
        children: unifiedInternalRoutes,
      },
      {
        path: '',
        element: <Navigate to="unified" replace />,
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="unified" replace />,
  },
];
