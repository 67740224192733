import { merge } from 'lodash';

import { additionalIncomeFields } from '../../../../../utils/helpers/income/unified';
import BaseModel from '../common/BaseModel';

const model = merge({}, BaseModel, {
  form: {
    fields: additionalIncomeFields,
    actions: {
      primary: {
        testId: 'continue',
        label: 'Continue',
      },
    },
    withConfirmModal: true,
  },
  info: {
    title: 'Applicant additional income',
    subtitle: 'Help',
    content: [
      {
        copy: [
          'Please provide us with a bit more detail about these alternative income sources.',
        ],
      },
    ],
  },
  headerBlock: {
    title: 'Tell us more about your income',
    subtitle: ['Applicant', '80% complete'],
    progressBar: {
      progress: 80,
      withContainer: true,
    },
  },
});

export default model;
