import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const Container = styled('div', ({ $isActive, $theme }: any) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  height: '100%',
  width: '100%',
  borderTop: `1px solid ${$theme.colors.secondaryOverlay}`,
  backgroundColor: $isActive
    ? $theme.colors.secondaryOverlay
    : $theme.colors.primaryOverlay,
  padding: '0',
}));
