export enum RelationshipType {
  NONE = 'NONE',
  OTHER = 'OTHER',
  SPOUSE = 'SPOUSE',
  FRIEND = 'FRIEND',
  RELATIVE = 'RELATIVE',
  CO_WORKER = 'CO_WORKER',
}

export type relationshipTypeT = keyof typeof RelationshipType;

export enum ReferenceRelationship {
  OTHER = 'OTHER',
  SPOUSE = 'SPOUSE',
  FRIEND = 'FRIEND',
  RELATIVE = 'RELATIVE',
  GUARDIAN = 'GUARDIAN',
  PARENT = 'PARENT',
}
