import useApplyStore, {
  ApplicationType,
  SearchVehicle,
} from '../store/applyStore';
import { WorkflowPage } from './workflowPage';

export const applyWorkflow: WorkflowPage[] = [
  { path: '/apply' },
  {
    path: '/apply/application-type',
    completed() {
      const {
        computed: { hasValidApplicationType },
      } = useApplyStore.getState();

      // if the application type is valid, this step is complete
      return hasValidApplicationType;
    },
  },
  {
    path: '/apply/refinance-goal',
    completed() {
      const {
        applicationType,
        computed: { hasValidApplicationType, hasValidRefinanceGoal },
      } = useApplyStore.getState();

      // if the application type is invalid (or empty),
      // this step cannot be completed
      if (!hasValidApplicationType) {
        return false;
      }

      // if the application type is REFINANCE,
      // this step is complete if we have a valid refinance goal
      if (applicationType === ApplicationType.REFINANCE) {
        return hasValidRefinanceGoal;
      }

      // if the application type is not REFINANCE,
      // this step can be considered completed
      return true;
    },
    condition() {
      const { applicationType } = useApplyStore.getState();
      return applicationType === ApplicationType.REFINANCE;
    },
  },
  {
    path: '/apply/purchase-type',
    completed() {
      const {
        applicationType,
        computed: { hasValidApplicationType, hasValidPurchaseType },
      } = useApplyStore.getState();

      // if the application type is invalid (or empty),
      // this step cannot be completed
      if (!hasValidApplicationType) {
        return false;
      }

      // if the application type is PURCHASE,
      // this step is complete if we have a valid purchase type
      if (applicationType === ApplicationType.PURCHASE) {
        return hasValidPurchaseType;
      }

      // if the application type is not PURCHASE,
      // this step can be considered completed
      return true;
    },
    condition() {
      const { applicationType } = useApplyStore.getState();
      return applicationType === ApplicationType.PURCHASE;
    },
  },
  {
    path: '/apply/vehicle-condition',
    completed() {
      const {
        applicationType,
        computed: { hasValidApplicationType, hasValidVehicleCondition },
      } = useApplyStore.getState();

      // if the application type is invalid (or empty),
      // this step cannot be completed
      if (!hasValidApplicationType) {
        return false;
      }

      // if the application type is PURCHASE,
      // this step is complete if we have a valid vehicle condition
      if (applicationType === ApplicationType.PURCHASE) {
        return hasValidVehicleCondition;
      }

      return true;
    },
    condition() {
      const { applicationType } = useApplyStore.getState();
      return applicationType === ApplicationType.PURCHASE;
    },
  },
  {
    path: '/apply/search-vehicle',
    completed() {
      const {
        computed: { hasValidVehicleSearch },
      } = useApplyStore.getState();

      return hasValidVehicleSearch;
    },
  },
  {
    path: '/apply/search-vin',
    completed() {
      const {
        computed: { hasValidVehicleVin },
        searchVehicle,
      } = useApplyStore.getState();

      // if the vehicle search type is invalid (or empty),
      // this step cannot be completed
      if (!searchVehicle) {
        return false;
      }

      // if the vehicle search type is BY_VIN,
      // this step is complete if we have a valid looking vin number
      if (searchVehicle === SearchVehicle.BY_VIN) {
        return hasValidVehicleVin;
      }

      // if the vehicle search type is not BY_VIN,
      // this step can be considered completed
      return true;
    },
    condition() {
      const { searchVehicle } = useApplyStore.getState();
      return searchVehicle === SearchVehicle.BY_VIN;
    },
  },
  {
    path: '/apply/search-plate',
    completed() {
      const {
        computed: { hasValidVehicleLicensePlate },
        searchVehicle,
      } = useApplyStore.getState();

      // if the vehicle search type is invalid (or empty),
      // this step cannot be completed
      if (!searchVehicle) {
        return false;
      }

      // if the vehicle search type is BY_LICENSE_PLATE,
      // this step is complete if we have a valid looking license plate number
      if (searchVehicle === SearchVehicle.BY_LICENSE_PLATE) {
        return hasValidVehicleLicensePlate;
      }

      // if the vehicle search type is not BY_LICENSE_PLATE,
      // this step can be considered completed
      return true;
    },
    condition() {
      const { searchVehicle } = useApplyStore.getState();
      return searchVehicle === SearchVehicle.BY_LICENSE_PLATE;
    },
  },
  {
    path: '/apply/search-year',
    completed() {
      const {
        computed: { hasValidVehicleYear },
        searchVehicle,
      } = useApplyStore.getState();

      // if the vehicle search type is invalid (or empty),
      // this step cannot be completed
      if (!searchVehicle) {
        return false;
      }

      // if the vehicle search type is BY_MAKE_MODEL,
      // this step is complete if we have a valid vehicle year
      if (searchVehicle === SearchVehicle.BY_MAKE_MODEL) {
        return hasValidVehicleYear;
      }

      // if the vehicle search type is not BY_MAKE_MODEL,
      // this step can be considered completed
      return true;
    },
    condition() {
      const { searchVehicle } = useApplyStore.getState();
      return searchVehicle === SearchVehicle.BY_MAKE_MODEL;
    },
  },
  {
    path: '/apply/search-make',
    completed() {
      const {
        computed: { hasValidVehicleMake },
        searchVehicle,
      } = useApplyStore.getState();

      // if the vehicle search type is invalid (or empty),
      // this step cannot be completed
      if (!searchVehicle) {
        return false;
      }

      // if the vehicle search type is BY_MAKE_MODEL,
      // this step is complete if we have a valid vehicle make
      if (searchVehicle === SearchVehicle.BY_MAKE_MODEL) {
        return hasValidVehicleMake;
      }

      // if the vehicle search type is not BY_MAKE_MODEL,
      // this step can be considered completed
      return true;
    },
    condition() {
      const { searchVehicle } = useApplyStore.getState();
      return searchVehicle === SearchVehicle.BY_MAKE_MODEL;
    },
  },
  {
    path: '/apply/search-model',
    completed() {
      const {
        computed: { hasValidVehicleModel },
        searchVehicle,
      } = useApplyStore.getState();

      // if the vehicle search type is invalid (or empty),
      // this step cannot be completed
      if (!searchVehicle) {
        return false;
      }

      // if the vehicle search type is BY_MAKE_MODEL,
      // this step is complete if we have a valid vehicle model
      if (searchVehicle === SearchVehicle.BY_MAKE_MODEL) {
        return hasValidVehicleModel;
      }

      // if the vehicle search type is not BY_MAKE_MODEL,
      // this step can be considered completed
      return true;
    },
    condition() {
      const { searchVehicle } = useApplyStore.getState();
      return searchVehicle === SearchVehicle.BY_MAKE_MODEL;
    },
  },
  {
    path: '/apply/confirm-vehicle',
    completed() {
      const {
        applicationType,
        computed: {
          hasValidApplicationType,
          hasValidPayoffAmount,
          hasValidPurchasePrice,
          hasValidVehicleMileage,
          hasValidVehicleTrim,
        },
      } = useApplyStore.getState();

      // if the application type is invalid (or empty),
      // this step cannot be completed
      if (!hasValidApplicationType) {
        return false;
      }

      // if we are missing the trim, regardless of application type,
      // this step cannot be completed
      if (!hasValidVehicleTrim) {
        return false;
      }

      // if we are missing the mileage, regardless of application type,
      // this step cannot be completed
      if (!hasValidVehicleMileage) {
        return false;
      }

      // if the application type is PURCHASE or LEASE_BUYOUT
      // this step is completed if we have a valid purchase price
      if (
        applicationType === ApplicationType.PURCHASE ||
        applicationType === ApplicationType.LEASE_BUYOUT
      ) {
        return hasValidPurchasePrice;
      }

      // for any other application type (REFINANCE, etc),
      // this step is completed if we have a valid payoff amount
      return hasValidPayoffAmount;
    },
  },
];
