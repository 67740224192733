import { FooterLink } from '../../../../components/PillowForm';
import { PillowType } from '../../../../components/shared/PillowImage/pillowTypes';
import { Workflow } from '../../../../constants/Workflow';
import { VerificationContext } from '../XState/Model';

export const BaseModel = (context: VerificationContext) => ({
  template: {
    footer: {
      isActive: false,
      ...(context.workflow === Workflow.unified
        ? {
            links: [FooterLink.ContactUs],
          }
        : {}),
    },
    img: {
      type: PillowType.VERIFICATION,
    },
  },
});
