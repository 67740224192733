import { isEmpty, toString } from 'lodash';

import { incomeFrequencyFromAPI } from '../../applicant/functions/incomeFrequencyFromAPI';
import { isEmployedType } from '../../applicant/functions/isEmployedType';
import { reduceIncomeMonthsAt } from '../../applicant/functions/reduceIncomeMonthsAt';
import { NO, YES, YesOrNo } from '../../applicant/YesOrNo';
import { CoapplicantState } from '../CoapplicantState';
import { CoapplicantIncomesDto } from '../dto/CoapplicantIncomesDto';

const isCurrentlyEmployed = (
  employmentType?: string | null,
): YesOrNo | null => {
  if (!employmentType) {
    return null;
  }

  return isEmployedType(employmentType) ? YES : NO;
};

export const toCoapplicantStateFromIncomes = (
  income: CoapplicantIncomesDto,
): Partial<CoapplicantState> => {
  const state: Partial<CoapplicantState> = {};

  if (isEmpty(income)) {
    return state;
  }

  if (income.additional) {
    state.coapplicantAdditionalIncomeAmount =
      income.additional.amount?.toString() || null;
    state.coapplicantAdditionalIncomeFrequency = incomeFrequencyFromAPI(
      income.additional.incomeFrequency,
    );
    state.coapplicantAdditionalIncomeType =
      income.additional.additionalIncomeType || null;

    state.hasAdditionalIncome = income.additional.amount ? YES : NO;
  }

  if (income.current) {
    state.coapplicantCurrentIncomeEmployerName =
      income.current.employerName || null;
    state.coapplicantCurrentIncomeEmploymentType =
      income.current.employmentType || null;
    state.coapplicantCurrentIncomeGrossAnnual =
      toString(income.current.amount) || null;
    state.coapplicantCurrentIncomeJobTitle = income.current.jobTitle || null;

    state.coapplicantCurrentIncomeMonthsAt = toString(
      reduceIncomeMonthsAt(income.current.monthsAt),
    );

    state.isCoapplicantCurrentlyEmployed = isCurrentlyEmployed(
      income.current.employmentType,
    );
  }

  if (income.previous) {
    state.coapplicantPreviousIncomeEmployerName =
      income.previous.employerName || null;
    state.coapplicantPreviousIncomeEmploymentType =
      income.previous.employmentType || null;
    state.coapplicantPreviousIncomeGrossAnnual =
      income.previous.amount.toString() || null;
    state.coapplicantPreviousIncomeJobTitle = income.previous.jobTitle || null;
  }

  return state;
};
