import { useCallback } from 'react';

import EditAdditionalIncome from '../../../../common/Steps/EditAdditionalIncome';

const Controller = (props: any) => {
  const { presModel, send, context: workflowDetails } = props;
  const { coApplicant = {}, editIncome } = workflowDetails || {};

  const {
    incomes: { additional },
  } = coApplicant;

  const onSubmit = useCallback(
    (updatedIncome: any) => {
      send('Next', {
        data: {
          incomes: {
            additional: additional.map((v: any, i: number) =>
              i !== editIncome ? v : { ...v, ...updatedIncome },
            ),
          },
        },
      });
    },
    [send, additional, editIncome],
  );

  const onBack = useCallback(() => {
    send('Previous');
  }, [send]);

  const onRemove = useCallback(() => {
    send('Next', {
      data: {
        incomes: {
          additional: additional.filter(
            (v: any, i: number) => i !== editIncome,
          ),
        },
      },
    });
  }, [editIncome, additional, send]);

  return (
    <EditAdditionalIncome
      editedIncome={additional[editIncome]}
      presModel={presModel}
      onBack={onBack}
      onRemove={onRemove}
      onSubmit={onSubmit}
    />
  );
};

Controller.displayName = 'CoApplicantIncomeAdditionalEdit.Controller';
export default Controller;
