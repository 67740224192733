import { componentTypes } from '../constants/componentTypes';
import { InputFormatter } from '../utils/input/inputFormatter';

export const fields = {
  ssn: {
    name: 'ssn',
    component: componentTypes.TEXT,
    label: 'Applicant SSN',
    validationRules: {
      required: 'SSN is required',
      minLength: {
        value: 9,
        message: 'SSN length should be 9 characters',
      },
      maxLength: {
        value: 9,
        message: 'SSN length should be 9 characters',
      },
    },
    format: InputFormatter.SSN,
  },
  coApplicantSsn: {
    name: 'coApplicantSsn',
    component: componentTypes.TEXT,
    label: 'Co-Applicant SSN',
    validationRules: {
      required: 'Co-applicant SSN is required',
      minLength: {
        value: 9,
        message: 'SSN length should be 9 characters',
      },
      maxLength: {
        value: 9,
        message: 'SSN length should be 9 characters',
      },
    },
    format: InputFormatter.SSN,
  },
};
