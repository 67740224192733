import { get } from 'lodash';

import {
  AdditionalIncomeType,
  EmploymentType,
  PayFrequency,
} from '../../../common';
import { FormFieldsModelT } from '../../../forms/formFieldsModelT';
import { fields, unifiedFields } from '../../../forms/income';
import { InputFormatter } from '../../input/inputFormatter';

const unifiedIncomeTypesToDisplayAdditionalFields = [
  EmploymentType.FULL_TIME,
  EmploymentType.PART_TIME,
  EmploymentType.ACTIVE_MILITARY,
  EmploymentType.SELF_EMPLOYED,
  EmploymentType.OTHER,
];

export const shouldHaveExtendedFields = (selectedIncomeType: EmploymentType) =>
  unifiedIncomeTypesToDisplayAdditionalFields.includes(selectedIncomeType);

const onIncomeTypeUpdate =
  (primaryIncomeTypeName: string) =>
  ({ data, setVisible }: any) => {
    const incomeType = get(data, primaryIncomeTypeName);
    setVisible(shouldHaveExtendedFields(incomeType));
  };

const onAdditionalIncomeTypeUpdate = ({ data, setVisible }: any) => {
  setVisible(
    data.additionalIncomeType === AdditionalIncomeType.SECONDARY_EMPLOYMENT,
  );
};

const getPrimaryFields = (): FormFieldsModelT => {
  const incomeTypeName = 'current.incomeType';
  return {
    incomeType: unifiedFields.incomeType,
    employerName: {
      ...fields.employerName,
      shouldUnregister: true,
      dependsOn: {
        fields: [incomeTypeName],
        handler: onIncomeTypeUpdate(incomeTypeName),
      },
    },
    jobTitle: {
      ...fields.jobTitle,
      shouldUnregister: true,
      dependsOn: {
        fields: [incomeTypeName],
        handler: onIncomeTypeUpdate(incomeTypeName),
      },
    },
    amount: {
      ...fields.amount,
      label: 'Gross annual income',
      format: InputFormatter.MONEY_ANNUALLY_SPACER,
    },
    startDate: fields.startDate,
    incomeFrequency: {
      ...fields.incomeFrequency,
      value: PayFrequency.YEARLY,
      isHidden: true,
    },
  };
};

const getPreviousFields = (): FormFieldsModelT => ({
  employerName: fields.employerName,
  jobTitle: fields.jobTitle,
  startDate: fields.startDate,
});

const incomeFields = {
  current: getPrimaryFields,
  previous: getPreviousFields,
};

export const unifiedCommonFields = (
  type: 'current' | 'previous',
): FormFieldsModelT => {
  const config = incomeFields[type]();
  return Object.keys(config).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        ...config[key],
        name: [type, config[key].name].join('.'),
      },
    }),
    {},
  );
};

export const additionalIncomeFields = {
  additionalIncomeType: fields.additionalIncomeType,
  employerName: {
    ...fields.employerName,
    label: 'Second employer name',
    validationRules: {
      required: 'Second employer name is required',
      maxLength: {
        value: 38,
        message: 'Second employer name should be 38 characters or less',
      },
    },
    dependsOn: {
      fields: ['additionalIncomeType'],
      handler: onAdditionalIncomeTypeUpdate,
    },
  },
  jobTitle: {
    ...fields.jobTitle,
    label: 'Second employer job title',
    validationRules: {
      required: 'Second employer job title is required',
      maxLength: {
        value: 38,
        message: 'Second employer job title should be 38 characters or less',
      },
    },
    dependsOn: {
      fields: ['additionalIncomeType'],
      handler: onAdditionalIncomeTypeUpdate,
    },
  },
  startDate: {
    ...fields.startDate,
    label: 'Second employer start date',
    dependsOn: {
      fields: ['additionalIncomeType'],
      handler: onAdditionalIncomeTypeUpdate,
    },
  },
  amount: {
    ...fields.amount,
    label: 'Income amount',
  },
  incomeFrequency: unifiedFields.incomeFrequency,
};
