import { merge } from 'lodash';

import { populateFields } from '../../../../../forms/populateFields';
import { commonFields } from '../../../../../utils/helpers/housing-info';
import { withContext } from '../../../../../utils/workflow/withContext';
import BaseModel from '../common/BaseModel';

const model = (send: any, { context }: any) =>
  merge({}, BaseModel, {
    form: {
      fields: merge({}, commonFields(false), {
        activeStartDate: {
          validationRules: {
            validate: (v: any) => {
              return (
                Date.parse(
                  context.updatedUser.residences.current.activeStartDate,
                ) > Date.parse(v) ||
                'Date should not be greater then move-in date of current property'
              );
            },
          },
        },
      }),
      actions: {
        primary: {
          testId: 'continue',
          label: 'Continue',
        },
      },
    },
    headerBlock: {
      subtitle: ['Applicant', '40% complete'],
      progressBar: {
        progress: 40,
      },
      title: 'Tell us about your previous residence',
    },
    info: {
      title: 'Applicant previous residence',
      content: [
        {
          copy: [
            'Please add additional housing information in order to satisfy our two-year housing requirement.',
          ],
        },
      ],
    },
  });

const populateData =
  (fields: any) =>
  (send: any, { context }: any) => {
    return populateFields(fields, context.updatedUser);
  };

const modalFn = (...args: [any, any]) =>
  withContext({ model: model(...args), populateFields: populateData })(...args);

export default modalFn;
