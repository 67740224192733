/**
 * Utilities for loading stylesheets into the dom.
 */

// load a list of style sheets.
export function loadStylesheets(stylesheets: string[]) {
  if (Array.isArray(stylesheets)) {
    // build a src of existing stylesheets to ensure we do not duplicate
    const existingSheets = Array.prototype.slice
      .call(document.getElementsByTagName('link'), 0)
      .reduce((existing, sheet) => ({ ...existing, [sheet.href]: true }), {});
    stylesheets.forEach((src) => {
      if (!existingSheets[src]) {
        loadStylesheet(src);
      }
    });
  }
}

// async load a single style sheet by appending it to document head
function loadStylesheet(src: string) {
  window.requestAnimationFrame(() =>
    document.head.appendChild(createStylesheet(src)),
  );
}

function createStylesheet(href: string) {
  const stylesheet = document.createElement('link');
  stylesheet.type = 'text/css';
  stylesheet.rel = 'stylesheet';
  stylesheet.href = href;
  return stylesheet;
}
