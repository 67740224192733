/**
 * getDisplayName
 *
 * Returns a display name for a given component so that we can have better access to it when debugging.
 *
 * @param  {Function} WrappedComponent
 * @return {String}
 */
function getDisplayName(WrappedComponent: any) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export { getDisplayName };
