import { OwnershipStatus, phoneNumberValidator } from '../common';
import { componentTypes } from '../constants/componentTypes';
import { USStates } from '../constants/states';
import { InputFormatter } from '../utils/input/inputFormatter';
import { generateDropdownPresentationModel } from '../utils/listOptions/DropdownPresentationModel';
import type { FormFieldsModelT } from './formFieldsModelT';
import { optionsList } from './housingTypeOptions';

export const fields: FormFieldsModelT = {
  firstName: {
    name: 'firstName',
    autoCompleteObj: { autocomplete: 'given-name', name: 'fname' },
    component: componentTypes.TEXT,
    label: 'First name',
    validationRules: {
      required: 'First name is required',
      pattern: {
        value: /^[a-zA-Z\s-']+$/,
        message: 'Correct First name is required',
      },
    },
  },
  lastName: {
    name: 'lastName',
    autoCompleteObj: { autocomplete: 'family-name', name: 'lname' },
    component: componentTypes.TEXT,
    label: 'Last name',
    validationRules: {
      required: 'Last name is required',
      pattern: {
        value: /^[a-zA-Z\s-']+$/,
        message: 'Correct Last name is required',
      },
    },
  },
  primaryEmailAddress: {
    name: 'primaryEmailAddress',
    autoCompleteObj: { autocomplete: 'email', name: 'email' },
    component: componentTypes.TEXT,
    label: 'Email address',
    shouldTrimValue: true,
    validationRules: {
      required: 'Email address is required',
      pattern: {
        value:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
        message: 'Correct Email address is required',
      },
    },
  },
  primaryPhoneNumber: {
    name: 'primaryPhoneNumber',
    autoCompleteObj: { autocomplete: 'tel', name: 'phone' },
    component: componentTypes.TEXT,
    label: 'Cell phone',
    validationRules: {
      required: 'Cell phone is required',
      pattern: {
        value: phoneNumberValidator,
        message: 'Valid phone number is required',
      },
    },
    format: InputFormatter.PHONE_NUMBER,
  },
  grossIncome: {
    name: 'incomes.current.amount',
    label: 'Gross annual income',
    component: componentTypes.TEXT,
    validationRules: {
      required: 'Gross income is required',
      validate: (v: any) =>
        parseFloat(v) > 0 || 'You should have some income to move forward',
    },
    format: InputFormatter.MONEY,
  },
  ownershipStatus: {
    name: 'ownershipStatus',
    component: componentTypes.RADIO,
    validationRules: {
      required: 'Housing type is required', // Just to make sure
    },
    defaultValue: OwnershipStatus.OWN,
    options: optionsList,
  },
  lineOne: {
    name: 'physicalAddress.lineOne',
    component: componentTypes.TEXT,
    label: 'Address',
    validationRules: {
      required: 'Address is required',
      pattern: {
        value:
          /^(?!.*(?:(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(box|bin)[-.\s]*)|.*((p |post)[-.\s]*(box|bin)[-.\s]*)))).*$/i,
        message: 'Address must be a physical address, not a P.O. Box',
      },
    },
  },
  lineTwo: {
    name: 'physicalAddress.lineTwo',
    component: componentTypes.TEXT,
    label: 'Unit, apt, suite',
  },
  city: {
    name: 'physicalAddress.city',
    component: componentTypes.TEXT,
    label: 'City',
    validationRules: {
      required: 'City is required',
    },
  },
  province: {
    name: 'physicalAddress.province',
    component: componentTypes.DROPDOWN,
    label: 'State',
    ...generateDropdownPresentationModel({
      optionsSource: USStates,
      nameFieldKey: 'name',
      valueFieldKey: 'abbreviation',
    }),
    validationRules: {
      required: 'State is required',
    },
  },
  postalCode: {
    name: 'physicalAddress.postalCode',
    component: componentTypes.TEXT,
    label: 'Zip',
    validationRules: {
      required: 'Zip is required',
      pattern: {
        value: /^\d{5}?$/,
        message: 'Zip must be a valid US postal code value',
      },
    },
    format: InputFormatter.NUMBER,
  },
  monthlyPaymentAmount: {
    name: 'monthlyPaymentAmount',
    component: componentTypes.TEXT,
    label: 'Monthly housing amount',
    validationRules: {
      required: 'Monthly housing amount is required',
      min: {
        value: 0,
        message: 'Correct amount is required',
      },
    },
    format: InputFormatter.MONEY,
  },
  pin: {
    name: 'pin',
    component: componentTypes.TEXT,
    label: '6-digit verification code',
    validationRules: {
      required: 'Verification code must be a 6-digit numeric value',
      minLength: {
        value: 6,
        message: 'Verification code must be a 6-digit numeric value',
      },
    },
    format: InputFormatter.PIN,
    autoCompleteObj: {
      autocomplete: 'one-time-code',
    },
  },
  dob: {
    name: 'personalInfo.birthDate',
    component: componentTypes.TEXT,
    label: 'Date of birth',
    validationRules: {
      required: 'Date of birth is required',
      pattern: {
        value: /^\d{4}-\d{2}-\d{2}/,
        message: 'Correct DOB is required',
      },
      validate: (v: any) => {
        return (
          Date.now() > Date.parse(v) || 'Date of birth must be a past date'
        );
      },
    },
    format: InputFormatter.DATE,
  },
  activeStartDate: {
    name: 'activeStartDate',
    component: componentTypes.TEXT,
    label: 'Move-in date',
    validationRules: {
      required: 'Date is required',
      pattern: {
        value: /^\d{4}-\d{2}/,
        message: 'Correct date is required',
      },
      validate: (v: any) => {
        return Date.now() > Date.parse(v) || 'Must be a past date';
      },
    },
    format: InputFormatter.DATE,
    formatOptions: {
      placeholder: 'MM/YYYY',
      displayFormat: '##/####',
    },
  },
  employments: {
    name: 'employments',
    component: componentTypes.SUMMARY_LIST,
    cta: 'Edit',
    largeTitle: true,
  },
};
