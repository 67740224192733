import { ButtonGroupProps } from '../shared/ButtonGroup/ButtonGroup';
import { FooterProps } from '../shared/Footer/Footer';
import { FormInterface } from '../shared/Form/interfaces/form.interface';
import { HeaderProps } from '../shared/Header/Header';
import { HeaderBlockProps } from '../shared/HeaderBlock/HeaderBlock';
import { PillowImageProps } from '../shared/PillowImage/PillowImage';

export enum FooterLink {
  Home = 'Home',
  ContactUs = 'ContactUs',
}

interface FormWithConfirmInterface extends FormInterface {
  withConfirmModal?: boolean;
}

interface ExtendedFooterProps extends Partial<FooterProps> {
  isHidden?: boolean;
  links?: FooterLink[];
}

export interface GatePresModelInterface {
  template: {
    header?: HeaderProps;
    footer?: ExtendedFooterProps;
    img?: PillowImageProps;
    centerContent?: boolean;
    persistImage?: boolean;
  };
  info?: {
    title: string;
    subtitle?: string;
    content?: {
      accordion?: boolean;
      asList?: boolean;
      copy?: (string | undefined)[];
      title?: string;
    }[];
    buttonGroupPresModel?: ButtonGroupProps;
  };
  form: FormWithConfirmInterface;
  headerBlock?: HeaderBlockProps;
  resendTimerDuration?: number;
}
