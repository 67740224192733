import { unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

const commonPseudo = ($theme: any) => ({
  content: '""',
  display: 'block',
  position: 'absolute',
  backgroundColor: $theme.colors.primaryActive,
});

export const FooterLink = styled(
  'a',
  ({ $theme }: any) =>
    ({
      color: $theme.colors.primaryActive,
      position: 'relative',
      marginRight: unit[4],
      textDecoration: 'none',
      lineHeight: unit[3],
      display: 'inline-block',
      ':hover': {
        color: $theme.colors.primaryActive,
      },
      ':before': {
        ...commonPseudo($theme),
        bottom: 0,
        left: `-${unit[2]}`,
        width: `calc(100% + ${unit[4]})`,
        height: '1px',
      },
      ':after': {
        ...commonPseudo($theme),
        right: `-${unit[2]}`,
        top: '50%',
        transform: 'translateY(-50%)',
        width: '1px',
        height: unit[3],
      },
      ':last-child': {
        marginRight: '0',
        ':before': {
          left: 'auto',
          right: '0',
          width: `calc(100% + ${unit[2]})`,
        },
        ':after': {
          display: 'none',
        },
      },
      ':first-child': {
        ':before': {
          left: '0',
          width: `calc(100% + ${unit[2]})`,
        },
      },
      ':only-child': {
        ':before': {
          width: '100%',
        },
      },
    } as any),
);

export const SidebarLink = styled(
  'a',
  ({ $theme }: any) =>
    ({
      color: $theme.colors.transparency.whiteTransparentMedium,
      position: 'relative',
      marginRight: unit[4],
      textDecoration: 'none',
      fontWeight: 500,
      ':hover': {
        color: $theme.colors.primaryActive,
      },
      ':after': {
        ...commonPseudo($theme),
        right: `-${unit[2]}`,
        bottom: '0',
        width: '1px',
        height: unit[2],
      },
      ':last-child': {
        marginRight: '0',
        ':after': {
          display: 'none',
        },
      },
    } as any),
);
