import { theme, unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const Breadcrumb = styled(
  'span',
  ({ $isLast, $theme, $isClickable }: any) => ({
    marginRight: $isLast ? 0 : unit[4],
    ...theme.fonts.paragraph,
    color: $theme.colors.primaryActive,
    position: 'relative',
    ...($isClickable
      ? {
          cursor: 'pointer',
        }
      : {}),
    ':before': $isLast
      ? {}
      : {
          position: 'absolute',
          right: '-18px',
          top: '50%',
          transform: 'translateY(-50%)',
          content: "'/'",
          display: 'block',
        },
  }),
);
