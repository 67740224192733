import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { PersonalInformation as ViewComponent } from '../../../components/PersonalInformation/PersonalInformation';
import { PageHeader } from '../../../components/shared/PageHeader/PageHeader';
import { TABS } from '../constants';

export const PersonalInformationView = () => {
  const navigate = useNavigate();

  return (
    <Fragment>
      <PageHeader
        breadCrumbs={['Account']}
        navigate={navigate}
        selectedTab="Personal"
        tabs={TABS}
        tabsSiblingRouteDepth="../"
        title="Account"
      />
      <ViewComponent />
    </Fragment>
  );
};
