import { get, merge } from 'lodash';

import { getAddressFieldName } from '../../../../../common/housing-info';
import { AGREEMENT_STATUSES } from '../../../../../constants/choices/checklight';
import { fields as coApplicantFields } from '../../../../../forms/co-applicant';
import { FormFieldsModelT } from '../../../../../forms/formFieldsModelT';
import { unifiedOptionsList } from '../../../../../forms/housingTypeOptions';
import { populateFields } from '../../../../../forms/populateFields';
import { commonFields } from '../../../../../utils/helpers/housing-info';
import { withContext } from '../../../../../utils/workflow/withContext';
import BaseModel from '../common/BaseModel';

const fieldsConfig = merge({}, commonFields(true), {
  ownershipStatus: {
    options: unifiedOptionsList,
  },
});

const formattedAddressFields: FormFieldsModelT = {
  ...Object.keys(fieldsConfig).reduce((acc, key) => {
    return {
      ...acc,
      [key]: {
        ...fieldsConfig[key],
        name: getAddressFieldName(key, true),
        shouldUnregister: true,
        dependsOn: {
          fields: ['residences.sameAsApplicant'],
          handler: ({ data, setVisible }: any) => {
            setVisible(
              get(data, 'residences.sameAsApplicant') === AGREEMENT_STATUSES.NO,
            );
          },
        },
      },
    };
  }, {}),
};

const populateData =
  (fields: any) =>
  (send: any, { context }: any) => {
    return populateFields(fields, context.coApplicant);
  };

const model = merge({}, BaseModel, {
  headerBlock: {
    title: 'Help us with their housing details',
    subtitle: ['Co-applicant', '40%'],
    progressBar: {
      progress: 40,
      withContainer: true,
    },
  },
  form: {
    fields: {
      sameAsApplicant: {
        ...coApplicantFields.sameAsApplicant,
        value: AGREEMENT_STATUSES.YES,
      },
      ...formattedAddressFields,
    },
    actions: {
      primary: {
        testId: 'continue',
        label: 'Continue',
      },
    },
  },
  info: {
    title: 'Co-applicant housing details',
    subtitle: 'Help',
    content: [
      {
        copy: [
          'At a minimum, we need to know where they have lived for the past two years.',
          'Haven’t they been at their current residence for that long? Do not fret, you’ll be given the chance to add additional housing information in order to satisfy this requirement.',
        ],
      },
    ],
  },
});

const modelFn = (...args: [any, any]) =>
  withContext({
    model,
    populateFields: populateData,
  })(...args);

export default modelFn;
