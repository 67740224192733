import { font, unit } from '../../common/theme/index';
import { styled } from '../../providers/context/StyletronProvider/withStyletronProvider';

export const Panel = styled('div', ({ $isInactive, $isLast, $theme }: any) => ({
  paddingBottom: unit[6],
  display: 'flex',
  position: 'relative',
  ':before': {
    position: 'absolute',
    left: '11px',
    top: unit[3], // checkbox height
    content: $isLast ? '' : '""',
    width: '2px',
    height: `calc(100% - ${unit[3]})`,
    backgroundColor: $isInactive
      ? $theme.colors.disabled
      : $theme.colors.primaryActive,
  },
}));

export const CheckboxContainer = styled('div', {
  marginRight: unit[3],
});

export const InfoContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const Title = styled('span', ({ $theme }: any) => ({
  fontFamily: font.family.Merriweather,
  fontSize: font.size.headingLarge,
  lineHeight: font.lineHeight.subTitle,
  color: $theme.colors.infoOverlay,
  marginBottom: unit[1],
}));

export const Description = styled('span', ({ $theme }: any) => ({
  fontSize: font.size.headingMedium,
  lineHeight: font.lineHeight.subTitle,
  color: $theme.colors.primaryActive,
}));
