import { PMT } from '@formulajs/formulajs';
import round from 'lodash/round';

/**
 * calculateMonthlyPayment
 *
 * @param {number} rate:                     the yearly interest or APR depending on what you are presenting to the customer as a decimal ie. 9.9% should be 0.099
 * @param {number} numberOfPaymentsInMonths: term of the loan, represented in months.  ie: 60 month term
 * @param {number} amountFinanced:           total amount of the loan
 * @param {number} downPayment:              any down payment
 *
 * @returns {number}
 */
export const calculateMonthlyPayment = (
  rate = 0,
  numberOfPaymentsInMonths = 0,
  amountFinanced = 0,
  downPayment = 0,
): number => {
  // we divide the yearly interest by 12 months to be interest for the individual month
  const monthlyRate = rate / 12;

  // amount financed is the total amount of the loan, less any down payments
  const amount = amountFinanced - downPayment;

  // if the amount is zero or below, we do not need
  // to calculate the payments as there are none to be made
  if (amount <= 0) {
    return 0;
  }

  // use the PMT formula to calculate monthly payments
  const monthlyPayment = PMT(
    monthlyRate,
    numberOfPaymentsInMonths,
    amount * -1,
    null,
    null,
  ) as number;

  // return a "converted value" that formats it into two decimal places. ie: 132.12
  return round(monthlyPayment, 2);
};
