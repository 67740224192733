import { merge } from 'lodash';
import { Fragment, useCallback, useMemo } from 'react';

import { PillowForm } from '../../../../components/PillowForm';
import { ConfirmModal } from '../../../../components/shared/confirmModal/ConfirmModal';
import { populateFields } from '../../../../forms/populateFields';
import { useModal } from '../../../../hooks/useModal/useModal';
import { UserIncome } from '../../../../providers/context/CurrentUserContext/CurrentUserContextT';

const Controller = (props: {
  presModel: any;
  editedIncome: UserIncome;
  onSubmit: (data: any) => void;
  onRemove: () => void;
  onBack: () => void;
}) => {
  const removeModal = useModal();

  const {
    presModel,
    editedIncome,
    onSubmit: onSubmitForm,
    onRemove: onRemoveForm,
    onBack: onBackForm,
  } = props;

  const onSubmit = useCallback(
    (data: any) => {
      onSubmitForm(data);
    },
    [onSubmitForm],
  );

  const removeModalContent = useMemo(() => {
    return removeModal.isShowing ? (
      <ConfirmModal
        btnText="Remove"
        title="Are you sure you want to remove the income?"
        autoClose
        onCancel={removeModal.close}
        onConfirm={onRemoveForm}
      >
        Removing this item from your dashboard will delete it, and all of its
        records. Are you sure you want to continue?
      </ConfirmModal>
    ) : null;
  }, [removeModal, onRemoveForm]);

  const enhancedProps = useMemo(
    () =>
      merge(
        {},
        { presModel },
        {
          presModel: {
            template: {
              header: {
                onBack: onBackForm,
              },
            },
            form: {
              fields: populateFields(presModel.form.fields, editedIncome),
              actions: {
                primary: {
                  handler: onSubmit,
                },
                secondary: {
                  handler: removeModal.open,
                },
              },
            },
          },
        },
      ),
    [onSubmit, removeModal, onBackForm, presModel, editedIncome],
  );

  return (
    <Fragment>
      <PillowForm {...enhancedProps} />
      {removeModalContent}
    </Fragment>
  );
};

Controller.displayName = 'EditAdditionalIncome.Controller';
export default Controller;
