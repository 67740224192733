import { lowerCase, random } from 'lodash';
import { flow as flowFP, replace as replaceFP } from 'lodash/fp';

/**
 *
 *
 * @param prefix
 * @param generator optional, allows testing without doing a module level mock on lodash.
 * @returns {(text) => String}
 */

/**
 * React requires unique keys for items in an Array.  This will generate a unique key.
 *
 * This will coerce input text from 'Foo Bar' to 'foo-bar' for the sake of debugging.
 *
 * @param prefix
 * @param generator, optional. Defaults to random.
 * @returns {*}
 */
export const generateDOMKey = (prefix: any, generator = random) =>
  flowFP(
    lowerCase,
    replaceFP(' ', '-'),
    (text) => `${prefix}-${text}-${generator(999)}`,
  );

/**
 * Same as generateDOMKey only it uses a monotonically increasing value if numeric order is important.
 *
 * @param prefix
 * @returns {*}
 */
export const generateDOMKeyMonotonically = (prefix: any) => {
  let startingIndex = 0;
  // eslint-disable-next-line no-unused-vars, no-plusplus
  return generateDOMKey(prefix, () => startingIndex++);
};
