import { useEffect, useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';

import { ChecklightSession, isApplicationValid } from '../../common';
import { LoanConsultant } from '../../common/interfaces/applications/loan-consultant.interface';
import { Branding } from '../../common/interfaces/branding/branding';
import { useAPI } from '../../hooks/useAPI/useAPI';
import { ContactUs, ContactUsType } from '../shared/ContactUs/ContactUs';

export const SelectedOfferContact = ({
  applicationBranding,
  autopayNumber,
  vehicleId,
  checklightSession,
}: {
  applicationBranding: Branding;
  autopayNumber?: string;
  vehicleId: string;
  checklightSession: ChecklightSession;
}) => {
  const api = useAPI();

  const fetchLoanConsultant = useAsyncCallback(
    (): Promise<LoanConsultant> =>
      api.get(`/vehicles/${vehicleId}/application/loan-consultant`),
  );

  const { result: loanConsultant, loading } = fetchLoanConsultant || {};

  useEffect(() => {
    if (isApplicationValid(checklightSession)) {
      fetchLoanConsultant.execute();
    }
  }, []);

  const isAvailableFields = useMemo(() => {
    const consultantFieldsArr = [
      loanConsultant?.employeePhoneNumber,
      loanConsultant?.employeeFirstName,
      loanConsultant?.employeeLastName,
      loanConsultant?.employeeEmailAddress,
    ];

    return consultantFieldsArr.some(Boolean);
  }, [loanConsultant]);

  return (
    <ContactUs
      applicationBranding={applicationBranding}
      autopayNumber={autopayNumber}
      isLoading={loading}
      loanConsultant={loanConsultant}
      type={
        isAvailableFields
          ? ContactUsType.LOAN_CONSULTANT
          : ContactUsType.DEFAULT
      }
    />
  );
};
