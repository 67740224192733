import { common, theme, unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const ResourcesContainer = styled('div', ({ $theme }: any) => ({
  overflow: 'hidden',
  borderRadius: unit[2],
  border: `1px solid ${$theme.colors.secondaryOverlay}`,
}));

export const ResourcePanelWrapper = styled('div', ({ $theme }: any) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: unit[3],
  borderBottom: `1px solid ${$theme.colors.disabled}`,
  height: unit[19],
  cursor: 'pointer',
  ':hover': {
    backgroundColor: $theme.colors.tertiaryOverlay,
  },
  ':last-of-type': {
    borderBottom: 0,
  },
}));

export const ResourcePanelTitle = styled('span', ({ $theme }: any) => ({
  fontFamily: common.font.family.Merriweather,
  fontSize: unit[2],
  lineHeight: unit[3],
  color: $theme.colors.infoOverlay,
  marginBottom: unit[1],
  display: 'block',
}));

export const ResourcePanelSubtitle = styled(
  'span',
  ({ $isMobile, $theme }: any) => ({
    ...theme.fonts.paragraph,
    color: $theme.colors.primaryActive,
    marginBottom: $isMobile ? unit[3] : unit[6],
  }),
);
