import { FooterLink } from '../../components/PillowForm';
import { PillowType } from '../../components/shared/PillowImage/pillowTypes';
import { fields } from '../../forms/user';

const model = {
  template: {
    header: {
      showBack: true,
      showClose: false,
    },
    footer: {
      isActive: false,
      links: [FooterLink.ContactUs],
    },
    img: {
      type: PillowType.ACCOUNT,
    },
  },
  headerBlock: {
    title: 'Enter the code we just sent to your phone',
    progressBar: {
      progress: 100,
      withContainer: true,
    },
    subtitle: ['Sign in'],
  },
  info: {
    title: 'Verify one-time password',
    subtitle: 'Help',
    content: [
      {
        copy: [
          "Your one-time password is an autogenerated code that's good for a single login and used to verify your identity. You should receive this token by email or SMS and will be required to enter it into the sign in form to access your account. If for some reason you did not receive a code, review the phone number provided or click " +
            '"re-send".',
        ],
      },
    ],
  },
  resendTimerDuration: 15,
  form: {
    actions: {
      primary: {
        testId: 'verify',
        label: 'Verify',
        preserveLabel: true,
        preserveTestId: true,
      },

      secondary: {
        label: 'Re-send',
        testId: 'retry',
        preserveLabel: true,
        preserveTestId: true,
      },
    },
    fields: {
      pin: {
        testId: 'pin',
        ...fields.pin,
      },
    },
  },
};

export default model;
