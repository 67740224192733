import { yearList } from '../common';
import { componentTypes } from '../constants/componentTypes';
import { InputFormatter } from '../utils/input/inputFormatter';
import type { FormFieldsModelT } from './formFieldsModelT';
import { fields as userFields } from './user';

export const fields: FormFieldsModelT = {
  mileage: {
    name: 'mileage',
    component: componentTypes.TEXT,
    label: 'Mileage',
    validationRules: {
      required: 'Mileage is required',
      min: {
        value: 1,
        message: 'Valid Mileage is required',
      },
      max: {
        value: 150000,
        message: 'Mileage must be less than 150,000 mi',
      },
    },
    format: InputFormatter.MILEAGE,
  },

  // Vehicle Identification Group: YMMT
  year: {
    name: 'year',
    component: componentTypes.SINGLE_SELECT,
    options: yearList(20).map((year) => ({ label: year, value: year })),
    largeTitle: true,
  },
  makeId: {
    name: 'makeId',
    component: componentTypes.SINGLE_SELECT,
    largeTitle: true,
  },
  modelId: {
    name: 'modelId',
    component: componentTypes.SINGLE_SELECT,
    largeTitle: true,
  },
  trimId: {
    name: 'trimId',
    component: componentTypes.DROPDOWN,
    label: 'Trim',
    validationRules: {
      required: 'Trim is required',
    },
  },

  // Vehicle Identification Group: VIN
  vin: {
    name: 'vin',
    component: componentTypes.TEXT,
    label: 'VIN',
    validationRules: {
      required: 'VIN is required',
    },
  },

  // Vehicle Identification Group: Plate/State
  plate: {
    name: 'plate',
    component: componentTypes.TEXT,
    label: 'License plate',
    validationRules: {
      required: 'License plate is required',
    },
  },
  province: {
    ...userFields.province,
    name: 'province',
  },
  estimatedPayoffAmount: {
    name: 'loan.payoffAmount',
    component: componentTypes.TEXT,
    label: 'Estimated payoff amount',
    validationRules: {
      required: 'Estimated payoff amount is required',
      min: {
        value: 0,
        message: 'Correct remaining amount is required',
      },
    },
    format: InputFormatter.MONEY,
  },
  purchasePrice: {
    name: 'purchasePrice',
    component: componentTypes.TEXT,
    label: 'Purchase price of vehicle',
    validationRules: {
      required: 'Purchase price of vehicle is required',
      min: {
        value: 0,
        message: 'Correct price is required',
      },
    },
    format: InputFormatter.MONEY,
  },
};
