import { get, isEmpty, isMatchWith, set } from 'lodash';
import { Fragment, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { componentTypes } from '../../constants/componentTypes';
import { useModal } from '../../hooks/useModal/useModal';
import { ConfirmCancelModal } from '../shared/confirmModal/ConfirmCancelModal';
import { GateFormProps } from './GateForm';

/**
 * Currently, there are 3 options to go back (close) form with unsaved changes:
 * - browser back button. Handled by ConfirmCancelModal and navigation.block
 * - back icon in the header. Responsible method - `headerBackIconAction`
 * - form secondary button. Responsible method - `secondaryButtonBackAction`
 *
 * Component should work fine when header back icon and/or form secondary button are not defined
 *
 * @param WrappedComponent
 * @returns
 */
export const withConfirmModal =
  (WrappedComponent: any) => (props: GateFormProps) => {
    const { isShowingAny } = useModal();
    const [openConfirmModal, setOpenRef] = useState<any>();

    const preDefinedMethods = props.methods;

    const freshMethods = useForm();

    const methods = useMemo(
      () => preDefinedMethods || freshMethods,
      [preDefinedMethods, freshMethods],
    );

    const { watch } = methods;
    const formValue = watch();

    const defaultFormValue = useMemo(() => {
      if (!props.presModel.form.fields) {
        return {};
      }

      return Object.values(props.presModel.form.fields).reduce(
        (acc: any, field) => {
          if (field.component === componentTypes.SUBTITLE) {
            return acc;
          }
          set(acc, field.name, field.value);
          return acc;
        },
        {},
      );
    }, [props]);

    const formHasChanges = useMemo(() => {
      if (isEmpty(formValue)) {
        return false;
      }

      return !isMatchWith(
        defaultFormValue,
        formValue,
        (oldValue, updatedValue) => {
          // Handle cases when values have undefined and empty string
          // Form populates fields with empty strings and we usually have undefined in context
          if ([oldValue, updatedValue].every((v) => !v)) {
            return true;
          }
        },
      );
    }, [formValue, defaultFormValue]);

    const headerBackIconAction = useMemo(() => {
      const definedHeaderConfig = get(props, 'presModel.template.header');

      if (!definedHeaderConfig?.onBack) {
        return {};
      }

      return {
        onBack: (data?: any) => {
          if (formHasChanges) {
            openConfirmModal.open(() => {
              definedHeaderConfig?.onBack?.(data);
            });
          } else {
            definedHeaderConfig?.onBack?.(data);
          }
        },
      };
    }, [formHasChanges, props, openConfirmModal]);

    const secondaryButtonBackAction = useMemo(() => {
      const definedSecondaryButton = get(
        props,
        'presModel.form.actions.secondary',
      );

      if (
        !definedSecondaryButton?.handler ||
        definedSecondaryButton?.label === 'Remove'
      ) {
        return {};
      }
      return {
        secondary: {
          ...definedSecondaryButton,
          handler: (data?: any) => {
            if (formHasChanges) {
              openConfirmModal.open(() => {
                definedSecondaryButton?.handler?.(data);
              });
            } else {
              definedSecondaryButton?.handler?.(data);
            }
          },
        },
      };
    }, [formHasChanges, props, openConfirmModal]);

    const enhancedProps = useMemo(() => {
      return {
        ...props,
        presModel: {
          ...props.presModel,
          template: {
            ...props.presModel.template,
            header: {
              ...props.presModel.template.header,
              ...headerBackIconAction,
            },
          },
          form: {
            ...props.presModel.form,
            actions: {
              ...props.presModel.form.actions,
              ...secondaryButtonBackAction,
            },
          },
        },
      };
    }, [props, headerBackIconAction, secondaryButtonBackAction]);

    return (
      <Fragment>
        <WrappedComponent {...enhancedProps} methods={methods} />

        {/**
         * This condition is very important. It is responsible for preventing react-router navigation from being blocked within gates.
         * This happens because the ConfirmCancelModal is using react-router's useBlocker hook. This version of the hook is unstable and
         * does not account for how we navigate using xState machines within gates.
         *
         * Our react-router version seems to offer no better way of prompting the user of unsaved changes before navigating away from a page.
         *
         * Bug theory:
         * The underlying problem is that the ConfirmCancelModal contains the Modal component. The Modal component will be set to show whenever
         * the useModal hook is used. So regardless of whether the ConfirmCancelModal is showing, the Modal component it contains will be set to show which
         * triggers the useBlocker hook in ConfirmCancelModal. This can occur in a case where the user is attempting to see an info modal on a gate page that
         * also offers a ConfirmCancelModal.
         *
         * This is why we need to check if any modal is showing. If no modal is showing, we can safely show the ConfirmCancelModal without locking
         * the react-router navigation.
         *
         * Reference: https://savingsgroup.atlassian.net/browse/CP-4890
         */}
        {!isShowingAny && (
          <ConfirmCancelModal
            formChanged={formHasChanges}
            setOpenRef={setOpenRef}
          />
        )}
      </Fragment>
    );
  };
