import { merge, pick } from 'lodash';

import {
  addressFields,
  getAddressFieldsNames,
} from '../../../../../common/housing-info';
import { componentTypes } from '../../../../../constants/componentTypes';
import { populateFields } from '../../../../../forms/populateFields';
import { fields } from '../../../../../forms/user';
import { Signal } from '../../../../common/constants/signals';
import { AccountContext } from '../../common/unified-workflow-context.interface';
import { Step } from '../../steps';
import BaseModel from '../common/BaseModel';

const model = (
  send: any,
  {
    context: { user, coApplicant, userExistsOtherErrors },
    matches,
  }: { context: AccountContext; matches: any },
) => {
  const accountFields = {
    ...pick(fields, [
      'firstName',
      'lastName',
      'primaryEmailAddress',
      'primaryPhoneNumber',
      'dob',
      'grossIncome',
    ]),
    subtitle: {
      label: "What's your address?",
      name: 'subtitle',
      component: componentTypes.SUBTITLE,
    },
    ...merge(
      {},
      pick(fields, addressFields),
      getAddressFieldsNames(addressFields, true),
    ),
    ...merge(
      {},
      pick(fields, ['monthlyPaymentAmount']),
      getAddressFieldsNames(['monthlyPaymentAmount'], true),
    ),
  };
  const savedFields = populateFields(accountFields, user);
  const populatedFields = merge({}, accountFields, savedFields);
  const progress = coApplicant.coApplicantAnswer ? 36 : 44;

  return merge({}, BaseModel, {
    template: {
      header: {
        showBack: true,
        onBack: () => {
          send(Signal.Previous);
        },
      },
    },
    headerBlock: {
      progressBar: {
        progress,
        withContainer: true,
      },
      title: 'Personal details',
      subtitle: ['Account', `${progress}% complete`],
    },
    form: {
      fields: populatedFields,
      actions: {
        primary: {
          testId: 'continue',
          label: 'Continue',
          handler: (data: any) => {
            send(Signal.Next, { data: { user: merge({}, user, data) } });
          },
          isLoading: matches(`${Step.PersonalDetails}.VerifyUser`),
        },
      },
      autoFocus: true,
      globalErrors: userExistsOtherErrors,
    },
    info: {
      title: 'Applicant details',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Providing us with correct name, age, and contact information will ensure the entire loan process is conducted as smoothly as possible.',
          ],
        },
      ],
    },
  });
};

export default model;
