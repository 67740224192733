import { useEffect } from 'react';

import { theme } from '../../../common/theme';
import { useModal } from '../../../hooks/useModal/useModal';
import { Icon } from '../Icon/Icon';
import { IconTypes } from '../Icon/iconTypes';
import { RotationDirections } from '../Icon/rotationDirections';
import {
  ResourcePanelSubtitle,
  ResourcePanelTitle,
  ResourcePanelWrapper,
} from './styles';

export const ResourcePanel = ({
  title,
  subtitle,
  children,
  shouldInstantlyOpen,
}: any) => {
  const { isShowing, open } = useModal();

  useEffect(() => {
    if (shouldInstantlyOpen) {
      open();
    }
  }, []);

  return (
    <ResourcePanelWrapper onClick={open}>
      <div>
        <ResourcePanelTitle>{title}</ResourcePanelTitle>
        <ResourcePanelSubtitle>{subtitle}</ResourcePanelSubtitle>
      </div>
      <Icon
        height={theme.unit[3]}
        name={IconTypes.ARROW}
        rotate={RotationDirections.RIGHT}
      />
      {isShowing ? children : null}
    </ResourcePanelWrapper>
  );
};
