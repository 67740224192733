import incomeIcon from '../../../../assets/Income.svg';
import {
  ADDITIONAL_INCOME,
  getAmountByPayFrequency,
  PayFrequency,
} from '../../../../common';
import {
  UserIncome,
  UserIncomes,
} from '../../../../providers/context/CurrentUserContext/CurrentUserContextT';
import { IncomeReviewSignal } from '../../interfaces/applicant.interface';

export const incomeReviewSignals: IncomeReviewSignal = {
  AddIncome: 'AddIncome',
  EditIncomePrimary: 'EditIncomePrimary',
  EditIncomeAdditional: 'EditIncomeAdditional',
};

const getPrimaryIncomeLabel = (primaryIncome: UserIncome) => {
  const { employerName, incomeType, additionalIncomeType } = primaryIncome;
  let label;
  if (incomeType === 'OTHER') {
    if (additionalIncomeType) {
      label = (
        ADDITIONAL_INCOME.find((v) => v.value === additionalIncomeType) || {}
      ).label;
    }
  } else {
    label = employerName;
  }
  return label || 'Other';
};

export const getPrimaryIncomeWithoutLabel = (primaryIncome: UserIncome) => {
  const { amount, incomeFrequency } = primaryIncome;

  const subtitle = [amount, incomeFrequency].some((v) => v === undefined)
    ? ''
    : {
        value: getAmountByPayFrequency(PayFrequency.MONTHLY as any)(
          incomeFrequency as any,
          amount,
        ),
        format: {
          thousandSeparator: true,
          prefix: '$',
          suffix: '/month',
        },
      };

  return {
    key: 'primaryIncome',
    value: 0,
    image: incomeIcon,
    subtitle,
  };
};

export const getPrimaryIncome = (primaryIncome: UserIncome) => {
  const primaryIncomeLabel = getPrimaryIncomeLabel(primaryIncome);

  return {
    ...getPrimaryIncomeWithoutLabel(primaryIncome),
    label: primaryIncomeLabel,
  };
};

export const getAdditionalIncomes = (additionalIncomes: UserIncome[]) => {
  return (additionalIncomes || []).map((additionalIncome: any, index: any) => {
    const { amount, incomeFrequency, additionalIncomeType } = additionalIncome;

    const subtitle = [amount, incomeFrequency].some((v) => v === undefined)
      ? ''
      : {
          value: getAmountByPayFrequency(PayFrequency.MONTHLY as any)(
            incomeFrequency,
            amount,
          ),
          format: {
            thousandSeparator: true,
            prefix: '$',
            suffix: '/month',
          },
        };

    const incomeTypeLabel = ADDITIONAL_INCOME.find(
      ({ value }) => value === additionalIncomeType,
    );
    const label = incomeTypeLabel ? incomeTypeLabel.label : 'Other';

    return {
      key: `additionalIncome${index}`,
      value: index + 1,
      label,
      image: incomeIcon,
      subtitle,
    };
  });
};

export const getIncomesList = (
  incomes: UserIncomes & { current: UserIncome },
) => {
  const {
    current: primaryIncome,
    additional: additionalIncomes = [],
  }: UserIncomes & { current: UserIncome } = incomes;

  const primary = getPrimaryIncome(primaryIncome);

  const additional = getAdditionalIncomes(additionalIncomes);

  return [primary, ...additional];
};

export const onEditIncome =
  (send: any, signal: Required<IncomeReviewSignal>) => (index: number) => {
    const signalName =
      index === 0 ? signal.EditIncomePrimary : signal.EditIncomeAdditional;
    send(signalName, {
      editIncome: index - 1,
    });
  };
