import { common, unit } from '../../common/theme/index';
import { styled } from '../../providers/context/StyletronProvider/withStyletronProvider';

export const FormContainer = styled('div', ({ $isDesktop }: any) => ({
  ...($isDesktop ? { maxWidth: '672px' } : {}),
}));

export const FormTitle = styled('div', () => ({
  fontSize: common.font.size.title20px,
  fontFamily: common.font.family.Merriweather,
  margin: [unit[1], 0, unit[6]].join(' '),
}));
