import { merge } from 'lodash';

import CommonPersonalDetailsModel from '../../../../common/Steps/ApplicantPersonalInfo/Model';
import BaseModel from '../common/BaseModel';

const model = merge({}, CommonPersonalDetailsModel, BaseModel, {
  headerBlock: {
    title: 'Edit applicant details',
    subtitle: ['Applicant', '100% complete'],
    progressBar: {
      progress: 100,
      withContainer: true,
    },
  },
  form: {
    actions: {
      primary: {
        label: 'Save',
        testId: 'save',
      },
      secondary: {
        testId: 'cancel',
        label: 'Cancel',
      },
    },
    withConfirmModal: true,
  },
  info: {
    title: 'Review personal details',
    subtitle: 'Help',
    content: [
      {
        copy: [
          'Does everything look correct? Use this time to correct any mistakes or confirm these details, as accurate information will allow for the smoothest application process possible.',
        ],
      },
    ],
  },
});

export default model;
