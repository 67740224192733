import { get, merge } from 'lodash';
import { useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import TagManager from 'react-gtm-module';

import { PillowForm } from '../../../../../components/PillowForm';
import { useAPI } from '../../../../../hooks/useAPI/useAPI';
import { useCurrentUserContext } from '../../../../../hooks/useCurrentUserContext/useCurrentUserContext';
import { prepareApplicantPayload } from './utils';

const Controller = (props: any) => {
  const [currentUserContext, setCurrentUserContext] = useCurrentUserContext();
  const { presModel, send, context: workflowDetails } = props;
  const { updatedUser = {}, currentVehicle } = workflowDetails || {};

  const api = useAPI();

  const onSubmit = useAsyncCallback(() => {
    const payload = prepareApplicantPayload(updatedUser);
    return api
      .put('/users/current', { ...payload, vehicleId: currentVehicle.id })
      .then((user) => {
        setCurrentUserContext({ ...currentUserContext, ...user });

        /**
         * User does not have a co-applicant and will not be
         * redirected to the review gate because SSN has already
         * been provided. Call endpoint to update the application
         * status appropriately
         */
        const hasCoApplicant =
          get(currentVehicle, 'coApplicant.coApplicantAnswer') === true;
        const applicantHasSSN = get(user, 'personalInfo.hasSSN') === true;

        if (!hasCoApplicant && applicantHasSSN) {
          return api
            .post(`/vehicles/${currentVehicle.id}/application`, {
              hasSSN: true,
            })
            .then(() =>
              TagManager.dataLayer({
                dataLayer: {
                  event: 'user-submitted-no-ssn',
                },
              }),
            )
            .then(() => user);
        }
        return user;
      })
      .then((user) => {
        send('Next', {
          data: {
            currentUser: user,
          },
        });
      })
      .catch((e) => {
        console.error(
          'An unexpected exception occurred while attempting to save an applicant data',
          e,
        );

        throw e;
      });
  });

  const enhancedProps = useMemo(
    () =>
      merge(
        {},
        props,
        { presModel },
        {
          presModel: {
            template: {
              header: {
                onBack: () => {
                  send('Previous');
                },
              },
            },
            form: {
              actions: {
                primary: {
                  handler: onSubmit.execute,
                  isDisabled: onSubmit.loading,
                },
              },
              globalErrors: onSubmit.error,
            },
          },
        },
      ),
    [props, presModel, onSubmit, send],
  );

  return <PillowForm {...enhancedProps} />;
};

Controller.displayName = 'ApplicantEmploymentReview.Controller';
export default Controller;
