import { every, merge, uniqueId } from 'lodash';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  AuthType,
  DeliveryMechanism,
  HttpStatusCode,
  RateSelectedErrors,
} from '../../common';
import { LeadChannel } from '../../common/interfaces/branding/leadChannel';
import { PillowForm } from '../../components/PillowForm';
import PrequalifiedRate from '../../components/PrequalifiedRate/PrequalifiedRate';
import { UserAgreement } from '../../components/shared/UserAgreement/UserAgreement';
import { urlParamsError } from '../../constants/urlParamsError';
import { useAPI } from '../../hooks/useAPI/useAPI';
import { useWorkflowPathname } from '../../hooks/useWorkflowPathname/useWorkflowPathname';
import { SearchParamKey } from '../../services/urlParams/urlParams';
import { urlParamHook } from '../../services/urlParams/urlParamsService';
import { mapErrorsToFormFields } from '../SignIn/Controller';
import model from './Model';
import { DecreaseHeaderFormMargin, LeadChannelLogoContainer } from './styles';

const Controller = () => {
  const navigate = useNavigate();
  const rootPath = useWorkflowPathname();
  const { savedParam: firstName } = urlParamHook(SearchParamKey.firstName);
  const { savedParam: phoneNumber } = urlParamHook(SearchParamKey.phoneNumber);
  const { savedParam: monthlyPaymentAmount } = urlParamHook(
    SearchParamKey.monthlyPaymentAmount,
  );
  const { savedParam: apr } = urlParamHook(SearchParamKey.apr);
  const { savedParam: term } = urlParamHook(SearchParamKey.term);
  const { savedParam: errorMessage } = urlParamHook<RateSelectedErrors>(
    SearchParamKey.error,
  );
  const { savedParam: collectAgreeToTerms } = urlParamHook(
    SearchParamKey.collectAgreeToTerms,
  );
  const { savedParam: coApplicantCollectAgreeToTerms } = urlParamHook(
    SearchParamKey.coApplicantCollectAgreeToTerms,
  );
  const { savedParam: leadChannelCode } = urlParamHook(
    SearchParamKey.leadChannelCode,
  );
  const isDisplayedPrequalified = every([
    firstName,
    monthlyPaymentAmount,
    apr,
    term,
  ]);

  const leadChannelData = useAsync(
    () =>
      api.get<any, LeadChannel>(
        `/ui-configuration/lead-channel/${leadChannelCode}`,
      ),
    [leadChannelCode],
  );

  const isDisplayedApplicantAgreeToTerms = collectAgreeToTerms === 'true';
  const isDisplayedCoApplicantAgreeToTerms =
    coApplicantCollectAgreeToTerms === 'true';
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [isSubmittedWithoutAgreement, setIsSubmittedWithoutAgreement] =
    useState(false);
  const methods = useForm();
  const {
    formState: { isSubmitted },
  } = methods;

  const redirectToAccountSignUp = useCallback(
    () =>
      navigate(`/${rootPath}/sign-up`, {
        replace: true,
        state: { invalidPhoneNumber: undefined },
      }),
    [navigate, rootPath],
  );
  const api = useAPI();

  const isDisplayedAgreeToTerms = useMemo(
    () =>
      isDisplayedApplicantAgreeToTerms || isDisplayedCoApplicantAgreeToTerms,
    [isDisplayedApplicantAgreeToTerms, isDisplayedCoApplicantAgreeToTerms],
  );

  const onSubmit = useAsyncCallback(
    async ({ primaryPhoneNumber }: { primaryPhoneNumber: string }) => {
      if (isDisplayedAgreeToTerms && !agreementChecked) {
        return;
      }
      try {
        await api.post('pin/deliver', {
          purpose: AuthType.ACCOUNT_SIGN_IN_USING_PIN,
          deliverWith: DeliveryMechanism.SMS,
          phone: primaryPhoneNumber,
        });
        navigate(`/${rootPath}/sign-in/verify/pin`, {
          state: { primaryPhoneNumber },
        });
      } catch (e: any) {
        if (
          e.find((error: any) => error.statusCode === HttpStatusCode.NOT_FOUND)
        ) {
          navigate(`/${rootPath}/sign-up/`, {
            state: { invalidPhoneNumber: true },
          });
        }
        throw mapErrorsToFormFields(e);
      }
    },
  );

  useEffect(() => {
    if (isDisplayedAgreeToTerms) {
      if (isSubmitted) {
        setIsSubmittedWithoutAgreement(!agreementChecked);
      }
    }
  }, [
    isSubmitted,
    agreementChecked,
    setIsSubmittedWithoutAgreement,
    isDisplayedAgreeToTerms,
  ]);

  const headerTitle = useMemo(() => {
    if (!leadChannelData?.result) {
      return '';
    }
    return `Welcome ${firstName || ''}, \nfrom ${
      leadChannelData?.result?.displayLabel
    }`;
  }, [firstName, leadChannelData?.result]);

  const headerParagraph = useMemo(() => {
    if (isDisplayedPrequalified) {
      return 'Congrats you’re pre-qualified for the following rate:';
    }
    return 'Please confirm your phone number to continue.';
  }, [isDisplayedPrequalified]);

  const beforeHeaderContent = useMemo(
    () =>
      leadChannelData?.result ? (
        <Fragment>
          <LeadChannelLogoContainer
            src={leadChannelData?.result.primaryLogo?.url}
          />
        </Fragment>
      ) : null,
    [leadChannelData],
  );

  const beforeFormContent = useMemo(
    () => (
      <Fragment>
        <DecreaseHeaderFormMargin key={uniqueId('margin_')} />
        {isDisplayedPrequalified && (
          <PrequalifiedRate
            apr={apr}
            monthlyPaymentAmount={monthlyPaymentAmount}
            term={term}
          />
        )}
      </Fragment>
    ),
    [isDisplayedPrequalified, monthlyPaymentAmount, apr, term],
  );

  const enhancedProps = useMemo(
    () =>
      merge(
        {},
        { presModel: model },
        {
          presModel: {
            headerBlock: {
              title: headerTitle,
              paragraph: headerParagraph,
            },
            form: {
              globalErrors: [urlParamsError[errorMessage]],
              actions: {
                primary: {
                  handler: onSubmit.execute,
                  isDisabled: onSubmit.loading,
                },
                secondary: {
                  handler: redirectToAccountSignUp,
                  isDisabled: onSubmit.loading,
                },
              },
              fields: {
                primaryPhoneNumber: {
                  disabled: onSubmit.loading,
                  value: phoneNumber,
                  ...(onSubmit.loading
                    ? {
                        hint: 'Delivering verification code...',
                      }
                    : {}),
                },
              },
              ...onSubmit.error,
            },
          },
          beforeHeaderContent,
          beforeFormContent,
        },
      ),
    [
      beforeHeaderContent,
      beforeFormContent,
      headerTitle,
      headerParagraph,
      redirectToAccountSignUp,
      onSubmit,
      phoneNumber,
      errorMessage,
    ],
  );

  return (
    <PillowForm {...enhancedProps} methods={methods}>
      {isDisplayedAgreeToTerms && (
        <UserAgreement
          agreementChecked={agreementChecked}
          isSubmittedWithoutAgreement={isSubmittedWithoutAgreement}
          setAgreementChecked={setAgreementChecked}
        />
      )}
    </PillowForm>
  );
};

Controller.displayName = 'Activation.Controller';
export default Controller;
