import './circularProgressFullScreen.css';

import { CircularProgress } from '@mui/material';

export const CircularProgressFullScreen = () => {
  return (
    <div className="fullscreen-container">
      <CircularProgress />
    </div>
  );
};
