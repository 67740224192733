import { Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { SIGN_OUT_EVENT_NAME } from '../../constants/events';
import { REACT_ROOT_NODE_ID } from '../../constants/rootNode';
import { useWorkflowPathname } from '../../hooks/useWorkflowPathname/useWorkflowPathname';

export const SignOutListener = () => {
  const rootPath = useWorkflowPathname();
  const navigate = useNavigate();

  useEffect(() => {
    const handleSignOut = () => {
      // When no rootPath, redirects are handled by Router
      if (rootPath) {
        navigate(`/${rootPath}/sign-out`);
      }
    };

    document
      .getElementById(REACT_ROOT_NODE_ID)
      ?.addEventListener(SIGN_OUT_EVENT_NAME, handleSignOut);

    return () => {
      document
        .getElementById(REACT_ROOT_NODE_ID)
        ?.removeEventListener(SIGN_OUT_EVENT_NAME, handleSignOut);
    };
  }, []);

  return <Fragment />;
};
