import { config } from '../../config';

export enum SearchParamKey {
  amount = 'amount',
  apr = 'apr',
  authType = 'authType',
  autopayNumber = 'autopayNumber',
  applicationUpdateMessage = 'applicationUpdateMessage',
  coApplicantCollectAgreeToTerms = 'coApplicantCollectAgreeToTerms',
  collectAgreeToTerms = 'collectAgreeToTerms',
  error = 'error',
  firstName = 'firstName',
  hasDuplicateApplication = 'hasDuplicateApplication',
  leadChannelCode = 'leadChannelCode',
  migrateUser = 'migrateUser',
  monthlyPaymentAmount = 'monthlyPaymentAmount',
  phoneNumber = 'phoneNumber',
  showProgressModal = 'showProgressModal',
  term = 'term',
  token = 'token',
  vehicleId = 'vehicleId',
  viewedOffers = 'viewedOffers',
}

export const availableSearchParams = Object.values(SearchParamKey);

export interface SearchParamOptionsInterface {
  localStorageKey?: string;
  keepAfterAuth?: boolean;
}

export const searchParamsOptions: {
  [key in SearchParamKey]?: SearchParamOptionsInterface;
} = {
  [SearchParamKey.token]: {
    localStorageKey: config.session.CHECKLIGHT_TOKEN_KEY,
    keepAfterAuth: true,
  },
  [SearchParamKey.autopayNumber]: {
    keepAfterAuth: true,
  },
  [SearchParamKey.leadChannelCode]: {
    keepAfterAuth: true,
  },
  [SearchParamKey.vehicleId]: {
    keepAfterAuth: true,
  },
  [SearchParamKey.viewedOffers]: {
    keepAfterAuth: true,
  },
  [SearchParamKey.hasDuplicateApplication]: {
    keepAfterAuth: true,
  },
  [SearchParamKey.showProgressModal]: {
    keepAfterAuth: true,
  },
  [SearchParamKey.migrateUser]: {
    keepAfterAuth: true,
  },
};
