import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const SvgAnimated = styled('svg', {
  animationDuration: '0.5s',
  animationIterationCount: 'infinite',
  animationTimingFunction: 'linear',
  animationName: {
    to: {
      transform: 'rotate(360deg)',
    },
  },
} as any);

export const Container = styled(
  'div',
  ({ $fullScreen, $fullScreenHeight }: any) => ({
    display: 'flex',
    alignItems: 'center',
    ...($fullScreen
      ? {
          width: '100%',
          height: $fullScreenHeight || '100vh',
          justifyContent: 'center',
        }
      : {}),
  }),
);
