import { set } from 'lodash';
import { assign, MachineConfig } from 'xstate';

import { ChecklightSessionStatus } from '../../../../common';
import { Step } from '../steps';

export const Signal = {
  Next: 'Next',
  ExitToDashboard: 'ExitToDashboard',
};

export const workflowName = 'unified-default-no-offers';
export const defaultConfiguration: MachineConfig<any, any, any> = {
  id: workflowName,
  initial: Step.Pillow,
  states: {
    [Step.Pillow]: {
      on: {
        [Signal.Next]: {
          target: Step.ExitToApplicant,
          actions: assign((context) =>
            set(
              context,
              'currentVehicle.checklightSession.status',
              ChecklightSessionStatus.SELECTED_OFFER,
            ),
          ),
        },
        [Signal.ExitToDashboard]: Step.ExitToDashboard,
      },
    },
    [Step.ExitToApplicant]: {
      type: 'final',
    },
    [Step.ExitToDashboard]: {
      type: 'final',
    },
  },
};

// NOTE: You can paste the content of this file into @see https://xstate.js.org/viz
// and uncomment this line to test the logic manually
// Machine({ ...defaultConfiguration }, { ...defaultServiceOptions });

export default defaultConfiguration;
