import { Fragment, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import LogoDefault from '../../../../assets/logo-default.svg';
import { ApplicationTasks } from '../../../../components/ApplicationTasks/ApplicationTasks';
import { ErrorsBlock } from '../../../../components/shared/ErrorsBlock/ErrorsBlock';
import { Link, LinkType } from '../../../../components/shared/link/link';
import { VehiclePageHeader } from '../../../../components/shared/PageHeader/VehiclePageHeader';
import { config } from '../../../../config';
import { Workflow } from '../../../../constants/Workflow';
import { useWorkflowPathname } from '../../../../hooks/useWorkflowPathname/useWorkflowPathname';
import { getAllApplicationTasks } from '../../../../services/status/statusService';
import { ApplicationDataInterface } from '../../applicationData.interface';
import ApplicationResources from './ApplicationResources';
import { ContentBlock, ContentTitle, ContentTitleContainer } from './styles';

export const ViewApplication = ({
  applicationData,
  availableTasks,
  fetchVehicleError,
  openSummary,
}: {
  applicationData: ApplicationDataInterface;
  availableTasks: any;
  fetchVehicleError: any;
  openSummary: boolean;
}) => {
  const navigate = useNavigate();
  const { currentVehicle } = applicationData || {};

  const autopayNumber = currentVehicle?.checklightSession?.autopayNumber;

  const workflowName = useWorkflowPathname();

  const navigateToAllTasks = useCallback(() => {
    navigate('Tasks', {
      state: applicationData,
    });
  }, [navigate, applicationData]);

  const applicationPageContent = (
    <Fragment>
      <ContentBlock>
        <ContentTitleContainer>
          <ContentTitle>Next task</ContentTitle>
          {availableTasks.length > 1 && (
            <Link type={LinkType.PRIMARY_LARGE} onClick={navigateToAllTasks}>
              See all ({availableTasks.length})
            </Link>
          )}
        </ContentTitleContainer>
        <ApplicationTasks
          availableTasks={availableTasks}
          state={applicationData}
          type="application"
        />
      </ContentBlock>

      {config.debug.gates && workflowName === Workflow.unified ? (
        <ContentBlock>
          <ContentTitle>All tasks (for testing only)</ContentTitle>
          <ApplicationTasks
            availableTasks={getAllApplicationTasks(workflowName)}
            state={applicationData}
            type="application"
            isList
          />
        </ContentBlock>
      ) : null}
      <ApplicationResources
        applicationData={applicationData}
        openSummary={openSummary}
      />
    </Fragment>
  );

  return (
    <Fragment>
      <VehiclePageHeader
        breadCrumbs={autopayNumber ? [autopayNumber] : []}
        image={
          currentVehicle.imageList?.find(
            (image: any) => image.vehicleImageType === 'DEFAULT',
          )?.url
        }
        logo={applicationData?.branding?.logo?.url || LogoDefault}
        navigate={navigate}
        title={`${currentVehicle.year} ${currentVehicle.make} ${currentVehicle.model}`}
      />
      {fetchVehicleError ? (
        <ErrorsBlock
          errorsList={[
            'An unexpected exception occurred while attempting load the users vehicle details',
          ]}
        />
      ) : (
        applicationPageContent
      )}
    </Fragment>
  );
};
