import { Workflow } from '../../../constants/Workflow';

export const getBreadcrumbs = (
  workflowName: Workflow,
  navigate: any,
  applicationData: any,
) => {
  const {
    checklightSession: { autopayNumber },
  } = applicationData?.currentVehicle || {};
  if (workflowName === Workflow.checklight) {
    return ['Applications', autopayNumber];
  }

  return [
    {
      page: 'Vehicle',
      onClick: () => {
        navigate('../..');
      },
    },
    autopayNumber,
  ];
};
