import { isEmpty } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useAPI } from '../../hooks/useAPI/useAPI';
import { useBranding } from '../../hooks/useBranding/useBranding';
import {
  availableSearchParams,
  SearchParamKey,
} from '../../services/urlParams/urlParams';
import { urlParamHook } from '../../services/urlParams/urlParamsService';
import useAnalyticStore from '../../store/analyticStore';

const SetupParamsGuard = ({ children }: { children: JSX.Element }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const api = useAPI();
  const { initializeBranding, isLoading } = useBranding();
  const { sendLandedWithParametersAnalytics } = useAnalyticStore();

  const resetSearchParams = useCallback(
    (param: string) => {
      searchParams.delete(param);
      setSearchParams(searchParams, { replace: true, state: location.state });
    },
    [searchParams, setSearchParams, location.state],
  );

  const urlParamHandler = useCallback(
    (key: any) => {
      const valueFromParams = searchParams.get(key);
      const { saveParam } = urlParamHook(key);
      saveParam(valueFromParams);
      resetSearchParams(key);
    },
    [resetSearchParams, searchParams],
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const entries = Object.fromEntries(searchParams.entries());

    if (isEmpty(entries)) {
      return;
    }

    sendLandedWithParametersAnalytics(
      'LandedWithParameters',
      window.location.href,
      window.location.host,
      window.location.pathname,
      entries,
    );
  }, []);

  useEffect(() => {
    availableSearchParams.forEach(urlParamHandler);
    const { savedParam: leadChannelCode } = urlParamHook(
      SearchParamKey.leadChannelCode,
    );
    initializeBranding(api, leadChannelCode || undefined);
  }, []);

  return isLoading ? null : children;
};

export default SetupParamsGuard;
