import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useAsync } from 'react-async-hook';
import { Outlet, useNavigate } from 'react-router-dom';

import FooterIcon from '../../assets/footer-celebrate-icon.svg?react';
import { UserVehicle } from '../../common/interfaces/vehicle/vehicle.interface';
import { unit } from '../../common/theme';
import { ErrorsBlock } from '../../components/shared/ErrorsBlock/ErrorsBlock';
import { Footer } from '../../components/shared/Footer/Footer';
import { Loader } from '../../components/shared/Loader/Loader';
import { Menu } from '../../components/shared/Menu/Menu';
import { useAPI } from '../../hooks/useAPI/useAPI';
import { useCurrentUserContext } from '../../hooks/useCurrentUserContext/useCurrentUserContext';
import { useNavigationLinks } from '../../hooks/useNavigationLinks/useNavigationLinks';
import { useWorkflowPathname } from '../../hooks/useWorkflowPathname/useWorkflowPathname';
import { DynamicBrandingContext } from '../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { withMatchMedia } from '../../providers/context/MatchMediaProvider/withMatchMedia';
import { SearchParamKey } from '../../services/urlParams/urlParams';
import { urlParamHook } from '../../services/urlParams/urlParamsService';
import { use100vh } from '../../utils/use100vh';
import {
  ErrorsContainer,
  FooterContainer,
  LayoutContainer,
  PageContainer,
  PageContentContainer,
  PageHamburger,
} from './styles';

export const MainLayoutComponent = ({ isDesktop, isMobile }: any) => {
  const api = useAPI();
  const navigate = useNavigate();
  const workflowRootPath = useWorkflowPathname();

  const {
    branding: { theme: $theme },
  } = useContext(DynamicBrandingContext);

  const { savedParam: showProgressModal } = urlParamHook(
    SearchParamKey.showProgressModal,
  );
  const { savedParam: autopayNumber } = urlParamHook(
    SearchParamKey.autopayNumber,
  );

  const [currentUserContext, setCurrentUserContext] = useCurrentUserContext();

  const fetchUserVehicles = async () =>
    api.get<any, UserVehicle[]>('/vehicles').then((data: UserVehicle[]) => {
      setCurrentUserContext({ ...currentUserContext, userVehicles: data });
      return data;
    });

  const updateVehicleImages = async (vehicles: UserVehicle[]) => {
    const isMissingImages = (vehicle: UserVehicle) => !vehicle.imagesLoaded;

    const shouldUpdateImages = vehicles.some((vehicle: UserVehicle) =>
      isMissingImages(vehicle),
    );

    if (shouldUpdateImages) {
      const updateVehicleImages = vehicles.map(async (vehicle: UserVehicle) => {
        if (isMissingImages(vehicle)) {
          await api.put(`/vehicles/${vehicle.id}`, vehicle);
        }
      });
      await Promise.all(updateVehicleImages);

      await fetchUserVehicles();
    }
  };

  const handleVehicleApplicationUpdate = (vehicles: UserVehicle[]) => {
    vehicles.forEach((vehicle: UserVehicle) => {
      const applications = get(vehicle, 'checklightSessions');

      const belongingApplication = applications.find(
        (application) => application.autopayNumber === autopayNumber,
      );

      if (belongingApplication) {
        navigate(`/${workflowRootPath}/dashboard/vehicle/application`, {
          replace: true,
          state: {
            currentVehicle: { id: vehicle.id },
          },
        });
      }
    });
  };

  const userVehicles = useAsync(async () => {
    const fetchedVehicles = await fetchUserVehicles();

    // update missing vehicle images when trimId is available
    await updateVehicleImages(fetchedVehicles);

    // determine whether an application has been updated for a vehicle
    // in which case we should redirect to the vehicle application page
    if (showProgressModal && autopayNumber) {
      handleVehicleApplicationUpdate(fetchedVehicles);
    }
  }, []);

  const navConfig = useNavigationLinks();

  return (
    <LayoutContainer
      $height={use100vh()}
      $isDesktop={isDesktop}
      $isMobile={isMobile}
    >
      <div id="sidebar-root" />
      <PageContainer $isDesktop={isDesktop}>
        <PageHamburger>
          <Menu navConfig={navConfig} sidebarContainerId="sidebar-root" />
        </PageHamburger>

        <PageContentContainer>
          {userVehicles.loading ? (
            <Loader size={unit[6]} fullScreen />
          ) : userVehicles.error ? (
            <ErrorsContainer $isDesktop={isDesktop}>
              <ErrorsBlock
                errorsList={[
                  'An unexpected exception occurred while attempting load the users vehicles',
                ]}
              />
            </ErrorsContainer>
          ) : (
            <Outlet />
          )}
        </PageContentContainer>

        <FooterContainer>
          <Footer isActive={false}>
            <span style={{ marginRight: '10px' }}>
              You’ve reached the bottom
            </span>
            <FooterIcon fill={$theme.colors.primaryActive} />
          </Footer>
        </FooterContainer>
      </PageContainer>
    </LayoutContainer>
  );
};

MainLayoutComponent.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  navigation: PropTypes.shape({
    content: PropTypes.node,
    isGreyBackground: PropTypes.bool,
    disableHamburger: PropTypes.bool,
  }),
  headerContent: PropTypes.node,
  footerContent: PropTypes.node,
  signOut: PropTypes.func,
};

export const MainLayout = withMatchMedia(MainLayoutComponent);
