import { FooterLink, SidebarLink } from './styles';

export interface RouterLinksProps {
  footerLinks: Array<{
    testId: string;
    label: string;
    onClick?: (e: any) => void;
  }>;
  type?: 'SIDEBAR' | 'FOOTER';
}

export const RouterLinks = ({
  footerLinks,
  type = 'FOOTER',
}: RouterLinksProps) => {
  return (
    <>
      {footerLinks.map(({ testId, onClick, label }) => {
        return type === 'SIDEBAR' ? (
          <SidebarLink data-testid={testId} key={testId} onClick={onClick}>
            {label}
          </SidebarLink>
        ) : (
          <FooterLink data-testid={testId} key={testId} onClick={onClick}>
            {label}
          </FooterLink>
        );
      })}
    </>
  );
};
