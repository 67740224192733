import i18n from 'i18next';

import { ResidenceTypeEnum } from '../enums/ResidenceTypeEnum';

export const residenceTypeFormat: Record<ResidenceTypeEnum, string> = {
  [ResidenceTypeEnum.OWN]: i18n.t('residenceType.own'),
  [ResidenceTypeEnum.RENT]: i18n.t('residenceType.rent'),
  [ResidenceTypeEnum.OTHER]: i18n.t('residenceType.other'),
  [ResidenceTypeEnum.UNKNOWN]: i18n.t('residenceType.unknown'),
};
