import { isEmpty } from 'lodash';
import { useCallback } from 'react';

import { withMatchMedia } from '../../../providers/context/MatchMediaProvider/withMatchMedia';
import { Button, ButtonProps } from '../Button/Button';
import { ButtonType } from '../Button/buttonType';
import { sendButtonEvent } from '../Button/helper';
import { ButtonDiv, Container } from './styles';

export interface ButtonGroupProps {
  primaryButton?: ButtonProps & { hidden?: boolean };
  secondaryButton?: ButtonProps & { hidden?: boolean };
  rtl?: boolean;
  isMobile?: boolean;
  isVerticallyAligned?: boolean;
  hasContentAfterButton?: boolean;
  isFormButtons?: boolean;
}

export const ButtonGroupComponent = (props: ButtonGroupProps) => {
  const {
    isMobile,
    isVerticallyAligned,
    primaryButton: {
      children: primaryButtonText,
      onClick: primaryButtonOnClick,
      stylingType: primaryButtonStylingType,
      hidden: primaryButtonHidden,
      order: primaryButtonOrder,
      name: primaryButtonName,
      ...theRestOfThePrimaryProps
    } = {},
    secondaryButton: secondaryButtonProps,
    rtl,
    hasContentAfterButton,
    isFormButtons,
  } = props;
  const {
    children: secondaryButtonText,
    onClick: secondaryButtonOnClick,
    stylingType: secondaryButtonStylingType,
    hidden: secondaryButtonHidden,
    order: secondaryButtonOrder,
    name: secondaryButtonName,
    ...theRestOfTheSecondaryProps
  } = secondaryButtonProps || {};
  // [NOTE] only 2 buttons are currently supported - 1 primary and 1 secondary
  const hasPrimaryButton = !isEmpty(props.primaryButton);
  const hasSecondaryButton = !isEmpty(props.secondaryButton);
  const hasSingleButton =
    (hasPrimaryButton && !hasSecondaryButton) ||
    (!hasPrimaryButton && hasSecondaryButton);

  const onPrimaryClick = useCallback(
    (event: any) => {
      if (primaryButtonOnClick) {
        primaryButtonOnClick(event);
      }
      if (isFormButtons) {
        sendButtonEvent(primaryButtonText || primaryButtonName);
      }
    },
    [primaryButtonOnClick, isFormButtons, primaryButtonText, primaryButtonName],
  );

  const onSecondaryClick = useCallback(() => {
    if (secondaryButtonOnClick) {
      secondaryButtonOnClick();
    }
    if (isFormButtons) {
      sendButtonEvent(secondaryButtonText || secondaryButtonName);
    }
  }, [
    secondaryButtonOnClick,
    isFormButtons,
    secondaryButtonText,
    secondaryButtonName,
  ]);

  const primaryButton =
    hasPrimaryButton && !primaryButtonHidden ? (
      <ButtonDiv $order={primaryButtonOrder}>
        <Button
          {...theRestOfThePrimaryProps}
          isFullWidth={isVerticallyAligned}
          stylingType={primaryButtonStylingType || ButtonType.PRIMARY}
          isGrouped
          onClick={onPrimaryClick}
        >
          {String(primaryButtonText)}
        </Button>
      </ButtonDiv>
    ) : null;

  const secondaryButton =
    hasSecondaryButton && !secondaryButtonHidden ? (
      <ButtonDiv $order={secondaryButtonOrder}>
        <Button
          {...theRestOfTheSecondaryProps}
          isFullWidth={isVerticallyAligned}
          stylingType={secondaryButtonStylingType || ButtonType.GHOST}
          isGrouped
          onClick={onSecondaryClick}
        >
          {String(secondaryButtonText)}
        </Button>
      </ButtonDiv>
    ) : null;

  return (
    <Container
      $hasContentAfterButton={hasContentAfterButton}
      $isMobile={isMobile}
      $isVerticallyAligned={isVerticallyAligned}
      $rtl={rtl}
      $singleButton={hasSingleButton}
    >
      {secondaryButton}
      {primaryButton}
    </Container>
  );
};

export const ButtonGroup = withMatchMedia(ButtonGroupComponent);
