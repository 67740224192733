import { inspect } from '@xstate/inspect';

import { config, lsPrefix } from '../../config';

export const initializeWorkflowInspect = () => {
  if (localStorage?.getItem(`${lsPrefix}:inspect`) && config.debug.workflow) {
    inspect({
      iframe: false,
    });
  }
};
