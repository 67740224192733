import { IncomeFrequencyEnum } from '../enums/IncomeFrequencyEnum';

export const incomeFrequencyToAPI = (
  incomeFrequency: IncomeFrequencyEnum | null,
): string => {
  switch (incomeFrequency) {
    case IncomeFrequencyEnum.ANNUALLY:
      return 'YEARLY';
    case IncomeFrequencyEnum.MONTHLY:
      return 'MONTHLY';
    case IncomeFrequencyEnum.SEMI_MONTHLY:
      return 'TWICE_MONTHLY';
    case IncomeFrequencyEnum.BI_WEEKLY:
      return 'BI_WEEKLY';
    case IncomeFrequencyEnum.WEEKLY:
      return 'WEEKLY';
    default:
      return 'UNKNOWN';
  }
};
