import { get } from 'lodash';

import { FormFiledInterface } from '../components/shared/Form/interfaces/formFields.interface';
import { componentTypes } from '../constants/componentTypes';

const isValidValue = (
  field: FormFiledInterface,
  sourceValue: string,
): boolean => {
  const { component, options } = field;

  if (component === componentTypes.DROPDOWN && options) {
    return options.some(({ value }: any) => sourceValue === value);
  }
  return true;
};

const getFieldValue = (field: FormFiledInterface, source: any) => {
  const { name, value: defaultValue } = field;
  const sourceValue = get(source, name);
  const value = sourceValue ?? defaultValue;
  return isValidValue(field, value) ? value : '';
};

export const populateFields = (fields: any, source: any) =>
  Object.keys(fields).reduce((acc, key) => {
    return {
      ...acc,
      [key]: {
        value: getFieldValue(fields[key], source),
      },
    };
  }, {});
