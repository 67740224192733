import { common, unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const ResourceActionContainer = styled(
  'div',
  ({ $theme, $isMobile }: any) => ({
    display: 'flex',
    flexDirection: $isMobile ? 'column' : 'row',
    width: '100%',
    minHeight: unit[23],
    alignItems: 'center',
    backgroundColor: $theme.colors.primary,
    borderRadius: unit[2],
    color: 'white',
    padding: $isMobile ? `${unit[6]} ${unit[3]}` : unit[3],
    marginBottom: unit[3],
  }),
);

export const ResourceActionTitleContainer = styled(
  'div',
  ({ $isMobile }: any) => ({
    ...(!$isMobile ? { paddingRight: '92px' } : {}),
  }),
);

export const ResourceActionTitle = styled('span', () => ({
  display: 'block',
  fontFamily: common.font.family.Merriweather,
  fontWeight: '400',
  fontSize: unit[2],
  lineHeight: unit[3],
  marginBottom: unit[2],
}));

export const ResourceActionSubtitle = styled('span', ({ $isMobile }: any) => ({
  display: 'block',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: unit[3],
  opacity: '0.6',
  ...($isMobile ? { marginBottom: unit[3] } : {}),
}));

export const ResourceActionButtonContainer = styled(
  'div',
  ({ $isMobile }: any) => ({
    border: '2px solid white',
    borderRadius: '4px',
    ...($isMobile ? { width: '100%' } : { minWidth: unit[26] }),
  }),
);
