import { merge, pick } from 'lodash';

import { ApplicationType } from '../../../../../common';
import { PillowType } from '../../../../../components/shared/PillowImage/pillowTypes';
import { populateFields } from '../../../../../forms/populateFields';
import { fields } from '../../../../../forms/vehicle';
import { Signal } from '../../../../common/constants/signals';
import { DynamicWorkflowConfiguration } from '../../../../common/interfaces/gateStep.interface';
import { AccountState } from '../../common/unified-workflow-context.interface';
import BaseModel from '../common/BaseModel';

const model = (
  send: any,
  { context: { coApplicant, user, editedVehicle } }: AccountState,
  { contentful }: DynamicWorkflowConfiguration,
) => {
  const isPurchaseFlow = user.applicationType === ApplicationType.PURCHASE;
  const initialFields = {
    ...pick(fields, ['trimId', 'mileage']),
    ...(isPurchaseFlow
      ? {
          purchasePrice: fields.purchasePrice,
        }
      : {}),
    ...(contentful?.showEstimatedPayoffAmount && !isPurchaseFlow
      ? { estimatedPayoffAmount: fields.estimatedPayoffAmount }
      : {}),
  };
  const populatedFields = populateFields(initialFields, editedVehicle);
  const progress = coApplicant.coApplicantAnswer ? 72 : 80;

  return merge({}, BaseModel, {
    template: {
      header: {
        showBack: true,
        onBack: () => {
          send(Signal.Previous);
        },
      },
      img: {
        type: PillowType.VEHICLE_IMG,
      },
    },
    headerBlock: {
      title: "Please confirm this vehicle's details.",
      progressBar: {
        progress,
        withContainer: true,
      },
      subtitle: ['Vehicles', `${progress}% complete`],
    },
    form: {
      fields: merge({}, initialFields, populatedFields),
      actions: {
        primary: {
          label: 'Continue',
          testId: 'continue',
        },
      },
    },
    info: {
      title: 'Confirm vehicle',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Please confirm the trim and mileage of your vehicle as that will affect the offers we may be able to provide you at the close of this process.  Please be as accurate as possible when reporting your mileage, but a good estimate will be just fine for now.',
          ],
        },
      ],
    },
  });
};

export default model;
