import { merge } from 'lodash';
import { useCallback, useContext, useMemo } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';

import OfferSelectHeroImg from '../../../../assets/gap-car.png';
import gapIcon from '../../../../assets/GAP-Insurance.svg';
import noGapIcon from '../../../../assets/No-GAP-Insurance.svg';
import { PillowForm } from '../../../../components/PillowForm';
import { Modal } from '../../../../components/shared/Modal/Modal';
import { defaultModalButtons } from '../../../../components/shared/Modal/Modal';
import { useModal } from '../../../../hooks/useModal/useModal';
import { DynamicBrandingContext } from '../../../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { mapModalContent } from '../../../../utils/component/modal';
import {
  OffersInfo,
  OffersSummary,
  OffersTotal,
} from '../../styles/offer-styles';
import { modalContent } from './modal-config';
import { Description, Link, ModalImage, ModalLauncher } from './styles';

const Controller = (props: any) => {
  const { context, send, presModel } = props;
  const { offers = {} } = context;
  const methods = useForm();
  const { isShowing, open } = useModal();
  const {
    branding: { theme: $theme },
  } = useContext(DynamicBrandingContext);

  const {
    selectedOfferWithoutGap,
    selectedOfferWithGap = {},
    selectedGapOptionId = '0',
  } = offers;

  const offerContractModal = useMemo(
    () =>
      isShowing ? (
        <Modal
          buttonGroupPresModel={defaultModalButtons()}
          subtitle="Offers"
          subtitleColor={$theme.colors.primaryActive}
          testId="infoPopup"
          title="Guaranteed asset protection (GAP)"
        >
          <ModalImage src={OfferSelectHeroImg} />
          {mapModalContent(modalContent)}
        </Modal>
      ) : null,
    [isShowing, $theme],
  );

  const selectedGapId = useWatch({
    control: methods.control,
    name: 'gap',
    defaultValue: selectedGapOptionId,
  });

  const offerToDisplay = useMemo(
    () =>
      selectedGapId === '0' ? selectedOfferWithGap : selectedOfferWithoutGap,
    [selectedOfferWithoutGap, selectedOfferWithGap, selectedGapId],
  );

  const gapOptions = useMemo(() => {
    const { amtGapMonthly } = selectedOfferWithGap;
    const gapSubtitle = (
      <span>
        <span key="0">${amtGapMonthly.toFixed()}/month </span>
        <Link key="1">(included in payment)</Link>
      </span>
    );

    return [
      {
        value: '0',
        label: 'Include GAP coverage',
        subtitle: gapSubtitle,
        highlight: 'Recommended',
        amtGapMonthly,
        image: gapIcon,
      },
      {
        label: 'No coverage',
        subtitle: '$0/month',
        value: '1',
        amtGapMonthly: 0,
        image: noGapIcon,
      },
    ];
  }, [selectedOfferWithGap]);

  const selectedGap = useMemo<any>(
    () => gapOptions.find(({ value }: any) => value === selectedGapId) || {},
    [gapOptions, selectedGapId],
  );

  const buttonsAfterContent = useMemo(() => {
    const { amtPayment, term } = offerToDisplay;

    return (
      amtPayment && (
        <OffersSummary>
          <OffersTotal key="1">{`$${amtPayment.toFixed()}/month`}</OffersTotal>
          <OffersInfo key="2">{term} month term</OffersInfo>
        </OffersSummary>
      )
    );
  }, [offerToDisplay]);

  const onSubmit = useCallback(() => {
    const { value }: any = selectedGap;
    send('Next', {
      offers: merge({}, offers, {
        selectedGapOptionId: value,
      }),
    });
  }, [selectedGap, send, offers]);

  const enhancedProps = useMemo(
    () =>
      merge(
        {},
        { presModel },
        {
          presModel: {
            form: {
              actionsContent: buttonsAfterContent,
              actions: {
                primary: {
                  handler: methods.handleSubmit(onSubmit),
                },
              },
              fields: {
                offers: {
                  options: gapOptions,
                  value: selectedGapId,
                },
              },
            },
          },
        },
      ),
    [
      onSubmit,
      presModel,
      buttonsAfterContent,
      methods,
      gapOptions,
      selectedGapId,
    ],
  );

  const beforeFormContent = (
    <div>
      <Description>
        Guaranteed Asset Protection covers the “GAP” if you owe more than your
        car is worth in the event of a total loss or vehicle theft.
      </Description>
      <ModalLauncher onClick={open}>What does this cover?</ModalLauncher>
    </div>
  );

  return (
    <FormProvider {...methods}>
      <PillowForm
        beforeFormContent={beforeFormContent}
        methods={methods}
        {...enhancedProps}
      />
      {offerContractModal}
    </FormProvider>
  );
};

Controller.displayName = 'OffersGap.Controller';
export default Controller;
