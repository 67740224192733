import { borderRadii, shadows } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';
import { PanelTypes } from './types';

const getBorderRadius = (borderRadiusType: any) => {
  switch (borderRadiusType) {
    case PanelTypes.ROUNDED_BURLY:
      return borderRadii.roundedBurly;
    case PanelTypes.ROUNDED_MEDIUM:
      return borderRadii.roundedMedium;
    case PanelTypes.ROUNDED_SLIGHT:
      return borderRadii.roundedSlight;
    default:
      return borderRadii.sharp;
  }
};

const getBorder = (selected: any, selectable: any, $theme: any) => {
  if (selected) {
    return `0.0625rem solid ${$theme.colors.primary}`;
  }
  if (selectable) {
    return `0.0625rem solid ${$theme.colors.secondaryOverlay}`;
  }

  return '';
};

const getBoxShadow = (readOnly: any) => {
  if (readOnly) {
    return shadows.mid;
  }

  return shadows.high;
};

export const Container = styled(
  'div',
  ({ $readOnly, $selected, $selectable, $type, $theme }: any) => ({
    ...getBorderRadius($type),
    ...shadows.mid,
    backgroundColor: $theme.colors.primaryOverlay,
    border: getBorder($selected, $selectable, $theme),
    cursor: $readOnly ? 'default' : 'pointer',
    ':hover': {
      ...getBoxShadow($readOnly),
    },
    transition: '200ms ease-in-out',
    height: '100%',
  }),
);
