import { componentTypes } from '../constants/componentTypes';
import type { FormFieldsModelT } from './formFieldsModelT';

export const fields: FormFieldsModelT = {
  applicantDetails: {
    name: 'applicantDetails',
    component: componentTypes.SUMMARY_LIST,
    cta: 'View',
  },
};
