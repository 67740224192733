import { useMemo } from 'react';

import { PillowForm } from '../../../components/PillowForm';
import {
  DynamicWorkflowConfiguration,
  GateStepInterface,
} from '../interfaces/gateStep.interface';

export const GateView = ({
  currentStep,
  stepConfig,
  machine,
  dynamicWorkflowConfiguration,
}: {
  currentStep: string;
  stepConfig: GateStepInterface<any>;
  machine: any;
  dynamicWorkflowConfiguration: DynamicWorkflowConfiguration;
}) => {
  const [current, send] = machine;

  const presModel = useMemo(() => {
    const model = stepConfig.model;
    return typeof model === 'function'
      ? model(send, current, dynamicWorkflowConfiguration)
      : model;
  }, [current, send, stepConfig, dynamicWorkflowConfiguration]);

  const ViewComponent = stepConfig.controller || PillowForm;

  return (
    <ViewComponent
      context={current.context}
      key={currentStep}
      presModel={presModel}
      send={send}
      stepName={current.value}
    />
  );
};
