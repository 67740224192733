import { merge } from 'lodash';

import { isDocumentOptional } from '../../../../../services/document/documentService';
import { VerificationContext } from '../../XState/Model';
import { BaseModel } from '../BaseModel';

const model = (send: any, { context }: { context: VerificationContext }) => {
  // filter out optional documents as they are not required and should not be counted
  const uploadedTasksAmount = context.uploadedDocuments.filter((doc) =>
    isDocumentOptional(doc),
  ).length;

  const requiredTasksAmount = context.requiredDocuments.filter((doc) =>
    isDocumentOptional(doc),
  ).length;

  const baseModel = BaseModel(context);

  return merge({}, baseModel, {
    template: {
      header: {
        showClose: true,
        showBack: false,
        onClose: () => {
          send('Exit');
        },
      },
      centerContent: true,
      persistImage: true,
    },
    form: {
      actions: {
        primary: {
          testId: 'getStarted',
          label: 'Get started',
          handler: () => {
            send('Next');
          },
        },
        secondary: {
          name: 'dashboard',
          label: 'Dashboard',
          testId: 'dashboard',
          handler: () => {
            send('ExitToDashboard');
          },
        },
      },
    },
    headerBlock: {
      title: 'Upload your required verification documents',
      subtitle: [
        'Verifications',
        `${uploadedTasksAmount} of ${requiredTasksAmount} received`,
      ],
    },
  });
};

export default model;
