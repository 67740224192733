import { theme } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const Container = styled(
  'div',
  ({ $withContainer, $withContainerTop }: any) => ({
    ...($withContainer
      ? {
          marginTop: theme.unit[1],
        }
      : {}),
    ...($withContainerTop
      ? {
          marginTop: theme.unit[1],
        }
      : {}),
  }),
);

export const Baseline = styled('div', ({ $theme }: any) => ({
  position: 'relative',
  height: '.1875rem',
  borderRadius: '.09375rem',
  backgroundColor: $theme.colors.secondaryActive,
  width: '100%',
}));

export const ProgressOverlay = styled('div', ({ $progress, $theme }: any) => ({
  position: 'absolute',
  height: '.1875rem',
  borderRadius: '.09375rem',
  backgroundColor: $theme.colors.primary,
  top: 0,
  width: `${$progress}%`,
}));
