import { LoanTaskEnum } from '../../../constants/loanTasks';
import { CoApplicantContext } from '../../common/interfaces/co-applicant.interface';
import { Gate } from '../../common/interfaces/gate.interface';
import { defaultServiceOptions } from '../../common/utils/co-applicant.utils';
import { Step } from './steps';
import AddAdditionalIncomeController from './Steps/AddAdditionalIncome/Controller';
import AddAdditionalIncomeModel from './Steps/AddAdditionalIncome/Model';
import EditAdditionalIncomeController from './Steps/EditAdditionalIncome/Controller';
import EditAdditionalIncomeModel from './Steps/EditAdditionalIncome/Model';
import EditPrimaryIncomeModel from './Steps/EditPrimaryIncome/Model';
import ExitToVerification from './Steps/ExitToVerification/Controller';
import FinalIncomeReviewModel from './Steps/FinalIncomeReview/Model';
import FinalIncomeReviewAdditionalAddModel from './Steps/FinalIncomeReviewAdditionalAdd/Model';
import FinalIncomeReviewAdditionalEditModel from './Steps/FinalIncomeReviewAdditionalEdit/Model';
import FinalIncomeReviewPrimaryEditModel from './Steps/FinalIncomeReviewPrimaryEdit/Model';
import HousingDetailsModel from './Steps/HousingDetails/Model';
import PersonalDetailsModel from './Steps/PersonalDetails/Model';
import PreviousAddressModel from './Steps/PreviousHousingDetails/Model';
import PrimaryIncomeModel from './Steps/PrimaryIncome/Model';
import PrimaryPreviousIncomeModel from './Steps/PrimaryPreviousIncome/Model';
import RedirectToReview from './Steps/RedirectToReview/Controller';
import ReviewApplicantController from './Steps/ReviewCoApplicant/Controller';
import ReviewCoApplicantModel from './Steps/ReviewCoApplicant/Model';
import ReviewHousingDetailsModel from './Steps/ReviewHousing/Model';
import ReviewIncomeModel from './Steps/ReviewIncome/Model';
import ReviewPersonalDetailsModel from './Steps/ReviewPersonalDetails/Model';
import defaultConfiguration from './XState/Model';

const APPLICANT_GATE_INITIAL: Gate<CoApplicantContext> = {
  name: 'unified-default-co-applicant',
  initial: Step.PersonalDetails,
  showRoutes: true,
  cacheData: false,
  serviceOptions: defaultServiceOptions,
  accessibleForTask: LoanTaskEnum.CoApplicant,
  steps: [
    {
      name: Step.PersonalDetails,
      state: (defaultConfiguration.states as any)[Step.PersonalDetails],
      model: PersonalDetailsModel,
      path: 'personal-details',
    },
    {
      name: Step.HousingDetails,
      state: (defaultConfiguration.states as any)[Step.HousingDetails],
      model: HousingDetailsModel,
      path: 'housing-details',
    },
    {
      name: Step.PreviousAddress,
      state: (defaultConfiguration.states as any)[Step.PreviousAddress],
      model: PreviousAddressModel,
      path: 'previous-address',
    },
    {
      name: Step.PrimaryIncome,
      state: (defaultConfiguration.states as any)[Step.PrimaryIncome],
      model: PrimaryIncomeModel,
      path: 'primary-income',
    },
    {
      name: Step.PrimaryPreviousIncome,
      state: (defaultConfiguration.states as any)[Step.PrimaryPreviousIncome],
      model: PrimaryPreviousIncomeModel,
      path: 'primary-previous-income',
    },
    {
      name: Step.ReviewIncome,
      state: (defaultConfiguration.states as any)[Step.ReviewIncome],
      model: ReviewIncomeModel,
      path: 'review-income',
    },
    {
      name: Step.EditPrimaryIncome,
      state: (defaultConfiguration.states as any)[Step.EditPrimaryIncome],
      model: EditPrimaryIncomeModel,
      path: 'edit-primary-income',
    },
    {
      name: Step.AddAdditionalIncome,
      state: (defaultConfiguration.states as any)[Step.AddAdditionalIncome],
      model: AddAdditionalIncomeModel,
      path: 'add-additional-income',
      controller: AddAdditionalIncomeController,
    },
    {
      name: Step.EditAdditionalIncome,
      state: (defaultConfiguration.states as any)[Step.EditAdditionalIncome],
      model: EditAdditionalIncomeModel,
      path: 'edit-additional-income',
      controller: EditAdditionalIncomeController,
    },
    {
      name: Step.ReviewCoApplicant,
      state: (defaultConfiguration.states as any)[Step.ReviewCoApplicant],
      model: ReviewCoApplicantModel,
      path: 'review-co-applicant',
      controller: ReviewApplicantController,
    },
    {
      name: Step.ReviewPersonalDetails,
      state: (defaultConfiguration.states as any)[Step.ReviewPersonalDetails],
      model: ReviewPersonalDetailsModel,
      path: 'review-personal-details',
    },
    {
      name: Step.ReviewHousingDetails,
      state: (defaultConfiguration.states as any)[Step.ReviewHousingDetails],
      model: ReviewHousingDetailsModel,
      path: 'review-housing-details',
    },
    {
      name: Step.FinalIncomeReview,
      state: (defaultConfiguration.states as any)[Step.FinalIncomeReview],
      model: FinalIncomeReviewModel,
      path: 'final-income-review',
    },
    {
      name: Step.FinalIncomeReviewAdditionalAdd,
      state: (defaultConfiguration.states as any)[
        Step.FinalIncomeReviewAdditionalAdd
      ],
      model: FinalIncomeReviewAdditionalAddModel,
      path: 'final-income-review-additional-add',
      controller: AddAdditionalIncomeController,
    },
    {
      name: Step.FinalIncomeReviewAdditionalEdit,
      state: (defaultConfiguration.states as any)[
        Step.FinalIncomeReviewAdditionalEdit
      ],
      model: FinalIncomeReviewAdditionalEditModel,
      path: 'final-income-review-additional-edit',
      controller: EditAdditionalIncomeController,
    },
    {
      name: Step.FinalIncomeReviewPrimaryEdit,
      state: (defaultConfiguration.states as any)[
        Step.FinalIncomeReviewPrimaryEdit
      ],
      model: FinalIncomeReviewPrimaryEditModel,
      path: 'final-income-review-primary-edit',
    },
    {
      name: Step.RedirectToReview,
      state: (defaultConfiguration.states as any)[Step.RedirectToReview],
      controller: RedirectToReview,
    },
    {
      name: Step.Done,
      state: (defaultConfiguration.states as any)[Step.Done],
      controller: ExitToVerification,
    },
  ],
};

export const CO_APPLICANT_GATE: Gate<CoApplicantContext> = {
  ...APPLICANT_GATE_INITIAL,
  steps: APPLICANT_GATE_INITIAL.steps.map((s) => ({
    ...s,
    ...(s.name === Step.PersonalDetails
      ? {}
      : {
          stepValidators: Boolean,
        }),
  })),
};
