import PropTypes from 'prop-types';

import { BadgeContainer } from './styles';

export const Badge = ({ label }: any) => {
  return <BadgeContainer>{label}</BadgeContainer>;
};

Badge.propTypes = {
  label: PropTypes.string,
};
