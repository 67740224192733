import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';

import { Card } from '../card/Card';
import { Cell, Dashboard, EmptyCell } from './styles';

export const DashboardGrid = ({ items }: any) => {
  return (
    <Dashboard>
      {items.map((item: any) => (
        <Cell data-testid="vehicleCard" key={uniqueId('cell-')}>
          <Card {...item} />
        </Cell>
      ))}
      {/* 2 empty cells with gradient. They will be hidden when wrapped to a next line */}
      <EmptyCell data-testid="vehicleCard" />
      <EmptyCell data-testid="vehicleCard" />
    </Dashboard>
  );
};

DashboardGrid.propTypes = {
  items: PropTypes.any,
};
