import { Baseline, Container, ProgressOverlay } from './styles';

export interface ProgressBarProps {
  progress: number;
  withContainer?: boolean;
  withContainerTop?: boolean;
}

export const ProgressBar = ({
  progress,
  withContainer,
  withContainerTop,
}: ProgressBarProps) => {
  return (
    <Container
      $withContainer={withContainer}
      $withContainerTop={withContainerTop}
    >
      <Baseline>
        <ProgressOverlay $progress={progress} />
      </Baseline>
    </Container>
  );
};
