import { get, merge } from 'lodash';

import { FormFiledInterface } from '../../../../../components/shared/Form/interfaces/formFields.interface';
import { AGREEMENT_STATUSES } from '../../../../../constants/choices/checklight';
import { componentTypes } from '../../../../../constants/componentTypes';
import { fields as coApplicantFields } from '../../../../../forms/co-applicant';
import { FormFieldsModelT } from '../../../../../forms/formFieldsModelT';
import { unifiedOptionsList } from '../../../../../forms/housingTypeOptions';
import { populateFields } from '../../../../../forms/populateFields';
import {
  commonFields,
  displayPrevious,
} from '../../../../../utils/helpers/housing-info';
import { withContext } from '../../../../../utils/workflow/withContext';
import { Signal } from '../../XState/Model';
import BaseModel from '../common/BaseModel';

const currentHousingFields = merge({}, commonFields(true), {
  ownershipStatus: {
    options: unifiedOptionsList,
  },
});

const previousHousingFields = commonFields(false);
const previousHousingSubtitle = {
  subtitle: {
    label: 'Previous address',
    hint: 'Our lenders require two years of residence history. Please share your previous residence with us.',
    name: 'subtitle',
    component: componentTypes.SUBTITLE,
  },
};

const formattedAddressFields: FormFieldsModelT = {
  ...Object.entries<FormFiledInterface>(currentHousingFields).reduce(
    (acc, [key, value]) => {
      return {
        ...acc,
        [key]: {
          ...value,
          shouldUnregister: true,
          dependsOn: {
            fields: ['residences.sameAsApplicant'],
            handler: ({ data, setVisible }: any) => {
              setVisible(
                get(data, 'residences.sameAsApplicant') ===
                  AGREEMENT_STATUSES.NO,
              );
            },
          },
        },
      };
    },
    {},
  ),
};

const formattedPreviousAddressFields: FormFieldsModelT =
  Object.entries<FormFieldsModelT>({
    ...previousHousingSubtitle,
    ...previousHousingFields,
  }).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [`${key}Previous`]: {
        ...value,
        shouldUnregister: true,
        dependsOn: {
          fields: [
            'residences.sameAsApplicant',
            'residences.current.activeStartDate',
          ],
          handler: ({ data, setVisible, setValidation }: any) => {
            const isSameAsApplicant =
              get(data, 'residences.sameAsApplicant') ===
              AGREEMENT_STATUSES.YES;
            const currentStartDate = get(
              data,
              'residences.current.activeStartDate',
            );
            const displayPreviousHousing = displayPrevious(currentStartDate);

            setVisible(!isSameAsApplicant && displayPreviousHousing);

            if (key === 'activeStartDate') {
              setValidation({
                validate: (v: any) =>
                  Date.parse(currentStartDate as string) > Date.parse(v) ||
                  'Date should not be greater then start date of current property',
              });
            }
          },
        },
      },
    };
  }, {});

const model = (send: any) =>
  merge({}, BaseModel, {
    form: {
      actions: {
        primary: {
          label: 'Save',
          testId: 'save',
        },
        secondary: {
          label: 'Cancel',
          testId: 'cancel',
          handler: () => {
            send(Signal.Previous);
          },
        },
      },
      fields: {
        sameAsApplicant: {
          ...coApplicantFields.sameAsApplicant,
          value: AGREEMENT_STATUSES.YES,
        },
        ...formattedAddressFields,
        ...formattedPreviousAddressFields,
      },
      withConfirmModal: true,
    },
    info: {
      title: 'Review co-applicant housing details',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Does everything look correct? Use this time to correct any mistakes or confirm these details, as accurate information will allow for the smoothest application process possible.',
          ],
        },
      ],
    },
    headerBlock: {
      title: 'Edit housing details.',
      subtitle: ['Co-applicant', '100% complete'],
      progressBar: {
        progress: 100,
        withContainer: true,
      },
    },
  });

const populateData =
  (fields: any) =>
  (send: any, { context }: any) => {
    return populateFields(fields, context.coApplicant);
  };

const modelFn = (...args: [any, any]) =>
  withContext({ model: model(args[0]), populateFields: populateData })(...args);

export default modelFn;
