import { common, theme, unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const Container = styled(
  'div',
  ({
    $isError,
    $hasTitle,
    $disableMargin,
    $hasBorder,
    $isErrorBorder,
    $customBorderColor,
    $theme,
  }: any) => ({
    ...theme.fonts.paragraph,
    width: '100%',
    backgroundColor: $theme.colors.tertiaryOverlay,
    ...($hasBorder
      ? {
          border: `1px solid ${
            $isErrorBorder
              ? $theme.colors.error
              : $customBorderColor || $theme.colors.primary
          }`,
        }
      : {}),
    borderRadius: theme.unit[2],
    color: $isError ? $theme.colors.error : $theme.colors.primaryActive,
    padding: $hasTitle ? `${theme.unit[6]} ${theme.unit[3]}` : theme.unit[3],
    marginBottom: $disableMargin ? 0 : theme.unit[6],
  }),
);

export const Title = styled('h5', ({ $theme }: any) => ({
  fontSize: common.font.size.headingLarge,
  fontFamily: common.font.family.Merriweather,
  fontWeight: 400,
  marginBottom: unit[2],
  color: $theme.colors.infoOverlay,
}));
