import { useContext } from 'react';

import { theme } from '../../../common/theme';
import { DynamicBrandingContext } from '../../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { Container, SvgAnimated } from './styles';

export interface LoaderProps {
  color?: string;
  size?: string;
  fullScreen?: boolean;
  fullScreenHeight?: string;
}

export const Loader = ({
  color,
  size = theme.unit[2],
  fullScreen,
  fullScreenHeight,
}: LoaderProps) => {
  const {
    branding: { theme: $theme },
  } = useContext(DynamicBrandingContext);
  const fillColor = color || $theme.colors.primary;

  return (
    <Container $fullScreen={fullScreen} $fullScreenHeight={fullScreenHeight}>
      <SvgAnimated
        height={size}
        viewBox="0 0 25 24"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
          <g transform="translate(-98.0, -2037.0)">
            <rect
              fill="transparent"
              height="56"
              rx="4"
              width="93"
              x="64"
              y="2021"
            />
            <path
              d="M110.5,2037 C117.127417,2037 122.5,2042.37258 122.5,2049 C122.5,2055.62742 117.127417,2061 110.5,2061 C109.76362,2061 109.166667,2060.40305 109.166667,2059.66667 C109.166667,2058.93029 109.76362,2058.33333 110.5,2058.33333 C115.654658,2058.33333 119.833333,2054.15466 119.833333,2049 C119.833333,2043.84534 115.654658,2039.66667 110.5,2039.66667 C105.345342,2039.66667 101.166667,2043.84534 101.166667,2049 C101.166667,2049.73638 100.569713,2050.33333 99.8333333,2050.33333 C99.0969537,2050.33333 98.5,2049.73638 98.5,2049 C98.5,2042.37258 103.872583,2037 110.5,2037 Z"
              fill={fillColor}
              fillRule="nonzero"
            />
          </g>
        </g>
      </SvgAnimated>
    </Container>
  );
};

Loader.propTypes = {};
