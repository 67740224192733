import { componentTypes } from '../../constants/componentTypes';
import { generateDropdownPresentationModel } from './DropdownPresentationModel';

export const generateComponentPropsWithListOptions = (
  options = [],
  {
    componentType: component = componentTypes.DROPDOWN,
    ...fieldConfiguration
  } = {},
) => {
  const componentProps = generateDropdownPresentationModel({
    optionsSource: options,
    ...fieldConfiguration,
  });
  return {
    ...componentProps,
    component,
  };
};
