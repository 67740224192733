import { merge } from 'lodash';

import AddAdditionalIncomeModel from '../AddAdditionalIncome/Model';

const model = merge({}, AddAdditionalIncomeModel, {
  headerBlock: {
    subtitle: ['Applicant', '100% complete'],
    progressBar: {
      progress: 100,
      withContainer: true,
    },
  },
});

export default model;
