import { addIdsToOffers, mapOffers } from './Steps/common/utils/offers';

export const mockOffersList = [
  {
    term: 24,
    options: [
      {
        offerId: '1800180',
        amtFees: 449,
        amtGap: 0,
        amtGapMonthly: 0,
        amtLoan: 19419,
        amtPayment: 880.48,
        downPaymentRequired: 0,
        lenderUniqueCode: 'FIRST_INVESTORS_FINANCIAL_SERVICES',
        pctAPR: 0.0825,
        pctInterestRate: 0.0825,
      },
      {
        offerId: '1800181',
        amtFees: 449,
        amtGap: 795,
        amtGapMonthly: 36.05,
        amtLoan: 20214,
        amtPayment: 916.53,
        downPaymentRequired: 0,
        lenderUniqueCode: 'FIRST_INVESTORS_FINANCIAL_SERVICES',
        pctAPR: 0.0825,
        pctInterestRate: 0.0825,
      },
    ],
  },
  {
    term: 42,
    options: [
      {
        offerId: '1800176',
        amtFees: 449,
        amtGap: 0,
        amtGapMonthly: 0,
        amtLoan: 19419,
        amtPayment: 494.08,
        downPaymentRequired: 0,
        lenderUniqueCode: 'PENFED',
        pctAPR: 0.0375,
        pctInterestRate: 0.0375,
      },
      {
        offerId: '1800177',
        amtFees: 449,
        amtGap: 795,
        amtGapMonthly: 20.23,
        amtLoan: 20214,
        amtPayment: 514.31,
        downPaymentRequired: 0,
        lenderUniqueCode: 'PENFED',
        pctAPR: 0.0375,
        pctInterestRate: 0.0375,
      },
    ],
  },
];
export const mockOffersWithModifiedIds = addIdsToOffers(mockOffersList as any);
export const mockOffersWithoutGap = mapOffers(mockOffersWithModifiedIds, false);
export const mockOffersWithGap = mapOffers(mockOffersWithModifiedIds, true);
