import { merge } from 'lodash';

import CommonGapModel from '../../../../common/Steps/OffersGap/Model';
import { Signal } from '../../XState/Model';
import BaseModel from '../common/BaseModel';

const model = (send: any) => {
  return merge({}, BaseModel, CommonGapModel, {
    template: {
      header: {
        onBack: () => {
          send(Signal.Previous);
        },
      },
    },
    headerBlock: {
      title: 'Consider GAP coverage',
      subtitle: ['Offers', '75% complete'],
      progressBar: {
        progress: 75,
        withContainer: true,
      },
    },
  });
};

export default model;
