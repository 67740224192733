import { map } from 'lodash';

import type {
  DropdownDefaultOption,
  DropdownOption,
  DropdownPresentationModel,
} from './types';

export const mapOptions = (
  optionsSource: any[],
  nameFieldKey: string,
  valueFieldKey: string,
): DropdownOption[] =>
  map(optionsSource, (option) => ({
    ...option,
    name: option[nameFieldKey],
    value: option[valueFieldKey],
    optionValue: (option || {}).optionValue,
    testId: (option || {}).testId,
    image: (option || {}).image,
    checked: (option || {}).checked,
    disabled: false,
  }));

const emptyDefaultOption: DropdownDefaultOption = {
  value: '',
  name: '',
  active: false,
  disabled: false,
};

export const addEmptyDefaultDropdownOption = (options: DropdownOption[]) => [
  emptyDefaultOption,
  ...options,
];

type GenerateDropdownPresentationModel = (args: {
  optionsSource: any[];
  nameFieldKey?: string;
  valueFieldKey?: string;
}) => DropdownPresentationModel;

export const generateDropdownPresentationModel: GenerateDropdownPresentationModel =
  ({ optionsSource = [], nameFieldKey = 'label', valueFieldKey = 'value' }) => {
    const options = mapOptions(optionsSource, nameFieldKey, valueFieldKey);
    return {
      options,
    };
  };
