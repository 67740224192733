import { merge, pick } from 'lodash';

import {
  getAddressFieldsNames,
  previousAddressFields,
} from '../../../../../common/housing-info';
import { populateFields } from '../../../../../forms/populateFields';
import { fields } from '../../../../../forms/user';
import { withContext } from '../../../../../utils/workflow/withContext';
import BaseModel from '../common/BaseModel';

const populateData =
  (fields: any) =>
  (send: any, { context }: any) => {
    return populateFields(fields, context.coApplicant);
  };

const model = (send: any, { context }: any) =>
  merge({}, BaseModel, {
    headerBlock: {
      title: 'Tell us about their previous residence',
      subtitle: ['Co-applicant', '60%'],
      progressBar: {
        progress: 60,
        withContainer: true,
      },
    },
    form: {
      fields: merge(
        {},
        pick(fields, previousAddressFields),
        getAddressFieldsNames(previousAddressFields, false),
        {
          activeStartDate: {
            validationRules: {
              validate: (v: any) => {
                return (
                  Date.parse(
                    context.coApplicant.residences.current.activeStartDate,
                  ) > Date.parse(v) ||
                  'Date should not be greater then move-in date of current property'
                );
              },
            },
          },
        },
      ),
      actions: {
        primary: {
          label: 'Continue',
          testId: 'continue',
        },
      },
    },
    info: {
      title: 'Co-applicant previous residence',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Please add additional housing information in order to satisfy our two-year housing requirement.',
          ],
        },
      ],
    },
  });

const modelFn = (...args: [any, any]) =>
  withContext({ model: model(...args), populateFields: populateData })(...args);

export default modelFn;
