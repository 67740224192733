import { LoanTaskEnum } from '../../../constants/loanTasks';
import { Gate } from '../../common/interfaces/gate.interface';
import { Step } from './steps';
import ExitToVerification from './Steps/ExitToVerification/Controller';
import ReviewController from './Steps/ReviewSsn/Controller';
import ReviewModel from './Steps/ReviewSsn/Model';
import defaultConfiguration, { ReviewContext } from './XState/Model';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const states = defaultConfiguration.states!;

export const REVIEW_GATE: Gate<ReviewContext> = {
  name: 'unified-default-review',
  initial: Step.ReviewSsn,
  showRoutes: true,
  cacheData: false,
  serviceOptions: {},
  accessibleForTask: LoanTaskEnum.Review,
  steps: [
    {
      name: Step.ReviewSsn,
      state: states[Step.ReviewSsn],
      model: ReviewModel,
      controller: ReviewController,
      path: 'review-ssn',
    },
    {
      name: Step.Done,
      state: states[Step.Done],
      controller: ExitToVerification,
    },
  ],
};
