const unexpectedErrMessage = 'There was an unexpected error. Please try again.';

export const mapGlobalErrors = (httpErrors: any, mappingConfig: any = {}) =>
  httpErrors?.reduce(
    (mappedErrors: any, error: any) => [
      ...mappedErrors,
      mappingConfig[error.statusCode] &&
      mappingConfig[error.statusCode][error.errorCode]
        ? mappingConfig[error.statusCode][error.errorCode] ||
          unexpectedErrMessage
        : mappingConfig[error.statusCode] || unexpectedErrMessage,
    ],
    [],
  );
