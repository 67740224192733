import { merge } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useAPI } from '../../hooks/useAPI/useAPI';
import { useCurrentUserContext } from '../../hooks/useCurrentUserContext/useCurrentUserContext';
import defaultUserContext from '../../providers/context/CurrentUserContext/defaultUserContext';
import { SearchParamKey } from '../../services/urlParams/urlParams';
import { urlParamHook } from '../../services/urlParams/urlParamsService';
import SignIn from '../SignIn';

const Controller = (props: any) => {
  const [, setCurrentUserContext] = useCurrentUserContext();
  const api = useAPI();

  const location: any = useLocation();

  const { removeParam: removeToken } = urlParamHook(SearchParamKey.token);

  const {
    savedParam: hasDuplicateApplication,
    removeParam: removeHasDuplicateApplication,
  } = urlParamHook(SearchParamKey.hasDuplicateApplication);

  const { removeParam: removeLeadChannelCode } = urlParamHook(
    SearchParamKey.leadChannelCode,
  );

  useEffect(() => {
    // Ignore errors and try to logout at least on client
    api.delete('/users/current/session').finally(() => {
      if (window) {
        removeToken();
        removeLeadChannelCode();

        if (hasDuplicateApplication === 'false') {
          removeHasDuplicateApplication();
        }
      }
      setCurrentUserContext({ ...defaultUserContext });
    });
  }, []);

  const enhancedProps = useMemo(
    () =>
      merge({}, props, {
        presModel: {
          template: {
            header: {
              // onClose: redirectToClose,
            },
          },
          headerBlock: {
            title: "You've been signed out.",
          },
          form: {
            globalErrors: location.state?.globalErrors,
            fields: {
              primaryPhoneNumber: {
                value: '',
                hint:
                  location.state && location.state.manually
                    ? "You've successfully been logged out of your account."
                    : "You've been logged out due to inactivity.",
              },
            },
          },
        },
      }),
    [props, location],
  );

  return <SignIn {...enhancedProps} />;
};

Controller.displayName = 'SignOut.Controller';
export default Controller;
