import { common } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const LoadingOverlay = styled(
  'div',
  () =>
    ({
      ...common.helpers.flexVerticalCenter,
      cursor: 'default',
      position: 'absolute',
      margin: 'auto',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    } as any),
);

export const ImageContainer = styled('img', ({ $hidden }: any) => ({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  opacity: $hidden ? 0 : 1,
}));
