import { theme, unit } from '../../common/theme';
import { styled } from '../../providers/context/StyletronProvider/withStyletronProvider';

const { rateBorder, rateHeading, rateInfo } = theme.colors;

export const RateWrapper = styled('div', ({ $isMobile }: any) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: $isMobile ? 'column' : 'row',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  border: `1px solid ${rateBorder}`,
  borderRadius: '6px',
  marginBottom: unit[3],
}));

export const RateItem = styled('div', ({ $isMobile }: any) => ({
  ...($isMobile
    ? {
        display: 'inline-flex',
        borderBottom: `1px solid ${rateBorder}`,
      }
    : {
        borderRight: `1px solid ${rateBorder}`,
      }),
  flex: '1',
  padding: '1.2rem',
  ':last-child': {
    borderRight: 'unset',
    borderBottom: 'unset',
  },
}));

export const RateItemTitle = styled('div', ({ $isMobile }: any) => ({
  color: rateHeading,
  fontWeight: 'bold',
  marginBottom: $isMobile ? 'unset' : '5px',
  minWidth: '45%',
}));

export const RateItemValue = styled('div', {
  color: rateInfo,
  fontWeight: 'bolder',
  whiteSpace: 'nowrap',
});
