import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Form } from '../Form/Form';
import { Modal } from '../Modal/Modal';

export const Warning = (props: any) => {
  const { popupInfo, onClose, onSubmit } = props;
  const { name } = popupInfo.field;

  const methods = useForm();

  const formConfig = useMemo(
    () => ({
      fields: {
        [name]: {
          ...popupInfo.field,
          value: popupInfo.value,
          warningValidation: null,
          autoFocus: true,
        },
      },
      actions: {
        primary: {
          isHidden: true,
          handler: onSubmit,
        },
      },
    }),
    [popupInfo, onSubmit, name],
  );

  const buttonConfig = useMemo(
    () => ({
      rtl: true,
      primaryButton: {
        name: 'accept',
        children: 'Accept',
        isDisabled: false,
        testId: `accept${name}`,
        onClick: methods.handleSubmit(onSubmit),
      },
    }),
    [methods, onSubmit, name],
  );

  return (
    <Modal
      buttonGroupPresModel={buttonConfig}
      subtitle={popupInfo.subtitle}
      title={popupInfo.title}
      onClose={onClose}
    >
      <FormProvider {...methods}>
        <Form form={formConfig} methods={methods} />
      </FormProvider>
    </Modal>
  );
};
