import { withStyle } from 'styletron-react';

import { theme } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const Dashboard = styled('div', () => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit,minmax(324px, 1fr))',
  margin: '-0.75rem',
}));

export const Cell = styled('div', () => ({
  margin: '0.75rem',
}));

export const EmptyCell: any = withStyle(Cell, ({ $theme }: any) => ({
  background: `linear-gradient(180deg, ${$theme.colors.tertiaryOverlay} 0%, #FFFFFF 100%);`,
  borderRadius: theme.unit[2],
}));
