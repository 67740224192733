import { Fragment } from 'react';

import { useModal } from '../../../hooks/useModal/useModal';
import { ContactUs } from '../ContactUs/ContactUs';

export const withContactModal = (WrappedComponent: any) => {
  return (props: any) => {
    const { isShowing, open } = useModal();

    const isContactUsLink = (link: any) => link.label === 'Contact us';

    if (!props?.navConfig?.footerItems.some(isContactUsLink)) {
      return <WrappedComponent {...props} />;
    }

    const updatedFooterLinks = props?.navConfig?.footerItems.map(
      (link: any) => {
        if (isContactUsLink(link)) {
          return {
            ...link,
            onClick: () => {
              open();
            },
          };
        }
        return link;
      },
    );

    const updatedProps = {
      ...props,
      navConfig: {
        ...props.navConfig,
        footerItems: updatedFooterLinks,
      },
    };

    return (
      <Fragment>
        <WrappedComponent {...updatedProps} />
        {isShowing ? <ContactUs /> : null}
      </Fragment>
    );
  };
};
