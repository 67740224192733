import { AgnosticIndexRouteObject } from '@sentry/react/types/types';
import { pick } from 'lodash';
import { matchRoutes } from 'react-router-dom';
import { devtools, persist } from 'zustand/middleware';

import { routes } from '../routes/ChecklightRouter';
import useParameterStore from './parameterStore';
import { create, storeDevToolOptions } from './storeManager';

export enum NonAuthRedirectPath {
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
}

export const DEFAULT_AUTH_PATH = '/dashboard/garage';

type RouteStateComputed = {
  authenticatedRoutes: AgnosticIndexRouteObject[];
  nonAuthRedirectPath: string;
  signUpRedirectPath: string;
};

type RouteState = {
  authRedirectPath: string;
  isOnNewUIRoute: boolean;
  computed: RouteStateComputed;

  isAuthenticatedRoute: (pathname: string) => boolean;
  setAuthRedirectPath: (authPath: string) => void;
};

const keysToPersist = ['authRedirectPath'];

const useRouteStore = create()(
  devtools(
    persist<RouteState>(
      (set, get) => ({
        authRedirectPath: DEFAULT_AUTH_PATH,
        isOnNewUIRoute: true,

        computed: {
          get authenticatedRoutes() {
            const authenticatedRoutes: AgnosticIndexRouteObject[] = routes
              // filter out the wildcard route that matches all paths.
              // this route is used to redirect the user when attempting to go to a non-existent route.
              .filter((route) => route.path !== '*')

              // filter out non-authenticated routes
              .map((route) => ({
                ...route,
                children: route.children?.filter(
                  (childRoute) => !childRoute.isPublicRoute,
                ),
              }));

            return authenticatedRoutes;
          },
          get nonAuthRedirectPath() {
            const { intent } = useParameterStore.getState();

            if (intent && Object.keys(NonAuthRedirectPath).includes(intent)) {
              return NonAuthRedirectPath[intent];
            }

            return NonAuthRedirectPath.SIGN_IN;
          },
          get signUpRedirectPath() {
            const { authRedirectPath } = get();

            if (authRedirectPath === DEFAULT_AUTH_PATH) {
              return '/apply';
            }

            return authRedirectPath;
          },
        },

        isAuthenticatedRoute: (pathname: string) => {
          const { authenticatedRoutes } = get().computed;

          const routeExists = matchRoutes(authenticatedRoutes, pathname);

          return Boolean(routeExists);
        },
        setAuthRedirectPath: (pathname: string) => {
          const { isAuthenticatedRoute } = get();

          set({
            authRedirectPath: isAuthenticatedRoute(pathname)
              ? pathname
              : DEFAULT_AUTH_PATH,
          });
        },
      }),
      {
        name: 'consumer_portal:route',
        partialize: (state: RouteState) =>
          pick(state, keysToPersist) as RouteState,
      },
    ),
    storeDevToolOptions('routeStore'),
  ),
);

export default useRouteStore;
