import { uniqueId } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { NavigationItem } from './NavigationItem';
import { MainContainer } from './styles';

export const SidebarNavigation = ({ navigationItems }: any) => {
  return (
    <div>
      <MainContainer>
        {navigationItems &&
          navigationItems.map((item: any, i: any) => (
            <React.Fragment key={uniqueId('navigation-item-')}>
              <NavigationItem
                isLast={i === navigationItems.length - 1}
                {...item}
              />
            </React.Fragment>
          ))}
      </MainContainer>
    </div>
  );
};

SidebarNavigation.propTypes = {
  navigationItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      notificationCount: PropTypes.number,
      route: PropTypes.string,
    }),
  ).isRequired,
};
