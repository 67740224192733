import { theme, unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const Container = styled('div', () => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingTop: theme.unit[4],
}));

export const Back = styled('div', ({ $theme }: any) => ({
  color: $theme.colors.infoOverlay,
  cursor: 'pointer',
}));

export const Close = styled('div', ({ $theme }: any) => ({
  color: $theme.colors.infoOverlay,
  marginLeft: 'auto',
  cursor: 'pointer',
}));

export const IconContainer = styled('div', ({ $theme }: any) => ({
  color: $theme.colors.infoOverlay,
  marginLeft: 'auto',
  cursor: 'pointer',
}));

export const LeftContent = styled('div', {
  display: 'flex',
  gap: unit[2],
});

export const MenuWrapper = styled('div', {
  position: 'relative',
}) as any;

export const MenuIconContainer = styled(
  'div',
  ({ $isDesktop, $collapsedView }: any) => ({
    cursor: 'pointer',
    ...($isDesktop && !$collapsedView
      ? {
          display: 'none',
        }
      : {}),
  }),
);

export const MenuContainer = styled(
  'div',
  ({ $isDesktop, $isTablet, $isOpened, $collapsedView }: any) => ({
    position: 'fixed',
    left: '0',
    top: '0',
    padding: `${unit[4]} ${unit[3]}`,
    backgroundColor: 'black',
    zIndex: theme.zIndexMap.sidebar,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...($isDesktop
      ? {
          maxWidth: '20rem',
          ...($collapsedView
            ? {
                position: 'absolute',
                gap: unit[5],
                top: '100%',
              }
            : {
                height: '100%',
                width: '20rem',
              }),
          ...(!$isOpened
            ? {
                display: 'none',
              }
            : {}),
        }
      : {
          height: '100%',
          transition: '0.2s linear',
          width: $isTablet ? '50%' : '100%',
          transform: `translateX(${$isOpened ? '0' : '-100%'})`,
        }),
  }),
);

export const NavHeader = styled(
  'div',
  ({ $isDesktop, $collapsedView }: any) => ({
    display: $isDesktop && $collapsedView ? 'none' : 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.unit[4],
  }),
);

export const NavIconContainer = styled('div', ({ $isDesktop }: any) => ({
  cursor: 'pointer',
  ...($isDesktop
    ? {
        display: 'none',
      }
    : {}),
}));

export const SidebarFooterContainer = styled('div', {
  width: '100%',
  margin: theme.unit[3],
});

export const LogoContainer = styled(
  'div',
  ({ $isMobile, $isTablet, $isChecklight }: any) => ({
    width: '100%',
    ...($isChecklight
      ? {}
      : {
          maxWidth: $isMobile || $isTablet ? '30%' : '55%',
        }),
  }),
);
