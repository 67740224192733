import { ChecklightSessionStatus } from '../../common';

export const activeApplicationSteps = [
  {
    title: 'Complete application',
    description:
      'Finalize any missing information about yourself and your financial situation so we can process your application.',
    statuses: [
      ChecklightSessionStatus.HAS_OFFERS,
      ChecklightSessionStatus.SELECTED_OFFER,
      ChecklightSessionStatus.APPLICANT_COMPLETE,
      ChecklightSessionStatus.CO_APPLICANT_COMPLETE,
    ],
  },
  {
    title: 'Submitted to lender',
    description:
      'The lender is reviewing your application and should be decisioned shortly. Submit documents to verify the information you provided is accurate.',
    statuses: [ChecklightSessionStatus.SUBMITTED_TO_LENDER],
  },
  {
    title: 'Approved by lender',
    description:
      'Your application is approved by the lender. Complete any and all required tasks to help us progress your application.',
    statuses: [ChecklightSessionStatus.APPROVED_BY_LENDER],
  },
  {
    title: 'Preparing contracts',
    description:
      'Our processors are preparing your contracts. Once ready, we will need your signature to lock in your new refinance terms with the lender.',
    statuses: [ChecklightSessionStatus.PREPARING_CONTRACTS],
  },
  {
    title: 'Sign contracts',
    description:
      'Your contracts are ready for your signature. Please review and sign the final paperwork required for your new loan.',
    statuses: [ChecklightSessionStatus.SIGN_CONTRACTS],
  },
  {
    title: 'Reviewing documents',
    description:
      'Our processors are reviewing your verifications. We may request follow up items before submitting to the lender.',
    statuses: [ChecklightSessionStatus.REVIEWING_DOCUMENTS],
  },
  {
    title: 'Finalize verifications',
    description:
      'We need some additional verifications to meet the requirements of the lender. Provide any additional verifications to finalize your documents.',
    statuses: [ChecklightSessionStatus.FINALIZE_STIPS],
  },
  {
    title: 'Waiting on funding',
    description:
      'We have sent your loan package to the lender for funding. The lender should come back with a decision shortly.',
    statuses: [ChecklightSessionStatus.WAITING_ON_FUNDING],
  },
  {
    title: 'Lender funded',
    description:
      'The lender has approved funding for your refinance application. We are working with your existing lender to transfer the payoff balance.',
    statuses: [ChecklightSessionStatus.FUNDED],
  },
];

export const cancelledApplicationSteps = [
  {
    title: 'Application is cancelled',
  },
];
