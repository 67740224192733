import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useContext, useMemo, useState } from 'react';
import { useAsync } from 'react-async-hook';

import { useAPI } from '../../hooks/useAPI/useAPI';
import { DynamicBrandingContext } from '../../providers/context/DynamicBrandingProvider/DynamicBrandingContext';
import { Modal } from '../shared/Modal/Modal';
import { PanelList } from '../shared/PanelList/PanelList';
import { TabSelect } from '../shared/tabSelect/TabSelect';
import { buttonGroup, valuesConfig } from './config';
import { TabsContainer } from './styles';

const VehicleValue = ({ vehicle }: any) => {
  const api = useAPI();
  const [tab, triggerTab] = useState(true);
  const {
    branding: { theme: $theme },
  } = useContext(DynamicBrandingContext);

  const { make, model } = vehicle;

  const tabList = useMemo(
    () => [
      { label: 'Trade-in', value: true, isSelected: tab },
      { label: 'Private-party', value: false, isSelected: !tab },
    ],
    [tab],
  );

  const vehicleValue = useAsync(
    (vehicleId: string): Promise<any> =>
      api.get(`/vehicles/${vehicleId}/value`),
    [vehicle.id],
  );

  const tradeInValues = useMemo(() => {
    return valuesConfig.map((config) => {
      return {
        ...config,
        value: get(vehicleValue.result, `tradeIn.${config.key}`),
      };
    });
  }, [vehicleValue.result]);

  const privatePartyValues = useMemo(() => {
    return valuesConfig.map((config) => {
      return {
        ...config,
        value: get(vehicleValue.result, `private.${config.key}`),
      };
    });
  }, [vehicleValue.result]);

  return (
    <Modal
      buttonGroupPresModel={buttonGroup}
      isLoading={vehicleValue.loading}
      subtitle={`${make} ${model}`}
      subtitleColor={$theme.colors.primaryActive}
      title="Vehicle value"
    >
      <TabsContainer>
        <TabSelect tabs={tabList} onChange={(t: any) => triggerTab(t)} />
      </TabsContainer>

      {tab ? (
        <PanelList
          defaultValue="Not available"
          panelList={tradeInValues}
          isBorderWrapped
        />
      ) : (
        <PanelList
          defaultValue="Not available"
          panelList={privatePartyValues}
          isBorderWrapped
        />
      )}
    </Modal>
  );
};

VehicleValue.propTypes = {
  vehicle: PropTypes.shape({
    make: PropTypes.string,
    model: PropTypes.string,
  }),
};

export default VehicleValue;
