import { unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const FormContainer = styled('div', () => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
}));

export const FormElementWrapper = styled('div', () => ({
  marginBottom: unit[2],
}));

export const ButtonGroupContainer = styled(
  'div',
  ({ $isSticky, $isDesktop, $isMobile, $theme }: any) => ({
    ...($isSticky
      ? {
          display: 'grid',
          gridTemplateColumns: 'min-content auto',
          gridGap: unit[3],
          position: 'fixed',
          bottom: 0,
          right: 0,
          background: '#fff',
          border: `1px solid ${$theme.colors.secondaryOverlay}`,
          borderBottom: 'none',
          borderRight: 'none',
          height: unit[16],
          paddingTop: unit[3],
          paddingLeft: unit[14],
          ...($isDesktop
            ? {
                width: '50%',
              }
            : $isMobile
            ? {
                width: '100%',
                paddingLeft: unit[3],
                gridTemplateColumns: '50% 50%',
              }
            : {
                width: '100%',
                paddingLeft: unit[6],
              }),
        }
      : {}),
  }),
);
