import { Navigate } from 'react-router-dom';

import { useWorkflowPathname } from '../../../../../hooks/useWorkflowPathname/useWorkflowPathname';

const ExitToSignIn = () => {
  const rootPath = useWorkflowPathname();

  return <Navigate to={`/${rootPath}/sign-in`} />;
};

export default ExitToSignIn;
