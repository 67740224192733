import { merge, pick } from 'lodash';

import {
  addressFields,
  getAddressFieldName,
} from '../../../../../common/housing-info';
import { AGREEMENT_STATUSES } from '../../../../../constants/choices/checklight';
import { componentTypes } from '../../../../../constants/componentTypes';
import { fields as coApplicantFields } from '../../../../../forms/co-applicant';
import { FormFieldsModelT } from '../../../../../forms/formFieldsModelT';
import { populateFields } from '../../../../../forms/populateFields';
import { fields } from '../../../../../forms/user';
import { Signal } from '../../../../common/constants/signals';
import { AccountState } from '../../common/unified-workflow-context.interface';
import BaseModel from '../common/BaseModel';

const personalDetailsRawFields: FormFieldsModelT = {
  ...fields,
  ...coApplicantFields,
  sameAsApplicant: {
    ...coApplicantFields.sameAsApplicant,
    name: 'sameAsApplicant',
    value: AGREEMENT_STATUSES.YES,
  },
};

const personalDetailsFields = pick(personalDetailsRawFields, [
  'coApplicantRelationship',
  'firstName',
  'lastName',
  'primaryEmailAddress',
  'primaryPhoneNumber',
  'dob',
  'grossIncome',
]);

const formattedAddressFields: FormFieldsModelT = addressFields
  .concat(['monthlyPaymentAmount'])
  .reduce(
    (acc, field) => ({
      ...acc,
      [field]: {
        ...personalDetailsRawFields[field],
        name: getAddressFieldName(field, true),
        shouldUnregister: true,
        dependsOn: {
          fields: ['sameAsApplicant'],
          handler: ({ data, setVisible }: any) => {
            setVisible(data.sameAsApplicant === AGREEMENT_STATUSES.NO);
          },
        },
      },
    }),
    {},
  );

const model = (send: any, { context }: AccountState) => {
  const accountFields = {
    ...personalDetailsFields,
    subtitle: {
      label: 'Help us with Co-applicant housing details',
      name: 'subtitle',
      component: componentTypes.SUBTITLE,
    },
    sameAsApplicant: personalDetailsRawFields.sameAsApplicant,
    ...formattedAddressFields,
  };
  const savedFields = populateFields(accountFields, context.coApplicant);
  const populatedFields = merge({}, accountFields, savedFields);
  return merge({}, BaseModel, {
    template: {
      header: {
        showBack: true,
        onBack: () => {
          send(Signal.Previous);
        },
      },
    },
    headerBlock: {
      progressBar: {
        progress: 45,
        withContainer: true,
      },
      title: 'Please fill in Co-applicant personal details',
      subtitle: ['Account', '45% complete'],
    },
    form: {
      fields: populatedFields,
      actions: {
        primary: {
          testId: 'continue',
          label: 'Continue',
          handler: (data: any) => {
            send(Signal.Next, {
              data: {
                coApplicant: {
                  ...(context.coApplicant || {}),
                  ...data,
                },
              },
            });
          },
        },
      },
      autoFocus: true,
    },
    info: {
      title: 'Co-applicant details',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Providing us with correct name, age, and contact information will ensure the entire loan process is conducted as smoothly as possible.',
          ],
        },
      ],
    },
  });
};

export default model;
