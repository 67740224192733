import { font, unit } from '../../../../common/theme';
import { styled } from '../../../../providers/context/StyletronProvider/withStyletronProvider';

export const FormMarginFix = styled('div', {
  marginTop: `-${unit[4]}`,
});

export const InfoBlockContainer = styled('div', {
  marginTop: unit[6],
});

export const InfoText = styled('span', {
  fontSize: font.size.headingSmall,
});

export const PanelMargin = styled('div', {
  marginBottom: unit[9],
});

export const PanelTitle = styled('div', {
  fontFamily: font.family.Merriweather,
  fontSize: font.size.title20px,
  marginBottom: unit[6],
});
