/* eslint-disable react-refresh/only-export-components */
import { GateForm, GateFormProps, mapModalContent } from './Form/GateForm';
import { FooterLink } from './Form/gatePresModel.interface';
import { withConfirmModal } from './Form/withConfirmModal';

export { FooterLink, mapModalContent };

const FormWithConfirm = withConfirmModal(GateForm);

export const PillowForm = (props: GateFormProps) => {
  if (props.presModel.form.withConfirmModal) {
    return <FormWithConfirm {...props} />;
  }

  return <GateForm {...props} />;
};
