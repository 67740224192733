import { map } from 'lodash';

import { Accordion } from '../../components/shared/Accordion/Accordion';
import { CopyBlock } from '../../components/shared/CopyBlock/CopyBlock';
import { ModalContentTitle } from '../../components/shared/Modal/styles';

export interface ModalContent {
  asList?: boolean;
  accordion?: boolean;
  title?: string;
  copy: string[];
}

export const mapModalContent = (content: ModalContent[]) => {
  return map(content, (con: ModalContent, index) => {
    const CopyComponent = (
      <div key={index}>
        {con.title && (
          <ModalContentTitle key={'content-title' + index}>
            {con.title}
          </ModalContentTitle>
        )}
        <CopyBlock
          asList={!!con.asList}
          blockIndex={index}
          copy={con.copy}
          identifier="modalText"
        />
      </div>
    );
    return con.accordion ? (
      <Accordion key={index} title={con.title}>
        {CopyComponent}
      </Accordion>
    ) : (
      CopyComponent
    );
  });
};
