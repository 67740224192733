import { Branding } from '../../../common/interfaces/branding/branding';
import { theme } from '../../../common/theme';

export const defaultChecklightBranding: Omit<
  Branding,
  'navigation' | 'favicon' | 'logo'
> = {
  brandName: 'The Savings Group',
  availableHours: 'Mon - Fri 7:00am to 7:00pm MT, Sat 9:00am to 5:00pm MT',
  displayLabel: 'The Savings Group',
  emailAddress: 'save@thesavingsgroup.com',
  phoneNumber: '844-276-3272',
  applicationTitle: 'Find the Right Auto Loan For You',
  emailLogo: {
    description: '',
    title: 'AP-RG-TL',
    url: 'https://s3.amazonaws.com/alex-autopay/autopay-gray+(1).png',
  },
  widgetUrl: 'https://www.autopay.com',
  theme: { colors: theme.colors },
};
