import { theme } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

const { fonts, unit, colors } = theme;

export const AgreementLabel = styled('span', ({ $isError }: any) => ({
  ...fonts.headingMedium,
  color: $isError ? colors.error : colors.infoOverlay,
  marginLeft: unit[3],
}));

export const CheckboxContainer = styled('div', {
  display: 'flex',
  marginBottom: unit[3],
});
