import { OffersContext } from '../../common/interfaces/offers.interface';
import { mockOffersList } from './mock';
import { addIdsToOffers, mapOffers } from './Steps/common/utils/offers';

export const getOffers = async (context: OffersContext, api: any) => {
  try {
    const { mileage, id } = context.currentVehicle;
    const useMock = false;
    const getOffersPromise = useMock
      ? () =>
          new Promise((resolve) => {
            setTimeout(() => {
              if (context.downPayment === 1) {
                return resolve([]);
              }
              resolve(mockOffersList);
            }, 2000);
          })
      : () =>
          api.get(`/vehicles/${id}/application/offers`, {
            params: { downPayment: context.downPayment || '0', mileage },
          });

    const offersList = await getOffersPromise();

    const offersWithModifiedIds = addIdsToOffers(offersList);
    const offersWithoutGap = mapOffers(offersWithModifiedIds, false);
    const offersWithGap = mapOffers(offersWithModifiedIds, true);

    return {
      offersWithoutGap,
      offersWithGap,
    };
  } catch (e) {
    console.error(e);
    throw e;
  }
};
