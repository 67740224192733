import { lsPrefix } from '../../config';
import { getSourceApplicationUrl } from '../../utils/helpers/sourceApplicationUrl';
import { SearchParamConfig } from './searchParamsConfig';
import {
  availableSearchParams,
  SearchParamKey,
  searchParamsOptions,
} from './urlParams';

export const urlParamsConfig: {
  [key in SearchParamKey]?: SearchParamConfig;
} = availableSearchParams.reduce(
  (acc, param) => ({
    ...acc,
    [param]: new SearchParamConfig(searchParamsOptions[param]),
  }),
  {},
);

export const urlParamHook = <T = string | null>(
  param: SearchParamKey,
): {
  savedParam: T;
  saveParam: (name: string | null) => void;
  removeParam: () => void;
} => {
  const lsKey = urlParamsConfig[param]?.localStorageKey;
  const CONFIG_LS_KEY = lsKey || `${lsPrefix}:${param}`;
  const savedParam = localStorage.getItem(CONFIG_LS_KEY) as T;

  const saveParam = (name: string | null) => {
    if (name) {
      localStorage.setItem(CONFIG_LS_KEY, name);
    }
  };

  const removeParam = () => localStorage.removeItem(CONFIG_LS_KEY);

  return {
    savedParam,
    saveParam,
    removeParam,
  };
};

export const getActiveParams = (): Record<string, string> => {
  return Object.values(SearchParamKey).reduce((acc, searchParamKey) => {
    const param = localStorage.getItem(`${lsPrefix}:${searchParamKey}`);
    return {
      ...acc,
      ...(param
        ? {
            [searchParamKey]: param,
          }
        : {}),
    };
  }, {});
};

export const getOriginalUrl = () => {
  const sourceUrl = new URL(getSourceApplicationUrl());
  const activeParams = getActiveParams();

  Object.entries(activeParams).forEach(([key, value]) => {
    sourceUrl.searchParams.append(key, value);
  });

  return sourceUrl.toString();
};
