import { merge, pick } from 'lodash';

import { fields as coApplicantFields } from '../../../../../forms/co-applicant';
import { FormFieldsModelT } from '../../../../../forms/formFieldsModelT';
import { populateFields } from '../../../../../forms/populateFields';
import { fields } from '../../../../../forms/user';
import { Signal } from '../../../../common/constants/signals';
import { AccountState } from '../../common/unified-workflow-context.interface';
import BaseModel from '../common/BaseModel';

const personalDetailsRawFields: FormFieldsModelT = {
  ...fields,
  ...coApplicantFields,
};

const personalDetailsFields = pick(personalDetailsRawFields, [
  'coApplicantRelationship',
  'firstName',
  'lastName',
  'primaryEmailAddress',
  'primaryPhoneNumber',
  'dob',
  'grossIncome',
]);

const model = (send: any, { context }: AccountState) => {
  const savedFields = populateFields(
    personalDetailsFields,
    context.coApplicant,
  );
  const populatedFields = merge({}, personalDetailsFields, savedFields);
  return merge({}, BaseModel, {
    template: {
      header: {
        showBack: true,
        onBack: () => {
          send(Signal.Previous);
        },
      },
    },
    headerBlock: {
      progressBar: {
        progress: 90,
        withContainer: true,
      },
      title: 'Edit Co-applicant personal details',
      subtitle: ['Account', '90% complete'],
    },
    form: {
      fields: populatedFields,
      actions: {
        primary: {
          testId: 'continue',
          label: 'Continue',
          handler: (data: any) =>
            send(Signal.Next, {
              data: { coApplicant: merge({}, context.coApplicant || {}, data) },
            }),
        },
        secondary: {
          testId: 'cancel',
          label: 'Cancel',
          handler: () => {
            send(Signal.Previous);
          },
        },
      },
      autoFocus: true,
      withConfirmModal: true,
    },
    info: {
      title: 'Review co-applicant details',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Does everything look correct? Use this time to correct any mistakes or confirm these details, as accurate information will allow for the smoothest application process possible.',
          ],
        },
      ],
    },
  });
};

export default model;
