import hoistStatics from 'hoist-non-react-statics';
import { PureComponent } from 'react';

import {
  defaultContextValue,
  DynamicBrandingContext,
  DynamicBrandingContextProps,
} from './DynamicBrandingContext';

export const withDynamicBrandingProvider = (WrappedComponent: any) => {
  const DynamicBrandingContextProvider = class extends PureComponent<
    any,
    DynamicBrandingContextProps
  > {
    constructor(props: any) {
      super(props);
      const changeBrandingState = (updatedState: any) => {
        this.setState((previousState) => ({
          ...previousState,
          ...updatedState,
        }));
      };
      this.state = {
        ...defaultContextValue,
        changeBrandingState,
      };
    }

    render() {
      return (
        <DynamicBrandingContext.Provider value={this.state}>
          <WrappedComponent {...this.props} />
        </DynamicBrandingContext.Provider>
      );
    }
  };
  return hoistStatics(DynamicBrandingContextProvider, WrappedComponent);
};

withDynamicBrandingProvider.contextType = DynamicBrandingContext;
