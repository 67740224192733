import { Fragment } from 'react';

import { unit } from '../../../common/theme';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import { BreadcrumbsProps } from '../Breadcrumbs/Breadcrumbs';
import { Icon } from '../Icon/Icon';
import { IconTypes } from '../Icon/iconTypes';
import { RotationDirections } from '../Icon/rotationDirections';
import { MainLayoutHeaderTitle } from '../MainLayoutStyles/styles';
import { EditGoBack, HeaderContainer } from './styles';

export const EditPageHeader = ({
  handleGoBack,
  breadCrumbs,
  title,
}: {
  handleGoBack: () => void;
  breadCrumbs: BreadcrumbsProps[];
  title: string;
}) => (
  <Fragment>
    <EditGoBack>
      <Icon
        cursor="pointer"
        height={unit[3]}
        name={IconTypes.ARROW}
        rotate={RotationDirections.LEFT}
        onClick={handleGoBack}
      />
    </EditGoBack>
    <HeaderContainer>
      <Breadcrumbs pagesList={breadCrumbs} />
      <MainLayoutHeaderTitle style={{ marginTop: unit[1] }}>
        {title}
      </MainLayoutHeaderTitle>
    </HeaderContainer>
  </Fragment>
);
