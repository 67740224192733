import { isEmpty } from 'lodash';

import { HttpStatusCode } from '../../../common';
import { AccountContext } from './common/unified-workflow-context.interface';

export class ConflictError extends Error {
  private internalParams: any;

  constructor(message: any, data: any) {
    super(message);
    this.internalParams = data;
  }

  get params() {
    return this.internalParams;
  }
}

export const verifyExistingUser = async (
  ctx: Partial<AccountContext>,
  api: any,
) => {
  const { primaryPhoneNumber, primaryEmailAddress } = ctx.user || {};
  try {
    await api.post('/users/verify-existing', {
      primaryPhoneNumber,
      primaryEmailAddress,
    });
  } catch (e: any) {
    const conflictErrorParams = e
      ?.filter((e: any) => e.statusCode === HttpStatusCode.CONFLICT)
      .reduce((acc: any, e: any) => {
        if (e?.message?.includes('phone')) {
          return {
            ...acc,
            phone: primaryPhoneNumber,
          };
        }
        if (e?.message?.includes('email')) {
          return {
            ...acc,
            email: primaryEmailAddress,
          };
        }
        return acc;
      }, {});

    if (!isEmpty(conflictErrorParams)) {
      // Message is not important here
      throw new ConflictError('User already exists', conflictErrorParams);
    }
    throw e;
  }
};
