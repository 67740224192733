import { merge, pick } from 'lodash';

import { populateFields } from '../../../../../forms/populateFields';
import { fields } from '../../../../../forms/user';
import { Signal } from '../../../../common/constants/signals';
import { AccountState } from '../../common/unified-workflow-context.interface';
import BaseModel from '../common/BaseModel';

const model = (send: any, { context: { user, coApplicant } }: AccountState) => {
  const accountFields = pick(fields, [
    'firstName',
    'lastName',
    'dob',
    'grossIncome',
  ]);
  const savedFields = populateFields(accountFields, user);
  const populatedFields = merge({}, accountFields, savedFields);
  const progress = coApplicant.coApplicantAnswer ? 81 : 92;

  return merge({}, BaseModel, {
    template: {
      header: {
        showBack: true,
        onBack: () => {
          send(Signal.Previous);
        },
      },
    },
    headerBlock: {
      progressBar: {
        progress,
        withContainer: true,
      },
      title: 'Edit applicant details',
      subtitle: ['Account', `${progress}% complete`],
    },
    form: {
      fields: populatedFields,
      actions: {
        primary: {
          testId: 'save',
          label: 'Save',
          handler: (data: any) => {
            send(Signal.Next, { data: { user: merge({}, user, data) } });
          },
        },
        secondary: {
          testId: 'cancel',
          label: 'Cancel',
          handler: () => {
            send(Signal.Previous);
          },
        },
      },
      autoFocus: true,
      withConfirmModal: true,
    },
    info: {
      title: 'Review applicant details',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'Does everything look correct? Use this time to correct any mistakes or confirm these details, as accurate information will allow for the smoothest application process possible.',
          ],
        },
      ],
    },
  });
};

export default model;
