import { get, merge } from 'lodash';
import { useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import TagManager from 'react-gtm-module';

import { PillowForm } from '../../../../../components/PillowForm';
import { AGREEMENT_STATUSES } from '../../../../../constants/choices/checklight';
import { useAPI } from '../../../../../hooks/useAPI/useAPI';
import { Signal } from '../../XState/Model';

const Controller = (props: any) => {
  const { presModel, send, context: workflowDetails } = props;
  const {
    currentUser,
    coApplicant,
    currentVehicle: { id: vehicleId },
  } = workflowDetails || {};

  const api = useAPI();

  const onSubmit = useAsyncCallback(() => {
    const payload = {
      ...coApplicant,
      ...(coApplicant.residences.sameAsApplicant === AGREEMENT_STATUSES.YES
        ? {
            residences: {
              sameAsApplicant: coApplicant.residences.sameAsApplicant,
              ...workflowDetails?.currentUser?.residences,
            },
          }
        : {}),
    };
    return api
      .post(`/vehicles/${vehicleId}/application/co-applicant`, payload)
      .then(() => {
        /**
         * User will not be redirected to the review gate because
         * SSN has already been provided for both applicant and
         * co-applicant. Call endpoint to update the application
         * status appropriately
         */
        const applicantHasSSN =
          get(currentUser, 'personalInfo.hasSSN') === true;
        const coApplicantHasSSN =
          get(coApplicant, 'personalInfo.hasSSN') === true;

        if (applicantHasSSN && coApplicantHasSSN) {
          return api
            .post(`/vehicles/${vehicleId}/application`, {
              hasSSN: true,
            })
            .then(() =>
              TagManager.dataLayer({
                dataLayer: {
                  event: 'user-submitted-no-ssn',
                },
              }),
            );
        }
      })
      .then(() => {
        send(Signal.Next);
      })
      .catch((e: any) => {
        console.error(
          'An unexpected exception occurred while attempting to save an co-applicant data',
          e,
        );
        throw e;
      });
  });

  const enhancedProps = useMemo(
    () =>
      merge(
        {},
        props,
        { presModel },
        {
          presModel: {
            template: {
              header: {
                onBack: () => {
                  send('Previous');
                },
              },
            },
            form: {
              actions: {
                primary: {
                  handler: onSubmit.execute,
                  isDisabled: onSubmit.loading,
                },
              },
              globalErrors: onSubmit.error,
            },
          },
        },
      ),
    [props, presModel, onSubmit, send],
  );

  return <PillowForm {...enhancedProps} />;
};

Controller.displayName = 'CoApplicantEmploymentReview.Controller';
export default Controller;
