import { common, theme, unit } from '../../../common/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

export const Card = styled(
  'div',
  ({ $isMobile, $isVertical, $theme }: any) => ({
    border: `1px solid ${$theme.colors.secondaryOverlay}`,
    padding: unit[3],
    borderRadius: unit[2],
    display: 'flex',
    ...($isMobile || $isVertical ? { flexDirection: 'column' } : {}),
  }),
);

export const ImageContainer = styled(
  'div',
  ({ $isMobile, $isVertical, $isTablet, $theme }: any) => ({
    borderRadius: unit[1],
    backgroundColor: $theme.colors.tertiaryOverlay,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ...($isVertical || $isMobile
      ? { marginBottom: unit[3] }
      : $isTablet
      ? {
          marginRight: unit[3],
        }
      : {
          marginRight: '60px',
        }),
    ...($isVertical && !$isMobile
      ? {
          width: '276px',
          height: '207px',
        }
      : $isMobile
      ? {
          width: '100%',
          height: '208px',
        }
      : {
          width: '100%',
          maxWidth: '240px',
          height: '240px',
        }),
  }),
);

export const Image = styled('img', () => ({
  width: '100%',
  height: '100%',
}));

export const ContentContainer = styled('div', ({ $isMobile }: any) => ({
  flex: $isMobile ? '1 0 auto' : '1 0 0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: !$isMobile ? `${unit[3]} 0 ${unit[3]}` : '',
}));

export const ButtonGroupContainer = styled(
  'div',
  ({ $isVerticallyAligned }: any) => ({
    marginTop: $isVerticallyAligned ? unit[3] : 'auto',
  }),
);

export const CardTitle = styled('h3', () => ({
  fontFamily: common.font.family.Merriweather,
  fontWeight: 400,
  fontSize: common.font.size.headingLarge,
  margin: `0 0 ${unit[2]}`,
}));

export const CardDescription = styled('h5', ({ $theme }: any) => ({
  ...theme.fonts.smallTitle,
  color: $theme.colors.primaryActive,
  fontWeight: 400,
  margin: '0',
  display: '-webkit-box',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '-webkit-line-clamp': 3,
  '-webkit-box-orient': 'vertical',
}));
