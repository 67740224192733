import { unit } from '../../../common/theme/theme';
import { styled } from '../../../providers/context/StyletronProvider/withStyletronProvider';

// BackgroundFillSquare is only to fill in the left and right gaps with the same background
// color as the vehicle image. There was a situation where the size and position of the vehicle
// box was creating extra space on the right that allowed for minor scrolling
export const BackgroundFillSquare = styled('div', ({ $theme }: any) => ({
  position: 'absolute',
  left: `-${unit[3]}`,
  width: `calc(100% + ${unit[6]})`,
  height: '100%',
  backgroundColor: $theme.colors.tertiaryOverlay,
  zIndex: '-1',
}));

export const GoBack = styled('div', ({ $isMobile }: any) => ({
  ...($isMobile
    ? {
        width: `calc(100% - ${unit[6]})`,
        position: 'absolute',
      }
    : {}),
  display: 'flex',
}));

export const EditGoBack = styled('div', ({ $transparent }: any) => ({
  position: 'absolute',
  height: unit[11],
  display: 'flex',
  alignItems: 'center',
  ...($transparent
    ? {}
    : {
        backgroundColor: 'white',
      }),
  zIndex: 10,
}));

export const LogoContainer = styled('img', {
  height: '32px',
});

export const TabsContainer = styled('div', {
  margin: [unit[2], 0, unit[6]].join(' '),
});

export const HeaderContainer = styled(
  'div',
  ({
    $hideBorderBottom,
    $theme,
  }: {
    $hideBorderBottom?: boolean;
    $theme?: any;
  }) => ({
    margin: [unit[11], 0, unit[6]].join(' '),
    ...($hideBorderBottom
      ? {}
      : {
          borderBottom: `1px solid ${$theme.colors.secondaryOverlay}`,
          paddingBottom: unit[6],
        }),
  }),
);

export const IconWrapper = styled('div', ({ $isMobile }: any) => ({
  alignItems: 'center',
  display: 'flex',
  height: unit[11],
  zIndex: 10,
  ...($isMobile ? {} : { backgroundColor: 'white' }),
}));

export const LogoWrapper = styled('div', () => ({
  alignItems: 'center',
  display: 'flex',
  height: unit[11],
  justifyContent: 'center',
  paddingRight: unit[3], // compensate for 24 px back icon
  width: '100%',
  zIndex: 10,
}));

export const VehicleHeaderContainer = styled('div', () => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexDirection: 'column',
  position: 'relative',
  marginBottom: unit[6],
  backgroundColor: 'white',
  zIndex: 1,
}));

export const ImageContainer = styled('div', ({ $isMobile, $theme }: any) => ({
  ...($isMobile
    ? {
        height: '333px',
        padding: `${unit[9]} ${unit[4]}`,
        backgroundColor: $theme.colors.tertiaryOverlay,
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: unit[3],
        maxWidth: 'auto',
      }
    : {
        position: 'absolute',
        right: 0,
        bottom: 0,
        maxWidth: unit[19],
      }),
}));

export const HeaderBorder = styled('div', ({ $theme }: any) => ({
  borderBottom: `1px solid ${$theme.colors.secondaryOverlay}`,
  marginBottom: unit[6],
}));

export const TitleContainer = styled('div', ({ $isMobile }: any) => ({
  width: $isMobile ? '100%' : `calc(100% - ${unit[20]})`,
}));
