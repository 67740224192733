import { merge } from 'lodash';

import { withContext } from '../../../../../utils/workflow/withContext';
import { incomeFormConfig } from '../../../../common/Steps/ApplicantIncome/Model';
import { applicantBaseModel } from '../../../../common/Steps/common/ApplicantBaseModel';
import { getIncomesList } from '../../../UnifiedDefaultApplicant/Steps/ReviewIncome/utils';
import { Signal } from '../../XState/Model';
import BaseModel from '../common/BaseModel';

const model = (send: any, { context }: any) => {
  const options = getIncomesList(context.coApplicant.incomes);
  return merge(
    {},
    applicantBaseModel,
    BaseModel,
    incomeFormConfig(send, options, Signal),
    {
      headerBlock: {
        title: 'Do they have another source of income?',
        subtitle: ['Co-Applicant', '80% complete'],
        progressBar: {
          progress: 80,
          withContainer: true,
        },
      },
      info: {
        title: 'Co-applicant income details',
        content: [
          {
            copy: [
              'Many receive a source of income outside of standard W2 and 1099 Employment, please tell us what those sources may be.',
            ],
          },
        ],
      },
      form: {
        actions: {
          primary: {
            label: 'Continue',
          },
        },
      },
    },
  );
};

const modalFn = (...args: [any, any]) =>
  withContext({ model: model(...args) })(...args);

export default modalFn;
