import { merge } from 'lodash';

import { additionalIncomeFields } from '../../../../../utils/helpers/income/unified';
import BaseModel from '../common/BaseModel';

const model = merge({}, BaseModel, {
  form: {
    fields: additionalIncomeFields,
    actions: {
      primary: {
        label: 'Save',
        testId: 'save',
      },
      secondary: {
        label: 'Remove',
        testId: 'remove',
      },
    },
    withConfirmModal: true,
  },
  info: {
    title: 'Review income details',
    subtitle: 'Help',
    content: [
      {
        copy: [
          'Does everything look correct? Use this time to correct any mistakes or confirm these details, as accurate information will allow for the smoothest application process possible.',
        ],
      },
    ],
  },
  headerBlock: {
    title: 'Edit your income details.',
    subtitle: ['Applicant', '80% complete'],
    progressBar: {
      progress: 80,
      withContainer: true,
    },
  },
});

export default model;
