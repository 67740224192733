import { PillowType } from '../../../../components/shared/PillowImage/pillowTypes';

export const applicantBaseModel = {
  template: {
    header: {
      showClose: false,
      showBack: true,
    },
    footer: {
      isActive: false,
    },
    img: {
      type: PillowType.PERSONAL_DATA,
    },
  },
  form: {
    actions: {
      primary: {
        testId: 'continue',
        label: 'Continue',
      },
    },
  },
  info: {
    subtitle: 'Help',
  },
  headerBlock: {
    progressBar: {
      withContainer: true,
    },
  },
};
