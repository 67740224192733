import { FooterLink } from '../../../components/PillowForm';
import { PillowType } from '../../../components/shared/PillowImage/pillowTypes';

const model = {
  template: {
    img: {
      type: PillowType.VERIFICATION,
    },
    header: {
      showClose: false,
      showBack: true,
    },
    footer: {
      isActive: false,
      links: [FooterLink.ContactUs],
    },
  },
  form: {
    actions: {
      primary: {
        testId: 'submit',
        label: 'Submit',
      },
      secondary: {
        testId: 'cancel',
        label: 'Cancel',
      },
    },
  },
  headerBlock: {
    subtitle: 'Loan documents',
    progressBar: {
      progress: 0,
      withContainer: true,
    },
  },
};

export default model;
