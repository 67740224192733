import { useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useAPI } from '../../hooks/useAPI/useAPI';
import { useCurrentUserContext } from '../../hooks/useCurrentUserContext/useCurrentUserContext';
import { useWorkflowPathname } from '../../hooks/useWorkflowPathname/useWorkflowPathname';
import { withMatchMedia } from '../../providers/context/MatchMediaProvider/withMatchMedia';
import { FormConfirmModal } from '../FormConfirmModal/FormConfirmModal';
import { Form } from '../shared/Form/Form';
import { presModel } from './Model';
import { FormContainer } from './styles';

const HousingInfoEditComponent = ({ isDesktop }: { isDesktop?: boolean }) => {
  const navigate = useNavigate();
  const [currentUserContext, setCurrentUserContext] = useCurrentUserContext();
  const api = useAPI();
  const workflowPathname = useWorkflowPathname();
  const methods = useForm();

  const updateUserData = useAsyncCallback((data) => {
    return api.put('/users/current', data).then((user: any) => {
      const updatedContext = { ...currentUserContext, ...user };
      setCurrentUserContext(updatedContext);
      navigate('..');
    });
  });

  const enhancedForm = useMemo(() => {
    const formConfig = presModel(workflowPathname, currentUserContext);
    return {
      ...formConfig,
      actions: {
        ...formConfig.actions,
        primary: {
          ...formConfig.actions.primary,
          handler: updateUserData.execute,
          isDisabled: updateUserData.loading,
        },
        secondary: {
          ...formConfig.actions.secondary,
          handler: () => navigate('..'),
          isDisabled: updateUserData.loading,
        },
      },
      globalErrors: updateUserData.error,
    };
  }, [
    updateUserData.execute,
    updateUserData.loading,
    updateUserData.error,
    workflowPathname,
    currentUserContext,
    navigate,
  ]);

  return (
    <FormContainer $isDesktop={isDesktop}>
      <Form form={enhancedForm} methods={methods} />
      <FormConfirmModal formFields={enhancedForm.fields} methods={methods} />
    </FormContainer>
  );
};

export const HousingInfoEdit = withMatchMedia(HousingInfoEditComponent);
