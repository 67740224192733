import i18n from 'i18next';
import { get as lodashGet, isEmpty, toString } from 'lodash';
import { devtools } from 'zustand/middleware';

import { AuthType, HttpStatusCode } from '../common';
import useApiStore from './apiStore';
import useAttributionStore from './attributionStore';
import useParameterStore from './parameterStore';
import { create, storeDevToolOptions } from './storeManager';

export enum UserExistsExceptionCode {
  BOTH_NOT_FOUND = 'BOTH_NOT_FOUND',
  BOTH_REGISTERED = 'BOTH_REGISTERED',
  EMAIL_ADDRESS_REGISTERED = 'EMAIL_ADDRESS_REGISTERED',
  PHONE_NUMBER_REGISTERED = 'PHONE_NUMBER_REGISTERED',
}

type User = {
  firstName: string;
  id: string;
  lastName: string;
  primaryEmailAddress: string;
  primaryPhoneNumber: string;
};

type UserStateComputed = {
  hasCurrentUser: boolean;
  userEmailAddress: string;
  userFirstName: string;
  userLastName: string;
  userPhoneNumber: string;
  userTruncatedName: string;
};

export type UserState = {
  computed: UserStateComputed;
  fetchCurrentUserFailed: boolean;
  isUpdatingUser: boolean;
  user: Partial<User> | null;
  userAuthenticateFailedMessage: string | null;
  userAuthenticateLocked: boolean;
  userAuthenticateSuccess: boolean;
  userCreateFailedMessage: string | null;
  userCreateSuccess: boolean;
  userExistsException: UserExistsExceptionCode | null;
  userUpdateFailedMessage: string | null;
  userUpdateSuccess: boolean;

  clearUserUpdate(): void;
  fetchCurrentUser: () => Promise<void>;
  setUserFirstName: (value: string) => void;
  setUserLastName: (value: string) => void;
  userAuthenticate: (
    primaryPhoneNumber: string,
    pin: string,
    leadChannelCode: string | null | undefined,
    purpose: AuthType,
    autopayNumber?: string,
  ) => Promise<void>;
  userCreate: (
    firstName: string,
    lastName: string,
    primaryEmailAddress: string,
    primaryPhoneNumber: string,
    pin: string,
  ) => Promise<void>;
  userExists: (
    primaryPhoneNumber: string,
    primaryEmailAddress: string,
  ) => Promise<void>;
  userUpdate: () => Promise<void>;
};

const useUserStore = create({ hmrPersist: 'userStore' })(
  devtools<UserState>(
    (set, get) => ({
      fetchCurrentUserFailed: false,
      isUpdatingUser: false,
      user: null,
      userAuthenticateFailedMessage: null,
      userAuthenticateLocked: false,
      userAuthenticateSuccess: false,
      userCreateFailedMessage: null,
      userCreateSuccess: false,
      userExistsException: null,
      userUpdateFailedMessage: null,
      userUpdateSuccess: false,

      computed: {
        get hasCurrentUser() {
          return !isEmpty(get().user);
        },
        get userEmailAddress() {
          const user = get().user;
          const { primaryEmailAddress = '' } = user || {};

          return primaryEmailAddress;
        },
        get userFirstName() {
          const user = get().user;
          const { firstName = '' } = user || {};

          return firstName;
        },
        get userLastName() {
          const user = get().user;
          const { lastName = '' } = user || {};

          return lastName;
        },
        get userPhoneNumber() {
          const user = get().user;
          const { primaryPhoneNumber = '' } = user || {};

          return primaryPhoneNumber;
        },
        get userTruncatedName() {
          const user = get().user;
          const { firstName = '', lastName = '' } = user || {};

          return `${firstName} ${lastName.charAt(0)}.`;
        },
      },

      clearUserUpdate: () => {
        set({
          isUpdatingUser: false,
          userUpdateFailedMessage: null,
          userUpdateSuccess: false,
        });
      },
      fetchCurrentUser: () => {
        const radixHttpRequest = useApiStore.getState().radixApi();

        set({ fetchCurrentUserFailed: false });

        return radixHttpRequest
          .get<never, User>('/2.0/users/current')
          .then((user) => set({ user }))
          .catch(() => {
            // current user is being checked for on all routes aside from /sign-out.
            // if the user is unable to be fetched in any case, we reset the user state
            // and token to ensure the user cannot access an authenticated route in the SessionGuard.
            useParameterStore.setState({ token: null });

            set({
              fetchCurrentUserFailed: true,
              user: null,
            });
          });
      },
      setUserFirstName: (value: string) => {
        set({ user: { ...get().user, firstName: value } });
      },
      setUserLastName: (value: string) => {
        set({ user: { ...get().user, lastName: value } });
      },
      userAuthenticate: (
        primaryPhoneNumber: string,
        pin: string,
        leadChannelCode: string | null | undefined,
        purpose: AuthType,
        autopayNumber?: string,
      ) => {
        set({
          userAuthenticateFailedMessage: null,
          userAuthenticateLocked: false,
          userAuthenticateSuccess: false,
        });

        const radixHttpRequest = useApiStore.getState().radixApi();

        const {
          computed: { attributionsForLeadChannel },
        } = useAttributionStore.getState();

        return radixHttpRequest
          .post<never, { user: User }>('/2.0/session', {
            attributions: attributionsForLeadChannel,
            autopayNumber,
            includeUser: true,
            leadChannelCode,
            pin,
            primaryPhoneNumber,
            purpose,
          })
          .then(({ user }) => set({ user, userAuthenticateSuccess: true }))
          .catch((error) => {
            set({
              userAuthenticateFailedMessage: lodashGet(
                i18n.t('errors.userAuthenticate.statusMessage'),
                toString(error.response.status),
                i18n.t('errors.userAuthenticate.defaultMessage'),
              ),
              userAuthenticateLocked:
                lodashGet(error, 'response.status') ===
                HttpStatusCode.FORBIDDEN,
            });
          });
      },
      userCreate: (
        firstName: string,
        lastName: string,
        primaryEmailAddress: string,
        primaryPhoneNumber: string,
        pin: string,
      ) => {
        set({
          userCreateFailedMessage: null,
          userCreateSuccess: false,
          userExistsException: null,
        });

        const { leadChannelCode } = useParameterStore.getState();

        const radixHttpRequest = useApiStore.getState().radixApi();

        const {
          computed: { attributionsForLeadChannel },
        } = useAttributionStore.getState();

        return radixHttpRequest
          .post<never, { user: User }>('/2.0/users', {
            attributions: attributionsForLeadChannel,
            firstName,
            lastName,
            leadChannelCode,
            pin,
            primaryEmailAddress,
            primaryPhoneNumber,
          })
          .then(({ user }) => set({ user, userCreateSuccess: true }))
          .catch((error) => {
            const errorCode = lodashGet(
              error,
              'response.data._embedded.errors.0.errorCode',
              null,
            );

            if (errorCode in UserExistsExceptionCode) {
              set({ userExistsException: errorCode });
            } else {
              set({
                userCreateFailedMessage: lodashGet(
                  i18n.t('errors.userCreate.statusMessage'),
                  toString(error.response.status),
                  i18n.t('errors.userCreate.defaultMessage'),
                ),
              });
            }
          });
      },
      userExists: (primaryPhoneNumber: string, primaryEmailAddress: string) => {
        set({ userExistsException: null });

        const radixHttpRequest = useApiStore.getState().radixApi();

        return radixHttpRequest
          .get(`/2.0/users`, {
            params: {
              primaryEmailAddress,
              primaryPhoneNumber,
            },
          })
          .then(() => {})
          .catch((error) => {
            const errorCode = lodashGet(
              error,
              'response.data._embedded.errors.0.errorCode',
              null,
            );

            set({ userExistsException: errorCode });
          });
      },
      userUpdate: () => {
        const {
          computed: { userFirstName: firstName, userLastName: lastName },
        } = get();

        const radixHttpRequest = useApiStore.getState().radixApi();

        set({
          isUpdatingUser: true,
          userUpdateFailedMessage: null,
          userUpdateSuccess: false,
        });

        return radixHttpRequest
          .patch<never, User>('/2.0/users/current', {
            firstName,
            lastName,
          })
          .then((user) => set({ user, userUpdateSuccess: true }))
          .catch((error) => {
            const errorCode = lodashGet(
              error,
              'response.data._embedded.errors.0.errorCode',
              null,
            );

            if (errorCode in UserExistsExceptionCode) {
              set({ userExistsException: errorCode });
            } else {
              set({
                userUpdateFailedMessage: i18n.t(
                  'errors.userUpdate.defaultMessage',
                ),
              });
            }
          })
          .finally(() => set({ isUpdatingUser: false }));
      },
    }),
    storeDevToolOptions('userStore'),
  ),
);

export default useUserStore;
