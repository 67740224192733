import { font, theme } from '../../../../../common/theme';
import { styled } from '../../../../../providers/context/StyletronProvider/withStyletronProvider';

export const Container = styled('div', ({ $theme }: any) => ({
  borderTop: `1px solid ${$theme.colors.secondaryOverlay}`,
  fontSize: font.size.titleSmall,
  fontFamily: font.family.Merriweather,
  lineHeight: font.lineHeight.titleMedium,
  padding: [theme.unit[6], 0].join(' '),
}));
