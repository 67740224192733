export enum ChecklightSessionStatus {
  APPLICANT_COMPLETE = 'APPLICANT_COMPLETE',
  APPROVED_BY_LENDER = 'APPROVED_BY_LENDER',
  CANCELLED = 'CANCELLED',
  CANCELLED_CONSUMER = 'CANCELLED_CONSUMER',
  CO_APPLICANT_COMPLETE = 'CO_APPLICANT_COMPLETE',
  DUPLICATE = 'DUPLICATE',
  EXPIRED = 'EXPIRED',
  FINALIZE_STIPS = 'FINALIZE_STIPS',
  FUNDED = 'FUNDED',
  HAS_OFFERS = 'HAS_OFFERS',
  NO_LOAN = 'NO_LOAN',
  NO_OFFERS = 'NO_OFFERS',
  PENDING_SUBMISSION = 'PENDING_SUBMISSION',
  PREPARING_CONTRACTS = 'PREPARING_CONTRACTS',
  REVIEWING_DOCUMENTS = 'REVIEWING_DOCUMENTS',
  SELECTED_OFFER = 'SELECTED_OFFER',
  SIGN_CONTRACTS = 'SIGN_CONTRACTS',
  SUBMITTED_TO_LENDER = 'SUBMITTED_TO_LENDER',
  WAITING_ON_FUNDING = 'WAITING_ON_FUNDING',
  LOAN_DOCUMENTS_STARTED = 'LOAN_DOCUMENTS_STARTED',
  LOAN_DOCUMENTS_PROVIDED = 'LOAN_DOCUMENTS_PROVIDED',
}

export enum LoanDecisionStatus {
  Approved = 'Approved',
}

// CAUTION: Adding values to this map can have unexpected effects
// Example, `NEW_LEAD` is not in this mapping because code in other places
// assumes that if the status is not in this list, it means we do not need to
// update the status (example: the `getFreshLeadApplications` method)
//
// CAUTION: Adding `PENDING_SUBMISSION` as _any_ mapped value here will
// introduce far-ranging issues, avoid unless you intend to address the fallout
//
// CAUTION: DO NOT TOUCH THIS OBJECT UNLESS YOU WANT TO SPEND A DAY+ DEBUGGING A
// RANDOM ISSUE THAT HAS NOTHING TO DO WITH YOUR ACTUAL CHANGES
export const LOS_CHECKLIGHT_STATUSES = {
  APPLICATION_AT_LENDER: ChecklightSessionStatus.SUBMITTED_TO_LENDER,
  APPLICATION_COMPLETE_PORTAL: ChecklightSessionStatus.SUBMITTED_TO_LENDER,
  APPLICATION_COMPLETE: ChecklightSessionStatus.SUBMITTED_TO_LENDER,
  APPLICATION_EXPIRED: ChecklightSessionStatus.EXPIRED,
  APPROVED_BY_LENDER: ChecklightSessionStatus.APPROVED_BY_LENDER,
  ARCHIVE: ChecklightSessionStatus.REVIEWING_DOCUMENTS,
  AT_LENDER_FOR_FUNDING: ChecklightSessionStatus.WAITING_ON_FUNDING,
  AT_LENDER_FUNDING_PENDING: ChecklightSessionStatus.WAITING_ON_FUNDING,
  AUTOPAY_FUNDED: ChecklightSessionStatus.FUNDED,
  CANT_REACH_CUSTOMER: ChecklightSessionStatus.CANCELLED,
  CONTRACT_REQUESTED: ChecklightSessionStatus.PREPARING_CONTRACTS,
  CUSTOMER_DECLINED_OFFER: ChecklightSessionStatus.CANCELLED_CONSUMER,
  CUSTOMER_NOT_APPROVED: ChecklightSessionStatus.CANCELLED,
  DEAL_RETURNED_BY_LENDER: ChecklightSessionStatus.WAITING_ON_FUNDING,
  DEALER_HOLD: ChecklightSessionStatus.WAITING_ON_FUNDING,
  DO_NOT_CONTACT: ChecklightSessionStatus.CANCELLED,
  DOCS_AT_STATE: ChecklightSessionStatus.FUNDED,
  DOCS_WITH_CUSTOMER: ChecklightSessionStatus.SIGN_CONTRACTS,
  DUPLICATE: ChecklightSessionStatus.DUPLICATE,
  EDOCS_SIGNED_DELIVERED: ChecklightSessionStatus.REVIEWING_DOCUMENTS,
  EDOCS_SIGNED_TRACKING: ChecklightSessionStatus.REVIEWING_DOCUMENTS,
  EDOCS_SIGNED: ChecklightSessionStatus.SIGN_CONTRACTS,
  FINANCED_WITH_OTHER: ChecklightSessionStatus.CANCELLED,
  FUNDING_DELAYED: ChecklightSessionStatus.WAITING_ON_FUNDING,
  FUNDING_HOLD: ChecklightSessionStatus.WAITING_ON_FUNDING,
  LEAD_EXPIRED: ChecklightSessionStatus.EXPIRED,
  LENDER_FUNDED_PENDING: ChecklightSessionStatus.FUNDED,
  LENDER_FUNDED: ChecklightSessionStatus.FUNDED,
  NO_OFFER_LTV: ChecklightSessionStatus.CANCELLED,
  NO_OFFER_OPTIMAL: ChecklightSessionStatus.CANCELLED,
  NO_OFFER_TERMS: ChecklightSessionStatus.CANCELLED,
  NO_OFFER: ChecklightSessionStatus.CANCELLED,
  PAYOFF_SENT: ChecklightSessionStatus.FUNDED,
  PERFECTING_TITLE_AUTOPAY: ChecklightSessionStatus.FUNDED,
  PERFECTING_TITLE_DEALER: ChecklightSessionStatus.FUNDED,
  QA_HOLD: ChecklightSessionStatus.REVIEWING_DOCUMENTS,
  QA: ChecklightSessionStatus.FINALIZE_STIPS,
  RECONTRACTING: ChecklightSessionStatus.PREPARING_CONTRACTS,
  RETURN_FROM_UW: ChecklightSessionStatus.SUBMITTED_TO_LENDER,
  RETURNED_BY_CONTRACTING: ChecklightSessionStatus.SIGN_CONTRACTS,
  RETURNED_BY_QA: ChecklightSessionStatus.REVIEWING_DOCUMENTS,
  SENT_TO_LEAD_PARTNER: ChecklightSessionStatus.FUNDED,
  STIP_QA: ChecklightSessionStatus.FINALIZE_STIPS,
  STIPS_REQUIRED: ChecklightSessionStatus.FINALIZE_STIPS,
  STIPULATIONS_NOT_FULFILLED: ChecklightSessionStatus.CANCELLED,
  TITLE_DOCS_QA: ChecklightSessionStatus.REVIEWING_DOCUMENTS,
  TITLE_DOCS_WITH_CUSTOMER: ChecklightSessionStatus.SIGN_CONTRACTS,
  TITLE_PERFECTED: ChecklightSessionStatus.FUNDED,
  TITLE_QA: ChecklightSessionStatus.FUNDED,
  UNWIND_DEAL: ChecklightSessionStatus.CANCELLED,
  UW_PROCESSING: ChecklightSessionStatus.SUBMITTED_TO_LENDER,
  WORKING_LEAD: ChecklightSessionStatus.SELECTED_OFFER,
};

/**
 * An application in one of these statuses means the application is considered
 * a "lead" and has not been worked yet.
 *
 * NOTE: The CP is free to change the status of an application if they are in
 * one of these statuses
 */
export const LOS_LEAD_STATUSES = ['NEW_LEAD', 'RETURN_FROM_UW', 'WORKING_LEAD'];

/**
 * An application in one of these statuses means the application has moved past
 * the "lead" category and is being worked by a BDR or LS.
 *
 * NOTE: The CP should not change the status of an application if they are in
 * one of these statuses
 */
export const LOS_APPLICATION_STATUSES = [
  'APPLICATION_AT_LENDER',
  'APPLICATION_COMPLETE_PORTAL',
  'APPLICATION_COMPLETE',
  'APPROVED_BY_LENDER',
  'UW_PROCESSING',
];

/**
 * An application in one of these statuses means the application has moved
 * past the "application" category and is in the process of having contracts
 * created and signed.
 *
 * NOTE: The CP should not change the status of an application if they are in
 * one of these statuses
 */
export const LOS_CONTRACTING_STATUSES = [
  'ARCHIVE',
  'CONTRACT_REQUESTED',
  'DOCS_WITH_CUSTOMER',
  'EDOCS_SIGNED_TRACKING',
  'EDOCS_SIGNED',
  'QA_HOLD',
  'RECONTRACTING',
  'RETURNED_BY_CONTRACTING',
  'RETURNED_BY_QA',
];

/**
 * An application in one of these statuses means the application has moved
 * past the "contracting" category and is in the process of having the
 * new loan funded
 *
 * NOTE: The CP should not change the status of an application if they are in
 * one of these statuses
 */
export const LOS_FULFILLMENT_STATUSES = [
  'AT_LENDER_FOR_FUNDING',
  'AT_LENDER_FUNDING_PENDING',
  'DEAL_RETURNED_BY_LENDER',
  'FUNDING_DELAYED',
  'FUNDING_HOLD',
  'QA',
];

/**
 * An application in one of these statuses means the application has moved
 * past the "fulfillment" category and the new loan is funded (or will be shortly)
 *
 * NOTE: The CP should not change the status of an application if they are in
 * one of these statuses
 */
export const LOS_FUNDED_STATUSES = [
  'DOCS_AT_STATE',
  'LENDER_FUNDED_PENDING',
  'LENDER_FUNDED',
  'PERFECTING_TITLE_AUTOPAY',
  'PERFECTING_TITLE_DEALER',
  'TITLE_PERFECTED',
];

/**
 * An application in one of these statuses means the application has moved
 * past the "funded" category and the new loan has been successfully completed
 *
 * Applications in this status should not be considered "active" and new
 * application submissions should not be marked as duplicate for the same
 * vehicle unless the submission is within a certain time period (i.e. 90 days)
 *
 * NOTE: The CP should not change the status of an application if they are in
 * one of these statuses
 */
export const LOS_COMPLETED_STATUSES = ['PAYOFF_SENT'];

/**
 * An application in one of these statuses means the application has been
 * cancelled, is a duplicate or the customer is not interested or couldn't
 * qualify for a better deal
 *
 * NOTE: The CP should not change the status of an application if they are in
 * one of these statuses
 */
export const LOS_CANCELLED_STATUSES = [
  'APPLICATION_EXPIRED',
  'CANT_REACH_CUSTOMER',
  'CUSTOMER_DECLINED_OFFER',
  'CUSTOMER_NOT_APPROVED',
  'DO_NOT_CONTACT',
  'DUPLICATE',
  'FINANCED_WITH_OTHER',
  'LEAD_EXPIRED',
  'NO_OFFER_LTV',
  'NO_OFFER_OPTIMAL',
  'NO_OFFER_TERMS',
  'NO_OFFER',
  'STIPULATIONS_NOT_FULFILLED',
  'UNWIND_DEAL',
];

/**
 * An application in one of these statuses means the application is likely very
 * old as these statuses are no longer being used
 *
 * NOTE: The CP should not change the status of an application if they are in
 * one of these statuses
 */
export const LOS_LEGACY_STATUSES = [
  'APPLICATION_AT_AUTOPAY',
  'AUTOPAY_FUNDED',
  'CHECKLIGHT_INITIAL_DATA_CANCELED',
  'DEALER_HOLD',
  'EDOCS_SIGNED_DELIVERED',
  'HOT_LEAD',
  'SENT_TO_LEAD_PARTNER',
  'STIP_QA',
  'STIPS_REQUIRED',
  'TITLE_DOCS_QA',
  'TITLE_DOCS_WITH_CUSTOMER',
  'TITLE_QA',
  'WARM_LEAD',
];

// These are the statues we currently have available for lead channel's to
// assign to an application after being imported to LOS
export const LEAD_CHANNEL_IMPORT_STATUSES = [
  'NEW_LEAD',
  'APPLICATION_COMPLETE_PORTAL',
];

// Order of active statuses in radix
// This is used to ensure we are not moving a status "backwards" unexpectedly
export const RADIX_ACTIVE_STATUS_ORDER = [
  'PENDING_SUBMISSION',
  'NO_OFFERS',
  'HAS_OFFERS',
  'SELECTED_OFFER',
  'APPLICANT_COMPLETE',
  'CO_APPLICANT_COMPLETE',
  'SUBMITTED_TO_LENDER',
  'APPROVED_BY_LENDER',
  'PREPARING_CONTRACTS',
  'SIGN_CONTRACTS',
  'REVIEWING_DOCUMENTS',
  'FINALIZE_STIPS',
  'WAITING_ON_FUNDING',
  'FUNDED',
];
