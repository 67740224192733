class HttpError extends Error {
  toJSON: () => any;

  // standard http "status codes"
  statusCode?: number;

  // very "specific" codes we are exposing
  errorCode?: string;

  // This is the message we want to expose to our clients however.
  // we prefer this value always over the message property.
  publicErrorMessage?: string;

  // any additional payload data we want to set.
  payload: any;

  // a payload that we will serialize and return with this error
  publicPayload: any;

  // a list of errors that this errors that are associated... when more then one error.. ie: during validation
  errorList?: any[];

  // a flag which informs us if we need to sanitize this error response
  sanitize?: boolean;

  // the original error that gives us more information for logs
  originalError?: any;

  constructor(
    message: string,
    statusCode?: number,
    errorCode?: string,
    publicErrorMessage?: string,
    originalError?: any,
  ) {
    super(message);
    this.statusCode = statusCode || undefined;
    this.errorCode = errorCode || undefined;
    this.publicErrorMessage = publicErrorMessage;
    this.originalError = originalError;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }

    this.toJSON = () => ({
      statusCode: this.statusCode,
      errorCode: this.errorCode,
      // [NOTE] [INVESTIGATE]
      // when doing the refactor to use this we noticed that almost all error messages are meaningful
      // this.message is coming through when we output.
      // routesService.errorPayload transforms and pulls out relevant data...  it does not call toJSON
      message: this.publicErrorMessage || this.message,
      ...(this.publicPayload ? { publicPayload: this.publicPayload } : {}),
    });
  }
}

export default HttpError;
