export enum AdditionalActions {
  FETCH_IMAGES = 'FETCH_IMAGES',
}

export enum UserRelatedEntities {
  CURRENT_EMPLOYMENT = 'CURRENT_EMPLOYMENT',
  CURRENT_RESIDENCE = 'CURRENT_RESIDENCE',
  PERSONAL_INFORMATION = 'PERSONAL_INFORMATION',
  ALL_INCOME = 'ALL_INCOME',
}

export enum IncomeType {
  PRIMARY_INCOME = 'PRIMARY_INCOME',
  ADDITIONAL_INCOME = 'ADDITIONAL_INCOME',
  PREVIOUS_INCOME = 'PREVIOUS_INCOME',
}

export enum RateSelectedErrors {
  MISSING_APPLICATION_KEY = 'MISSING_APPLICATION_KEY',
  INVALID_APPLICATION_KEY = 'INVALID_APPLICATION_KEY',
  MISSING_ENCODED_DATA = 'MISSING_ENCODED_DATA',
  APPLICATION_KEY_MISSING_OR_EXPIRED = 'APPLICATION_KEY_MISSING_OR_EXPIRED',
  INVALID_RATE_SELECTED = 'INVALID_RATE_SELECTED',
  LOS_IMPORT_ERROR = 'LOS_IMPORT_ERROR',
}

export type TrimT = { year: number; makeId: string; modelId: string };
export type PlateStateT = { plate: string; state: string };
export type VinT = { vin: string };
export type MakeT = { year: number };
export type ModelT = { year: number; makeId: string };

export type ResContainer = {
  [keys: string]: any;
};
