import 'react-app-polyfill/stable';

import { createRoot } from 'react-dom/client';

import App from './App';
import { REACT_ROOT_NODE_ID } from './constants/rootNode';
import reportWebVitals from './reportWebVitals';
import useAnalyticStore from './store/analyticStore';
import useAttributionStore from './store/attributionStore';
import useParameterStore from './store/parameterStore';
import { initializeGoogleTagManager } from './utils/analytics/googleTagManager';
import { initialize as initThirdPartyAssets } from './utils/thirdPartyAssets';
import { initializeWorkflowInspect } from './utils/workflow/inspect';

const renderApplication = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  createRoot(document.getElementById(REACT_ROOT_NODE_ID)!).render(<App />);
};

// all dependencies necessary for this application.
const applicationDependencies = [
  initializeGoogleTagManager,
  initThirdPartyAssets,
  initializeWorkflowInspect,
];

Promise.all(applicationDependencies.map((d) => d())).then(renderApplication);

const urlSearchParams = new URLSearchParams(window.location.search);

// initialize parameter store states based on URL seach params
useParameterStore.getState().setFromURLSearchParams(urlSearchParams);

// initialize attribution store based on URL search params
useAttributionStore.getState().setFromURLSearchParams(urlSearchParams);

// initialize analytic tracking
useAnalyticStore.getState().initializeAnalytics();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
