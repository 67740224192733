import { pick } from 'lodash';
import { URLSearchParams } from 'url';
import { devtools, persist } from 'zustand/middleware';

import { NonAuthRedirectPath } from './routeStore';
import { create, storeDevToolOptions } from './storeManager';
import useUserStore from './userStore';

type ParameterStore = {
  apr?: string | null;
  authType?: string | null;
  autopayNumber?: string | null;
  coApplicantCollectAgreeToTerms?: string | null;
  collectAgreeToTerms?: string | null;
  error?: string | null;
  firstName?: string | null;
  hasDuplicateApplication?: string | null;
  intent?: keyof typeof NonAuthRedirectPath | null;
  leadChannelCode?: string | null;
  migrateUser?: string | null;
  monthlyPaymentAmount?: string | null;
  phoneNumber?: string | null;
  term?: string | null;
  token?: string | null;
  vehicleId?: string | null;

  setFromURLSearchParams: (searchParams: URLSearchParams) => void;
};

const keysToPersist = [
  'apr',
  'authType',
  'autopayNumber',
  'coApplicantCollectAgreeToTerms',
  'collectAgreeToTerms',
  'error',
  'firstName',
  'hasDuplicateApplication',
  'leadChannelCode',
  'migrateUser',
  'monthlyPaymentAmount',
  'phoneNumber',
  'term',
  'token',
  'vehicleId',
];

const useParameterStore = create({ ignoreReset: true })(
  devtools(
    persist<ParameterStore>(
      (set, get) => ({
        apr: null,
        authType: null,
        autopayNumber: null,
        coApplicantCollectAgreeToTerms: null,
        collectAgreeToTerms: null,
        error: null,
        firstName: null,
        hasDuplicateApplication: null,
        intent: null,
        leadChannelCode: null,
        migrateUser: null,
        monthlyPaymentAmount: null,
        phoneNumber: null,
        term: null,
        token: null,
        vehicleId: null,

        setFromURLSearchParams: (searchParams: URLSearchParams) => {
          if (!searchParams) {
            return;
          }

          Object.keys(get())
            .filter((stateKey: string) => searchParams.has(stateKey))
            .forEach((stateKey: string) =>
              set({ [stateKey]: searchParams.get(stateKey) }),
            );
        },
      }),
      {
        name: 'consumer_portal:storage',

        partialize(state: ParameterStore): ParameterStore {
          // current user is available. only keep keys that are required when authenticated.
          if (useUserStore.getState().computed.hasCurrentUser) {
            return {
              hasDuplicateApplication: state.hasDuplicateApplication,
              leadChannelCode: state.leadChannelCode,
              migrateUser: state.migrateUser,
              token: state.token,
              vehicleId: state.vehicleId,
            } as ParameterStore;
          }

          return pick(state, keysToPersist) as ParameterStore;
        },
      },
    ),
    storeDevToolOptions('parameterStore'),
  ),
);

export default useParameterStore;
