import { useEffect, useMemo, useState } from 'react';

import { theme } from '../../../common/theme';
import MatchMediaContext from './MatchMediaContext';

const mediaBreakpoints = {
  mobile: `(max-width: ${theme.media.mobileMaxWidth})`,
  tablet: `(min-width: ${theme.media.tabletMinWidth}) and (max-width: ${theme.media.desktopMinWidth})`,
  desktop: `(min-width: ${theme.media.desktopMinWidth})`,
};

const breakPoints: any = {
  isMobile: {
    media: mediaBreakpoints.mobile,
    matchMedia: window.matchMedia && window.matchMedia(mediaBreakpoints.mobile),
  },
  isDesktop: {
    media: mediaBreakpoints.desktop,
    matchMedia:
      window.matchMedia && window.matchMedia(mediaBreakpoints.desktop),
  },
  isTablet: {
    media: mediaBreakpoints.tablet,
    matchMedia: window.matchMedia && window.matchMedia(mediaBreakpoints.tablet),
  },
};

// loops through our breakpoints to evaluate if any of them currently match
const evaluateCurrentMediaMatches = () =>
  Object.keys(breakPoints).reduce(
    (state, device) => ({
      ...state,
      [device]: breakPoints[device].matchMedia.matches,
    }),
    {},
  );

export const withMatchMediaProvider = (WrappedComponent: any) => {
  return (props: any) => {
    const [evaluations, setEvaluations] = useState<{
      isMobile?: boolean;
      isTablet?: boolean;
      isDesktop?: boolean;
    }>({});

    const [evaluationsReady, setEvaluationsReady] = useState(false);

    const handleMediaChange = () => {
      setEvaluations(evaluateCurrentMediaMatches());
    };

    useEffect(() => {
      Object.keys(breakPoints).forEach((key) => {
        if (!breakPoints[key].matchMedia) {
          return;
        }
        try {
          breakPoints[key].matchMedia.addEventListener(
            'change',
            handleMediaChange,
          );
        } catch (e1) {
          try {
            breakPoints[key].matchMedia.addListener(handleMediaChange);
          } catch (e2) {
            console.error(e2);
          }
        }
      });
      setEvaluations(evaluateCurrentMediaMatches());
      setEvaluationsReady(true);

      return () => {
        Object.keys(breakPoints).forEach((key) => {
          if (!breakPoints[key].matchMedia) {
            return;
          }
          try {
            breakPoints[key].matchMedia.removeEventListener(
              'change',
              handleMediaChange,
            );
          } catch (e1) {
            try {
              breakPoints[key].matchMedia.removeListener(handleMediaChange);
            } catch (e2) {
              console.error(e2);
            }
          }
        });
      };
    }, []);

    const isHeadless = useMemo(() => {
      return evaluationsReady && !Object.values(evaluations).some(Boolean);
    }, [evaluations, evaluationsReady]);

    const contextValue = useMemo(() => {
      return {
        isDesktop: isHeadless || evaluations.isDesktop,
        isTablet: evaluations.isTablet,
        isMobile: evaluations.isMobile,
        isReady: evaluationsReady,
      };
    }, [isHeadless, evaluations, evaluationsReady]);

    return (
      <MatchMediaContext.Provider value={contextValue}>
        <WrappedComponent {...props} />
      </MatchMediaContext.Provider>
    );
  };
};
