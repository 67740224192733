import { LoanTaskEnum } from '../../../constants/loanTasks';
import { Gate } from '../../common/interfaces/gate.interface';
import { defaultServiceOptions } from '../../common/utils/applicant.utils';
import { Step } from './steps';
import ExitToApplicant from './Steps/ExitToApplicant/Controller';
import ExitToDashboard from './Steps/ExitToDashboard/Controller';
import PillowController from './Steps/Pillow/Controller';
import PillowModel from './Steps/Pillow/Model';
import defaultConfiguration from './XState/Model';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const states = defaultConfiguration.states!;

export const NO_OFFERS_GATE: Gate<any> = {
  name: 'unified-default-no-offers',
  initial: Step.Pillow,
  showRoutes: true,
  cacheData: false,
  serviceOptions: defaultServiceOptions,
  accessibleForTask: LoanTaskEnum.NoOffers,
  steps: [
    {
      name: Step.Pillow,
      state: states[Step.Pillow],
      model: PillowModel,
      controller: PillowController,
      path: '',
    },
    {
      name: Step.ExitToApplicant,
      state: states[Step.ExitToApplicant],
      controller: ExitToApplicant,
    },
    {
      name: Step.ExitToDashboard,
      state: states[Step.ExitToDashboard],
      controller: ExitToDashboard,
    },
  ],
};
