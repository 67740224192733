import hoistStatics from 'hoist-non-react-statics';
import { PureComponent } from 'react';

import { ModalContext } from './ModalContext';

export const withModalContextProvider = (WrappedComponent: any) => {
  const ModalContextProvider = class extends PureComponent<
    any,
    { opened: boolean; changeModalState: (opened: boolean) => void }
  > {
    constructor(props: any) {
      super(props);
      const changeModalState = (opened: boolean) => {
        this.setState((state) => ({
          ...state,
          opened,
        }));
      };

      this.state = {
        opened: false,
        changeModalState,
      };
    }
    static wrappedComponent = WrappedComponent;
    static contextType = ModalContext;

    render() {
      return (
        <ModalContext.Provider value={this.state}>
          <WrappedComponent {...this.props} />
        </ModalContext.Provider>
      );
    }
  };

  return hoistStatics(ModalContextProvider, WrappedComponent);
};

withModalContextProvider.contextType = ModalContext;
